import styled from "styled-components";


export const ReportBarredTxt = styled.div.attrs(props => ({


    barColor:           props.barColor || "#454d5f",


    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "calc(100% - 36px)",
    height:             props.height || "auto",
    margin:             props.margin || "10px 0 0 18px",
    padding:            props.padding || "0 0 0 0",


    // fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    // fontSize:           props.fontSize || "24px",
    // fontWeight:         props.fontWeight  || "500",
    // textAlign:          props.textAlign || "center",
    // textDecoration:     props.textDecoration  || "none",
    // textColor:          props.textColor || "#454d5f",


    barFontFamily:         props.barFontFamily || 'Arial, Helvetica, sans-serif',
    barFontSize:           props.barFontSize || "11px",
    barFontWeight:         props.barFontWeight  || "550",
    barTextAlign:          props.barTextAlign || "left",
    barTextDecoration:     props.barTextDecoration  || "none",
    barTextColor:          props.barTextColor || "#454d5f",

    textFontFamily:         props.textFontFamily || 'Arial, Helvetica, sans-serif',
    textFontSize:           props.textFontSize || "16px",
    textFontWeight:         props.textFontWeight  || "500",
    textTextAlign:          props.textTextAlign || "left",
    textTextDecoration:     props.textTextDecoration  || "none",
    textTextColor:          props.textTextColor || "#454d5f",


    // border-bottom:${props => props.border};
    // border-left:${props => props.border};
    // border-right:${props => props.border};


}))`


    background-color:${props => props.backgroundColor};


    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;
    cursor:default;


    .BarText {

        width:100%;
        height:auto;
        margin:0 auto 4px auto;
        padding:7px 0 7px 0;

        font-family:${props => props.barFontFamily};
        font-size:${props => props.barFontSize};
        font-weight:${props => props.barFontWeight};
        text-align:${props => props.barTextAlign};
        text-decoration:${props => props.barTextDecoration};
        color:${props => props.barTextColor};


    }
    .Text {


        border:1px solid ${props => props.barColor};

        width:calc(100% - 2px);
        height:auto;

        padding:7px 0 4px 0;
        margin:0 auto 25px auto;

        font-family:${props => props.textFontFamily};
        font-size:${props => props.textFontSize};
        font-weight:${props => props.textFontWeight};
        text-align:${props => props.textTextAlign};
        text-decoration:${props => props.textTextDecoration};
        color:${props => props.textTextColor};
    
        line-height:1.5;
     
        
    }




`;





