import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import { 

    DonutChart,
    Hole,
    Parts

} from './Styled';



// import { 

//     handleCreateLines,
//     handleCreateBars,
//     handleCreateListItems,
//     handleCreateMarkers

// } from './Handles';



import { 

    ChartColorsA, 
    ChartColorsB, 
    ChartColorsC,
    ProfitColors

} from 'utils/components/Charts/ChartColors';




export const SvgDonutChart = (props) => {


    // console.log("HrzBarChart")
    // console.log(JSON.stringify(props,null,2));


    // -- window dims -- //
    const { 
        width,
        //height 
    } = useWindowDimensions();


    //=============================================
    // refs
    //=============================================

    // -- ref -- //
    const refChart = useRef(null);
    const refContent = useRef(null);

    

    //=============================================
    // state/effect - isLoaded
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [currentWidth, setCurrentWidth] = useState(false);  
    const [styl, setStyl] = useState(false);
    const [items, setItems] = useState([]);
    const [showHoleText, setShowHoleText] = useState(false);
    const [showChartKey, setShowChartKey] = useState(false);
    const [chartLabel, setChartLabel] = useState('');


    useEffect(() => {





        if(!isLoaded)
        {
            setIsLoaded(true);
            setShowChartKey(false);


            // -- set styl -- //
            setStyl({
                
                backgroundColor:props.data.backgroundColor,
                width:props.data.width,
                height:props.data.height,
                ring:props.data.ring,
                hole:props.data.hole

            });
            

            // // -- set items -- // 
            // var strokeColors = ChartColorsA;

            // if(props.data.colors 
            // && Object.prototype.toString.call(props.data.colors) === '[object Array]')
            // {
            //     strokeColors = props.data.colors;
            // }
            // else
            // if(Object.prototype.toString.call(props.data.colors) === '[object String]')
            // {

            //     switch(props.data.colors)
            //     {
            //     case'ChartColorsA':

            //         strokeColors = ChartColorsA;
            //     break;
            //     case'ChartColorsB':

            //         strokeColors = ChartColorsB;
            //     break;
            //     case'ChartColorsC':

            //         strokeColors = ChartColorsC;
            //     break;
            //     default:
            //     }

            // }

            // //strokeColors = props.data.colors;
            // //console.log(JSON.stringify(strokeColors,null,2));



            // // -- items sampler -- //

            // // setItems([


            // //     {
            // //         labelledby:"donut-segment-1-title donut-segment-1-desc",

            // //         titleId:"donut-segment-1-title",
            // //         title:'Dynamic Description 1',

            // //         descId:"donut-segment-1-desc",
            // //         desc:'Dynamic Description 1',

            // //         stroke:strokeColors[0],
            // //         strokeDasharray:"80 20",
            // //         strokeDashoffset:"25",

            // //     },
            // //     {

            // //         titleId:"donut-segment-1-title",
            // //         title:'Dynamic Title 2',

            // //         descId:"donut-segment-1-desc",
            // //         desc:'Dynamic Description 2',

            // //         stroke:strokeColors[1],
            // //         strokeDasharray:"20 80",
            // //         strokeDashoffset:"-55"

            // //     }


            // // ]);



            // var newItems = [];
            // if(Object.prototype.toString.call(props.data.items) === '[object Array]')
            // {

            //     var labelledby;
            //     var titleId, title;
            //     var descId, desc;
            //     var stroke, strokeDasharray, strokeDashoffset;
            //     var label;
           
            //     var array;
            //     var name, value, percent, difPercent;
            //     var i;

            //     var total = 0;
            //     const firstOffset = 25;

            //     array = props.data.items;
            //     for(i=0; i < array.length; i++)
            //     {
                
            //         //console.log(JSON.stringify(array[i],null,2));

            //         name = array[i].name;
            //         value = array[i].value;
            //         percent = array[i].percent;
            //         percent = parseFloat(percent);
            //         difPercent = (100 - percent);


            //         labelledby = "donut-segment-"+i+"-title donut-segment-1-desc";

            //         titleId = "donut-segment-"+i+"-title";
            //         title = "Title "+i;

            //         descId = "donut-segment-"+i+"-desc";
            //         desc = "Desc "+i;
                 
            //         stroke = strokeColors[i];
            //         strokeDasharray = percent+" "+difPercent;
                
            //         if(i===0)
            //         {
            //             strokeDashoffset = firstOffset;
            //         }else{
            //             //strokeDashoffset = -`${100 - preSegmentsTotalLength + firstSegmentOffset}`;
            //             strokeDashoffset = 175 + (50-total);
            //         }

            //         total = total + percent;



            //         label = percent+"%";
            //         title = name+" "+value+" ( "+percent+"% )";
            //         desc = label;



            //         // console.log("-----------------"
            //         //     +"\npercent: "+percent
            //         //     +"\ndifPercent: "+difPercent

            //         //     +"\n---VARS---"

            //         //     +"\nfirstOffset: "+firstOffset
            //         //     +"\ntotal: "+total
                      
            //         //     +"\n---STROKE---"
            //         //     +"\nstroke: "+stroke
            //         //     +"\nstrokeDasharray: "+strokeDasharray
            //         //     +"\nstrokeDashoffset: "+strokeDashoffset
                        
            //         // )

         
            //         newItems.push({
     
            //             labelledby:labelledby,

            //             titleId:titleId,
            //             title:title,

            //             descId:descId,
            //             desc:desc,

            //             stroke:stroke,
            //             strokeDasharray:strokeDasharray,
            //             strokeDashoffset:strokeDashoffset,

            //             label:label


            //         });
    

            //     }

            // }

            // setItems(newItems);
            // setShowHoleText(true);



        }else{



            // -- set items -- // 
            var strokeColors = ChartColorsA;

            if(props.data.colors 
            && Object.prototype.toString.call(props.data.colors) === '[object Array]')
            {
                strokeColors = props.data.colors;
            }
            else
            if(Object.prototype.toString.call(props.data.colors) === '[object String]')
            {

                switch(props.data.colors)
                {
                case'ChartColorsA':

                    strokeColors = ChartColorsA;
                break;
                case'ChartColorsB':

                    strokeColors = ChartColorsB;
                break;
                case'ChartColorsC':

                    strokeColors = ChartColorsC;
                break;
                case'ProfitColors':

                    strokeColors = ProfitColors;
                break;
                default:

                    strokeColors = Object.prototype.toString.call(props.data.colors) === '[object Array]' ? props.data.colors : null;
                }

            }

            //strokeColors = props.data.colors;
            //console.log(JSON.stringify(strokeColors,null,2));

            // -- items sampler -- //

            // setItems([


            //     {
            //         labelledby:"donut-segment-1-title donut-segment-1-desc",

            //         titleId:"donut-segment-1-title",
            //         title:'Dynamic Description 1',

            //         descId:"donut-segment-1-desc",
            //         desc:'Dynamic Description 1',

            //         stroke:strokeColors[0],
            //         strokeDasharray:"80 20",
            //         strokeDashoffset:"25",

            //     },
            //     {

            //         titleId:"donut-segment-1-title",
            //         title:'Dynamic Title 2',

            //         descId:"donut-segment-1-desc",
            //         desc:'Dynamic Description 2',

            //         stroke:strokeColors[1],
            //         strokeDasharray:"20 80",
            //         strokeDashoffset:"-55"

            //     }


            // ]);



            var newItems = [];
            if(Object.prototype.toString.call(props.data.items) === '[object Array]')
            {

                var labelledby;
                var titleId, title;
                var descId, desc;
                var stroke, strokeDasharray, strokeDashoffset;
                var label;
           
                var array;
                var name, value, percent, difPercent;
                var i;

                var total = 0;
                const firstOffset = 25;

                array = props.data.items;
                for(i=0; i < array.length; i++)
                {
                
                    //console.log(JSON.stringify(array[i],null,2));

                    name = array[i].name;
                    value = array[i].value;
                    percent = array[i].percent;
                    percent = parseFloat(percent);
                    difPercent = (100 - percent);


                    labelledby = "donut-segment-"+i+"-title donut-segment-1-desc";

                    titleId = "donut-segment-"+i+"-title";
                    title = "Title "+i;

                    descId = "donut-segment-"+i+"-desc";
                    desc = "Desc "+i;
                 
                    stroke = strokeColors[i];
                    strokeDasharray = percent+" "+difPercent;
                
                    if(i===0)
                    {
                        strokeDashoffset = firstOffset;
                    }else{
                        //strokeDashoffset = -`${100 - preSegmentsTotalLength + firstSegmentOffset}`;
                        strokeDashoffset = 175 + (50-total);
                    }

                    total = total + percent;



                    label = percent+"%";
                    title = name+" "+value+" ( "+percent+"% )";
                    desc = label;



                    // console.log("-----------------"
                    //     +"\npercent: "+percent
                    //     +"\ndifPercent: "+difPercent

                    //     +"\n---VARS---"

                    //     +"\nfirstOffset: "+firstOffset
                    //     +"\ntotal: "+total
                      
                    //     +"\n---STROKE---"
                    //     +"\nstroke: "+stroke
                    //     +"\nstrokeDasharray: "+strokeDasharray
                    //     +"\nstrokeDashoffset: "+strokeDashoffset
                        
                    // )

         
                    newItems.push({
     
                        labelledby:labelledby,

                        titleId:titleId,
                        title:title,

                        descId:descId,
                        desc:desc,

                        stroke:stroke,
                        strokeDasharray:strokeDasharray,
                        strokeDashoffset:strokeDashoffset,

                        label:label


                    });


                }



            

            }

            setItems(newItems);
            setShowHoleText(true);

            setChartLabel(props.data.hole.label);



            if(!currentWidth || width !== currentWidth)
            {
                setCurrentWidth(width);

                //https://www.varsitytutors.com/intermediate_geometry-help/how-to-find-the-angle-for-a-percentage-of-a-circle



                // -- reset styl on width change -- //

                var chartHeight = props.data.height;
                if(props.lessThanThresh) chartHeight = "auto";
            

                setStyl({

                    backgroundColor:props.data.backgroundColor,
                    width:props.data.width,

                    height:chartHeight,
                    margin:"0 0 0 0",

                    ring:props.data.ring,
                    hole:props.data.hole

                });

            }




        }
        //console.log("SvgDonutChart - props: "+JSON.stringify(props,null,2));

        // "data": {
        //     "backgroundColor": "transparent",
        //     "width": "100%",
        //     "height": "auto",
        //     "ring": {
        //       "size": "6",
        //       "color": "lightyellow"
        //     },
        //     "hole": {
        //       "size": 0.5,
        //       "color": "#fff",
        //       "outlineColor": "#fff",
        //       "textColor": "#000",
        //       "textSize": "27px"
        //     },
        //     "items": [

            // {
            //     "name": "Rent / Mortgage ( 80% )",
            //     "value": "$5,500.00",
            //     "percent": "80"
            // },
            // {
            //     "name": "Utilities ( 20% )",
            //     "value": "$1,100.00",
            //     "percent": "20"
            // }

        //     ],
        //     "colors": [
        //       "#07a2e3",
        //       "#20b2aa",
        //       "#fde23e",
        //       "#f16e23",
        //       "#57d9ff",
        //       "#937e88",
        //       "#20b2aa",
        //       "#ccff00",
        //       "lightblue",
        //       "lightgreen",
        //       "yellow",
        //       "pink",
        //       "lavender"
        //     ]
        //   }
        // }




    },
    [
        props,
        width,currentWidth,
        isLoaded,
        refChart,
        refContent

    ])








    // const handleClick = (hndl) => {

    //     //alert(JSON.stringify(hndl,null,2));
    //     setChartLabel(hndl.label);

    // };



    // const handleOverOut = (hndl) => {

    //     //alert(JSON.stringify(hndl,null,2));
    //     setChartLabel(hndl.label);

    // };








    if(!isLoaded)
    {

        return(

            <div></div>
        )


    }else{


        return (

            <div>

                <DonutChart
                ref={refChart}
                >


                    <Hole
                    width={(styl.hole.size*100)+"px"}
                    height={(styl.hole.size*100)+"px"}

                    paddingTop={styl.hole.paddingTop}

                    top={"calc(50% - "+(styl.hole.size*100)/2+"px)"}
                    left={"calc(50% - "+(styl.hole.size*100)/2+"px)"}

                    fontSize={styl.hole.textSize}                  
                    textColor={styl.hole.textColor}
                    >




                        {chartLabel}

                        {/* 
                        <div className="Title">


                        </div> */}

                    </Hole>




                    <Parts
                    svgHeight={styl.height}
                    figureMargin={styl.margin}

                    fontSize={styl.hole.textSize}                  
                    textColor={styl.hole.textColor}

                    >

                        <figure>

                            <div className="figure-content">
                                

                                <svg 
                                width="100%" height="100%" 
                                viewBox="0 0 42 42" 
                                className="donut" 
                                aria-labelledby="beers-title beers-desc" 
                                role="img">


                                    <title id="beers-title">No Title</title>
                                    <desc id="beers-desc">No Description. This could be a very long description if you desire.</desc>


                                    {/* hole*/}
                                    <circle className="donut-hole" 
                                    cx="21" cy="21" r="15.91549430918954" 
                                    fill={styl.hole.color} 
                                    role="presentation"
                                    >
                                    </circle>



                                    {/* ring */}
                                    <circle className="donut-ring" 
                                    cx="21" cy="21" r="15.91549430918954" 
                                    fill="transparent" 
                                    stroke={styl.ring.color} 
                                    strokeWidth={styl.ring.size} 
                                    role="presentation"
                                    >
                                    </circle>
                                


                                    {/* segments */}

                                    {/* 
                                    

                                    strokeDashOffset - set the position of the segement in relation to the others

                                    examples... 

                                    2 sections 50/50 = section 1( 25 ) section 2(-25)

                                    2 sections 40/60 = section 1( 25 ) section 2(-15)
                                    2 sections 60/40 = section 1( 25 ) section 2(-35)

                                    2 sections 30/70 = section 1( 25 ) section 2(-5)
                                    2 sections 70/30 = section 1( 25 ) section 2(-45)

                                    2 sections 20/80 = section 1( 25 ) section 2(5)
                                    2 sections 80/20 = section 1( 25 ) section 2(-55)

                                    2 sections 10/90 = section 1( 25 ) section 2(15) 
                                    2 sections 90/10 = section 1( 25 ) section 2(-65)
                                
                            
                                    
                                    */}



                                    {items.map((itm, i) => (



                                        <g
                                        key={i}
                                        className={'segment-container'}
                                        // onClick={(v) => handleClick({

                                        //     label:itm.label

                                        // })}
                                        // onMouseEnter={() => handleOverOut({

                                        //     label:itm.label
    
                                        // })}
                                        // onMouseOut={() => handleOverOut({
    
                                        //     label:''
    
                                        // })}
                                        
                                        >

        
                                        {i===0
                                        ?

                                            <circle 
                                            className="donut-segment" 
                                            cx="21" cy="21" r="15.91549430918954" 
                                            fill="transparent" 
                                            stroke={itm.stroke}
                                            strokeWidth={styl.ring.size}
                                            strokeDasharray={itm.strokeDasharray} 
                                            strokeDashoffset={itm.strokeDashoffset} 
                                            aria-labelledby={itm.labelledby}
                                            >

                                                <title id={itm.titleId}>{itm.title}</title>
                                                <desc id={itm.descId}>{itm.desc}</desc>

                                            </circle>

                                    
                                        :

                                            <circle 
                                            className="donut-segment" 
                                            cx="21" cy="21" r="15.91549430918954" 
                                            fill="transparent" 
                                            stroke={itm.stroke}
                                            strokeWidth={styl.ring.size}
                                            strokeDasharray={itm.strokeDasharray} 
                                            strokeDashoffset={itm.strokeDashoffset} 
                                            //aria-labelledby={itm.labelledby}
                                            >
                                                <title id={itm.titleId}>{itm.title}</title>
                                                <desc id={itm.descId}>{itm.desc}</desc>

                                            </circle>
                    

                                        }                   
                                        </g>
 

                                    ))}




                                    {/* Chart Text */}
                                    {
                                    showHoleText
                                    ?
                                        // <g className="chart-text">
                                        //     <text x="50%" y="50%" className="chart-number">
                                        //     10
                                        //     </text>
                                        //     <text x="50%" y="50%" className="chart-label">
                                        //     Beers
                                        //     </text>
                                        // </g>

                                        // <g className="chart-text">
            
                                        //     <text 
                                        //     className="chart-center-label"                                 
                                        //     x="50%" y="50%" 
                                        //     >
                                        //         {chartLabel}

                                        //     </text>

                                        // </g>


                                        <div></div>


                                    :null
                                    }



                                </svg>

                            </div>



                            {   
                            showChartKey
                            ?
                            
                                <figcaption className="figure-key">

                                    <p className="sr-only">Donut chart showing 10 total beers. Two beers are Imperial India Pale Ales, four beers are Belgian Quadrupels, and three are Russian Imperial Stouts. The last remaining beer is unlabeled.</p>

                                    <ul className="figure-key-list" aria-hidden="true" role="presentation">
                                        <li>
                                            <span className="shape-circle shape-fuschia"></span> Belgian Quadrupels (4)
                                        </li>
                                        <li>
                                            <span className="shape-circle shape-lemon-lime"></span> Imperial India Pale Ales (2)
                                        </li>
                                        <li>
                                            <span className="shape-circle shape-blue"></span> Russian Imperial Stouts (3)
                                        </li>
                                    </ul>

                                </figcaption> 

                    
                            :null
                            
                            }


                        </figure>

                    </Parts>

                </DonutChart>

            </div>


        )    

    }





}




