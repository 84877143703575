import styled from "styled-components";


export const DonutChart = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "100%",
    minHeight:          props.minHeight || "100px",

    

    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "20px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "black"



    // border-bottom:${props => props.border};
    // border-left:${props => props.border};
    // border-right:${props => props.border};




    // cursor:pointer;



    // @media (max-width: 300px) {

    //     height:80px;
    // }



    // &:hover {

    //     background-color:#caffca;
    //     text-decoration:underline;
    // }


    // .Bars {

    //     background-color:transparent;
    //     width:100%;
    //     height:375px;
    //     position:absolute;
    //     top:0px;
    //     left:0px;
    //     z-index:3;

    // }









}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};
    min-height:${props => props.minHeight};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};



`;




    //############################################################
    //############################################################

    export const Title = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "white",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "100%",
        height:             props.height || "auto",
        minHeight:          props.minHeight || "15px",

        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "10px 0 8px 0",

        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "20px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration  || "none",
        textColor:          props.textColor || "black"


    }))`


        background-color:${props => props.backgroundColor};


        width:${props => props.width};
        height:${props => props.height};
        min-height:${props => props.minHeight};

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};



    `;





    //############################################################
    //############################################################

    export const Hole = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "0",
        borderRadius:       props.borderRadius || "50%",

        width:              props.width || "50px",
        height:             props.height || "50px",

        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",

        paddingTop:         props.paddingTop || "0px",


        top:              props.top || "calc(50% - 25px)",
        left:             props.left || "calc(50% - 25px)",

        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "24px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration  || "none",
        textColor:          props.textColor || "white"




        // margin:${({fontSize}) => 

        //     (
        //         fontSize 
        //         && fontSize !== undefined  
        //         && parseInt(fontSize.replace(/px/gi,""))/2 + "px 0 0 0"
        //     ) 

        //     ||
            
        //     "0 0 0 0"
            
        // };
        




    }))`


        background-color:${props => props.backgroundColor};
        border:${props => props.border};
        border-radius:${props => props.borderRadius};

        width:${props => props.width};
        height:${({height,paddingTop}) => 

            (
                paddingTop 
                && paddingTop !== undefined  
                && parseInt(height.replace(/px/gi,"")) - parseInt(paddingTop.replace(/px/gi,""))/2 + "px"
            ) 

            ||
            
            height
            
        };


        margin:${props => props.margin};
        padding:${({paddingTop}) => 

            (
                paddingTop 
                && parseInt(paddingTop.replace(/px/gi,"")) + "px 0 0 0"
            ) 

            ||
            
            "0 0 0 0"
            
        };




        position:absolute;
        z-index:2;
        top:${props => props.top};
        left:${props => props.left};

        overflow:hidden;


        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};


        // .Title {

        //     background-color:purple;

        //     width:100%;
        //     height:auto;
        //     min-height:50px;

        //     padding:0 0 0 0;
        //     margin:0 0 0 0;

        //     position:relative;
        //     float:left;
    



        // }







    `;







    //############################################################
    //############################################################

    export const Parts = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",

        width:              props.width || "100%",
        height:             props.height || "auto",
        minHeight:          props.minHeight || "50px",

        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",





        //figure 

        figureMargin:           props.figureMargin || "0 0 0 0",



        //svg 

        svgHeight:              props.svgHeight || "auto",


        // donut stuff

        donutSize:                      props.donutSize || "250px",
        donutHalfSize:                  props.donutHalfSize || "125px",
        donutCenterSize:                props.donutCenterSize || "125px",
        donutCenterTopLeft:             props.donutCenterTopLeft || "62.5px",
        donutCenterBackgroundColor:     props.donutCenterBackgroundColor || "#edeef1", //"#394264",
        donutCenterBorderRadius:        props.donutCenterBorderRadius || "100%",




        // centerFont

        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "0.25em",
        fontWeight:         props.fontWeight  || "550",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration  || "none",
        textColor:          props.textColor || "white"



        // .clip {

        //     border-radius: 50%;
        //     clip: rect(0px, 200px, 200px, 100px);
        //     width: 100%;
        //     height: 100%;
        //     position: absolute;

        // }
        
        // .item {

        //     border-radius: 50%;
        //     clip: rect(0px, 100px, 200px, 0px);
        //     width: 100%;
        //     height: 100%;
        //     position: absolute;
        //     font-family: monospace;
        //     font-size: 1.5rem;
        // }
        



    }))`


        background-color:${props => props.backgroundColor};

        width:${props => props.width};
        height:${props => props.height};
        min-height:${props => props.minHeight};
    
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;




        .chart-text {
            fill: #000;
            -moz-transform: translateY(0.25em);
            -ms-transform: translateY(0.25em);
            -webkit-transform: translateY(0.25em);
            transform: translateY(0.25em);
        }
        
        .chart-number {
            font-size: 0.6em;
            line-height: 1;
            text-anchor: middle;
            -moz-transform: translateY(-0.25em);
            -ms-transform: translateY(-0.25em);
            -webkit-transform: translateY(-0.25em);
            transform: translateY(-0.25em);
        }
        
        .chart-label {
            font-size: 0.2em;
            text-transform: uppercase;
            text-anchor: middle;
            -moz-transform: translateY(0.7em);
            -ms-transform: translateY(0.7em);
            -webkit-transform: translateY(0.7em);
            transform: translateY(0.7em);
        }


        .chart-center-label {

            background-color:pink;
            font-size:${props => props.fontSize};
            color:${props => props.textColor};
            text-transform: uppercase;
            text-anchor: middle;
            -moz-transform: translateY(-0.6em);
            -ms-transform: translateY(-0.6em);
            -webkit-transform: translateY(-0.6em);
            transform: translateY(-0.6em);
        }

        
        figure {

            background-color:transparent;
            display: flex;
            justify-content: center;
            flex-direction: column;

            margin:${props => props.figureMargin};
            width:100%;
            height:100%;
         
        }
        
        @media (min-width: 768px) {
            figure {
                flex-direction: row;
            }
        }
        
        .figure-content,
        .figure-key {
            flex: 1;
            padding-left: 15px;
            padding-right: 15px;
            align-self: center;
        }
        
        .figure-content svg {
            height: ${props => props.svgHeight};
        }
        
        .figure-key {
            min-width: calc(8 / 12);
        }
        
        .figure-key [class*="shape-"] {
            margin-right: 6px;
        }
        
        .figure-key-list {
            margin: 0;
            padding: 0;
            list-style: none;
        }
        
        .figure-key-list li {
            margin: 0 0 8px;
            padding: 0;
        }
        
        .shape-circle {
            display: inline-block;
            vertical-align: middle;
            width: 32px;
            height: 32px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
                    border-radius: 50%;
        }
        
        .shape-fuschia {
            background-color: #ce4b99;
        }
        
        .shape-lemon-lime {
            background-color: #b1c94e;
        }
        
        .shape-blue {
            background-color: #377bbc;
        }
        
        .sr-only {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            clip: rect(0,0,0,0);
            border: 0;
        }






        .segment-container{

            background-color:pink;
            width:50px;
            height:50px;

        }
        



    `;


















