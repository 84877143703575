import React, { 

    //useState, 
    //useEffect,
    //useRef

} from 'react';



import { Overlay } from './_Styled';


export const FFoverlay = (props) => {

    return (

        <div
        key={props.indx+"_ffoverlay"}
        style={{

            width:props.width,
            height:"auto",
            margin:props.top+"px 0 0 0",
            position:"relative",
            float:"left"

        }} 
        >
            <Overlay
            backgroundColor={props.backgroundColor}
            width={props.width}
            top={props.top}
            left={props.left}
            percent={props.opacity > 0 ? props.opacity+"0%" : "0%"}
            decimal={props.opacity > 0 ? "0."+props.opacity : "0.0"}
            >
            </Overlay>

        </div>

    )

}

