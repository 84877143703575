// import {

//     useState,
//     useEffect,
//     //useRef

// } from 'react';



import {

    //LibArray,
    LibDetect,
    //LibString

} from 'library';


export const Fingerprint = () => {

    const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;

    const mobile = Boolean(
        userAgent.match(
            /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
        )
    );



    // var osArr = ["Other","Linux","Macintosh"];
    // var browserArr = ["UNKNOWN","CHROME","FIREFOX","SAFARI","IE","OPERA","SILK"];
    // var mobileArr = ["NotMobile","Android","BlackBerry","iPhone","iPad","iPod","Opera Mini","IEMobile","WPDesktop"];



    let os = "Other";
    if(userAgent.match(/Linux/gi))
    {
        os = 'Linux';
    }
    else
    if(userAgent.match(/Macintosh/gi))
    {
        os = 'Macintosh';
    }


    let browser = LibDetect.Browser();
    let detectObj = LibDetect.Mobile();


    let mobileAgent = 'NotMobile';
    if(detectObj.mobile_agent) mobileAgent = detectObj.mobile_agent;


    // var osNumber = LibArray.GetIndex(os,osArr);
    // var browserNumber = LibArray.GetIndex(browser,browserArr);
    // var mobileNumber = LibArray.GetIndex(mobileAgent,mobileArr);



    let rtnObj = {};

        let uniqueString;
        //uniqueString = os + "-" + browser + "-" + mobileAgent + '-' + userAgent;
        uniqueString = os + "-" + browser + "-" + mobileAgent;
        uniqueString = uniqueString.toLowerCase();
        //uniqueString = osNumber + "-" + browserNumber + "-" + mobileNumber;

        // uniqueString = uniqueString.toLowerCase();
        // uniqueString = uniqueString.replace(/\(/gi,"");
        // uniqueString = uniqueString.replace(/\)/gi,"");

        // var encodedString = LibString.Base64String(uniqueString,'encode');
        // var decodedEmailHost = LibString.Base64String(encodedEmailHost,'decode');


        let user;
        user = "TPC_User_"+uniqueString;


        let storage_id = 'accessEmail';
        if(localStorage[storage_id])
        {
            user = localStorage[storage_id];
    
        }//#



    rtnObj['user'] = user;
    rtnObj['userAgent'] = userAgent;
    rtnObj['os'] = os;  
    rtnObj['browser'] = browser; 
    rtnObj['isMobile'] = mobile;
    rtnObj['mobileAgent'] = detectObj.mobile_agent;

    return rtnObj;

}



// https://pixelprivacy.com/resources/browser-fingerprinting/




// dust fingerprint: 
// {
//   "user": "TPC_Fingerprint_User",
//   "userAgent": "Mozilla/5.0 (Linux; Android 11; SAMSUNG SM-G973U) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/14.2 Chrome/87.0.4280.141 Mobile Safari/537.36",
//   "browser": "CHROME",
//   "isMobile": true,
//   "mobileAgent": "android"
// }



// dust fingerprint:
// {
//   "user": "TPC_Fingerprint_User",
//   "userAgent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.2 Safari/605.1.15",
//   "browser": "SAFARI",
//   "isMobile": false,
//   "mobileAgent": false
// }