const LibString =  {


	//CapitalizeWords('this IS THE wOrst string eVeR');
	
	CapitalizeWords : (s) => {
	
	
		return s.toLowerCase().replace( /\b./g, function(a){ return a.toUpperCase(); } );
	
		
    },
	

	TrimWhitespaces : function(dirty) {

		var clean = dirty.replace(/(^\s+|\s+$)/g, '');//replace kills whitespace
		return clean;

	},

	
    InputMaxlength: (v) => {

        var str = v.str.toString();
        var maxlength = parseInt(v.maxlength);
        var diff;
    

        // console.log("IN STR: "+str+"\nmaxlength: "+maxlength)

        if(str.length === parseInt(maxlength)-1)
        {
            if(!str.match(/\./gi)) maxlength = maxlength-2;
            diff = str.length - maxlength;
            str = str.substr(0,str.length-diff);
        }
        else
        if(str.length > maxlength 
        || str.length === maxlength) 
        {
            if(!str.match(/\./gi)) maxlength = maxlength-1;
            diff = str.length - maxlength;
            str = str.substr(0,str.length-diff);
        }

        // console.log("OUT STR: "+str+"\nmaxlength: "+maxlength)


        return str;

    },



	SplitAtIndex: (str, index, splitter) => {


		var ret;
		ret = str.substring(0, index) + splitter + str.substring(index);
		return ret;
		
		//console.log(split_at_index('3123124', 2));

	},

	


	SplitOnCapitalLetter: (str,delimiter) => {

		let ret = "";
		const result = str
		  .trim()
		  .split(/(?=[A-Z])/)
		  .map(element => element.trim());
		
		// ['One , 'Two', 'Three']
		// console.log(result);

		ret = result.join(",");
		ret = ret.replace(/,/gi,delimiter);

		// console.log(ret);

		return ret;
	

	},

	




	//#######################################################################
	//#######################################################################

	ReplaceSpecialChars : function(string,exceptions){


		var R = string;

			//=============================================
			//REPLACE all special chars except( - _  . ')
			//=============================================


			//WITH APOSTROPHE
			//var regex = /[!@#\$%^&\*,+="':;?()\<\>\[\]\{\}\/]+/gi;

			//WITHOUT APOSTROPHE
			//var regex = /[!@#\$%^&\*,+=":;?()\<\>\[\]\{\}\/]+/gi;



			// str.replace(/\W/g, '') //doesnt include underscores
			// str.replace(/[^0-9a-z]/gi, '') //removes underscores too
	


			//---------------------
			//React (Not Working)
			//---------------------

			//WITH APOSTROPHE
			//var regex = /[!@#\$%^&\*,+="':;?()\<\>\[\]\{\}\/]+/gi;


			//WITHOUT APOSTROPHE
			// var regex = /[!@#$%^&*,+=":;?()<>[]{}\/]+/gi;

			// R = R.replace(regex,'');




			//REFERENCE

			// /[^a-zA-Z\d\s:\u00C0-\u00FF]/g

			// ^ negates what follows

			// a-zA-Z matches upper and lower case letters

			// \d matches digits

			// \s matches white space (if you only want to match spaces, replace this with a space)

			// : matches a colon

			// \u00C0-\u00FF matches the Unicode range for accented latin characters.



			//---------------------
			//React (Working)
			//---------------------

			let hasExceptions = false;
			if(Object.prototype.toString.call(exceptions) === '[object Array]')
			{
				if(exceptions.length > 0)
				{
					hasExceptions = true;
				}

			}
			if(hasExceptions)
			{


				var exceptionsString = exceptions.join("-");
				//alert("exceptionsString: "+exceptionsString);

				//R = R.replace(/[^\w\s]/gi, '');// doesnt include spaces
				//R = R.replace(/[^\w,]/gi, '');// doesnt include commas
				//R = R.replace(/[^\w,.]/gi, '');// doesnt include commas and periods
				//R = R.replace(/[^\w,.?!]/gi, '');// doesnt include all punctuation




				//strict - no spacing dashes and underscores

				if(exceptionsString.match(/dash/gi) 
				&& exceptionsString.match(/underscore/gi)
				&& exceptionsString.match(/comma/gi)
				)
				{
					R = R.replace(/[^a-zA-Z\d\-_,\u00C0-\u00FF]/g, '');// doesnt include dashes and underscors
			
				}
				else
				if(exceptionsString.match(/dash/gi) 
				&& exceptionsString.match(/underscore/gi)
				)
				{
					R = R.replace(/[^a-zA-Z\d\-_\u00C0-\u00FF]/g, '');// doesnt include dashes and underscors
			
				}
		



				// separators = spaces dashes and underscores

				else
				if(exceptionsString.match(/separators/gi) && exceptionsString.match(/comma/gi)
				)
				{
					R = R.replace(/[^a-zA-Z\d\s\-_,\u00C0-\u00FF]/g, '');// doesnt include spaces punct and specials
				
				}
				else
				if(exceptionsString.match(/separators/gi) && exceptionsString.match(/punct_specials/gi)
				)
				{
					R = R.replace(/[^a-zA-Z\d\s\-_:;,."'?!@#$%\u00C0-\u00FF]/g, '');// doesnt include spaces punct and specials
				
				}
				else
				if(exceptionsString.match(/separators/gi) && exceptionsString.match(/punct_all/gi)
				)
				{
					R = R.replace(/[^\w\s\-_,.?!"':;]/gi, '');// doesnt include spaces and all punctuation
				
				}
				else
				if(exceptionsString.match(/separators/gi)
				)
				{
					R = R.replace(/[^a-zA-Z\d\s\-_\u00C0-\u00FF]/g, '');// doesnt include spaces dashes and underscores
				
				}
		


				// others


				else
				if(exceptionsString.match(/spaces/gi) && exceptionsString.match(/punct/gi)
				)
				{
					R = R.replace(/[^\w\s,.?!]/gi, '');// doesnt include spaces and basic punctuation
				
				}
				else
				if(exceptionsString.match(/spaces/gi) && exceptionsString.match(/commas/gi)
				)
				{
					R = R.replace(/[^\w\s,]/gi, '');// doesnt include spaces and commas

				}
				else
				if(exceptionsString.match(/spaces/gi)
				)
				{
					R = R.replace(/[^\w\s]/gi, '');// doesnt include spaces
				}
				else
				if(exceptionsString.match(/commas/gi)
				)
				{
					R = R.replace(/[^\w,]/gi, '');// doesnt include commas
					
				}





			}else{


				R = R.replace(/\W/g, '');			// doesnt include underscores
				//R = R.replace(/[^0-9a-z]/gi, ''); 	// removes underscores too			
			}



	


		return R;


	},



	//#######################################################################
	//#######################################################################

	
	LettersToNumbers : function(str) {


		var pos;
		var out = 0, len = str.length;

		for(pos = 0; pos < len; pos++) 
		{
			out += (str.charCodeAt(pos) - 64) * Math.pow(26, len - pos - 1);
		}

		return out;


	},





	//#######################################################################
	//#######################################################################

	CharacterLength : function(text,charlen,use_dots) {
					
		var R = text;

			R = R.substring(0,charlen);					
			if(use_dots)
			{
				if(R.length > charlen) R = R + "..";

			}//

		return R;

	},





	//#######################################################################
	//#######################################################################

	
	LimitString : function( string, charlen, dots ){


		var R = string;
		var use_dots;

			if(dots)
			{
				if(R.length > charlen)
				{ 
					use_dots = true;
				}else{
					use_dots = false;
				}//#

			}//#
										
			R = R.substring(0,charlen);
							
			if(use_dots) R = R + "..";


		return R;


	},

	






	//#######################################################################
	//#######################################################################

	

	SlashCount : function(str) {
	

		if(str && str !== '')
		{
			var arr = str.split('/');
			var count = arr.length-1;
			return count;

		}//#

	
	},





	//#######################################################################
	//#######################################################################
	
	HasInvalidChars : function(str){
	
		var r = false;


		//Non React
		//var regex = /[!@#\$%^&\*,+="':;?\<\>\[\]\{\}\/]+/i;


		// React Trys
		//var regex = /^[0-9a-z]+$/;
		//var regex = /[!@#$%^&*,+="':;?<>[]{}\/]+/gi;
		//var invalid_str = str.match(regex);



		// React Works
		var invalid_str = str.match(/\W/g) //doesnt include underscores
		//var invalid_str = str.match(/[^0-9a-z]/gi) //removes underscores too

		if(invalid_str)
		{
			r = '! @ # $ % ^ & * , "'; 
			r += " ' + = : ; ? [] {} < > ()";

		}//


		// alert(""
		// 	+"\nstring in: "+str
		// 	+"\ninvalid_str: "+invalid_str
		// );

	
		return r;

	},







	//#######################################################################
	//#######################################################################


	RemoveInvalidChars : function(str){
	
		//var regex = /[!@#\$%^&\*,+="':;?\<\>\[\]\{\}\/]+/i;
		// var regex = /[!@#$%^&*,+="':;?<>[]{}\/]+/i;
		// str = str.replace(regex,'');

		//---------------------
		//React (Working)
		//---------------------

		str = str.replace(/\W/g, '') 			// doesnt include underscores
		//str = str.replace(/[^0-9a-z]/gi, '') 	// removes underscores too


		return str;
	
	},






	//#######################################################################
	//#######################################################################

	EncodeDecode :  (props) => {


        var str = props.str;
        var call = props.call;
        var type = props.type;

	
		var amp_chars = { 
						"&": "&amp;",
						"<": "&lt;",
						">": "&gt;",
						'"': '&quot;',
						"'": '&#39;',
						"/": '&#x2F;',
						"?": '&#63'
						};
		
		
		var percent_chars = {
							"&": "%26",
							"<": "%26lt;",
							">": "%26gt;",
							'"': '%26quot;',
							"'": '%26#39;',
							"/": '%26#x2F;',
							"?": '%26#63',
                            " ": '%20'
							};
		
		
		switch(call)
		{
		case'decode':
	

			switch(type)
			{
			case'percent':
	
				str = str.replace(new RegExp(percent_chars['"'],'g'), '"');
				str = str.replace(new RegExp(percent_chars["'"],'g'), "'");
				str = str.replace(new RegExp(percent_chars["&"],'g'), "&");
				str = str.replace(new RegExp(percent_chars["?"],'g'), "?");
				str = str.replace(new RegExp(percent_chars[" "],'g'), " ");
	
				str = str.replace(new RegExp("%26nbsp;",'g'), "");


			break;
			case'amp':

				str = str.replace(new RegExp(amp_chars["&"],'g'), "&");
				str = str.replace(new RegExp(amp_chars['"'],'g'), '"');
				str = str.replace(new RegExp(amp_chars["'"],'g'), "'");
				str = str.replace(new RegExp(amp_chars["?"],'g'), "?");
		
				str = str.replace(new RegExp("&nbsp;",'g'), "");
	
			break;
			default:


				str = str.replace(new RegExp(percent_chars["&"],'g'), "&");
				str = str.replace(new RegExp(percent_chars['"'],'g'), '"');
				str = str.replace(new RegExp(percent_chars["'"],'g'), "'");
				str = str.replace(new RegExp(percent_chars["?"],'g'), "?");
				str = str.replace(new RegExp(percent_chars[" "],'g'), " ");
		
		
				str = str.replace(new RegExp(amp_chars["&"],'g'), "&");
				str = str.replace(new RegExp(amp_chars['"'],'g'), '"');
				str = str.replace(new RegExp(amp_chars["'"],'g'), "'");
				str = str.replace(new RegExp(amp_chars["?"],'g'), "?");
		
		
				str = str.replace(new RegExp("%26nbsp;",'g'), "");
				str = str.replace(new RegExp("&nbsp;",'g'), "");
		

			break;
			}//switch
			//=======

	
		break;
		case'encode':


			switch(type)
			{
			case'percent':
	
				str = str.replace(new RegExp('"','g'), percent_chars['"']);
				str = str.replace(new RegExp("'",'g'), percent_chars["'"]);
				str = str.replace(new RegExp("&",'g'), percent_chars["&"]);
				str = str.replace(/\?/g, percent_chars["?"]);
	
			break;
			case'amp':

				str = str.replace(new RegExp("&",'g'), amp_chars["&"]);
				str = str.replace(new RegExp('"','g'), amp_chars['"']);
				str = str.replace(new RegExp("'",'g'), amp_chars["'"]);
				str = str.replace(/\?/g, amp_chars["?"]);
	
			break;
			default:


				str = str.replace(new RegExp("&",'g'), percent_chars["&"]);
				str = str.replace(new RegExp('"','g'), percent_chars['"']);
				str = str.replace(new RegExp("'",'g'), percent_chars["'"]);
				str = str.replace(/\?/g, percent_chars["?"]);
		
		
				str = str.replace(new RegExp("&",'g'), amp_chars["&"]);
				str = str.replace(new RegExp('"','g'), amp_chars['"']);
				str = str.replace(new RegExp("'",'g'), amp_chars["'"]);
				str = str.replace(/\?/g, amp_chars["?"]);


			break;
			}//switch
			//=======


		break;
		default:
		}//switch
		//#######
	
	
		return str;
	
	
	},



	//#######################################################################
	//#######################################################################

	UrlSpaces :  (props) => {


        var str = props.str;
        var call = props.call;
        var type = 'percent';


		
		var percent_chars = {
							// "&": "%26",
							// "<": "%26lt;",
							// ">": "%26gt;",
							// '"': '%26quot;',
							// "'": '%26#39;',
							// "/": '%26#x2F;',
							// "?": '%26#63',
                            " ": '%20'
							};
		
		
		switch(call)
		{
		case'decode':
	

			switch(type)
			{
			case'percent':
	

				str = str.replace(new RegExp(percent_chars[" "],'g'), " ");
	
			break;
			default:

				str = str.replace(new RegExp(percent_chars[" "],'g'), " ");
		
			break;
			}//switch
			//=======

	
		break;
		case'encode':


			switch(type)
			{
			case'percent':
	
				str = str.replace(new RegExp(' ','g'), percent_chars[' ']);

			break;
			default:

				str = str.replace(new RegExp(" ",'g'), percent_chars[" "]);

			break;
			}//switch
			//=======


		break;
		default:
		}//switch
		//#######
	
	
		return str;
	
	
	},







}




export default LibString;




/*



        
        //-----

        targetVal = targetVal.toString();
        if(targetVal.length === parseInt(maxlength)-1)
        {
            if(!targetVal.match(/\./gi)) maxlength = maxlength-2;
            diff = targetVal.length - maxlength;
            targetVal = targetVal.substr(0,targetVal.length-diff);
        }
        else
        if(targetVal.length > maxlength 
        || targetVal.length === maxlength) 
        {
            if(!targetVal.match(/\./gi)) maxlength = maxlength-1;
            diff = targetVal.length - maxlength;
            targetVal = targetVal.substr(0,targetVal.length-diff);
        }

        //------
        


*/