import React, { 

    //useState, 
    //useEffect,
    useRef

} from 'react';


import {

    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,
    //useRouteMatch,
    //useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";


//import { useWindowDimensions } from 'utils/WindowDimensionsProvider';

import './StyleSheets/formstuff.css';
import './StyleSheets/dynamic-form.css';


import {

    FormColumn,
    FormContainer,
    FormBox

} from './Styled';


import { 

    // decor
    FFheader, 
    FFheadline,
    FFbar,
    FFtitleline,
    FFstatement,
    FFnotice,
    FFspacer,

    FFbutton,
    FFlink,
    FFclicker,
    FFclickeredit,
    FFfield,
    FFlabelfield,
    FFeditfield,
    FFedittitle,
    FFeditlink,
    FFslider,
    FFdualslider

} from './FormFields';





const Show = (props) => {


    //const { width, height } = useWindowDimensions();
    //console.log("WIDTH: "+width+"\nHEIGHT: "+height);

    //let match = useRouteMatch();
    //let location = useLocation();
    //let history = useHistory();


    // -- redux -- //

    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));

    //if(zoneObj['handle'] && zoneObj['handle'] !== undefined) alert(zoneObj['handle']);


    // -- ref -- //
    const formRef = useRef(null);


    /*
    // -- state -- //
    //const [formWidthInt, setFormWidthInt] = useState(450);
    const [formArr, setFormArr] = useState([]); 
 
    useEffect(() => {

        if(formArr.length === 0)
        {
            var newArr = [];
            for(var i=0; i < props.info.length; i++)
            {
                //console.log(object['section'] + "\n" + JSON.stringify(object['data'],null,2));
                var frm = props.info[i];
                newArr.push(frm);
    
            }//== i

            setFormArr(newArr);

        }



    },[

        props,
        location,
        formRef,
        formArr
    ])

    */


    



    /*

    // -- size and position -- //

    var dfWidthInt;
    var dfWidth;

    //var dfHeightInt;
    //var dfHeight;

    var dfMarl;

    var dfBorder;


    dfWidthInt = 450;
    dfWidth = dfWidthInt + "px";

    dfBorder = "1px solid #1a1926";

    //dfMarl = (width - dfWidthInt)/2 + "px";
    dfMarl = (width - formWidthInt)/2 + "px";

    if(width < 500)
    {
        dfWidthInt = width - 50;
        dfWidth = dfWidthInt + "px";

        dfBorder = "0px solid white";

        dfMarl = 0 + "px";
    

    }
    else
    if(width < 650)
    {
        dfWidthInt = 450;
        dfWidth = dfWidthInt + "px";

        dfBorder = "0px solid white";

        dfMarl = (width - dfWidthInt)/2 - 20 + "px";
    
    }



    //dfHeightInt = 450;
    //dfHeight = dfHeightInt + "px";

    */



    //console.log("---FORM ITEMS---"+JSON.stringify(props.items,null,2));








    // -- display -- //

    return (


        <div>
        <FormColumn>
            <FormContainer>
            <FormBox
            border={props.border}
            >

            <div 
            ref={formRef}
            key={"dynamic_form"}
            className="dynamic-form"
            style={{

                //backgroundColor : "white", 
                //width           : dfWidth,
                //height          : dfHeight, 
                //margin          : "10px 0 50px "+dfMarl,
                //padding         : "0 20px 50px 20px",
                //border          : dfBorder,
                //borderRadius    : "10px"

            }}

            >


                {/**************
                <div>
                    <code>

                        {FormCompose.map((data, i) => (

                                <div 
                                key={i}
                                >
                                    {data.section}

                                </div>

                        ))}
                    
                    </code>

                </div>
                **************/}






                <div>
                
                    {props.items.map((frm, i) => (


                        <div
                        key={i}
                        id={"formSection_"+i}
                        style={{

                            //backgroundColor : "lightblue",
                            width           : "100%",
                            height          : "auto",
                            position        : "relative",
                            float           : "left"

                        }}

                        >


                            {
                            //------------------------------------
                            //header ( data = {} )
                            //------------------------------------
                            frm.section === "header" ? 

                                <FFheader 

                                    indx={i}
                                    data={frm.data} 

                                />

                            : null

                            }



                            {
                            //------------------------------------
                            //headline ( data = {} )
                            //------------------------------------
                            frm.section === "headline" ? 

                                <FFheadline 

                                    indx={i}
                                    data={frm.data} 

                                />

                            : null

                            }



                            {
                            //------------------------------------
                            //bar ( data = {} )
                            //------------------------------------
                            frm.section === "bar" ? 

                                <FFbar

                                    indx={i}
                                    data={frm.data} 

                                />

                            : null

                            }



                            {
                            //------------------------------------
                            //titleline ( data = {} )
                            //------------------------------------
                            frm.section === "titleline" ? 

                                <FFtitleline 

                                    indx={i}
                                    data={frm.data} 

                                />

                            : null

                            }



                            {
                            //------------------------------------
                            //statement ( data = {} )
                            //------------------------------------
                            frm.section === "statement" ? 

                                <FFstatement

                                    indx={i}
                                    data={frm.data} 

                                />

                            : null

                            }



                            {
                            //------------------------------------
                            //notice ( data = {} )
                            //------------------------------------
                            frm.section === "notice" ? 

                                <FFnotice

                                    indx={i}
                                    data={frm.data} 

                                />

                            : null

                            }





                            {
                            //------------------------------------
                            //spacer ( data = {} )
                            //------------------------------------
                            frm.section === "spacer" ? 

                                <FFspacer

                                    indx={i}
                                    data={frm.data} 

                                />

                            : null

                            }




                            {
                            //------------------------------------
                            //button ( data = {} )
                            //------------------------------------
                            frm.section === "button" ? 

                                <FFbutton

                                    indx={i}
                                    data={frm.data} 
                                    handle={(v) => props.handle(v)}

                                />

                            : null

                            }




                            {
                            //------------------------------------
                            //link ( data = {} )
                            //------------------------------------
                            frm.section === "link" ? 

                                <FFlink

                                    indx={i}
                                    data={frm.data} 
                                    handle={(v) => props.handle(v)}

                                />

                            : null

                            }




                            {
                            //------------------------------------
                            //clicker ( data = {} )
                            //------------------------------------
                            frm.section === "checkboxes" || frm.section === "radiobuttons" ? 

                                <FFclicker

                                    indx={i}
                                    data={frm.data} 
                                    handle={(v) => props.handle(v)}
                                    problemArr={props.problemArr}

                                />

                            : null

                            }




                            {
                            //------------------------------------
                            //clickeredit ( data = {} )
                            //------------------------------------
                            frm.section === "checkboxes-edit" || frm.section === "radiobuttons-edit" ? 

                                <FFclickeredit

                                    indx={i}
                                    data={frm.data} 
                                    handle={(v) => props.handle(v)}
                                    problemArr={props.problemArr}

                                />

                            : null

                            }




                            {
                            //------------------------------------
                            //slider ( data = {} )
                            //------------------------------------
                            frm.section === "slider" ? 

                                <FFslider

                                    indx={i}
                                    data={frm.data} 
                                    handle={(v) => props.handle(v)}
                                    problemArr={props.problemArr}

                                />

                            : null
                        
                            //-------------------------------------
                            }


                            {
                            //------------------------------------
                            //dualslider ( data = {} )
                            //------------------------------------
                            frm.section === "dualslider" ? 

                                <FFdualslider

                                    indx={i}
                                    data={frm.data} 
                                    handle={(v) => props.handle(v)}
                                    problemArr={props.problemArr}

                                />

                            : null
                        
                            //-------------------------------------
                            }







                            {
                            //------------------------------------
                            //fields ( data = [] )
                            //------------------------------------
                            frm.section === "fields" ? 

                                <div>
                                {frm.data.map((data, j) => (


                                    <div
                                    key={i+"-"+j}
                                    >
                                        <FFfield 

                                            ij={i+"-"+j}
                                            data={data}
                                            handle={(v) => props.handle(v)}
                                            problemArr={props.problemArr}

                                            handleMovement={(v) => props.handleMovement(v)}
                                            bumperRef={props.bumperRef}

                          
                                        />

                                    </div>

                                ))}
                                </div>
                                

                            : null
                        
                            //-------------------------------------
                            }








                            {
                            //------------------------------------
                            //labelfields ( data = [] )
                            //------------------------------------
                            frm.section === "labelfields" ? 

                                <div>

                                {frm.data.map((data, j) => (

                                    <div
                                    key={i+"-"+j}
                                    >
                                        <FFlabelfield

                                            ij={i+"-"+j}
                                            data={data}
                                            handle={(v) => props.handle(v)}
                                            problemArr={props.problemArr}
                                            viewPasswords={props.viewPasswords}
  
                                            handleMovement={(v) => props.handleMovement(v)}

                                    
                                            bumperRef={props.bumperRef}

                                        />

                                    </div>

                                ))}

                                </div>

                            : null
                        
                            //-------------------------------------
                            }




                            {
                            //------------------------------------
                            //titleline ( data = {} )
                            //------------------------------------
                            frm.section === "edittitle" ? 

                                <FFedittitle

                                    indx={i}
                                    data={frm.data} 
                                    handle={(v) => props.handle(v)}

                                />

                            : null

                            }


                            {
                            //------------------------------------
                            //editfields ( data = [] )
                            //------------------------------------
                            frm.section === "editfields" ? 

                                <div>

                                {frm.data.map((data, j) => (

                                    <div
                                    key={i+"-"+j}
                                    >
                                        <FFeditfield

                                            ij={i+"-"+j}
                                            data={data}
                                            handle={(v) => props.handle(v)}
                                            problemArr={props.problemArr}

                                            handleMovement={(v) => props.handleMovement(v)}

                                          
                        
                                        />

                                    </div>

                                ))}

                                </div>

                            : null
                        
                            //-------------------------------------
                            }


                            {
                            //------------------------------------
                            //button ( data = {} )
                            //------------------------------------
                            frm.section === "editlink" ? 

                                <FFeditlink

                                    indx={i}
                                    data={frm.data} 
                                    handle={(v) => props.handle(v)}

                                />

                            : null

                            }







                        </div>


                    ))}

    
                </div>

            </div>
            </FormBox>
            </FormContainer>
        </FormColumn>
        </div>


    )



}

export default Show;

