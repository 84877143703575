import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import {

    ReportGraphic

} from '../Styled';


// import SVGProfitPie from 'grfx/tpc_calc_profit_pie.svg';
// import SVGPriceTag from 'grfx/tpc_calc_price_tag.svg';
// import SVGBreakevenTag from 'grfx/tpc_calc_breakeven_tag.svg';
// import SVGCogs from 'grfx/tpc_calc_cogs.svg';


import SVGBillable from 'grfx/tpc_billable.svg';
import SVGCalendar from 'grfx/tpc_calendar.svg';
import SVGClock from 'grfx/tpc_clock.svg';
import SVGHealthcare from 'grfx/tpc_healthcare.svg';
import SVGIra from 'grfx/tpc_ira.svg';

import SVGOwner from 'grfx/tpc_ownr.svg';
import SVGEmployee from 'grfx/tpc_empl.svg';
import SVGMaterials from 'grfx/tpc_materials.svg';


import SVGOwnerEven from 'grfx/ownr_empl/ownr_even.svg';
import SVGOwnerDirect from 'grfx/ownr_empl/ownr_direct.svg';
import SVGOwnerDirectIndirect from 'grfx/ownr_empl/ownr_direct_indirect.svg';
import SVGOwnerIndirect from 'grfx/ownr_empl/ownr_indirect.svg';
import SVGOwnerIndirectDirect from 'grfx/ownr_empl/ownr_indirect_direct.svg';

import SVGEmployeeEven from 'grfx/ownr_empl/empl_even.svg';
import SVGEmployeeDirect from 'grfx/ownr_empl/empl_direct.svg';
import SVGEmployeeDirectIndirect from 'grfx/ownr_empl/empl_direct_indirect.svg';
import SVGEmployeeIndirect from 'grfx/ownr_empl/empl_indirect.svg';
import SVGEmployeeIndirectDirect from 'grfx/ownr_empl/empl_indirect_direct.svg';



import {

    // ACCOUNT as ACCOUNT_GRAPHIC,
    // EMPLOYEE as EMPLOYEE_GRAPHIC,
    // EXPENSES as EXPENSES_GRAPHIC,
    // OVERHEAD as OVERHEAD_GRAPHIC,
    // OWNER as OWNER_GRAPHIC,
    // SCHEDULE as SCHEDULE_GRAPHIC,

    // CALCULATOR as CALCULATOR_GRAPHIC,
    // DIRECT_LABOR_HOURS as HOURS_GRAPHIC,
    // DIRECT_LABOR_RATES as RATES_GRAPHIC,
    // HEALTHCARE as HEALTHCARE_GRAPHIC,
    // IRA as IRA_GRAPHIC,
    // PRICING as PRICING_GRAPHIC,
    // //PRICING_DOLLAR_SIGN as PRICING_GRAPHIC,
    // REPORTS as REPORTS_GRAPHIC,
    // SETTINGS as SETTINGS_GRAPHIC,

    // KNOWLEDGE_BASE as KNOWLEDGEBASE_GRAPHIC,


    // LOGIN as LOGIN_GRAPHIC,
    // SIGNUP as SIGNUP_GRAPHIC,


    PROFIT_PIE as PROFIT_PIE_GRAPHIC,
    PRICE_TAG as PRICE_TAG_GRAPHIC,
    BREAKEVEN_TAG as BREAKEVEN_TAG_GRAPHIC,
    COGS as COGS_GRAPHIC,


} from 'grfx/SVG_COMPS';





const Index = (props) => {


    // -- ref -- //
    // const refA = useRef(null);


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState(false);


    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

        }else{



            var svgSrc;

            switch(props.data.svgCall)
            {
            // case'cogs':
            //     svgSrc = SVGCogs;
            // break;

            // case'profit_pie':
            //     svgSrc = SVGProfitPie;
            // break;
            // case'price_tag':
            //     svgSrc = SVGPriceTag;
            // break;
            // case'breakeven_tag':
            //     svgSrc = SVGBreakevenTag;
            // break;


            case'billable':
                svgSrc = SVGBillable;
            break;
            case'calendar':
                svgSrc = SVGCalendar;
            break;
            case'clock':
                svgSrc = SVGClock;
            break;
            case'owner':
                svgSrc = SVGOwner;
            break;
            case'employee':
                svgSrc = SVGEmployee;
            break;
            case'healthcare':
                svgSrc = SVGHealthcare;
            break;
            case'ira':
                svgSrc = SVGIra;
            break;
            case'materials':
                svgSrc = SVGMaterials;
            break;



            case'ownr_even':
                svgSrc = SVGOwnerEven;
            break;
            case'ownr_direct':
                svgSrc = SVGOwnerDirect;
            break;
            case'ownr_direct_indirect':
                svgSrc = SVGOwnerDirectIndirect;
            break;
            case'ownr_indirect':
                svgSrc = SVGOwnerIndirect;
            break;
            case'ownr_indirect_direct':
                svgSrc = SVGOwnerIndirectDirect;
            break;


            case'empl_even':
                svgSrc = SVGEmployeeEven;
            break;
            case'empl_direct':
                svgSrc = SVGEmployeeDirect;
            break;
            case'empl_direct_indirect':
                svgSrc = SVGEmployeeDirectIndirect;
            break;
            case'empl_indirect':
                svgSrc = SVGEmployeeIndirect;
            break;
            case'empl_indirect_direct':
                svgSrc = SVGEmployeeIndirectDirect;
            break;


            default:
            }



            var margin;
            var marginTop;
            var marginLeft;
            if(props.lessThanThresh)
            {
                marginTop = "0px";
                marginLeft = "calc(50% - "+(props.data.svgWidth/2)+"px)";
            }else{
                marginTop = (props.data.boxHeight - props.data.svgHeight)/2+"px";
                marginLeft = "calc(50% - "+(props.data.svgWidth/2)+"px)"; 
            }
            margin = marginTop+" 0 0 "+marginLeft;





            var darklite,iconColor;

            darklite = "dark";
            iconColor = false;
          
            if(props.data.darklite) darklite= props.data.darklite;
            if(props.data.iconColor) iconColor = props.data.iconColor;


            setData({

                id:props.id,
                margin:margin,

                boxWidth:props.data.boxWidth,
                boxHeight:props.data.boxHeight,

                padding:props.data.padding,
   
                svgWidth:props.data.svgWidth,
                svgHeight:props.data.svgHeight,
                svgSrc:svgSrc,

                graphic:{

                    darklite:darklite, 
                    color:iconColor
              
                }

            });





        }
        //console.log("graphicData: "+JSON.stringify(props.data,null,2));
        // alert('graphicData');
    },
    [
        props,
        isLoaded
    ])





    if(!data)
    {
        return (

            <ReportGraphic
            //backgroundColor={"lightblue"}
            // width={data.svgWidth+"px"}
            // height={data.svgHeight+"px"}
            // margin={data.margin}
            >


            </ReportGraphic>

        )

    }else{


        return (

            <ReportGraphic
            //backgroundColor={"lightblue"}
            width={data.svgWidth+"px"}
            height={data.svgHeight+"px"}
            margin={data.margin}
            padding={data.padding}
            >

                {
                props.data.svgCall === 'cogs'
                ?

                    <COGS_GRAPHIC
                                            
                        darklite = {data.graphic.darklite}

                        // boxInsideColor = {"rgb(100,100,100)"}
                        // boxColor = {"rgb(255,255,255)"}
                        // coinSignColor = {"rgb(255,195,20)"}
                        // coinColor = {"rgb(255,165,25)"}

                    />

                :
                props.data.svgCall === 'profit_pie'
                ?

                    <PROFIT_PIE_GRAPHIC
                                        
                        darklite = {data.graphic.darklite}

                        pieceColor = {data.graphic.color}
                        //pieColor = {rgb(255,255,255)}

                    />

                :
                props.data.svgCall === 'price_tag'
                ?

                    <PRICE_TAG_GRAPHIC
                                            
                      
                        darklite = {data.graphic.darklite}     

                        //outerColor = {"rgb(255,255,255)"}
                        //innerColor = {"rgb(30,35,45)"}
                        signColor = {data.graphic.color}

                    />

                :
                props.data.svgCall === 'breakeven_tag'
                ?


                    <BREAKEVEN_TAG_GRAPHIC
                                            
                        //iconColor = {data.graphic.color}
                        darklite = {data.graphic.darklite}

                        //outerColor = {"rgb(255,255,255)"}
                        //innerColor = {"rgb(30,35,45)"}
                        signColor = {data.graphic.color}
 
                    />



                :
                props.data.svgCall === 'labor'
                ?

                    <img
                        id={data.id}
                        className="Img"
                        src={SVGEmployeeDirect} 
                        alt="graphic" 
                    /> 

 

                :
                props.data.svgCall === 'materials'
                ?

                    <img
                        id={data.id}
                        className="Img"
                        src={data.svgSrc} 
                        alt="graphic" 
                    /> 

                :

                    <img
                        id={data.id}
                        className="Img"
                        src={data.svgSrc} 
                        alt="graphic" 
                    /> 


                }

            </ReportGraphic>

        )

    


    }




}

export default Index;


