import styled from "styled-components";



export const ReportItem = styled.div.attrs(props => ({

    dark:               props.dark || false,
    darkBorder:         props.darkBorder || "1px solid #7c828f",

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid gray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 7px 0",
    padding:            props.padding || "0 0 0 0",


    // border:${props => props.border};


    // @media (max-width: 575px) {

    //     height:80px;
    // }



}))`


    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};

     ${props => props.dark && `
        
        border-bottom:${props.darkBorder};

    `}   

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;






`;




    export const ReportItemName = styled.div.attrs(props => ({


        dark:               props.dark || false,
        darkTextColor:      props.darkTextColor || "#fcfcfc",

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "calc(30% - 12px)",
        height:             props.height || "auto",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",

        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "18px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "black",


        linkFontFamily:         props.linkFontFamily || 'Arial, Helvetica, sans-serif',
        linkFontSize:           props.linkFontSize || "18px",
        linkFontWeight:         props.linkFontWeight  || "500",
        linkTextAlign:          props.linkTextAlign || "center",
        linkTextDecoration:     props.linkTextDecoration  || "underline",
        linkTextColor:          props.linkTextColor || "#07a2e3"


    }))`


        background-color:${props => props.backgroundColor};
   
        width:${props => props.width};
        height:${props => props.height};
     
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;

 


        .Text {

            background-color:transparent;
    
            width:auto;
            height:auto;
            padding:11px 10px 4px 10px;
            margin:0 10px 0 0;
            position:relative;
            float:left;
    
            cursor:pointer;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};

            ${props => props.dark && `
    
                color:${props.darkTextColor};

            `}

    
            &:hover{
    
                color:#191e28;
                text-decoration:underline;
    
            }
    

        }



        .PlainText {

            background-color:transparent;
    
            width:auto;
            height:auto;
            padding:11px 0px 4px 0px;
            margin:0 10px 0 0;
            position:relative;
            float:left;
    
            cursor:default;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};


            ${props => props.dark && `
    
                color:${props.darkTextColor};

            `}



            word-wrap: break-word;

    

        }


        .LinkText {

            background-color:transparent;
    
            width:auto;
            height:auto;
            padding:11px 10px 4px 10px;
            margin:0 10px 0 0;
            position:relative;
            float:left;
    
            cursor:pointer;
    
            font-family:${props => props.linkFontFamily};
            font-size:${props => props.linkFontSize};
            font-weight:${props => props.linkFontWeight};
            text-align:${props => props.linkTextAlign};
            text-decoration:${props => props.linkTextDecoration};
            color:${props => props.linkTextColor};

            ${props => props.dark && `
    
                color:${props.darkTextColor};

            `}


    
            &:hover{
    
                color:#191e28;
                text-decoration:underline;
    
            }
    

        }






    `;



    export const ReportItemValue = styled.div.attrs(props => ({

        dark:               props.dark || false,
        darkTextColor:      props.darkTextColor || "#fcfcfc",

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "calc(49% - 1px)",
        height:             props.height || "auto",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",


        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "18px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "right",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "black",


        buttonFontFamily:         props.buttonFontFamily || 'Arial, Helvetica, sans-serif',
        buttonFontSize:           props.buttonFontSize || "16px",
        buttonFontWeight:         props.buttonFontWeight  || "500",
        buttonTextAlign:          props.buttonTextAlign || "center",
        buttonTextDecoration:     props.buttonTextDecoration  || "underline",
        buttonTextColor:          props.buttonTextColor || "#07a2e3"


        //border-left:${props => props.border};


    }))`


        background-color:${props => props.backgroundColor};


        width:${props => props.width};
        height:${props => props.height};

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:right;

        overflow:hidden;


        .Text {

            background-color:transparent;
    
            width:auto;
            height:auto;
            padding:11px 10px 4px 10px;
            margin:0 10px 0 0;
            position:relative;
            float:left;
    
            cursor:pointer;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};

            ${props => props.dark && `
    
                color:${props.darkTextColor};

            `}

    
            &:hover{
    
                color:#191e28;
                text-decoration:underline;
    
            }
    

        }


        .PlainText {

            background-color:transparent;
    
            width:calc(100% - 0px);
            height:auto;
            padding:11px 0 4px 0;
            margin:0 0 0 0;
            position:relative;
            float:left;
    
            cursor:default;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};

            ${props => props.dark && `
    
                color:${props.darkTextColor};

            `}


            word-wrap: break-word;
    
    
        }




        .ValueText {

            background-color:transparent;
    
            width:calc(100% - 25px);
            height:auto;
            padding:11px 10px 4px 10px;
            margin:0 10px 0 0;
            position:relative;
            float:left;
    
            cursor:default;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};

            ${props => props.dark && `
    
                color:${props.darkTextColor};

            `}



            word-wrap: break-word;
    
    
        }



        .Button {

            background-color:transparent;
    
            width:40px;
            height:auto;
            padding:9px 0 6px 0;
            margin:0 0 0 0;

            position:absolute;
            right:7px;
            bottom:2px;
            z-index:2;
    
            cursor:pointer;
    
            font-family:${props => props.buttonFontFamily};
            font-size:${props => props.buttonFontSize};
            font-weight:${props => props.buttonFontWeight};
            text-align:${props => props.buttonTextAlign};
            text-decoration:${props => props.buttonTextDecoration};
            color:${props => props.buttonTextColor};


            &:hover{
        
                color:#191e28;
            
            }

        }











    `;




    export const ReportItemLineName = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "70%",
        height:             props.height || "100%",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",

        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "18px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "black"

    }))`


        background-color:${props => props.backgroundColor};

        width:${props => props.width};
        height:${props => props.height};
     
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;



        @media (max-width: 800px) {

            width:calc(100% - 180px);
        }

        @media (max-width: 475px) {

            width:calc(100% - 140px);
        }
    




        .Text {

            background-color:transparent;
    
            width:auto;
            height:auto;
            padding:11px 10px 4px 10px;
            margin:0 10px 0 0;
            position:relative;
            float:left;
    
            cursor:pointer;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};
    
            &:hover{
    
                color:#191e28;
                text-decoration:underline;
    
            }
    

        }



        .PlainText {

            background-color:transparent;
    
            width:auto;
            height:auto;
            padding:11px 10px 4px 10px;
            margin:0 10px 0 0;
            position:relative;
            float:left;
    
            cursor:default;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};


            word-wrap: break-word;
    

        }





    `;




    export const ReportItemControls = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "calc(25% - 1px)",
        height:             props.height || "100%",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",


        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "16px",
        fontWeight:         props.fontWeight  || "550",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "black"


    }))`


        background-color:${props => props.backgroundColor};
        border-left:${props => props.border};

        width:${props => props.width};
        height:${props => props.height};

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:right;

        overflow:hidden;


        @media (max-width: 800px) {

            width:125px;
        }
    




        .Button {

            background-color:transparent;
    
            width:auto;
            height:35px;
            padding:0 4.5px 0 4.5px;
            margin:2.5px 0 0 0;
            position:relative;
            float:left;
    
            cursor:pointer;
    
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};
    
            &:hover{
    
                color:#191e28;
    
            }
    
    
    
        }
    
        .ButtonMask {
    
            background-color:transparent;
    
            width:35px;
            height:35px;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:absolute;
            top:0px;
            left:0px;
            z-index:1;
    
            cursor:pointer;
    
        }
    
    
        .Img {
    
            width:35px;
            height:35px;
    
        }
    




    `;











