import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';


//import '../../StyleSheets/dynamic-form.css';
import 'app-style/dynamic-form/style.css';


export const FFlist = (props) => {


    const [dataArray, setDataArray] = useState([]);

    useEffect(() => {

        var array;
        array = [
            "This is my first list item", 
            "This is my second list item with extra long text to determine threshold"
        ];
        if(props.data 
        && Object.prototype.toString.call(props.data) === '[object Array]')
        {
            array = JSON.parse(JSON.stringify(props.data));

        }
        setDataArray(array);


     },[props])
 


    return (

        <div
        key={props.indx+"_fflist"} 
        className = "list"
        >
            {
            dataArray.map((dat, i) => (


                <div
                key={props.indx+"_fflist_line_"+i} 
                className = "line"
                >
                    <div
                    className = "bullet"
                    // style={{

                    //     backgroundColor:dat.style.bullet.color,
                    //     width:dat.style.bullet.size,
                    //     height:dat.style.bullet.size,

                    // }}
                    >
                    </div>

                    <div 
                    className = "text"
                    // style={{

                    //     fontSize:dat.style.text.fontSize,
                    //     color:dat.style.text.color,

                    // }}
                    >
                        {dat.text}

                    </div>


                </div>

            ))
            }

            
        </div>

    )

}
