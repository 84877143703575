
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';

import { 
    useSelector, 
    useDispatch 
} 
from 'react-redux';
import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    //useLocation,
    useHistory,   
    //useParams,

} from "react-router-dom";




import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
import useDeviceDetect from 'utils/hooks/DeviceDetect';
//import useKeyPress from 'utils/hooks/KeyPress';





//import { useLiqGrid } from 'utils/myHooks/LiquidFormula/LiqGrid';

//import 'app-style/alfabase.css';//alfa,base,tab,tab-x,flank
import { Alfa, Base, Tab, Bumper } from 'app-styled/AlfaBase.js';

import { Flyr } from './Styled';

import Canvas from './Comps/Canvas';
import Chart from './Comps/Chart';
import Chooser from './Comps/Chooser';
import Cards from './Comps/Cards';

import Print from './Comps/Print';
import Report from './Comps/Report';

import Form from './Comps/Form';
import FormFly from './Comps/Form/FormFly';

import Handler from './Handler';

import { LibElem, LibDetect } from 'library';


const Index = (props) => {
   

    // -- window dims -- //
    const { width, height } = useWindowDimensions();


    // -- router -- //
    //let match = useRouteMatch();
    //let location = useLocation();
    const history = useHistory();


    // -- Device Detect -- //
    const DeviceDetection = useDeviceDetect();
    //console.log("FFcostfield - DeviceDetection " + JSON.stringify(DeviceDetection));
    let isMobile = DeviceDetection.isMobile;




    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));

    //if(zoneObj['handle'] && zoneObj['handle'] !== undefined) alert(zoneObj['handle']);





    // -- state -- //
    const [highZ, setHighZ] = useState(0)

    useEffect(() => {
        
        //setFlyrHeight(flyrRef.current.offsetHeight);
        if(highZ === 0)
        {
            var z = LibElem.HighestZ();
            setHighZ(z)

        }

    }, [highZ])






    // -- vars -- //
    var title;
    //var call;
    var type = 'default';
    var data;
    var styl;
    var currentKey;
    var submitCall = false;
    var postTo = false;
    //call = props.data.call;

    var level;
    if(props.data.data.data !== undefined) level = 2;
    else if(props.data.data !== undefined) level = 1;
    else level = 0;





    //console.log("props.data : "+JSON.stringify(props.data.data,null,2));

    
    switch(level)
    {
    case 1:

        type = props.data.flyr.type;
        data = props.data.data;
        styl = props.data.flyr.styl;
    break;
    case 2:

        type = props.data.data.flyr.type;
        data = props.data.data.data;
        styl = props.data.data.flyr.styl;
    break;
    default:

        type = props.flyr.type;
        data = props.data;
        styl = props.flyr.styl;
    break;
    }//===



    switch(type.toLowerCase())
    {
    case'canvas':   
        title = 'Canvas'; 

    break;
    case'chart': 

        title = 'Chart'; 
        if(data.name !== '') title = data.name;


        console.log("Flyr - chart type: "+type);
        console.log("Flyr - chart data: "+JSON.stringify(data,null,2));


    break;
    case'css_chart': 

        title = 'Css Chart'; 
        if(data.name !== '') title = data.name;


        console.log("Flyr - chart type: "+type);
        console.log("Flyr - chart data: "+JSON.stringify(data,null,2));

    break;
    case'chooser':  

        title = 'Choose'; 
        if(data.name !== '') title = data.name;

    break;
    case'cards':

        title = 'Cards'; 
        if(data.name !== '') title = data.name;
    break;
    case'print':

        title = 'Print'; 
        if(data.name !== '') title = data.name;
    break;
    case'report':

        title = 'Report'; 
        if(data.name !== '') title = data.name;
    break;
    case'form':     
        title = 'Edit'; 


        switch(level)
        {
        case 1:
            currentKey=props.data.keyname
            submitCall = props.data.submitCall;
            postTo = props.data.postTo;
        break;
        case 2:
            currentKey=props.data.data.keyname;
            submitCall = props.data.data.submitCall;
            postTo = props.data.data.postTo;
        break;
        default:
            currentKey = props.keyname;
            submitCall = props.submitCall;
            postTo = props.postTo;
        break;
        }//===


        // console.log("form");

    break;
    default:
        title = '';      
    }



    // console.log("currentKey: "+currentKey)
    // console.log("LEVEL: "+level)
    // console.log("TYPE: "+type)
    // console.log("TITLE: "+title)
    //console.log("props.data: "+JSON.stringify(props.data,null,2));
    //console.log("props.data.data: "+JSON.stringify(props.data.data,null,2));
    //console.log("props.data.data.data: "+JSON.stringify(props.data.data.data,null,2))










    if(width < 400) title = '';


    var maxWidth;
    maxWidth = 900;

    if(styl
    && styl !== undefined) 
    {
        maxWidth = styl.maxWidth;
    }

    if(width < maxWidth + 50)
    {
       maxWidth = width;

    }//==


    

    //#########################################################
    //#########################################################


    // -- redux -- //
    const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    useEffect(() => {


        var currentFlyr;
        if(data.name && data.name !== undefined)
        {
            currentFlyr = data.name.toLowerCase();
        }else{
            currentFlyr = 'tempflyr';
        }
        currentFlyr = currentFlyr.replace(/\//gi,"-");
        currentFlyr = currentFlyr.replace(/ /gi,"-");


        if(zoneObj['currentFlyr'] !== currentFlyr)
        {

            //alert('dispatch');
            dispatch(ModifyZoneStore({

                type:"UPDATE_ZONE",
                item:{
                    key     :"currentFlyr",
                    value   :currentFlyr
                }

            })); 

            //console.log(zoneObj,null,2);
            //alert('initialize flyr');

            if(type === 'form')
            {


                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_formArr",
                        value   :[]
                    }
    
                })); 


                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_problemArr",
                        value   :[]
                    }
    
                })); 



                // -- submit - complete -- //

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_runSubmit",
                        value   :false
                    }
    
                })); 

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_runComplete",
                        value   :false
                    }
    
                })); 



                // -- error - errorType -- /

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_runError",
                        value   :false
                    }
    
                })); 


                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_errorType",
                        value   :''
                    }
    
                })); 





                // -- account - calculate - output - submitCall - postTo -- //

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_runAccount",
                        value   :false
                    }
    
                })); 

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_runCalculate",
                        value   :false
                    }
    
                })); 

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_runOutput",
                        value   :false
                    }
    
                })); 

                dispatch(ModifyZoneStore({

                    type:"DELETE_ZONE",
                    item:{
                        key     :currentFlyr + "_output",
                        value   :false
                    }
    
                })); 


                dispatch(ModifyZoneStore({

                    type:"DELETE_ZONE",
                    item:{
                        key     :currentFlyr + "_postTo",
                        value   :false
                    }
    
                })); 

                dispatch(ModifyZoneStore({

                    type:"DELETE_ZONE",
                    item:{
                        key     :currentFlyr + "_currentKey",
                        value   :false
                    }
    
                })); 

                dispatch(ModifyZoneStore({

                    type:"DELETE_ZONE",
                    item:{
                        key     :currentFlyr + "_completeMessage",
                        value   :false
                    }
    
                })); 



            }



        }//==



    },[

        dispatch,
        type,
        data,
        zoneObj

    ])






    //#########################################################
    //#########################################################


    // -- ref -- //
    const baseRef = useRef(null);
    const tabRef = useRef(null);
    const bumperRef = useRef(null);
    //const flankRef = useRef(null);
    const flyrRef = useRef(null);
    const binRef = useRef(null);

    
    /*
    const cellRefs = [];
    const setRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < cellRefs.length; i++)
            {
                if(cellRefs[i].id === ref.id) found = true;
            }
            if(!found) cellRefs.push(ref);
            //console.log(cellRefs.length);

        }

    };
    */

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [baseScrollHeight, setBaseScrollHeight] = useState(0);
    const [tabHeight, setTabHeight] = useState(10);
    const [flyrHeight, setFlyrHeight] = useState(height);
    const [flyrType, setFlyrType] = useState(false);
    const [flyrData, setFlyrData] = useState(false);
    const [flyrStyl, setFlyrStyl] = useState(false);
    const [contentsHeight, setContentsHeight] = useState(200);


    const [flyrW, setFlyrW] = useState(10);
    const [flyrH, setFlyrH] = useState(0);

    const [flyrMart, setFlyrMart] = useState(0);
    const [flyrMarl, setFlyrMarl] = useState(0);
    const [flyrPadBottom, setFlyrPadBottom] = useState(0);
    
    const [tabMart, setTabMart] = useState(0);
    const [tabMarl, setTabMarl] = useState(0);
    const [bumperMarb, setBumperMarb] = useState(0);

    const [flankHeight, setFlankHeight] = useState(0);


    useEffect(() => {


        if(!isLoaded)
        {

            setIsLoaded(true);


        }else{


            setFlyrType(type.toLowerCase());
            setFlyrStyl(styl);
            setFlyrData(data);


            //console.log("VPowners "+JSON.stringify(VPowners,null,2));
            //console.log("VPowner "+JSON.stringify(VPowner,null,2));
            
            //console.log("DATA:::::::::::"+JSON.stringify(data,null,2));
            //alert("DATA");


            var flyrPadBottomInt = 0;
            var flyrPadBottom = flyrPadBottomInt + "px";
        
             var flyrWInt;
             var flyrW;
        
             var flyrH;
        
             var flyrMarl;
        
             var flyrMartInt;
             var flyrMart;
        
             var tabMartInt;
             var tabMart;
             var tabMarl;
        
             var bumperMarbInt;
             var bumperMarb;

             var flankHeight;




            if(tabHeight < 50)
            {
                setTabHeight(tabRef.current.offsetHeight);
            }

            if(flyrHeight < 50)
            {
                setBaseScrollHeight(baseRef.current.scrollHeight);
                flyrH = flyrRef.current.offsetHeight;
                
            }
            else
            if(flyrHeight < contentsHeight+50)
            {
                if(contentsHeight < height) 
                    flyrH = baseRef.current.scrollHeight; 
                else
                    flyrH = contentsHeight+50; 

            }//==


            if(flyrHeight < binRef.current.offsetHeight)
            {
                flyrH = baseRef.current.scrollHeight;
            }

            
            if(flyrH !== undefined) setFlyrHeight(flyrH);    


            /*
            console.log(""

                +   "\n FLYR HEIGHT: " +flyrHeight

                +   "\n CONTENTS: " +contentsHeight 
                +   "\n BIN: "+binRef.current.offsetHeight
                +   "\n FLYR: " +flyrRef.current.offsetHeight
                +   "\n TAB: " +tabRef.current.offsetHeight
                +   "\n BASE SCROLL: " +baseRef.current.scrollHeight
                +   "\n HEIGHT: " +height
    
            );
            */


            // if(type === 'cards')
            // {

            //     console.log(""

            //         //+"\nCALL: "+call
            //         +"\nTYPE: "+type
            //         +"\nDATA: "+JSON.stringify(data,null,2)
            //         +"\nSTYL: "+JSON.stringify(styl,null,2)
            //     );
                

            //     //console.log("PROPS DATA :::::::::"+JSON.stringify(props.data,null,2));
            //     alert('props');


            // }







            //###########################################################
            //###########################################################

             flyrWInt = maxWidth;
             flyrMarl = (width - flyrWInt)/2 + "px";

             if(width < flyrWInt+50)
             {
                flyrWInt = width;
                flyrMarl = 0 + "px";
             }//==

             flyrW = flyrWInt + "px";

            //-------------------------------------------

            flyrMartInt = tabHeight;
            tabMartInt = 0;
            bumperMarbInt = 0;

            flyrMart = flyrMartInt + 'px';
            tabMart = tabMartInt + "px";
            tabMarl = (width - flyrWInt)/2 + "px";


            bumperMarb = bumperMarbInt + "px";

            //-------------------------------------------

            // flankLeftWidthInt = (width - flyrWInt)/2;
            // flankLeftWidth = flankLeftWidthInt + "px";
            // flankLeftMarl = "0px";
            
            // flankRightWidthInt = (width - flyrWInt)/2;
            // flankRightWidth = flankRightWidthInt + "px";
            // flankRightMarl = flankLeftWidthInt + flyrWInt + "px";


            //flyrH = baseScrollHeight - (tabHeight+flyrPadBottomInt) + "px";
            flyrH = flyrHeight + (0) + "px";
            flankHeight = flyrHeight + (flyrMartInt) + "px";

        
            //alert(flankHeight);

            setFlyrW(flyrW);
            setFlyrH(flyrH);

            setFlyrMart(flyrMart);
            setFlyrMarl(flyrMarl);
            setFlyrPadBottom(flyrPadBottom);
            
            setTabMart(tabMart);
            setTabMarl(tabMarl);
            setBumperMarb(bumperMarb);

            setFlankHeight(flankHeight);




        }





    },[

        props,

        width,
        height,
        maxWidth,

        baseScrollHeight,
        tabHeight,     
        flyrHeight,
        contentsHeight,

        type,
        data,
        styl,

        isLoaded,



        flyrRef,tabRef,




    ])








    //---------------------------------------------
    // state/effect - view zoneObj
    //---------------------------------------------

    const [zoneWasViewed, setZoneWasViewed] = useState(false);

    useEffect(() => {


        //alert(animateOut);
        //console.log(boardNumber+" "+props.data.boardNumber);


        if(!zoneWasViewed)
        {
            // console.log("transition..."+boardNumber+" --- "+props.data.boardNumber);

            // var transInterval = setInterval(() => {

            //     console.log("Billboard Overlay - transInterval");
          

            // }, 200);

            // return () => { 

            //     clearInterval(transInterval); 
            //     setBoardNumber(props.data.boardNumber)
            // }


            // console.log("===== zoneObj =====");
            // console.log(JSON.stringify(zoneObj,null,2));


            setZoneWasViewed(true);
    
        }else{

            //Delay before starting animation
            // var timer;
            // timer = setTimeout(() => {

            //     setZoneWasViewed(false);

            // }, 500);
            // return () => clearTimeout(timer);

        } 

    },
    [
        props,
        //isLoaded,
        zoneObj,
        zoneWasViewed

    ])







    //---------------------------------------------
    // state/effect - showBumper
    //---------------------------------------------

    const [showBumper, setShowBumper] = useState(false);
    const [evalH, setEvalH] = useState(0);

    useEffect(() => {


        if(isLoaded)
        {

            var mob = LibDetect.Mobile();
            //console.log("is_mobile: "+mob.is_mobile);

            if(mob.is_mobile)
            {
                //setShowBumper(true);
                //setEvalH("equal");
            }//endif mob


            switch(flyrType)
            {
            case'form':


                var currentFlyrHeight;
                currentFlyrHeight = flyrH.replace(/px/gi,"");
                currentFlyrHeight = parseInt(currentFlyrHeight);
                // console.log(""
                // +"\ncurrentFlyrHeight: "+currentFlyrHeight
                // +"\n baseRef.current.scrollHeight: "+baseRef.current.scrollHeight
                // +"\n baseRef.current.offsetHeight: "+baseRef.current.offsetHeight
                // );

                if(evalH === 'equal')
                {
                    if((baseRef.current.scrollHeight - baseRef.current.offsetHeight) < 100)
                    {
                        //console.log("adjusting...");
                        var max = (flyrRef.current.offsetHeight-baseRef.current.offsetHeight)-100;
                        if(baseRef.current.scrollTop > max)
                        {
                            //console.log("Showing Bumper");
                            setShowBumper(true);
                
                            
                        }

                    }//==
        
                }
                else
                if(currentFlyrHeight === baseRef.current.offsetHeight)
                {

                    //wait and see if remains equal
                    setEvalH("equal");

                }


            break;
            default:
            }

                
        

        }//==

        

    },
    [
  
        baseRef,
        flyrRef,
        isLoaded,
        flyrType,
        flyrH,
        evalH

    ])







    const handleCheckScroll = () => {


        switch(flyrType)
        {
        case'form':

            // console.log(baseRef.current.scrollHeight);
            // console.log(flyrType);

            //console.log(baseRef.current.scrollTop+" > "+(baseRef.current.scrollHeight-200));
            //console.log(baseRef.current.scrollTop+" > "+(baseRef.current.offsetHeight));
            //console.log(baseRef.current.scrollTop+" > "+(flyrRef.current.offsetHeight-baseRef.current.offsetHeight));

            var max = (flyrRef.current.offsetHeight-baseRef.current.offsetHeight)-100;
            if(baseRef.current.scrollTop > max)
            {
                //console.log("Showing Bumper");
                if(!showBumper) setShowBumper(true);
            }else{
        
                //console.log("Hide Bumper");
                if(showBumper) setShowBumper(false);
            }

        break;
        default:
        }

        var mob = LibDetect.Mobile();
        if(mob.is_mobile)
        {
            //setShowBumper(true);            
        
        }//endif mob
    
            

    }




    //---------------------------------------------
    // state/effect - others
    //---------------------------------------------

    const [viewPasswords,setViewPasswords] = useState(false);


    const handleLogout = (hndl) => {

        setTimeout(function(){

            history.push("/Logout/");

        },1500);

    }


    // -- handle -- //

    const handle = (hndl) => {

        //console.log(hndl);


        // console.log(JSON.stringify(hndl,null,2));
        // alert("utils/components/Flyr - handle");


        hndl['zoneObj'] = zoneObj;
        hndl['viewPasswords'] = viewPasswords;
        hndl['handleState'] = (v) => {

            if(v.call === 'contentsHeight') setContentsHeight(v.value);
            if(v.call === 'handleDispatch') handleDispatch(v.value);
            if(v.call === 'handleCloseFlyr') handleCloseFlyr(v.value); 
            if(v.call === 'primeHandle') primeHandle(v.value); 
            if(v.call === 'viewPasswords') setViewPasswords(v.value);
            if(v.call === 'loginRequired') handleLogout(v.value);
      
        };
        hndl['handleRoute'] = (v) => {

            switch(v.call)
            {
            case'goBack':
    
                history.goBack();
            break;
            case'reload':
    
                window.location.reload();//regular dom 
            break;
            case'redirect':
    
                history.replace(v.value);
            break;
            case'push':

                history.push(v.value);
            break;
            default:
            }
    
        }
        Handler(hndl);


    }


    const primeHandle = (hndl) => {

        //alert(hndl.handle);
        switch(hndl.handle)
        {
        case'messngr':

            props.handle({

                call:hndl.action

            });

        break;
        case'flyr':


            props.handle({

                call:hndl.action

            });

        break;
        case'removeHash':


            props.handle({

                call:hndl.action

            });

        break;
        default:
        }



    }

    const handleDispatch = (hndl) => {

        //if(hndl.action === 'DELETE_ZONE') alert('delete zone')
        //console.log('handleDispatch -- '+hndl.key+" "+hndl.value);
    
        dispatch(ModifyZoneStore({
    
            type:hndl.action,//UPDATE_ZONE or DELETE_ZONE
            item:{
                key     :hndl.key,
                value   :hndl.value
            }

        })); 

    }

    const handleCloseFlyr = (hndl) => {

        props.handle({

            call:'hide'

        })

    }




    


    //TM:

    //=============================================
    // handleMovement
    //=============================================

    function handleMovement(hndl) {

        //console.log("hndl.event.key: "+hndl.event.key);

        var formArr = [];
        var currentFlyr = false;
        if(zoneObj['currentFlyr'] && zoneObj['currentFlyr'] !== undefined)
        {
            currentFlyr = zoneObj['currentFlyr'];
            formArr = zoneObj[currentFlyr+"_formArr"];
        }


        //var activeLabelId = false;
        var elem = false;
        var formObj, dataObj;
        var find;
        var found = false;
        //var nextTab = 10000;
        var count;

        var ijArr;
        var el;
        var fromTop;


        if(hndl.event.key.toUpperCase() === 'ENTER')
        {
            //console.log(JSON.stringify(formArr,null,2));

            if(currentFlyr)
            {
    

                // // console.log("formArr: "+JSON.stringify(formArr,null,2));
                // alert("MOVE TO THE NEXT"

                //     +"\nhndl.index: "+hndl.index
                //     +"\nhndl.section: "+hndl.section
                //     +"\nhndl.event.key: "+hndl.event.key
                //     +"\nhndl.event.target.id: "+hndl.event.target.id
                //     +"\nhndl.event.target.value: "+hndl.event.target.value
                //     +"\nhndl.event.target.tabIndex: "+hndl.event.target.tabIndex

                // );




                //-----------------------------
                // find
                //-----------------------------

                for(formObj of formArr)
                {
                    //console.log("SECTION: "+formObj.section);

                    if(formObj.section === hndl.section)
                    {
                        
                        if(hndl.section === 'fields')
                        {
                            for(dataObj of formObj.data)
                            {

                                // if(hndl.event.target.id.match(/phone_/gi))
                                // {
                                //     //alert("PHONE BOX === "+hndl.event.target.id);
                                //     document.getElementById(hndl.event.target.id).blur();
                                //     find = parseInt(hndl.event.target.tabIndex) + 1;
                                //     break;
                                
                                // }
                                // else
                                if(dataObj.id === hndl.event.target.id)
                                { 
                                    //alert(dataObj.label.id +" === "+hndl.event.target.id);
                                    document.getElementById(dataObj.id).blur();
                                    find = parseInt(hndl.event.target.tabIndex) + 1;
                                    break;
                                }

                            }

                        }
                        else
                        if(hndl.section === 'labelfields')
                        {
                            for(dataObj of formObj.data)
                            {
                                if(dataObj.field.id === hndl.event.target.id)
                                { 
                                    //alert(dataObj.label.id +" === "+hndl.event.target.id);
                                    document.getElementById(dataObj.field.id).blur();
                                    find = parseInt(hndl.event.target.tabIndex) + 1;
                                    break;
                                }

                            }

                        }
                        else
                        if(hndl.section === 'editfields')
                        {

                            for(dataObj of formObj.data)
                            {
                                //alert(dataObj.field.id +" === "+hndl.event.target.id);          
                                if(dataObj.field.id === hndl.event.target.id)
                                { 
                                    document.getElementById(dataObj.field.id).blur();
                                    find = parseInt(hndl.event.target.tabIndex) + 1;
                                    break;
                                }

                            }

                        }
                        else
                        if(hndl.section === 'costfields')
                        {
                            for(dataObj of formObj.data)
                            {
                                if(dataObj.label.id === hndl.event.target.id)
                                { 
                                    //alert(dataObj.label.id +" === "+hndl.event.target.id);
                                    document.getElementById(dataObj.label.id).blur();
                                    find = parseInt(hndl.event.target.tabIndex) + 1;
                                    break;
                                }
                                if(dataObj.rate.id === hndl.event.target.id)
                                { 
                                    document.getElementById(dataObj.rate.id).blur();
                                    find = parseInt(hndl.event.target.tabIndex) + 1;
                                    break;
                                }
                                if(dataObj.qtyhrs.id === hndl.event.target.id)
                                {
                                    document.getElementById(dataObj.qtyhrs.id).blur();
                                    find = parseInt(hndl.event.target.tabIndex) + 1;
                                    break;
                                }

                            }  
                        
                        }
                        else
                        if(hndl.section === 'bluntfields')
                        {
                            for(dataObj of formObj.data)
                            {
                                //console.log("dataObj:::"+JSON.stringify(dataObj,null,2));
                                if(dataObj.field.id === hndl.event.target.id)
                                {
                                    document.getElementById(dataObj.field.id).blur();
                                    find = parseInt(hndl.event.target.tabIndex) + 1;
                                    break;
                                }
                            }  

                        }
                        // else
                        // if(hndl.section === 'button')
                        // {

                        //     // found = true;
                        //     // break;

                        // }


                    }

                    if(find) break;
                }





                //-----------------------------
                // found
                //-----------------------------

                if(find)
                {

                    //alert(find);

                    count = 0;
                    for(formObj of formArr)
                    {
                        //console.log("SECTION: "+formObj.section);



                        if(formObj.section === 'fields')
                        {
                            if(Object.prototype.toString.call(formObj.data) === '[object Array]')
                            {

                                for(dataObj of formObj.data)
                                {
                                    //console.log("dataObj:::"+JSON.stringify(dataObj,null,2));

                                    if(dataObj)
                                    {
                                        if(parseInt(dataObj.tabIndex) === find)
                                        {
                                            //alert(dataObj.tabIndex + "===" + find);
                                            found = {
                                                section:formObj.section,
                                                data:dataObj,
                                                index:count
                                            };
                                            break;
                                        }

                                    }

                                }  


                            }

                        }
                        else
                        if(formObj.section === 'labelfields')
                        {
                            if(Object.prototype.toString.call(formObj.data) === '[object Array]')
                            {

                                for(dataObj of formObj.data)
                                {
                                    //console.log("dataObj:::"+JSON.stringify(dataObj,null,2));

                                    if(dataObj.field)
                                    {
                                        if(parseInt(dataObj.field.tabIndex) === find)
                                        {
                                            found = {
                                                section:formObj.section,
                                                data:dataObj.field,
                                                index:count
                                            };
                                            break;
                                        }

                                    }

                                }  


                            }

                        }
                        else
                        if(formObj.section === 'editfields')
                        {
                            if(Object.prototype.toString.call(formObj.data) === '[object Array]')
                            {

                                for(dataObj of formObj.data)
                                {
                                    //console.log("dataObj:::"+JSON.stringify(dataObj,null,2));

                                    if(dataObj.field)
                                    {
                                        if(parseInt(dataObj.field.tabIndex) === find)
                                        {
                                            found = {
                                                section:formObj.section,
                                                data:dataObj.field,
                                                index:count
                                            };
                                            break;
                                        }

                                    }

                                }  


                            }

                        }
                        else
                        if(formObj.section === 'costfields')
                        {

                            if(Object.prototype.toString.call(formObj.data) === '[object Array]')
                            {

                                for(dataObj of formObj.data)
                                {

                                    if(dataObj.label)
                                    {
                                        if(parseInt(dataObj.label.tabIndex) === find)
                                        {
                                            found = {
                                                section:formObj.section,
                                                data:dataObj.label,
                                                index:count
                                            };
                                            break;
                                        }

                                    }
                                    if(dataObj.rate)
                                    {
                                        if(parseInt(dataObj.rate.tabIndex) === find)
                                        {
                                            found = {
                                                section:formObj.section,
                                                data:dataObj.rate,
                                                index:count
                                            };
                                            break;
                                        }

                                    }

                                    if(dataObj.qtyhrs)
                                    {
                                        if(parseInt(dataObj.qtyhrs.tabIndex) === find)
                                        {
                                            found = {
                                                section:formObj.section,
                                                data:dataObj.qtyhrs,
                                                index:count
                                            };
                                            break;
                                        }


                                    }



                                }  

                            }

                        
                        }
                        else
                        if(formObj.section === 'bluntfields')
                        {

                            if(Object.prototype.toString.call(formObj.data) === '[object Array]')
                            {
                                for(dataObj of formObj.data)
                                {
                                    //console.log("dataObj:::"+JSON.stringify(dataObj,null,2));

                                    if(dataObj.field)
                                    {
                                        if(parseInt(dataObj.field.tabIndex) === find)
                                        {
                                            found = {
                                                section:formObj.section,
                                                data:dataObj.field,
                                                index:count
                                            };
                                            break;
                                        }

                                    }

                                }  

                            }

                        }
                        else
                        if(formObj.section === 'button')
                        {

                            // found = true;
                            // break;

                        }

                        count++;

                        if(found) break;

        
                    }


                    if(found)
                    {
                        //console.log("found: "+JSON.stringify(found,null,2));

                        switch(found.section)
                        {
                        case'fields':
                        case'labelfields':
                        case'editfields':

                            if(found.data.id)
                            {
                                //alert("bluntfield: "+JSON.stringify(found.data,null,2));
                                elem = document.getElementById(found.data.id);

                            }

                        break;
                        case'costfields':

                            if(found.data.id.match(/label/gi))
                            { 
                                //console.log("costfields label - "+JSON.stringify(found.data,null,2));
                                //elem = document.getElementById(found.data.id);
                                // activeLabelId = found.data.id;

                            }
                            if(found.data.id.match(/rate/gi))
                            { 
                                //console.log("costfields rate - "+JSON.stringify(found.data,null,2));
                                elem = document.getElementById(found.data.id);

                            }
                            if(found.data.id.match(/qtyhrs/gi))
                            {
                                //console.log("costfields - qtyhrs: "+JSON.stringify(found.data,null,2));
                                elem = document.getElementById(found.data.id);

                            }

                        break;
                        case'bluntfields':


                            if(found.data.id)
                            {
                                //alert("bluntfield: "+JSON.stringify(found.data,null,2));
                                elem = document.getElementById(found.data.id);

                            }

                        break;
                        default:
                        }

                        if(elem) 
                        {
                            elem.focus();
                            //console.log("offsetTop: "+elem.offsetTop);

                            //alert(elem.id);

                            ijArr = hndl.index.split("-");


                            switch(found.section)
                            {
                            case'fields':
                            case'labelfields':
                            case'editfields':
                            case'costfields':

                                el = document.getElementById("formSection_"+found.index);
                                fromTop = el.offsetTop;
                                if(ijArr[1] !== 0)
                                {
                                    fromTop = fromTop + (25 * ijArr[1]);
                                }
                                //alert(el.offsetTop+" "+fromTop)
                                baseRef.current.scrollTop = fromTop; 

                            break;
                            case'bluntfields':

                            
                                el = document.getElementById("formSection_"+found.index);
                                if(elem.id === 'calcby') 
                                {
                                    fromTop = el.offsetTop - 150;
                                    //alert(el.offsetTop+" "+fromTop)
                                }else{
                                    fromTop = el.offsetTop - 40;
                                    //alert(el.offsetTop+" "+fromTop)
                                }
                                baseRef.current.scrollTop = fromTop; 
                            

                            break;
                            default:
                            }


                        }//==
                    
                    }//found


                }//find


                // alert("MOVE TO THE NEXT"

                //     +"\nhndl.section: "+hndl.section
                //     +"\nhndl.event.key: "+hndl.event.key
                //     +"\nhndl.event.target.id: "+hndl.event.target.id
                //     +"\nhndl.event.target.value: "+hndl.event.target.value
                //     +"\nhndl.event.target.tabIndex: "+hndl.event.target.tabIndex

                //     +"\nfind:"+find
                //     +"\nfound: "+JSON.stringify(found,null,2)

                // );


            }//currentFlyr 


        }//keypress ENTER



        // if(e.key === 'y')
        // {
        // 	alert('The sky is your starting point!')
        // }
        // else 
        // if(e.key === 'n') 
        // {
        //     alert('The sky is your limit👀')
        // }



    }//handleMovement







    //=============================================
    // keyPress
    //=============================================

    //const [currentFlyr, setCurrentFlyr] = useState(false);

    // useKeyPress('Enter', () => {

    //     //alert("Flyr Enter was pressed");

    //     // if(location.hash === "#!Calculator~labormenu" && showLaborMenu && currentFlyr)
    //     // {
    //     //     console.log('Close "Labor Menu 1" and move to next input');

    //     //     handleMovement({

    //     //         call:'laborMenu',
    //     //         data:showLaborMenu,
    //     //         section:"costfields"

    //     //     });
    //     //     setCurrentFlyr(false);
    //     //     setShowLaborMenu(false);       
    //     //     history.goBack();

    //     // } 

    // });








    // //=============================================
    // // handleMovement
    // //=============================================

    // const [hasFocus, setHasFocus] = useState(false);


    // useEffect(() => {


    //     //alert(animateOut);
    //     //console.log(boardNumber+" "+props.data.boardNumber);

    //     // if(elem === document.activeElement) 
    //     // {
    //     //     console.log('Element has focus!');
    //     // }
    //     // else
    //     // {
    //     //     console.log(`Element is not focused.`);
    //     // }


    //     console.log("activeElement:::: " +document.activeElement.id);

    //     setHasFocus(false);



    // },
    // [

    //     props,
    //     isMobile

    // ])






/*

    const VideoEnded = () => {

        console.log('video ended');

    }


*/




    if(!isLoaded)
    {

        return (

            <div></div>
        )


    }else{


        return (


            <div>

                {flyrType === 'form'
                ?

                    <FormFly 

                    handle={(v) => handle(v)}  

                        formArr={zoneObj[zoneObj.currentFlyr + "_formArr"]}
                        propblemArr={zoneObj[zoneObj.currentFlyr + "_problemArr"]} 

                        processSubmit={zoneObj[zoneObj.currentFlyr + "_processSubmit"]}
                        processComplete={zoneObj[zoneObj.currentFlyr + "_processComplete"]}
                        processError={zoneObj[zoneObj.currentFlyr + "_processError"]}
                        errorType={zoneObj[zoneObj.currentFlyr + "_errorType"]}
                
                    />


                :null}


                <Alfa
                zIndex={highZ+1}
                ></Alfa>




                <Tab

                    ref={tabRef}
                    //backgroundColor={"#EDEEF1"}
                    //border={"1px solid lightgray"}
                    showBumper={showBumper}

                    // saveButtonBackgroundColor={tabButtonStyle.saveButton.backgroundColor}
                    // closeButtonackgroundColor={tabButtonStyle.closeButton.backgroundColor}

                    width={flyrW}
                    top={tabMart}
                    left={tabMarl}
                    zIndex={highZ+3}


                >

                    {
                    flyrType === 'form'
                    ?

                        showBumper
                        ?
                            
                            <div>

                                <div 
                                className="SaveButton" 
                                onClick={() => handle({

                                    call        :'formSubmit',
                                    currentKey  :currentKey,
                                    submitCall  :submitCall,
                                    postTo      :postTo
                
                                })}
                                >  
                                    SUBMIT

                                </div>

                                <div 
                                className="CloseButton" 
                                // style={{

                                //     width:"calc(50% - 1px)",
                                //     borderLeft:"1px solid lightgray"

                                // }}
                                onClick={() => handle({

                                    call:'closeFlyr'

                                })}
                                >  
                                    CANCEL

                                </div>


                            </div>



                        :


                            // <div>

                            //     <div 
                            //     className="ClearButton" 
                            //     onClick={() => handle({

                            //         call        :'formSubmit',
                            //         currentKey  :currentKey,
                            //         submitCall  :submitCall,
                            //         postTo      :postTo
                
                            //     })}
                            //     >  
                            //         SUBMIT

                            //     </div>


                            //     <div 
                            //     className="ClearButton" 
                            //     style={{

                            //         width:"calc(50% - 1px)",
                            //         borderLeft:"1px solid lightgray"

                            //     }}
                            //     onClick={() => handle({

                            //         call:'closeFlyr'

                            //     })}
                            //     >  
                            //         CANCEL

                            //     </div>


                            // </div>




                            <div>

                            <div 
                            className="SaveButton" 
                            onClick={() => handle({

                                call        :'formSubmit',
                                currentKey  :currentKey,
                                submitCall  :submitCall,
                                postTo      :postTo
            
                            })}
                            >  
                                SUBMIT

                            </div>

                            <div 
                            className="CloseButton" 
                            // style={{

                            //     width:"calc(50% - 1px)",
                            //     borderLeft:"1px solid lightgray"

                            // }}
                            onClick={() => handle({

                                call:'closeFlyr'

                            })}
                            >  
                                CANCEL

                            </div>


                        </div>





                    :
                    


                        <div>

                            <div 
                            className="Title" 
                            >  

                                {title}

                            </div>

                            <div 
                            className="X" 
                            onClick={() => handle({

                                call:'closeFlyr'

                            })}
                            >  
                                X
                            </div>


                        </div>


                    
                    }


                </Tab>





                {
                flyrType === 'form'
                ?

                    showBumper && !isMobile
                    ?

                        <Bumper
                        ref={bumperRef}
                        id={"flyrBottomBumper"}
                        showBumper={showBumper}
                        //backgroundColor={'lightgreen'}
                        //border={"1px solid lightgray"}
                        width={flyrW}
                        bottom={bumperMarb}
                        left={flyrMarl}
                        zIndex={highZ+3}
                        >
                            
                            <div 
                            className="Button" 
                            onClick={() => handle({

                                call        :'formSubmit',
                                currentKey  :currentKey,
                                submitCall  :submitCall,
                                postTo      :postTo
            
                            })}
                            >  
                                SUBMIT

                            </div>

                    
                        </Bumper>


                    :null
                    

                :null 
                }





                <Base 
                id="flyrBase"
                ref={baseRef}
                zIndex={highZ+2}
                onScroll={handleCheckScroll}
                >



                    <Base zIndex={"2"}
                    //backgroundColor="darkmagenta"
                    height={flankHeight}
                    onClick={() => handle({

                        call:'closeFlyr'

                    })}
                    >
                    </Base>



                    <Flyr 
                    id="flyr"
                    ref={flyrRef}
                    backgroundColor={'white'}
                    width={flyrW}
                    height={flyrH}
                    padding={"0 0 "+flyrPadBottom+" 0"}
                    top={flyrMart}
                    left={flyrMarl}
                    zIndex={"4"}
                    >


                        <div 
                        ref={binRef}
                        className="Bin"
                        >
                            {

                                flyrType === 'canvas'
                                ?
                                
                                    <Canvas 
                                    
                                        handle={(v) => handle(v)}
                                        data={flyrData}
                                        styl={flyrStyl}
                                        
                                    />

                                :
                                flyrType === 'chart'
                                ?

                                    <Chart 
                                    
                                        primeHandle={(v) => props.handle(v)}
                                        handle={(v) => handle(v)}
                                        type={flyrType}
                                        data={flyrData}
                                        styl={flyrStyl}

                                        
                                    
                                    />

                                :
                                flyrType === 'css_chart'
                                ?

                                    <Chart 
                                    
                                        primeHandle={(v) => props.handle(v)}
                                        handle={(v) => handle(v)}
                                        type={flyrType}
                                        data={flyrData}
                                        styl={flyrStyl}

                                        
                                    
                                    />

                                :
                                flyrType === 'chooser'
                                ?

                                    <Chooser 
                                    
                                        handle={(v) => handle(v)}
                                        data={flyrData}
                                        styl={flyrStyl}
    
                                    
                                    />

                                :
                                flyrType === 'cards'
                                ?

                                    <Cards 
                                    
                                        primeHandle={(v) => props.handle(v)}
                                        handle={(v) => handle(v)}
                                        data={flyrData}
                                        styl={flyrStyl}
    
                                    
                                    />

                                :
                                flyrType === 'form'
                                ?
                                
                                    <Form 

                                        primeHandle={(v) => props.handle(v)}
                                        handle={(v) => handle(v)}
                                        data={flyrData}
                                        styl={flyrStyl}
                                        viewPasswords={viewPasswords}

                                        handleMovement={(v) => handleMovement(v)}
                    
                                        
                                    />


                                :
                                flyrType === 'report'
                                ?
                                
                                    <Report

                                        primeHandle={(v) => props.handle(v)}
                                        handle={(v) => handle(v)}
                                        data={flyrData}
                                        styl={flyrStyl}
                                        flyrH={flyrH}
                                
                                    />


                                :
                                flyrType === 'print'
                                ?

                                    <Print
                                    
                                        primeHandle={(v) => props.handle(v)}
                                        handle={(v) => handle(v)}
                                        data={flyrData}
                                        styl={flyrStyl}
    
                                    />

                                :null


                            }

                        </div>


                    </Flyr>


                </Base>


            </div>

        );

      
     }





}


export default Index;





/*

  inputRefs = [];
  
    setRef = (ref) => {
        this.inputRefs.push(ref);
    };
    
    focusInput = (id) => this.inputRefs[id].focus();
    
    render() {
        return (
        <div>
            {this.state.data.map(({ name }) => (
            <Hello 
                placeholder={name} 
                ref={this.setRef} 
                key={name} />
            ))}
            <Button onClick={this.focusInput} id={0}>focus input 1</Button>
            <Button onClick={this.focusInput} id={1}>focus input 2</Button>
        </div>
        );
    }
    }

*/