import React from 'react';
import {
    BrowserRouter as Router,
    //Switch,
    //Route
    //Link
} from "react-router-dom";


import WindowDimensionsProvider from 'utils/hooks/WindowDimensionsProvider'

//import logo from './grfx/logo.svg';
//import { Counter } from './features/counter/Counter';
import './App.css';

//https://muffinman.io/react-router-subfolder-on-server/
  

import Prime from "./app-components/Prime";



//const basename = '';

//-- as subfolder --//
const basename = '/app';
//const basename = '/calc/';




const App = () => (

    <WindowDimensionsProvider>

        <Router basename={basename}>

            <div> 

                <Prime basename={basename} /> 

            </div>

        </Router>

    </WindowDimensionsProvider>
)

export default App