import React, { 

    // useState, 
    // useEffect,
    // useRef

} from 'react';


//import '../../StyleSheets/dynamic-form.css';
import 'app-style/dynamic-form/style.css';


export const FFbutton = (props) => {


    // var _FUNC = props.data.funct['name'] + "()";
    // var _FUNC = props.data.funct['name'] + "('"+props.data.funct['object']+"')";
    // var _RUN = new Function(_FUNC);

    // const [isLoaded, setIsLoaded] = useState(false);
    // const [buttonStyle, setButtonStyle] = useState({});

    // useEffect(() => {



    //     if(!isLoaded)
    //     {


    //         setIsLoaded(true);
    //         console.log("props: "+JSON.stringify(props,null,2));

    //         // "width": "100%",
    //         // "fontSize": "16px",
    //         // "bgColorOver": "transparent",
    //         // "txtColorOver": "orange",
    //         // "borderOver": "0",
    //         // "bgColorOut": "transparent",
    //         // "txtColorOut": "white",
    //         // "borderOut": "0"



    //         // var width = "80%";
    //         // var margin = "0 0 0 8%";

    //         // var fontSize = "16px";

    //         // var bgColor = "#07a2e3";
    //         // var txtColor = "white";
    //         // var brColor = "#07a2e3";

    //         // var bgColorOver = "orange";
    //         // var txtColorOver = "white";
    //         // var brColorOver = "orange";



    //         // if(props.data.style.width) width = props.data.style.width;
    //         // if(props.data.style.margin) margin = props.data.style.margin;

    //         // if(props.data.style.fontSize) margin = props.data.style.fontSize;

    //         // if(props.data.style.bgColor) bgColor = props.data.style.bgColorOut;
    //         // if(props.data.style.txtColor) txtColor = props.data.style.txtColorOut;
    //         // if(props.data.style.brColor) brColor = props.data.style.brColorOut;       

    //         // if(props.data.style.bgColorOver) bgColorOver = props.data.style.bgColorOver;
    //         // if(props.data.style.txtColorOver) txtColorOver = props.data.style.txtColorOver;
    //         // if(props.data.style.brColorOver) brColorOver = props.data.style.brColorOver;



    //         // setButtonStyle({

    //         //     bgColorOver     :bgColorOver,
    //         //     txtColorOver    :txtColorOver,
    //         //     brColorOver     :brColorOver,

    //         //     bgColor         :bgColor,
    //         //     txtColor        :txtColor,
    //         //     brColor         :brColor,

    //         //     width           :width,
    //         //     margin          :margin,

    //         //     fontSize        :fontSize

    //         // });



    //     }//isLoaded




    //  },[
    //      props,
    //      isLoaded
    // ])



    // const handleSetStyle = (v) => {

    //     //console.log(v);
    //     setButtonStyle({

    //         bgColor     :v.bgColor,
    //         txtColor    :v.txtColor,
    //         brColor     :v.brColor,

    //         width       :v.width,
    //         margin      :v.margin

    //     });

    // }







    return (<div>


        {
        props.data.tabIndex
        ?

            <div 
            key={props.indx+"_ffbutton"} 
            id={"submitButton"}
            tabIndex={props.data.tabIndex}
            className = "btn-submit"
            // style={{

            //     // backgroundColor         : "darkslateblue",
            //     // border                  : "1px solid darkslateblue"
            //     // backgroundColor     :buttonStyle.bgColor,
            //     // width               :buttonStyle.width,
            //     // margin              :buttonStyle.margin,
            //     // color               :buttonStyle.txtColor,
            //     // border              :"1px solid "+buttonStyle.brColor,
            //     // fontSize            :buttonStyle.fontSize

            // }}

            onClick={() => props.handle({

                call        :props.data.action,
                data        :props.data,
                funcname    :props.data.funct.name

            })}


            // onClick={() => props.handle({

            //     call        :props.data.call,
            //     data        :{

            //         index:props.indx,
            //         fieldCall:props.data.fieldCall

            //     }

            // })}


            // onMouseEnter={() => handleSetStyle({

            //     call        :"over",
            //     bgColor     :buttonStyle.bgColorOver,
            //     txtColor    :buttonStyle.txtColorOver,
            //     brColor     :buttonStyle.brColorOver

            // })}

            // onMouseOut={() => handleSetStyle({

            //     call        :"out",
            //     bgColor     :buttonStyle.bgColor,
            //     txtColor    :buttonStyle.txtColor,
            //     brColor     :buttonStyle.brColor


            // })}

            >

                {props.data.text}
                
            </div>

        


        :


            <div 
            key={props.indx+"_ffbutton"} 
            className = "btn-submit"
            // style={{

            //     backgroundColor     :buttonStyle.bgColor,
            //     width               :buttonStyle.width,
            //     margin              :buttonStyle.margin,
            //     color               :buttonStyle.txtColor,
            //     border              :"1px solid "+buttonStyle.brColor,
            //     fontSize            :buttonStyle.fontSize

            // }}
            
            onClick={() => props.handle({

                call        :props.data.action,
                data        :props.data,
                funcname    :props.data.funct.name

            })}


            // onClick={() => props.handle({

            //     call        :props.data.call,
            //     data        :{

            //         index:props.indx,
            //         fieldCall:props.data.fieldCall

            //     }

            // })}


            // onMouseEnter={() => handleSetStyle({

            //     call        :"over",
            //     bgColor     :buttonStyle.bgColorOver,
            //     txtColor    :buttonStyle.txtColorOver,
            //     brColor     :buttonStyle.brColorOver

            // })}

            // onMouseOut={() => handleSetStyle({

            //     call        :"out",
            //     bgColor     :buttonStyle.bgColor,
            //     txtColor    :buttonStyle.txtColor,
            //     brColor     :buttonStyle.brColor


            // })}

            >

                {props.data.text}
                
            </div>


        }


    
    </div>)

}





