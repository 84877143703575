import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';



import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';


import {

    ReportMathLedger,
        ReportMathKey, 
        ReportMathItemBar,  
        ReportMathItem,
        ReportMathItemLarge

} from '../Styled';





const Index = (props) => {



    // -- window dims -- //
    const { 
        width, 
        //height 
    } = useWindowDimensions();



    //=============================================
    // refs
    //=============================================

    const [rowRefs] = useState([]);
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            //console.log(boxRefs.length);

        }

    };




    // -- ref -- //
    const refA = useRef(null);


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState({});
    const [items, setItems] = useState([]);


    useEffect(() => {

        if(!isLoaded)
        {

            setIsLoaded(true);
            setItems(props.data.value);

            //alert('Ledger');

        }
        else
        {



            //console.log("utils/Report/Ledger");

            // alert(JSON.stringify(props.data.style,null,2));

 
            if(items.length === 0) setItems(props.data.value);
            if(Object.keys(data).length === 0)
            {

                var width;

                var margin;
                var marginTop;
                var marginBottom;
                var marginLeft;

                var border;

                width = "calc(100% - 20px)"

                marginTop = "10px";
                marginBottom = "0px";
                marginLeft = "10px";

                border = false;

                if(props.data.style && props.data.style !== undefined)
                {
                    if(props.data.style.width) width = props.data.style.width;

                    if(props.data.style.marginTop) marginTop = props.data.style.marginTop;
                    if(props.data.style.marginBottom) marginBottom = props.data.style.marginBottom;
                    if(props.data.style.marginLeft) marginLeft = props.data.style.marginLeft;

                    if(props.data.style.border) border = props.data.style.border;
 
                }


                if(props.data.boxHeight 
                && props.data.boxHeight !== undefined
                )
                {
                    marginTop = (props.data.boxHeight - refA.current.offsetHeight)/2 - (5) + "px";
                }

                if(props.lessThanThresh)
                {
                    marginTop = "0px";
                    marginBottom = "30px";
                }

                margin = marginTop+" 0 "+marginBottom+" "+marginLeft;

                setData({

                    id:props.id,
                    width:width,
                    margin:margin,
                    border:border
            
                });



            }




        }
        //console.log("ledgerData: "+JSON.stringify(props.data,null,2));


    },
    [
        props,
        isLoaded,
        //rowRefs
        data,
        items
    ])






    const [adjust, setAdjust] = useState(false);
    useEffect(() => {

        if(width < 550)
        {
            if(!adjust && rowRefs.length === items.length)
            {

                setAdjust(true);

            }

        }else{

            if(adjust) setAdjust(false);
        }

    
    },
    [

        width,
        rowRefs,       
        items,
        adjust
    ])





    return (


        <ReportMathLedger
        ref={refA}
        id={data.id}
        width={data.width}
        margin={data.margin}
        //backgroundColor={'lightblue'}
        >


            {
            props.data.showKey
            ?

                <ReportMathKey                        
                dark={props.dark}
                //width={"calc(100% - 0px)"}
                // bulletBackgroundColor={itm.bullet.color}
                >
                    <div className="Txt">Material</div>          
                    <div className="Square"></div>

                    <div className="Txt">Time (Labor)</div>
                    <div className="Triangle"></div>


                </ReportMathKey>
      

            :null
            }




            <ReportMathItemBar                          
            dark={props.dark}
            //width={"calc(100% - 0px)"}
            // bulletBackgroundColor={itm.bullet.color}
            >
                <div className="Total">TOTAL</div>
                <div className="Equals"></div>
                <div className="QtyHrs">QTY/HRS</div>
                <div className="By"></div>
                <div className="Rate">RATE</div>
                <div className="Name">NAME</div>

            </ReportMathItemBar>



            {items.map((itm, k) => (

                itm.bullet && itm.bullet !== undefined
                ?

                    adjust 
                    && itm.value.rate.length + itm.value.qtyhrs.length + itm.value.total.length > 25
                    ?

                        <ReportMathItemLarge
                        key={k} 
                        ref={setRowRef} 
                        id={"row_"+k}
                        dark={props.dark}
                        //width={"calc(100% - 0px)"}
                        // bulletBackgroundColor={itm.bullet.color}
                        >

                            {
                            itm.value.isLabor
                            ?

                                <div className="Triangle"
                                >
                                </div>

                            :

                                <div className="Square"
                                >
                                </div>

                            }

                            <div className="Name"
                            >
                                {itm.name}

                            </div>

                

                            <div className="Value"
                            >
                                <div className="QtyHrs">{itm.value.qtyhrs}</div>
                                <div className="By">{" X "}</div>
                                <div className="Rate">{itm.value.rate}</div>  
                            </div>


                            <div className="Value"
                            style={{margin:"0 0 0 0"}}
                            >
                                <div className="Total">{itm.value.total}</div>
                                <div className="Equals">{"="}</div>

                            </div>

                    
                        </ReportMathItemLarge>



                    :


                        <ReportMathItem 
                        key={k} 
                        ref={setRowRef} 
                        id={"row_"+k}
                        dark={props.dark}
                        //width={"calc(100% - 0px)"}
                        // bulletBackgroundColor={itm.bullet.color}
                        >

                            {
                            itm.value.isLabor
                            ?

                                <div className="Triangle"
                                >
                                </div>

                            :

                                <div className="Square"
                                >
                                </div>

                            }

                            <div className="Name"
                            >
                                {itm.name}

                            </div>

                
                            <div className="Value"
                            >
                                <div className="Total">{itm.value.total}</div>
                                <div className="Equals">{"="}</div>
                                <div className="QtyHrs">{itm.value.qtyhrs}</div>
                                <div className="By">{" X "}</div>
                                <div className="Rate">{itm.value.rate}</div>  
                            </div>
                    

                        </ReportMathItem>

            
                 
                :


                    <ReportMathItem  
                    key={k}    
                    ref={setRowRef}                     
                    id={"row_"+k}
                    dark={props.dark}
                    darkBorder={data.border}
                    >
                        <div className="Name"
                        >
                            {itm.name}

                        </div>

                        <div className="Value">

                            <div className="Total">{itm.value.total}</div>
                            <div className="Equals">{"="}</div>
                            <div className="QtyHrs">{itm.value.qtyhrs}</div>
                            <div className="By">{" X "}</div>
                            <div className="Rate">{itm.value.rate}</div>

                        </div>

                    </ReportMathItem>



            ))}



        </ReportMathLedger>


    )


}

export default Index;


