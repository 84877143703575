import { 

    // BarChart,

    // Title,
    // Content,
    // List,
    // Lines,

    VerticalLine,
    HorizontalLine,

    VerticalBar,
    HorizontalBar,

    FlexVerticalTrack,
    FlexVerticalCapTrack

  
} from '../../Styled';





export const handleCreateMarkers = (hndl) => {

    var number;
    if(Object.prototype.toString.call(hndl.array) === '[object Array]')
    {
        number = hndl.array.length;
    }else{
        number = 0;
    }

    var maxHeight = hndl.maxHeight;
    //var elementHeight = (maxHeight/number);

    // console.log(JSON.stringify(hndl,null,2));
    // console.log(""

    //     +"\nmaxHeight: "+maxHeight
    //     +"\nelementHeight: "+elementHeight
    // );




    var markersDif,markersColor,markersPrefix,markersSuffix; 

    markersDif = hndl.markersDif ? hndl.markersDif : 13.5;
    markersColor = hndl.markersColor;
    markersPrefix = hndl.markersPrefix;
    markersSuffix = hndl.markersSuffix;



    var lineH = 0;
    if(maxHeight !== undefined)
    {
        lineH = maxHeight / (number); // 11 = 10  or 6 = 5 cause need 1 extra for the 100% line
    }


    var top;
    //var bgColor;
    var elements = [];
    var i;
    for(i =0; i < number; i++)
    {

        // if((i+1) % 2 === 0) bgColor = "blue";
        // else bgColor = 'red';

        if(i>0)
        {
            //top = lineH + 1.57;
            //top = lineH - 13.5;
            top = lineH - markersDif;

        }else{
            top = 0;
        }


        elements.push(
            
            <div 
            key={i} 
            className={"MarkerItem"}
            style={{

                //backgroundColor:bgColor,
                marginTop   : top + "px",
                color       : markersColor
            }}
            >
                {markersPrefix + hndl.array[i].name + markersSuffix}
            </div>
        );

    }
    return elements;
}





export const handleCreateListItems = (hndl) => {

    var number;
    if(Object.prototype.toString.call(hndl.array) === '[object Array]')
    {
        number = hndl.array.length;
    }else{
        number = 0;
    }

    var maxHeight = hndl.maxHeight;
    var elementHeight = (maxHeight/number);


    var gap;
    gap = 10;
    if(hndl.gap) 
        gap = hndl.gap;

    var padTop = (elementHeight/2.0) - (11); // fontSize = 14



    var barHeight; 
    barHeight = elementHeight - hndl.gap;
    barHeight = barHeight - padTop;



    var textColor = 'black';
    if(hndl.textColor) 
        textColor = hndl.textColor;


    var elements = [];
    var text;
    var i;
    for(i =0; i < number; i++)
    {

        text = hndl.array[i].name + " : "+hndl.array[i].percent+"%";
        text = text.toUpperCase();

        elements.push(
            
            <div 
            key={i} 
            className={"ListItem"}
            style={{

                //backgroundColor:"pink",
                height:barHeight+"px",
                //padding:padTop+" 0 "+padBottom+" 0",
                paddingTop : padTop + "px",
                marginBottom : gap + "px",
                color : textColor

            }}
            >
                {text}
            </div>
        );

    }
    return elements;
}








export const handleCreateLines = (hndl) => {


    var maxWidth = hndl.maxWidth;
    var maxHeight = hndl.maxHeight;

    var number = hndl.number;
    var type = hndl.type;

    var elements = [];

    var bottom = 0;
    var left = 0;


    var width = 50;
    if(maxWidth !== undefined)
    {
        width = maxWidth / number;
    }

    var height = 50;
    if(maxHeight !== undefined)
    {
        height = maxHeight / (number); // 11 = 10  or 6 = 5 cause need 1 extra for the 100% line
    }


    var linesColor = "lightgray";
    if(hndl.linesColor) linesColor = hndl.linesColor;


    // alert(""

    //     +"\nmaxWidth: "+maxWidth
    //     +"\nnumber: "+number
    //     +"\nwidth: "+width
    //     +"\nheight: "+height
    //     +"\nbottom: "+bottom
    //     +"\nlinesColor: "+linesColor

    // );



    var i;
    for(i =0; i < number; i++)
    {

        switch(type)
        {   
        case'horizontal':

            elements.push(
            
                <HorizontalLine
                key={i}
                height={height+"px"}
                bottom={bottom+"px"}
                border={"1px solid "+linesColor}
                >  
                </HorizontalLine>
             
            );

            bottom = bottom + height;   

        break;
        case'vertical':

          
            elements.push(
                <VerticalLine
                key={i}
                width={width+"px"}
                left={left+"px"}
                border={"1px solid "+linesColor}
                > 
                </VerticalLine>
            );

            left = left + width;

        break;
        default:
        }

    }
    return elements;
}






export const handleCreateBars = (hndl) => {

    var number;
    if(Object.prototype.toString.call(hndl.array) === '[object Array]')
    {
        number = hndl.array.length;
    }else{
        number = 0;
    }
    var type = hndl.type;
    var maxWidth = hndl.maxWidth;

    var maxHeight = hndl.maxHeight;

    // console.log("maxWidth: "+maxWidth);

    var elementWidth = (maxWidth/number);
    var elementHeight = (maxHeight/number);


    var elements = [];

    var dif = 0;
  
    var gap;
    gap = 10;
    if(hndl.gap) 
        gap = hndl.gap;



    var barWidth = elementWidth - gap;
    var barHeight = elementHeight - gap;


    // console.log(""
    //     +"\nelementWidth: "+elementWidth
    //     +"\nbarWidth: "+barWidth
    //     +"\nbarHeight: "+barHeight
    // );


    var i;

    switch(type)
    {
    case'fixed-horizontal':

        for(i =0; i < number; i++)
        {

            elements.push(
            
                <HorizontalBar
                key={i}
                width={hndl.array[i].percent+"%"}
                height={hndl.fixedBarHeight}
                margin={"0 0 "+gap+"px 0"}
                >  
                    <div className={"Track"}>
                        <div className={"Color"}></div>
                    </div>

                </HorizontalBar>
                
            );
    
        }

    break;
    case'horizontal':

        for(i =0; i < number; i++)
        {

            elements.push(
            
                <HorizontalBar
                key={i}
                width={hndl.array[i].percent+"%"}
                height={barHeight+"px"}
                margin={"0 0 "+gap+"px 0"}
                >  
                    <div className={"Track"}>
                        <div className={"Color"}></div>
                    </div>

                </HorizontalBar>
                
            );
    
        }

    break;
    case'vertical':


        for(i =0; i < number; i++)
        {

            // console.log(""

            //     +"\ndif: "+dif
            //     +"\nheightPercent: "+hndl.array[i].percent+"%"
            //     +"\nelementWidth: "+elementWidth
            //     +"\nbarWidth: "+barWidth
            //     +"\nbarHeight: "+barHeight
            // );

            elements.push(
                <VerticalBar
                key={i}
                width={barWidth+"px"}
                height={hndl.array[i].percent+"%"}
                left={dif+"px"}
                > 
                    <div className={"Track"}>
                        <div className={"Filler"}></div>
                        <div className={"Color"}></div>
                    </div>

                </VerticalBar>
            );

            dif = dif + (barWidth+10);

        }

    break;
    case'flex-vertical':


        for(i =0; i < number; i++)
        {
            

            // console.log("FlexVertical Cap Checked: "+JSON.stringify(hndl.array[i],null,2));



            elements.push(

                // <div key={i}
                // className={"Track"}
                // height={hndl.array[i].percent+"%"}
                // >
                //     <div className={"Filler"}></div>
                //     <div className={"Color"}></div>

                // </div>



                hndl.array[i].cap && hndl.array[i].cap !== undefined
                ?

                    <FlexVerticalCapTrack 
                    key={i}
                    height={hndl.array[i].percent+"%"}
                    backgroundColor={hndl.array[i].barColor}
                    cap={hndl.array[i].cap}
                    >
                        <div className={"Filler"}></div>
                        <div className={"Color"}>
                   
                            {
                            hndl.array[i].percent > 100
                            ?
                                <div className={"CapNText"}
                                >
                                    {"( "+hndl.array[i].percent + "% )"}

                                </div>
                            :
                                <div className={"Cap"}></div>
                            }


                        </div>

                    </FlexVerticalCapTrack>

                :

                    <FlexVerticalTrack 
                    key={i}
                    height={hndl.array[i].percent+"%"}
                    backgroundColor={hndl.array[i].barColor}
                    >
                        <div className={"Filler"}></div>
                        <div className={"Color"}></div>

                    </FlexVerticalTrack>


            )      
        
        }

    break;
    default:
    }


    return elements;
}







