export const TPC_PIE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};

	// let iconColor = props.iconColor || "rgb(0,0,0)";


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

            {"pie piece"}
            <path 
                fill="#00cc66"
                d="M158.356,47.109C185.874,32.982,217.039,25,250,25	c32.968,0,64.13,7.979,91.65,22.109L250,226.604L158.356,47.109L158.356,47.109z"
            />


            {"pie"}

            <path 
                fill="#fcfcfc"
                d="M250,475c111.041,0,201.606-90.563,201.606-201.606 c0-78.074-44.775-146.026-109.963-179.494L250,273.394v0.393L158.353,93.899c-65.185,33.468-109.96,101.42-109.96,179.494 C48.393,384.438,138.959,475,250,475L250,475z"
            />


		</svg>
		</div>

	);//return



	// let darklite = props.darklite || "lite";
	// if(darklite === 'dark')
	// {
	// 	return(<div></div>)
	// }
	// else // lite
	// {
	
	// }




}

