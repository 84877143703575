import styled from "styled-components";



export const ReportSection = styled.div.attrs(props => ({


    comp:               props.comp || false,


    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "20px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "black",


    spacerBackgroundColor:    props.spacerBackgroundColor || "transparent",
    spacerWidth:              props.spacerWidth || "100%",
    spacerHeight:             props.spacerHeight || "auto",


    // border-bottom:${props => props.border};
    // border-left:${props => props.border};
    // border-right:${props => props.border};


}))`


    background-color:${props => props.backgroundColor};

    width: ${({comp,width}) => 

        (comp === 'mathLedger' && "calc(100% - 60px)") || 

        width
    };



    height:${props => props.height};


    padding:${props => props.padding};
    margin:${props => props.margin};

    margin: ${({comp,margin}) => 

        (comp === 'mathLedger' && " 0 0 0 30px") || 

        margin
    };





    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:default;


    @media (max-width: 1080px) {

        width:${props => props.width};
        margin:${props => props.margin};

    }





    @media (max-width: 300px) {

        height:80px;
    }





    .Spacer {

        background-color:${props => props.spacerBackgroundColor};
        width:${props => props.spacerWidth};
        height:${props => props.spacerHeight};

        margin:0 0 0 0;
        padding:0 0 0 0;

        position:relative;
        float:left;
    
    }



`;




