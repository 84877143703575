import styled from "styled-components";




export const ReportMathLedger = styled.div.attrs(props => ({

    dark:               props.dark || false,

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "calc(100% - 20px)",
    height:             props.height || "auto",
    margin:             props.margin || "10px 0 0 10px",
    padding:            props.padding || "0 0 0 0",


}))`

    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;
    float:left;

    overflow:visible;


`;




export const ReportMathBullet = styled.div.attrs(props => ({


    dark:               props.dark || false,


    backgroundColor:    props.backgroundColor || "gray",
    border:             props.border || "0",

    width:              props.width || "20px",
    height:             props.height || "20px",
    margin:             props.margin || "10px 0 0 0",
    padding:            props.padding || "0 0 0 0",

    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "12px",
    fontWeight:         props.fontWeight  || "550",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "#fc0",


    // width: 50px;
    // height: 50px;
    // padding:0 0 0 0;
    // margin:14px 0 0 15px;
    // float:left;		
    // overflow:hidden;

    // border-radius: 25px;
    // -webkit-border-radius: 25px;
    // -moz-border-radius: 25px;


    // &:hover{

    //     color:#191e28;
    //     text-decoration:underline;

    // }



}))`


    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;



    .Text {

        background-color:transparent;

        width:100%;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:relative;
        float:left;

        cursor:default;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};


    }


`;






export const ReportMathItem = styled.div.attrs(props => ({


    dark:               props.dark || false,


    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid gray",

    width:              props.width || "calc(100% - 10px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "10px 0 4px 5px",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "white",




    //---------------
    //bullet


    // blue:'#07a2e3',
    // green:'#00e070',
    // yellow:'#fde23e',
    // red:'#c00',
    // orange:'orange',
    // gray:'#687484',//"#687484", //"#68748f",

    bulletBackgroundColor:    props.bulletBackgroundColor || "#fde23e",  //"#fdbb13",
    bulletBorderColor:        props.bulletBorderColor || "#fdbb13",

    bulletFontFamily:         props.bulletFontFamily || 'Arial, Helvetica, sans-serif',
    bulletFontSize:           props.bulletFontSize || "20px",
    bulletFontWeight:         props.bulletFontWeight  || "500",
    bulletTextAlign:          props.bulletTextAlign || "left",
    bulletTextDecoration:     props.bulletTextDecoration  || "none",
    bulletTextColor:          props.bulletTextColor || "black"



    // &:hover {

    //     background-color:#caffca;
    //     text-decoration:underline;
    // }



}))`


    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:visible;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:default;


    @media (max-width: 300px) {

        height:80px;
    }







    
    .Square {

        background-color:${props => props.bulletBackgroundColor};


        width:17px;
        height:17px;

        padding:0 0 0 0;
        margin:3px 10px 0 0;

        position:relative;
        float:left;

        font-family:${props => props.bulletFontFamily};
        font-size:${props => props.bulletFontSize};
        font-weight:${props => props.bulletFontWeight};
        text-align:${props => props.bulletTextAlign};
        text-decoration:${props => props.bulletTextDecoration};
        color:${props => props.bulletTextColor};

    }



    .Triangle {

        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 17px solid ${props => props.bulletBackgroundColor};
        
        width: 0; 
        height: 0; 

        padding:0 0 0 0;
        margin:3px 10px 0 0;

        position:relative;
        float:left;

    }

    
    .Name {

        background-color:transparent;

        width:auto;
        height:auto;

        padding:4px 0 2px 0;
        margin:0 0 0 0;

        position:relative;
        float:left;


        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};


    }

    @media (max-width: 600px) {

        .Name {
            width:calc(100% - 30px);
        }
    
    }




    .Value {

        background-color:transparent;

        width:auto;
        height:auto;

        padding:4px 0 2px 0;
        margin:0 0 0 0;

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:right;
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};


    }


    @media (max-width: 600px) {

        .Value {

            width:99%;
            margin:10px 0 0 0;
        }
    
    }






        .Value .Rate {

            background-color:transparent;

            width:120px;
            height:auto;

            padding:0 0 0 0;
            margin:0 0 0 0;

            position:relative;
            float:right;

            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:right;
            text-decoration:${props => props.textDecoration};
            color:${props => props.textColor};


        }


        .Value .By {

            background-color:transparent;

            width:auto;
            height:auto;

            padding:0 10px 0 25px;
            margin:0 0 0 0;

            position:relative;
            float:right;

            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:center;
            text-decoration:${props => props.textDecoration};
            color:${props => props.textColor};


        }



        .Value .QtyHrs {

            background-color:transparent;

            width:60px;
            height:auto;

            padding:0 0 0 0;
            margin:0 0 0 0;

            position:relative;
            float:right;

            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:right;
            text-decoration:${props => props.textDecoration};
            color:${props => props.textColor};


        }


        .Value .Equals {

            background-color:transparent;

            width:auto;
            height:auto;

            padding:0 10px 0 25px;
            margin:0 0 0 0;

            position:relative;
            float:right;

            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:center;
            text-decoration:${props => props.textDecoration};
            color:${props => props.textColor};


        }


        .Value .Total {

            background-color:transparent;

            width:120px;
            height:auto;

            padding:0 0 0 0;
            margin:0 0 0 0;

            position:relative;
            float:right;

            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:right;
            text-decoration:${props => props.textDecoration};
            color:${props => props.textColor};


        }






        @media (max-width: 600px) {

            .Value .Rate {

                width:auto;
            }
    
            .Value .By {
    
                padding:0 12px 0 12px;
            }
    
            .Value .QtyHrs {

                width:auto;
       
            }
    
            .Value .Equals {

                padding:0 12px 0 12px;
               
            }
    
            .Value .Total {
    

                width:auto;
            }
    
        }
    




`;








export const ReportMathItemLarge = styled.div.attrs(props => ({


    dark:               props.dark || false,


    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid gray",

    width:              props.width || "calc(100% - 10px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "10px 0 4px 5px",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "white",




    //---------------
    //bullet


    // blue:'#07a2e3',
    // green:'#00e070',
    // yellow:'#fde23e',
    // red:'#c00',
    // orange:'orange',
    // gray:'#687484',//"#687484", //"#68748f",

    bulletBackgroundColor:    props.bulletBackgroundColor || "#fde23e",  //"#fdbb13",
    bulletBorderColor:        props.bulletBorderColor || "#fdbb13",

    bulletFontFamily:         props.bulletFontFamily || 'Arial, Helvetica, sans-serif',
    bulletFontSize:           props.bulletFontSize || "20px",
    bulletFontWeight:         props.bulletFontWeight  || "500",
    bulletTextAlign:          props.bulletTextAlign || "left",
    bulletTextDecoration:     props.bulletTextDecoration  || "none",
    bulletTextColor:          props.bulletTextColor || "black"



    // &:hover {

    //     background-color:#caffca;
    //     text-decoration:underline;
    // }



}))`


    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:visible;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:default;


    @media (max-width: 300px) {

        height:80px;
    }







    
    .Square {

        background-color:${props => props.bulletBackgroundColor};


        width:17px;
        height:17px;

        padding:0 0 0 0;
        margin:3px 10px 0 0;

        position:relative;
        float:left;

        font-family:${props => props.bulletFontFamily};
        font-size:${props => props.bulletFontSize};
        font-weight:${props => props.bulletFontWeight};
        text-align:${props => props.bulletTextAlign};
        text-decoration:${props => props.bulletTextDecoration};
        color:${props => props.bulletTextColor};

    }



    .Triangle {

        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 17px solid ${props => props.bulletBackgroundColor};
        
        width: 0; 
        height: 0; 

        padding:0 0 0 0;
        margin:3px 10px 0 0;

        position:relative;
        float:left;

    }

    
    .Name {

        background-color:transparent;

        width:auto;
        height:auto;

        padding:4px 0 2px 0;
        margin:0 0 0 0;

        position:relative;
        float:left;


        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};


    }

    @media (max-width: 600px) {

        .Name {
            width:calc(100% - 30px);
        }
    
    }




    .Value {

        background-color:transparent;

        width:auto;
        height:auto;

        padding:4px 0 2px 0;
        margin:0 0 0 0;

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:right;
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};


    }


    @media (max-width: 600px) {

        .Value {

            width:99%;
            margin:10px 0 0 0;
        }
    
    }





        .Value .Rate {

            background-color:transparent;

            width:120px;
            height:auto;

            padding:0 0 0 0;
            margin:0 0 0 0;

            position:relative;
            float:right;

            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:right;
            text-decoration:${props => props.textDecoration};
            color:${props => props.textColor};


        }


        .Value .By {

            background-color:transparent;

            width:auto;
            height:auto;

            padding:0 10px 0 25px;
            margin:0 0 0 0;

            position:relative;
            float:right;

            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:center;
            text-decoration:${props => props.textDecoration};
            color:${props => props.textColor};


        }



        .Value .QtyHrs {

            background-color:transparent;

            width:60px;
            height:auto;

            padding:0 0 0 0;
            margin:0 0 0 0;

            position:relative;
            float:right;

            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:right;
            text-decoration:${props => props.textDecoration};
            color:${props => props.textColor};


        }


        .Value .Equals {

            background-color:transparent;

            width:auto;
            height:auto;

            padding:0 10px 0 25px;
            margin:0 0 0 0;

            position:relative;
            float:right;

            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:center;
            text-decoration:${props => props.textDecoration};
            color:${props => props.textColor};


        }


        .Value .Total {

            background-color:transparent;

            width:120px;
            height:auto;

            padding:0 0 0 0;
            margin:0 0 0 0;

            position:relative;
            float:right;

            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:right;
            text-decoration:${props => props.textDecoration};
            color:${props => props.textColor};


        }






        @media (max-width: 600px) {

            .Value .Rate {

                width:auto;
            }
    
            .Value .By {
    
                padding:0 12px 0 12px;
            }
    
            .Value .QtyHrs {

                width:auto;
       
            }
    
            .Value .Equals {

                padding:0 12px 0 12px;
               
            }
    
            .Value .Total {
    

                width:auto;
            }
    
        }
    




`;








export const ReportMathItemBar = styled.div.attrs(props => ({


    dark:               props.dark || false,


    backgroundColor:    props.backgroundColor || "#373d4c",
    border:             props.border || "0",

    width:              props.width || "calc(100% - 0px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "7px 0 5px 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "12px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "white",




    //---------------
    //bullet


    // blue:'#07a2e3',
    // green:'#00e070',
    // yellow:'#fde23e',
    // red:'#c00',
    // orange:'orange',
    // gray:'#687484',//"#687484", //"#68748f",

    bulletBackgroundColor:    props.bulletBackgroundColor || "#fde23e",  //"#fdbb13",
    bulletBorderColor:        props.bulletBorderColor || "#fdbb13",

    bulletFontFamily:         props.bulletFontFamily || 'Arial, Helvetica, sans-serif',
    bulletFontSize:           props.bulletFontSize || "12px",
    bulletFontWeight:         props.bulletFontWeight  || "500",
    bulletTextAlign:          props.bulletTextAlign || "left",
    bulletTextDecoration:     props.bulletTextDecoration  || "none",
    bulletTextColor:          props.bulletTextColor || "black"



    // &:hover {

    //     background-color:#caffca;
    //     text-decoration:underline;
    // }



}))`


    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:visible;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:default;


    @media (max-width: 300px) {

        height:80px;
    }




    
    .Name {

        background-color:transparent;

        width:83px;
        height:auto;

        padding:0 0 0 7px;
        margin:0 0 0 0;

        position:relative;
        float:left;


        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};


    }



    .Rate {

        background-color:transparent;

        width:80px;
        height:auto;

        padding:0 0 0 0;
        margin:0 0 0 0;

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:right;
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};


    }


    .By {

        background-color:transparent;

        width:auto;
        height:auto;

        padding:7px 24px 7px 30px;
        margin:0 0 0 0;

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:center;
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};


    }



    .QtyHrs {

        background-color:transparent;

        width:auto;
        height:auto;

        padding:0 0 0 0;
        margin:0 0 0 0;

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:right;
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};


    }


    .Equals {

        background-color:transparent;

        width:auto;
        height:auto;

        padding:7px 15px 7px 18px;
        margin:0 0 0 0;

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:center;
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};


    }


    .Total {

        background-color:transparent;

        width:130px;
        height:auto;

        padding:0 7px 0 0;
        margin:0 0 0 0;

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:right;
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};


    }






    @media (max-width: 600px) {

        .Rate {

            width:auto;
        }

        .By {

            padding:7px 12px 7px 12px;
        }

        .QtyHrs {

            width:auto;
    
        }

        .Equals {

            padding:7px 12px 7px 12px;
            
        }

        .Total {


            width:auto;
        }

    }





`;






export const ReportMathKey = styled.div.attrs(props => ({


    dark:               props.dark || false,


    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "0",

    width:              props.width || "calc(100% - 105px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 25px 0",
    padding:            props.padding || "7px 0 5px 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "white",




    //---------------
    //bullet


    // blue:'#07a2e3',
    // green:'#00e070',
    // yellow:'#fde23e',
    // red:'#c00',
    // orange:'orange',
    // gray:'#687484',//"#687484", //"#68748f",

    bulletBackgroundColor:    props.bulletBackgroundColor || "#fde23e",  //"#fdbb13",
    bulletBorderColor:        props.bulletBorderColor || "#fdbb13",

    bulletFontFamily:         props.bulletFontFamily || 'Arial, Helvetica, sans-serif',
    bulletFontSize:           props.bulletFontSize || "20px",
    bulletFontWeight:         props.bulletFontWeight  || "500",
    bulletTextAlign:          props.bulletTextAlign || "left",
    bulletTextDecoration:     props.bulletTextDecoration  || "none",
    bulletTextColor:          props.bulletTextColor || "black"




    // &:hover {

    //     background-color:#caffca;
    //     text-decoration:underline;
    // }



}))`


    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:visible;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:default;


    @media (max-width: 300px) {

        height:80px;
    }



    
    .Square {

        background-color:${props => props.bulletBackgroundColor};


        width:17px;
        height:17px;

        padding:0 0 0 0;
        margin:0 7px 0 0;

        position:relative;
        float:right;

        font-family:${props => props.bulletFontFamily};
        font-size:${props => props.bulletFontSize};
        font-weight:${props => props.bulletFontWeight};
        text-align:${props => props.bulletTextAlign};
        text-decoration:${props => props.bulletTextDecoration};
        color:${props => props.bulletTextColor};

    }



    .Triangle {

        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 17px solid ${props => props.bulletBackgroundColor};
        
        width: 0; 
        height: 0; 

        padding:0 0 0 0;
        margin:0 7px 0 0;

        position:relative;
        float:right;

    }

    
    .Txt {

        background-color:transparent;

        width:auto;
        height:auto;

        padding:0 0 0 0;
        margin:0 10px 0 0;

        position:relative;
        float:right;


        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};


    }






`;














