import styled from "styled-components";



export const ReportBlox = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 10px 10px",
    padding:            props.padding || "0 0 0 0",


}))`

    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;
    float:left;

    overflow:hidden;


`;



    export const ReportBlock = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "white",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "calc(100% - 2px)",
        height:             props.height || "auto",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",


        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "22px",
        fontWeight:         props.fontWeight  || "550",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "black",



        lineFontFamily:         props.lineFontFamily || 'Arial, Helvetica, sans-serif',
        lineFontSize:           props.lineFontSize || "16px",
        lineFontWeight:         props.lineFontWeight  || "500",
        lineTextAlign:          props.lineTextAlign || "left",
        lineTextDecoration:     props.lineTextDecoration  || "none",
        lineTextColor:          props.lineTextColor || "black",



    }))`


        background-color:${props => props.backgroundColor};
        border-right:${props => props.border};
        border-left:${props => props.border};
        border-bottom:${props => props.border};




        width:${props => props.width};
        height:${props => props.height};

        min-height:60px;

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;



        .Name {


            background-color:lightyellow;
            border-bottom:${props => props.border};

            width:calc(100% - 15px);
            height:auto;
            padding:15px 0 13px 15px;
            margin:0 10px 7px 0;
            position:relative;
            float:left;

            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};


        }



        .Group {

            background-color:transparent;

            width:80%;
            height:auto;

            padding:0 0 7px 0;
            margin:0 0 10px 30px;

            position:relative;
            float:left;

        }

            .Group .Key {

                background-color:transparent;

                width:150px;
                height:auto;
                padding:4px 7px 4px 0;
                margin:4px 0 0 0;
                position:relative;
                float:left;

                font-family:${props => props.lineFontFamily};
                font-size:${props => props.lineFontSize};
                font-weight:550;
                text-align:${props => props.keyTextAlign};
                text-decoration:${props => props.lineTextDecoration};
                color:${props => props.lineTextColor};



            }


            .Group .Value {

                background-color:transparent;

                width:calc(100% - 200px);
                height:auto;
                padding:4px 7px 4px 0;
                margin:4px 0 0 0;
                position:relative;
                float:left;

                font-family:${props => props.lineFontFamily};
                font-size:${props => props.lineFontSize};
                font-weight:${props => props.lineFontWeight};
                text-align:${props => props.lineTextAlign};
                text-decoration:${props => props.lineTextDecoration};
                color:${props => props.lineTextColor};




            }


            @media (max-width: 375px) {

               
                .Group {

                    width:calc(100% - 15px);
                    margin:0 0 10px 15px;
        
                }
        
                    .Group .Key {
        
                        width:100px;
                    }
    
                    .Group .Value {
        
                        width:calc(100% - 125px);

    
                    }

            }


    `;








