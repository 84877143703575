import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';

import store from './app-store/store';
import { 
    Provider, 
    //ReactReduxContext 
} from 'react-redux';




//---------------------------------
// Matomo
//---------------------------------

// import { 
//     MatomoProvider, 
//     createInstance 
// } from '@datapunt/matomo-tracker-react'



//---------------------------------
// Fingerprint
//---------------------------------

// Must be last in the chain but before instance
// dust is an object returned by Fingerprint

// import { Fingerprint } from 'utils/components/Fingerprint';
// let dust = Fingerprint();

// console.log("dust fingerprint: \n"+JSON.stringify(dust,null,2));
// console.log("" 
//     + "\ndust.user: "+dust.user 
//     + "\ndust.isMobile: "+dust.isMobile
//     + "\ndust.agent: "+dust.agent
// );


//---------------------------------
// matomo instance
//---------------------------------

// //matomo-start
// const instance = createInstance({
// 	urlBase: "http://theprofitcalculator.com",
// 	siteId: "2",
// 	userId: dust.user,
// 	trackerUrl: "https://matomo.powerdigitalmedia.com/matomo/matomo.php",
// 	srcUrl: "https://matomo.powerdigitalmedia.com/matomo/matomo.js",
// 	disabled: false,
// });
// //matomo-end







ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
        <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);


// ReactDOM.render(
//     <React.StrictMode>
//         <MatomoProvider value={instance}>
//             <Provider store={store}>
//                 <App />
//             </Provider>
//         </MatomoProvider>
//     </React.StrictMode>,
//     document.getElementById('root')
// );





// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
