export const LOGIN = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};

	let iconColor = props.iconColor || "rgb(0,0,0)";


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

			<path 
				fill={iconColor}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M73.95,316.02V183.981h131.928v-66.019L337.804,250 L205.878,382.039v-66.02H73.95L73.95,316.02z M112.319,99.811h275.36c4.708,0,8.56,3.853,8.56,8.561v283.26
				c0,4.707-3.852,8.559-8.56,8.559h-275.36c-4.707,0-8.559-3.852-8.559-8.559V345.83H73.95v45.801 c0,21.104,17.266,38.369,38.369,38.369h275.36c21.104,0,38.37-17.266,38.37-38.369v-283.26C426.05,87.268,408.784,70,387.68,70	h-275.36C91.216,70,73.95,87.268,73.95,108.371v45.8h29.811v-45.8C103.761,103.663,107.612,99.811,112.319,99.811L112.319,99.811z"
			/>


		</svg>
		</div>

	);//return



	// let darklite = props.darklite || "lite";
	// if(darklite === 'dark')
	// {
	// 	return(<div></div>)
	// }
	// else // lite
	// {
	
	// }




}