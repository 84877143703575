import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';



//---------------------------
//utils/hooks
//---------------------------

import useDeviceDetect from 'utils/hooks/DeviceDetect';


//---------------------------
//utils/components
//---------------------------

import {
    Cleaner as XssCleaner
} from 'utils/components/Xss';






//import '../../StyleSheets/dynamic-form.css';
import 'app-style/dynamic-form/style.css';

import StandardFieldOptions from '../StandardFieldOptions';

import {

    // ACCOUNT as ACCOUNT_GRAPHIC,
    // // EMPLOYEE as EMPLOYEE_GRAPHIC,
    // // EXPENSES as EXPENSES_GRAPHIC,
    // // OVERHEAD as OVERHEAD_GRAPHIC,
    // // OWNER as OWNER_GRAPHIC,
    // // SCHEDULE as SCHEDULE_GRAPHIC,

    // CALCULATOR as CALCULATOR_GRAPHIC,
    // // DIRECT_LABOR_HOURS as HOURS_GRAPHIC,
    // // DIRECT_LABOR_RATES as RATES_GRAPHIC,
    // // HEALTHCARE as HEALTHCARE_GRAPHIC,
    // HOME as HOME_GRAPHIC,
    // // IRA as IRA_GRAPHIC,
    // KNOWLEDGE_BASE as KNOWLEDGE_GRAPHIC,
    // LOGIN as LOGIN_GRAPHIC,
    // LOGOUT as LOGOUT_GRAPHIC,
    // PRICING as PRICING_GRAPHIC,  
    // REPORTS as REPORTS_GRAPHIC,
    // SETTINGS as SETTINGS_GRAPHIC,
    // SIGNUP as SIGNUP_GRAPHIC


    EYE_CLOSED as EYE_CLOSED_GRAPHIC,
    EYE_OPENED as EYE_OPENED_GRAPHIC,


} from 'app-components/Zone/_SVGComps/ICONS';



import {
    LibNumber,
    LibString
} from 'library';



export const FFfield = (props) => {



    // -- Device Detect -- //
    const DeviceDetection = useDeviceDetect();
    //console.log("FFcostfield - DeviceDetection " + JSON.stringify(DeviceDetection));
    let isMobile = DeviceDetection.isMobile;





    // -- ref -- //

    const refPword = useRef(null);


    const [pwordInputType, setPwordInputType] = useState('password');

    //const [fieldArr, setFieldArr] = useState([]);
    const [fieldVal, setFieldVal] = useState(false);
    const [fieldStyl, setFieldStyl] = useState({});
 
    useEffect(() => {


        var value;

        var phoneStyl = {};

        var fontStyle = 'normal';
        var fontColor = "#000";

        switch(props.data.fieldtype)
        {
        case'textarea':


            value = XssCleaner({call:"lenient",value:props.data.text});
            //alert("Xss Safe Value: "+newVal);

            setFieldVal(value);
        break;
        case'select':

            if(props.data.hint === props.data.text 
            || props.data.text === ''
            || props.data.text.length === 0
            )
            {
                fontStyle = 'italic';
                fontColor = '#777';

            }

            value = XssCleaner({call:"lenient",value:props.data.text});
            setFieldVal(value);

        break;
        // case'phone':
    
    
    
        //     var phone_id = props.data.id;
    
        //     var phone_array = ["###","###","####"];
    
        //     if(props.data.text
        //     && props.data['text'] !== ''  
        //     && props.data['text'] !== props.data.hint 
        //     && props.data['text'] !== undefined
        //     )
        //     {
        //         //alert(Object.prototype.toString.call(props.data.text));
        //         //alert(props.data.text);

        //         phone_array = props.data['text'].split("-");
   
        //     }//#
    
    
            
        //     var phArr = [];
        //     for(var phx=0; phx < 3; phx++)
        //     {
    
        //         var ID;
    
        //         var init_val;
        //         var safe_init_val;
        //         var curr_val;
    
        //         var wrap_class;
        //         var wrap_id;
        //         var wrap_width;	
                              
        //         var input_class;
        //         var input_value;
        //         var input_maxlength;

    
        //         phoneStyl['phx'+phx] = {

        //             bgColor     :'white',
        //             fontStyle   :'italic',
        //             fontColor   :'#777'

        //         };


        //         switch(phx)
        //         {
        //         case 0:
    
    
    
        //             //################################################
        //             //area CODE
            
        //             ID = phone_id+"_1";
        //             init_val = '###';
        //             safe_init_val = init_val.replace( / /gi,'--');
    
        //             if(phone_array[phx] !== ''  
        //             && phone_array[phx] !== init_val 
        //             && phone_array[phx] !== undefined
        //             )
        //             {
        //                 curr_val = phone_array[phx];
        //                 if(curr_val.match(/###/gi))
        //                 {
        //                     phoneStyl['phx'+phx] = {

        //                         bgColor     :'white',
        //                         fontStyle   :'italic',
        //                         fontColor   :'#777'
    
        //                     };
    
        //                 }else{

        //                     phoneStyl['phx'+phx] = {

        //                         bgColor     :'white',
        //                         fontStyle   :'normal',
        //                         fontColor   :'#000'

        //                     };

        //                 }


        //             }//=====
    
              
        //             wrap_class = "input-wrap-3digit";
        //             wrap_id = ID+"_wrap";
    
        //             wrap_width = "20%";
        //             //if(!threshold_6) wrap_width = "20%";
                
    
    
    
                                                    
        //             input_class = 'input-tag-empty';
        //             input_value = init_val;	
        
    
        //             if(curr_val !== ''  
        //             && curr_val !== init_val 
        //             && curr_val !== undefined
        //             )
        //             {
        //                 input_class = 'input-tag';
        //                 input_value = curr_val;	

        //             }//#


        //             input_maxlength = "3";
    
           
        //         break;
        //         case 1:
    
    
    
        //             //###############################################
        //             //LOCAL CODE
    
        //             ID = phone_id+"_2";
        //             init_val = '###';//fetext;
        //             safe_init_val = init_val.replace( / /gi,'--');
    
                   
        //             if(phone_array[phx] !== ''  
        //             && phone_array[phx] !== init_val 
        //             && phone_array[phx] !== undefined
        //             )
        //             {
        //                 curr_val = phone_array[phx];

        //                 if(curr_val.match(/###/gi))
        //                 {
        //                     phoneStyl['phx'+phx] = {

        //                         bgColor     :'white',
        //                         fontStyle   :'italic',
        //                         fontColor   :'#777'
    
        //                     };
    
        //                 }else{

        //                     phoneStyl['phx'+phx] = {

        //                         bgColor     :'white',
        //                         fontStyle   :'normal',
        //                         fontColor   :'#000'

        //                     };
        //                 }


        //             }//===
    
            
     
        //             wrap_class = "input-wrap-3digit";
        //             wrap_id = ID+"_wrap";
    
        //             wrap_width = "20%";
        //             //if(!threshold_6) wrap_width = "20%";
                
    
    
                           
        //             input_class = 'input-tag-empty';
        //             input_value = init_val;	
    
    
        //             if(curr_val !== ''  
        //             && curr_val !== init_val 
        //             && curr_val !== undefined
        //             )
        //             {
        //                 input_class = 'input-tag';
        //                 input_value = curr_val;	
        
    
        //             }//#
                  
        //             input_maxlength = "3";
    
                                                           
        
        //         break;
        //         case 2:
    
    
    
        //             //##############################################
        //             //4 DIGITS
    
        //             ID = phone_id+"_3";
        //             init_val = '####';//fetext;
        //             safe_init_val = init_val.replace( / /gi,'--');
    
                   
        //             if(phone_array[phx] !== ''  
        //             && phone_array[phx] !== init_val 
        //             && phone_array[phx] !== undefined
        //             )
        //             {
        //                 curr_val = phone_array[phx];
        //                 if(curr_val.match(/####/gi))
        //                 {
        //                     phoneStyl['phx'+phx] = {

        //                         bgColor     :'white',
        //                         fontStyle   :'italic',
        //                         fontColor   :'#777'
    
        //                     };
    
        //                 }else{

        //                     phoneStyl['phx'+phx] = {

        //                         bgColor     :'white',
        //                         fontStyle   :'normal',
        //                         fontColor   :'#000'

        //                     };

        //                 }

    
        //             }//=====
    
                 
        
        //             wrap_class = "input-wrap-4digit";
        //             wrap_id = ID+"_wrap";
    
        //             wrap_width = "25%";
        //             //if(!threshold_6) wrap_width = "25%";
                
                              
        //             input_class = 'input-tag-empty';
        //             input_value = init_val;	
    
                
        //             if(curr_val !== ''  
        //             && curr_val !== init_val 
        //             && curr_val !== undefined
        //             )
        //             {
        //                 input_class = 'input-tag';
        //                 input_value = curr_val;	

        //             }//#

                                
        //             input_maxlength = "4";
    
                    
        //         break;
        //         default:
        //         break;
        //         }//####
    
    
    
        //         phArr.push({
    
        //             initVal         :init_val,
        //             safeInitVal     :safe_init_val,
        //             currVal         :curr_val,
    
        //             wrapClass       :wrap_class,
        //             wrap_id         :wrap_id,
        //             wrapWidth       :wrap_width,
    
        //             inputClass      :input_class,
        //             inputId         :ID,
        //             inputValue      :input_value,
        //             inputMaxLength  :input_maxlength
                   
        //         });

    
        //     }//==


        //     setFieldArr(phArr);
        // break;
        case'date':
        break;
        case'expdata':
        break;
        default:

            if(props.data.hint === props.data.text 
            || props.data.text === ''
            )
            {
                fontStyle = 'italic';
                fontColor = '#777';
            }


            switch(props.data.id)
            {
            case'email':
            case'password':
            case'pword':
            case'url':


                value = XssCleaner({call:props.data.id,value:props.data.text});
                //alert("Xss Safe Value: "+newVal);
            break;
            case'price':

                value = props.data.text;
                value = LibNumber.RealMoney(value);
                value = parseFloat(value);
                value = value.toFixed(2);

                value = XssCleaner({call:props.data.id,value:props.data.text});
                //alert("Xss Safe Value: "+newVal);
            break;
            case'percent':

                value = props.data.text;
                value = LibNumber.RealPercent(value);
                value = parseFloat(value);
                value = value.toFixed(1);

                value = XssCleaner({call:props.data.id,value:props.data.text});
                //alert("Xss Safe Value: "+newVal);
            break;
            default:

                if(props.data.id.match(/email/gi))
                {
                    value = XssCleaner({call:'email',value:props.data.text});
                    //alert("Xss Safe Value: "+newVal);
                }else{

                    value = XssCleaner({call:"lenient",value:props.data.text});
                    //alert("Xss Safe Value: "+newVal);
                 
                }

            }
            setFieldVal(value);
        break;
        }//===
    
    

        setFieldStyl({
            "bgColor"       :"transparent",
            "fontStyle"     :fontStyle,
            "fontColor"     :fontColor,
            "phoneStyl"     :phoneStyl
        });




        //console.log(JSON.stringify(props,null,2));


        
        if(props.problemArr.length > 0)
        {
            var arr = props.problemArr;
            for(var i=0; i < arr.length; i++)
            {
                if(props.ij === arr[i]['key']
                )
                {
                    setFieldStyl({
                        "bgColor":"lightyellow",
                        "fontStyle":fontStyle,
                        "fontColor":fontColor
                    });
                    break;

                }
        
            }//== i

        }//==

    

 
     },[
         props
        
    ])
 







     
    //<input name="firstName" onChange={e => setFirstName(e.target.value)} />
     


    // -- handle -- //

    function handlePwordInputType() {

        var pwordType;
        if(pwordInputType === 'password') 
        {
            pwordType = 'text';
        }else{
            pwordType = 'password';
        }

        setPwordInputType(pwordType);

        //alert(refPword.current.id);
        refPword.current.focus();

    }


      

    // -- handle -- //

    function handleFocus(target) {

        target.select();

    }

    
    function handleBlur(e) {

        //console.log(e.target.value);
        //e.target.select();
        //alert(e.target.id);

        var inp = document.getElementById(e.target.id);
        //alert(inp.value);
        inp.blur();
      

        if(e.target.id === 'pword' || e.target.id === 'password')
        {
            //alert('is pword')
            if(pwordInputType === 'text') setPwordInputType('password');
        }


        var newVal;

        if(e.fieldType === 'price' 
        || e.fieldType === 'percent'
        )
        {

            newVal = fieldVal;
            if(e.target.value !== '')
            {
                newVal = e.target.value;
            }

            switch(e.fieldType)
            {
            case'price':

                if(isNaN(newVal)) newVal = 1;

                newVal = parseFloat(newVal);
                newVal = newVal.toFixed(2);
            break;
            case'percent':

                if(isNaN(newVal)) newVal = 1;

                newVal = parseFloat(newVal);
                newVal = newVal.toFixed(1);
            break;
            default:
            }//==




            switch(props.data.id)
            {
            case'email':
            case'password':
            case'pword':
            case'url':
    
                newVal = XssCleaner({call:props.data.id,value:newVal});
                //alert("Xss Safe Value: "+newVal);
            break;
            case'price':

                newVal = LibNumber.RealMoney(newVal);
                newVal = parseFloat(newVal);
                newVal = newVal.toFixed(2);

                newVal = XssCleaner({call:props.data.id,value:newVal});
                //alert("Xss Safe Value: "+newVal);
            break;
            case'percent':

                newVal = LibNumber.RealPercent(newVal);
                newVal = parseFloat(newVal);
                newVal = newVal.toFixed(2);

                newVal = XssCleaner({call:props.data.id,value:newVal});
                //alert("Xss Safe Value: "+newVal);
            break;
            default:

                if(props.data.id.match(/email/gi))
                {
                    newVal = XssCleaner({call:'email',value:newVal});
                    //alert("Xss Safe Value: "+newVal);
                }else{
                    newVal = XssCleaner({call:"default",value:newVal});
                    //alert("Xss Safe Value: "+newVal);
                }
            }

            setFieldVal(newVal);



            props.handle({

                call        :'capture',
                fieldCall   :"field",
                key         :props.ij,
                id          :props.data.id,
                data        :props.data,
                newValue    :newVal
        
            })



        }


    }
    

    function handleChange(e) {


        //console.log("TARGET INDX: ", e.indx);
        //console.log("TARGET VALUE: ", e.target.value);
        //console.log("PLACEHOLDER: ", e.target.placeholder);
        //console.log("TARGET ID: ", e.target.id);
        //console.log("TARGET TYPE: ", e.target.type);
        //console.log("TARGET HINT: ", props.data.hint);

        //alert(e.target.id);

        var newStyl;
        var newVal;
        var newArr;
        var i;
        //var phoneStyl;
        //var phoneVal;


        if(e.target.type.match(/select/gi)
        )
        {
            newStyl = fieldStyl;

            if(e.target.value !== props.data.hint && e.target.value !== '')
            {
                newStyl['fontStyle'] = 'normal';
                newStyl['fontColor'] = '#000';
            }else{
                newStyl['fontStyle'] = 'italic';
                newStyl['fontColor'] = '#999';
            }
            
            setFieldStyl(newStyl);


  
            newVal = fieldVal;
            if(e.target.value !== '')
            {
                newVal = e.target.value;
            }

            newVal = XssCleaner({call:"default",value:newVal});
            console.log("Xss Safe Value: "+newVal);

            setFieldVal(newVal);


            props.handle({

                call        :'capture',
                fieldCall   :"field",
                key         :props.ij,
                id          :props.data.id,
                data        :props.data,
                newValue    :newVal
        
            })


        }
        else



        //PHONE - Triple Input Box

        // if(e.target.id.match(/phone/gi)
        // )
        // {

        //     /*
        //     phArr.push({
    
        //         initVal         :init_val,
        //         safeInitVal     :safe_init_val,
        //         currVal         :curr_val,

        //         wrapClass       :wrap_class,
        //         wrap_id         :wrap_id,
        //         wrapWidth       :wrap_width,

        //         inputClass      :input_class,
        //         inputId         :ID,
        //         inputValue      :input_value,
        //         inputMaxLength  :input_maxlength
               
        //     });
        //     */





        //     newStyl = fieldStyl;

        //     if(fieldStyl.phoneStyl 
        //     && Object.prototype.toString.call(fieldStyl.phoneStyl) === '[object Object]')
        //     {
        //         phoneStyl = fieldStyl.phoneStyl;
        //     }else{
        //         phoneStyl = {};
        //     }


 


        //     var phx = e.target.id.split("_")[1];

        //     var phoneNumber;
        //     newArr = [];
        //     for(i=0; i < fieldArr.length; i++)
        //     {
        //         var object = fieldArr[i];

            
        //         if(parseInt(phx) === parseInt(i+1))
        //         {
        //             //console.log(phx+"==="+i); 
        //             object['inputValue'] = e.target.value;

        //             if(e.target.value.match(/###/gi))
        //             {
        //                 phoneStyl['phx'+i] = {

        //                     bgColor     :'white',
        //                     fontStyle   :'italic',
        //                     fontColor   :'#999'

        //                 };
        //             }
        //             else
        //             {
        //                 phoneStyl['phx'+i] = {

        //                     bgColor     :'white',
        //                     fontStyle   :'normal',
        //                     fontColor   :'#000'

        //                 };
                
        //             }


        //         }
        //         newArr.push(object);



        //         if(i===0)
        //         {
        //             phoneNumber = object['inputValue'];
      
        //         }
        //         else
        //         if(i===fieldArr.length-1)
        //         {

        //            phoneNumber = phoneNumber + object['inputValue'];
      
        //         }
        //         else
        //         {

        //             phoneNumber = phoneNumber + "-" + object['inputValue'] + "-";

        //         }
           

        //     }//==


        //     newStyl.phoneStyl = phoneStyl;

        //     setFieldStyl(newStyl);
        //     setFieldArr(newArr);

        //     props.handle({

        //         call        :'capture',
        //         fieldCall   :"field",
        //         key         :props.ij,
        //         id          :props.data.id,
        //         data        :props.data,
        //         newValue    :phoneNumber
     
        //     })




        // }


        //PHONE - Single Input Box

        if(e.target.id.match(/phone/gi)
        )
        {

            newStyl = fieldStyl;

            if(e.target.value !== props.data.hint && e.target.value !== '')
            {
                newStyl['fontStyle'] = 'normal';
                newStyl['fontColor'] = '#000';
            }else{
                newStyl['fontStyle'] = 'italic';
                newStyl['fontColor'] = '#999';
            }

            setFieldStyl(newStyl);



            newVal = fieldVal;
            if(e.target.value !== '')
            {
                newVal = e.target.value;
            }else{
                newVal = '';
            }
            //alert("newVal: "+newVal);


            if(newVal.length > fieldVal.length)
            {
                if(newVal.charAt(newVal.length-1) !== '-')
                {
                    //if(newVal.match(/ /g)) newVal = newVal.replace(/ /g,"");
                    //if(newVal.match(/\./g)) newVal = newVal.replace(/\./g,"");
                    if(newVal.match(/-/g)) newVal = newVal.replace(/-/g,"");



                    // if(newVal.length > 6)
                    // {
                    //     newVal = LibString.SplitAtIndex(newVal,7,"-");
                    // }
                    // else
                    // if(newVal.length > 2 && newVal.length < 6) 
                    // {
                    //     newVal = LibString.SplitAtIndex(newVal,3,"-");
                    // }


                    // if(newVal.length === 4 || newVal.length === 5) newVal = LibString.SplitAtIndex(newVal,3,"-");




                    if(!newVal.match(/-/g) && newVal.length > 7)
                    {
                        newVal = LibString.SplitAtIndex(newVal,3,"-");
                        newVal = LibString.SplitAtIndex(newVal,7,"-");
                    }
                    else
                    if(newVal.length === 7) 
                    {
                        newVal = LibString.SplitAtIndex(newVal,3,"-");
                        newVal = LibString.SplitAtIndex(newVal,7,"-");
                    }
                    else
                    if(newVal.length > 3 && newVal.length < 7) 
                    {
                        newVal = LibString.SplitAtIndex(newVal,3,"-");
                    }
                    else
                    if(newVal.length === 3) 
                    {
                        newVal = LibString.SplitAtIndex(newVal,3,"-");
                    }



                    if(newVal.match(/ /g)) newVal = newVal.replace(/ /g,"-");
                    if(newVal.match(/\./g)) newVal = newVal.replace(/\./g,"-");
                    if(newVal.match(/--/g)) newVal = newVal.replace(/--/g,"-");


                }


            }

            newVal = XssCleaner({call:"default",value:newVal});
            console.log("Xss Safe Value: "+newVal);

        
            setFieldVal(newVal);


            props.handle({

                call        :'capture',
                fieldCall   :"field",
                key         :props.ij,
                id          :props.data.id,
                data        :props.data,
                newValue    :newVal
        
            })




        }

        else
        {

            newStyl = fieldStyl;

            if(e.target.value !== props.data.hint && e.target.value !== '')
            {
                newStyl['fontStyle'] = 'normal';
                newStyl['fontColor'] = '#000';
            }else{
                newStyl['fontStyle'] = 'italic';
                newStyl['fontColor'] = '#999';
            }

            setFieldStyl(newStyl);



            newVal = fieldVal;
            if(e.target.value !== '')
            {
                newVal = e.target.value;
            }else{
                newVal = '';
            }
            // alert("newVal: "+newVal);



            switch(props.data.id)
            {
            case'email':
            case'password':
            case'pword':
            case'url':
    
                newVal = XssCleaner({call:props.data.id,value:newVal});
                //alert("Xss Safe Value: "+newVal);
            break;
            case'price':

                
                newVal = LibNumber.RealMoney(newVal);
                newVal = parseFloat(newVal);
                newVal = newVal.toFixed(2);
                
                newVal = XssCleaner({call:props.data.id,value:newVal});
                //alert("Xss Safe newVal: "+newVal);
            break;
            case'percent':
            
                
                newVal = LibNumber.RealPercent(newVal);
                newVal = parseFloat(newVal);
                newVal = newVal.toFixed(1);
                
                newVal = XssCleaner({call:props.data.id,value:newVal});
                //alert("Xss Safe newVal: "+newVal);
            break;
            default:

                if(props.data.id.match(/email/gi))
                {
                    newVal = XssCleaner({call:'email',value:newVal});
                    //alert("Xss Safe Value: "+newVal);
                }else{
                    newVal = XssCleaner({call:"default",value:newVal});
                    //alert("Xss Safe Value: "+newVal);
                }

            }


            setFieldVal(newVal);

            props.handle({

                call        :'capture',
                fieldCall   :"field",
                key         :props.ij,
                id          :props.data.id,
                data        :props.data,
                newValue    :newVal
        
            })





        }//==




        

        if(props.problemArr.length > 0)
        {
            if(props.ij !== undefined)
            {
                newArr = [];
                for(i=0; i < props.problemArr.length; i++)
                {

                    //console.log(props.problemArr[i]['key'] +" "+ props.ij);
                    if(props.problemArr[i]['key'] !== props.ij)
                    {
                        newArr.push(props.problemArr[i]);
                    }
                }

                //console.log(JSON.stringify(newArr,null,2));
                //alert('new')
                props.handle({

                    call:"resetProblemArr",
                    errors:newArr

                });

            }
        }


        



    }








    //=======================================================
    //=======================================================

    return (


        <div className="frm">



            {
            isMobile
            ?

                //------------------------------------
                //HIDDEN
                //------------------------------------

                props.data.fieldtype === "hidden" 
                ? 


                    <div 
                    key={props.ij}
                    className="input-wrap"
                    >

                        <input
                            type="hidden"
                            className="input-tag"
                            id={props.data.id}
                            value={fieldVal}          
                            placeholder={props.data.hint}
                            tabIndex={props.data.tabIndex}

                            //onChange={handleChange}
                            onChange={event => {

                                handleChange({

                                    target:event.target,
                                    indx:props.ij

                                })


                            }}

                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"fields",
                                    index       :props.ij
                                
        
                                }), 0);
                            }}

            
                        />

                    </div>



                : 


                //------------------------------------
                //TEXT FIELD
                //------------------------------------

                props.data.fieldtype === "text" 
                ? 


                    <div 
                    key={props.ij}
                    className="input-wrap"
                    style={{
                        backgroundColor:fieldStyl.bgColor
                    }}

                    >

                        <input
                            type="text"
                            inputMode="text"
                            // type="number" pattern="[0-9]*"
                            // inputMode="numeric"

                            className="input-tag"
                            style={{
                                backgroundColor:fieldStyl.bgColor,
                                fontStyle:fieldStyl.fontStyle,
                                color:fieldStyl.fontColor
                            }}

                            id={props.data.id}
                            value={fieldVal}
                            placeholder={props.data.hint}
                            tabIndex={props.data.tabIndex}

        
                            //onFocus={handleFocus}

                            /*
                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => target.select(), 0);
                            }}
                            */

                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}
                            //onBlur={handleBlur}  
                            //onChange={handleChange}
                            onChange={event => {

                                handleChange({

                                    target:event.target,
                                    indx:props.ij

                                })


                            }}

                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"fields",
                                    index       :props.ij
                                
        
                                }), 0);
                            }}



                        />

                    </div>



                : 


                 // TM:
                //------------------------------------
                //PASSWORD FIELD
                //------------------------------------

                props.data.fieldtype === "password" 
                ? 

            
                    <div 
                    key={props.ij}
                    className="input-wrap"
                    style={{
                        backgroundColor:fieldStyl.bgColor
                    }}

                    >

                        <input
                            ref={refPword}

                            type={pwordInputType}
                            //type="password"
                            inputMode="text"
                            // type="number" pattern="[0-9]*"
                            // inputMode="numeric"

                            className="input-tag"
                            style={{
                                backgroundColor:fieldStyl.bgColor,
                                fontStyle:fieldStyl.fontStyle,
                                color:fieldStyl.fontColor
                            }}

                            id={props.data.id}
                            value={fieldVal}
                            placeholder={props.data.hint}
                            tabIndex={props.data.tabIndex}

                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}
                            onBlur={handleBlur}  
                            //onChange={handleChange}
                            onChange={event => {

                                handleChange({

                                    target:event.target,
                                    indx:props.ij

                                })


                            }}

                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"fields",
                                    index       :props.ij
                                
        
                                }), 0);
                            }}


                        />




                        <div 
                        className="input-view-button"
                        onClick={handlePwordInputType}
                        >

                            {
                            pwordInputType === 'text'
                            ?

                                <EYE_OPENED_GRAPHIC
                                                    
                                    iconColor = {"#191e28"}
                                    darklite = {"lite"}

                                />

                            :

                                <EYE_CLOSED_GRAPHIC
                                                    
                                    iconColor = {"#191e28"}
                                    darklite = {"lite"}

                                />


                            }


                        </div>



                    </div>



                :


                //------------------------------------
                //TEXTAREA
                //------------------------------------

                props.data.fieldtype === "textarea" 
                ? 
    
                    <div 
                    key={props.ij}
                    className="ta-wrap"
                    style={{
                        backgroundColor:fieldStyl.bgColor
                    }}

                    >

                        <textarea
                        className="ta-tag"
                        style={{
                            backgroundColor:fieldStyl.bgColor,
                            fontStyle:fieldStyl.fontStyle,
                            color:fieldStyl.fontColor
                        }}

                        id={props.data.id}
                        placeholder={props.data.hint}
                        tabIndex={props.data.tabIndex}

                        value={fieldVal}


                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}
                            //onBlur={handleBlur}  
                            //onChange={handleChange}
                            onChange={event => {

                                handleChange({

                                    target:event.target,
                                    indx:props.ij

                                })

                            }}

                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"fields",
                                    index       :props.ij
                                
        
                                }), 0);
                            }}


                        >
                        </textarea>
                    
                    </div>
                
                :


            
                //------------------------------------
                //SELECT
                //------------------------------------

                props.data.fieldtype === "select" 
                ? 
    
                    <div 
                    key={props.ij}
                    className="sel-wrap"
                    style={{
                        backgroundColor:fieldStyl.bgColor
                    }}

                    >

                        <select
                        className="sel-tag"
                        style={{
                            backgroundColor:fieldStyl.bgColor,
                            fontStyle:fieldStyl.fontStyle,
                            color:fieldStyl.fontColor
                        }}

                        id={props.data.id}
                        placeholder={props.data.hint}
                        tabIndex={props.data.tabIndex}

                        //value={fieldVal}

                        value={

                            fieldVal === '' && props.data.hint.toLowerCase() === 'country'
                            ? 
                                "US" 
                            :  
                            fieldVal !== '' 
                            ? 
                                fieldVal 

                            :''

                        }


                        //onChange={handleChange}
                        onChange={event => {

                            handleChange({

                                target:event.target,
                                indx:props.ij

                            })


                        }}

                        onKeyPress = {event => {
                            // event properties must be copied to use async
                            setTimeout(() => props.handleMovement({

                                event       :event,
                                section     :"fields",
                                index       :props.ij
                            

                            }), 0);
                        }}
                        
                
                        /*
                        onFocus = {event => {
                            // event properties must be copied to use async
                            const target = event.target;
                            setTimeout(() => handleFocus(target), 0);
                        }}
                        */
                        //onBlur={handleBlur}
                        

                        >

                            {
                            //====================================			
                            //first option
                            //====================================
                            }

                            <option 
                            className="opt"       
                            key={props.data.hint} 
                            value={props.data.hint}
                            >

                                {props.data.hint}

                            </option>
                        

            
                            {
                            //====================================			
                            //options
                            //====================================


                            Object.prototype.toString.call(props.data.array) === '[object Array]'
                            ?

                                props.data.array.map((option) => (

                                    <option 
                                    className="opt"                     
                                    key={option} 
                                    value={option}
                                    >

                                        {option}

                                    </option>
                                ))

                            :


                                
                                props.data.hint.toUpperCase() === 'STATE'
                                ?

                                    StandardFieldOptions.state.map((option) => (

                                        <option 
                                        className="opt"                     
                                        key={option[0]} 
                                        value={option[0]}

                                        >

                                            {option[1]}

                                        </option>
                                    ))


                                :
                                props.data.hint.toUpperCase() === 'COUNTRY'
                                ?

                                    StandardFieldOptions.country.map((option) => (


                                        <option 
                                        className="opt"                     
                                        key={option[0]} 
                                        value={option[0]}
                                        // selected={"US" === option[0]}
                                        >

                                            {option[1]}

                                        </option>


                                    ))


                                :null



                            }

                    
                        </select>
                    
                    </div>
                
                : 





                //TM:
                //------------------------------------
                //ZIPCODE
                //------------------------------------

                props.data.fieldtype === "zipcode" 
                ? 


                    <div 
                    key={props.ij}
                    className="half"
                    style={{
                        backgroundColor:fieldStyl.bgColor
                    }}

                    >


                        <div 
                        //key={props.ij}
                        className="input-wrap-half"
                        style={{
                            backgroundColor:fieldStyl.bgColor
                        }}

                        >

                            <input
                                type="text"
                                inputMode="text"
                                // type="number" pattern="[0-9]*"
                                // inputMode="numeric"

                                maxLength="5"

                                className="input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}

                                id={props.data.id}
                                value={fieldVal}
                                placeholder={props.data.hint}
                                tabIndex={props.data.tabIndex}

            
                                //onFocus={handleFocus}

                                /*
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => target.select(), 0);
                                }}
                                */

                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocus(target), 0);
                                }}
                                //onBlur={handleBlur}  
                                //onChange={handleChange}
                                onChange={event => {

                                    handleChange({

                                        target:event.target,
                                        indx:props.ij

                                    })


                                }}

                                onKeyPress = {event => {
                                    // event properties must be copied to use async
                                    setTimeout(() => props.handleMovement({

                                        event       :event,
                                        section     :"fields",
                                        index       :props.ij
                                    
            
                                    }), 0);
                                }}



                            />

                        </div>

                    </div>



                :




                //TM:
                //------------------------------------
                //PHONE FIELD
                //------------------------------------

                props.data.fieldtype === "phone" 
                ? 

                

                    // <div
                    // key={props.ij}
                    // className="phone"
                    // id={props.data.id}
                    // >
                    // {fieldArr.map((phData, j) => (
        
                    //     <div
                    //     key={props.ij+"-"+j}
                    //     className={phData.wrapClass}
                    //     style={{

                    //         backgroundColor     :fieldStyl.bgColor,
                    //         width               :phData.wrapWidth

                    //     }}


                    //     >

                    //         <input
                    //             className={phData.inputClass}
                    //             style={{

                    //                 backgroundColor :fieldStyl.phoneStyl['phx'+j].bgColor,
                    //                 fontStyle:fieldStyl.phoneStyl['phx'+j].fontStyle,
                    //                 color:fieldStyl.phoneStyl['phx'+j].fontColor
                    //             }}


                    //             id={phData.inputId}
            
                    //             // type="text"
                    //             // inputMode="text"
                    //             type="number" pattern="[0-9]*"
                    //             inputMode="numeric"


                    //             value={phData.inputValue}
                    //             placeholder={phData.safeInitVal}
                    //             tabIndex={parseInt(props.data.tabIndex) + j}

                    //             maxLength={phData.inputMaxLength}

                    //             onFocus = {event => {
                    //                 // event properties must be copied to use async
                    //                 const target = event.target;
                    //                 setTimeout(() => handleFocus(target), 0);
                    //             }}
                    //             //onBlur={handleBlur}  
                    //             //onChange={handleChange}
                    //             onChange={event => {

                    //                 handleChange({

                    //                     target:event.target,
                    //                     indx:props.ij

                    //                 })

                    //             }}

                    //             onKeyPress = {event => {
                    //                 // event properties must be copied to use async
                    //                 setTimeout(() => props.handleMovement({
        
                    //                     event       :event,
                    //                     section     :"fields",
                    //                     index       :props.ij
                                    
            
                    //                 }), 0);
                    //             }}


                    //         />

                    //     </div>

                    // ))}
                    // </div>




                    <div 
                    key={props.ij}
                    className="half"
                    style={{
                        backgroundColor:fieldStyl.bgColor
                    }}

                    >

                        <div 
                        //key={props.ij}
                        className="input-wrap-half"
                        style={{
                            backgroundColor:fieldStyl.bgColor
                        }}

                        >

                            <input
                                type="text"
                                inputMode="text"
                                // type="number" pattern="[0-9]*"
                                // inputMode="numeric"

                                maxLength="10"

                                className="input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}

                                id={props.data.id}
                                value={fieldVal}
                                placeholder={props.data.hint}
                                tabIndex={props.data.tabIndex}

            
                                //onFocus={handleFocus}

                                /*
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => target.select(), 0);
                                }}
                                */

                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocus(target), 0);
                                }}
                                //onBlur={handleBlur}  
                                //onChange={handleChange}
                                onChange={event => {

                                    handleChange({

                                        target:event.target,
                                        indx:props.ij

                                    })


                                }}

                                onKeyPress = {event => {
                                    // event properties must be copied to use async
                                    setTimeout(() => props.handleMovement({

                                        event       :event,
                                        section     :"fields",
                                        index       :props.ij
                                    
            
                                    }), 0);
                                }}



                            />

                        </div>


                    </div>






                :



                //------------------------------------
                //DATE
                //------------------------------------

                props.data.fieldtype === "date" ? 
    
                    <input
                        type="text"
                        inputMode="text"
                        // type="number" pattern="[0-9]*"
                        // inputMode="numeric"

                        value=""
                        placeholder="Date"
                        tabIndex={props.data.tabIndex}
                        //onChange={e => handleChange(idx, e)}
                    />

                :

                
                //------------------------------------
                //EXPDATE
                //------------------------------------

                props.data.fieldtype === "expdate" ? 
    
                    <input
                        type="text"
                        inputMode="text"
                        // type="number" pattern="[0-9]*"
                        // inputMode="numeric"
                        value=""
                        placeholder="Date"
                        tabIndex={props.data.tabIndex}
                        //onChange={e => handleChange(idx, e)}
                    />

                : 


                //------------------------------------
                //SYYMBOL FIELD
                //------------------------------------

                props.data.fieldtype === "price" || props.data.fieldtype === "percent"
                ? 


                    <div 
                    key={props.ij}
                    className="symbol-field"
                    style={{
                        backgroundColor:fieldStyl.bgColor
                    }}

                    >


                        {
                        props.data.fieldtype === 'price' 
                        ?

                            <div
                            className="symbol"
                            >
                                $
                            </div>

                        :
                        props.data.fieldtype === 'percent'
                        ?

                            <div
                            className="symbol"
                            >
                                %
                            </div>

                        :null
                        }

                    

                        <input
                            type="text"
                            inputMode="text"
                            // type="number" pattern="[0-9]*"
                            // inputMode="numeric"

                            className="symbol-input-tag"
                            style={{
                                backgroundColor:fieldStyl.bgColor,
                                fontStyle:fieldStyl.fontStyle,
                                color:fieldStyl.fontColor
                            }}

                            id={props.data.id}
                            value={fieldVal}
                            placeholder={props.data.hint}
                            tabIndex={props.data.tabIndex}

        
                            //onFocus={handleFocus}

                            /*
                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => target.select(), 0);
                            }}
                            */

                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}

                            //onBlur={handleBlur} 
                            onBlur={event => {

                                handleBlur({

                                    target      :event.target,
                                    indx        :props.ij,
                                    fieldType   :props.data.fieldtype

                                })


                            }}
                            
                            //onChange={handleChange}
                            onChange={event => {

                                handleChange({

                                    target      :event.target,
                                    indx        :props.ij,
                                    fieldType   :props.data.fieldtype

                                })


                            }}
                            
                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"fields",
                                    index       :props.ij
                                
        
                                }), 0);
                            }}



                        />

                    </div>



                :null







            : 
            //###########################################################################################

            // NOT MOBILE

            //###########################################################################################



                //------------------------------------
                //HIDDEN
                //------------------------------------

                props.data.fieldtype === "hidden" 
                ? 


                    <div 
                    key={props.ij}
                    className="input-wrap-hidden"
                    >

                        <input
                            type="hidden"
                            className="input-tag"
                            id={props.data.id}
                            value={fieldVal}          
                            placeholder={props.data.hint}
                            tabIndex={props.data.tabIndex}

                            //onChange={handleChange}
                            onChange={event => {

                                handleChange({

                                    target:event.target,
                                    indx:props.ij

                                })

                            }}
            
                        />

                    </div>



                : 


                //------------------------------------
                //TEXT FIELD
                //------------------------------------

                props.data.fieldtype === "text" 
                ? 


                    <div 
                    key={props.ij}
                    className="input-wrap"
                    style={{
                        backgroundColor:fieldStyl.bgColor
                    }}

                    >

                        <input
                            type="text"
                            inputMode="text"
                            // type="number" pattern="[0-9]*"
                            // inputMode="numeric"

                            className="input-tag"
                            style={{
                                backgroundColor:fieldStyl.bgColor,
                                fontStyle:fieldStyl.fontStyle,
                                color:fieldStyl.fontColor
                            }}

                            id={props.data.id}
                            value={fieldVal}
                            placeholder={props.data.hint}
                            tabIndex={props.data.tabIndex}

        
                            //onFocus={handleFocus}

                            /*
                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => target.select(), 0);
                            }}
                            */

                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}
                            //onBlur={handleBlur}  
                            //onChange={handleChange}
                            onChange={event => {

                                handleChange({

                                    target:event.target,
                                    indx:props.ij

                                })


                            }}

                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"fields",
                                    index       :props.ij
                                
        
                                }), 0);
                            }}



                        />

                    </div>



                : 


                 // TM:
                //------------------------------------
                //PASSWORD FIELD
                //------------------------------------

                props.data.fieldtype === "password" ? 

            
                    <div
                    key={props.ij}
                    className="input-wrap"
                    style={{
                        backgroundColor:fieldStyl.bgColor
                    }}

                    >

                        <input

                            ref={refPword}

                            type={pwordInputType}
                            //type="password"
                            inputMode="text"
                            // type="number" pattern="[0-9]*"
                            // inputMode="numeric"

                            className="input-tag"
                            style={{
                                backgroundColor:fieldStyl.bgColor,
                                fontStyle:fieldStyl.fontStyle,
                                color:fieldStyl.fontColor
                            }}

                            id={props.data.id}
                            value={fieldVal}
                            placeholder={props.data.hint}
                            tabIndex={props.data.tabIndex}

                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}
                            onBlur={handleBlur}  
                            //onChange={handleChange}
                            onChange={event => {

                                handleChange({

                                    target:event.target,
                                    indx:props.ij

                                })


                            }}

                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"fields",
                                    index       :props.ij
                                
        
                                }), 0);
                            }}


                        />



                        <div 
                        className="input-view-button"
                        onClick={handlePwordInputType}
                        >

                            {
                            pwordInputType === 'text'
                            ?

                                <EYE_OPENED_GRAPHIC
                                                    
                                    iconColor = {"#191e28"}
                                    darklite = {"lite"}

                                />

                            :

                                <EYE_CLOSED_GRAPHIC
                                                    
                                    iconColor = {"#191e28"}
                                    darklite = {"lite"}

                                />


                            }


                        </div>


                    </div>



                :


                //------------------------------------
                //TEXTAREA
                //------------------------------------

                props.data.fieldtype === "textarea" ? 
    
                    <div 
                    key={props.ij}
                    className="ta-wrap"
                    style={{
                        backgroundColor:fieldStyl.bgColor
                    }}

                    >

                        <textarea
                        className="ta-tag"
                        style={{
                            backgroundColor:fieldStyl.bgColor,
                            fontStyle:fieldStyl.fontStyle,
                            color:fieldStyl.fontColor
                        }}

                        id={props.data.id}
                        placeholder={props.data.hint}
                        tabIndex={props.data.tabIndex}

                        value={fieldVal}


                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}
                            //onBlur={handleBlur}  
                            //onChange={handleChange}
                            onChange={event => {

                                handleChange({

                                    target:event.target,
                                    indx:props.ij

                                })

                            }}

                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"fields",
                                    index       :props.ij
                                
        
                                }), 0);
                            }}


                        >
                        </textarea>
                    
                    </div>
                
                :



                
                //------------------------------------
                //SELECT
                //------------------------------------

                props.data.fieldtype === "select" ? 
    
                    <div 
                    key={props.ij}
                    className="sel-wrap"
                    style={{
                        backgroundColor:fieldStyl.bgColor
                    }}

                    >

                        <select
                        className="sel-tag"
                        style={{
                            backgroundColor:fieldStyl.bgColor,
                            fontStyle:fieldStyl.fontStyle,
                            color:fieldStyl.fontColor
                        }}

                        id={props.data.id}
                        placeholder={props.data.hint}
                        tabIndex={props.data.tabIndex}

                        //value={fieldVal}

                        value={

                            fieldVal === '' && props.data.hint.toLowerCase() === 'country'
                            ? 
                                "US" 
                            : 
                            fieldVal !== '' 
                            ? 
                                fieldVal 

                            :''

                        }


                        //onChange={handleChange}
                        onChange={event => {

                            handleChange({

                                target:event.target,
                                indx:props.ij

                            })


                        }}

                        onKeyPress = {event => {
                            // event properties must be copied to use async
                            setTimeout(() => props.handleMovement({

                                event       :event,
                                section     :"fields",
                                index       :props.ij
                            

                            }), 0);
                        }}
                        
                
                        /*
                        onFocus = {event => {
                            // event properties must be copied to use async
                            const target = event.target;
                            setTimeout(() => handleFocus(target), 0);
                        }}
                        */
                        //onBlur={handleBlur}
                        

                        >

                            {
                            //====================================			
                            //first option
                            //====================================
                            }

                            <option 
                            className="opt"       
                            key={props.data.hint} 
                            value={props.data.hint}
                            >

                                {props.data.hint}

                            </option>
                        

            
                            {
                            //====================================			
                            //options
                            //====================================


                            Object.prototype.toString.call(props.data.array) === '[object Array]'
                            ?

                                props.data.array.map((option) => (

                                    <option 
                                    className="opt"                     
                                    key={option} 
                                    value={option}
                                    >

                                        {option}

                                    </option>
                                ))

                            :


                            
                                props.data.hint.toUpperCase() === 'STATE'
                                ?

                                    StandardFieldOptions.state.map((option) => (

                                        <option 
                                        className="opt"                     
                                        key={option[0]} 
                                        value={option[0]}

                                        >

                                            {option[1]}

                                        </option>
                                    ))


                                :
                                props.data.hint.toUpperCase() === 'COUNTRY'
                                ?

                                    StandardFieldOptions.country.map((option) => (


                                        <option 
                                        className="opt"                     
                                        key={option[0]} 
                                        value={option[0]}
                                        // selected={"US" === option[0]}
                                        >

                                            {option[1]}

                                        </option>


                                    ))


                                :null



                            }

                    
                        </select>
                    
                    </div>
                
                : 




                //TM:
                //------------------------------------
                //ZIPCODE
                //------------------------------------

                props.data.fieldtype === "zipcode" 
                ? 


                    <div 
                    key={props.ij}
                    className="half"
                    style={{
                        backgroundColor:fieldStyl.bgColor
                    }}

                    >

                        <div 
                        //key={props.ij}
                        className="input-wrap-half"
                        style={{
                            backgroundColor:fieldStyl.bgColor
                        }}

                        >

                            <input
                                type="text"
                                inputMode="text"
                                // type="number" pattern="[0-9]*"
                                // inputMode="numeric"

                                maxLength="5"

                                className="input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}

                                id={props.data.id}
                                value={fieldVal}
                                placeholder={props.data.hint}
                                tabIndex={props.data.tabIndex}

            
                                //onFocus={handleFocus}

                                /*
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => target.select(), 0);
                                }}
                                */

                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocus(target), 0);
                                }}
                                //onBlur={handleBlur}  
                                //onChange={handleChange}
                                onChange={event => {

                                    handleChange({

                                        target:event.target,
                                        indx:props.ij

                                    })


                                }}

                                onKeyPress = {event => {
                                    // event properties must be copied to use async
                                    setTimeout(() => props.handleMovement({

                                        event       :event,
                                        section     :"fields",
                                        index       :props.ij
                                    
            
                                    }), 0);
                                }}



                            />

                        </div>


                    </div>




                :




                //TM:
                //------------------------------------
                //PHONE FIELD
                //------------------------------------

                props.data.fieldtype === "phone" 
                ? 

                

                    // <div
                    // key={props.ij}
                    // className="phone"
                    // id={props.data.id}
                    // >
                    // {fieldArr.map((phData, j) => (
        
                    //     <div
                    //     key={props.ij+"-"+j}
                    //     className={phData.wrapClass}
                    //     style={{

                    //         backgroundColor     :fieldStyl.bgColor,
                    //         width               :phData.wrapWidth

                    //     }}


                    //     >

                    //         <input
                    //             className={phData.inputClass}
                    //             style={{

                    //                 backgroundColor :fieldStyl.phoneStyl['phx'+j].bgColor,
                    //                 fontStyle:fieldStyl.phoneStyl['phx'+j].fontStyle,
                    //                 color:fieldStyl.phoneStyl['phx'+j].fontColor
                    //             }}


                    //             id={phData.inputId}
            
                    //             type="text"
                    //             inputMode="text"
                    //             //type="number" pattern="[0-9]*"
                    //             // inputMode="numeric"


                    //             value={phData.inputValue}
                    //             placeholder={phData.safeInitVal}
                    //             tabIndex={parseInt(props.data.tabIndex) + j}

                    //             maxLength={phData.inputMaxLength}

                    //             onFocus = {event => {
                    //                 // event properties must be copied to use async
                    //                 const target = event.target;
                    //                 setTimeout(() => handleFocus(target), 0);
                    //             }}
                    //             //onBlur={handleBlur}  
                    //             //onChange={handleChange}
                    //             onChange={event => {

                    //                 handleChange({

                    //                     target:event.target,
                    //                     indx:props.ij

                    //                 })

                    //             }}

                    //             onKeyPress = {event => {
                    //                 // event properties must be copied to use async
                    //                 setTimeout(() => props.handleMovement({
        
                    //                     event       :event,
                    //                     section     :"fields",
                    //                     index       :props.ij
                                    
            
                    //                 }), 0);
                    //             }}


                    //         />

                    //     </div>

                    // ))}
                    // </div>




                    <div 
                    key={props.ij}
                    className="half"
                    style={{
                        backgroundColor:fieldStyl.bgColor
                    }}

                    >



                        <div 
                        //key={props.ij}
                        className="input-wrap-half"
                        style={{
                            backgroundColor:fieldStyl.bgColor
                        }}

                        >

                            <input
                                type="text"
                                inputMode="text"
                                // type="number" pattern="[0-9]*"
                                // inputMode="numeric"

                                maxLength="12"

                                className="input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}

                                id={props.data.id}
                                value={fieldVal}
                                placeholder={props.data.hint}
                                tabIndex={props.data.tabIndex}

            
                                //onFocus={handleFocus}

                                /*
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => target.select(), 0);
                                }}
                                */

                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocus(target), 0);
                                }}
                                //onBlur={handleBlur}  
                                //onChange={handleChange}
                                onChange={event => {

                                    handleChange({

                                        target:event.target,
                                        indx:props.ij

                                    })


                                }}

                                onKeyPress = {event => {
                                    // event properties must be copied to use async
                                    setTimeout(() => props.handleMovement({

                                        event       :event,
                                        section     :"fields",
                                        index       :props.ij
                                    
            
                                    }), 0);
                                }}



                            />

                        </div>


                    </div>





                :

             
                //------------------------------------
                //DATE
                //------------------------------------

                props.data.fieldtype === "date" ? 
    
                    <input
                        type="text"
                        inputMode="text"
                        // type="number" pattern="[0-9]*"
                        // inputMode="numeric"

                        value=""
                        placeholder="Date"
                        tabIndex={props.data.tabIndex}
                        //onChange={e => handleChange(idx, e)}
                    />

                :

                
                //------------------------------------
                //EXPDATE
                //------------------------------------

                props.data.fieldtype === "expdate" ? 
    
                    <input
                        type="text"
                        inputMode="text"
                        // type="number" pattern="[0-9]*"
                        // inputMode="numeric"
                        value=""
                        placeholder="Date"
                        tabIndex={props.data.tabIndex}
                        //onChange={e => handleChange(idx, e)}
                    />

                : 


                //------------------------------------
                //SYYMBOL FIELD
                //------------------------------------

                props.data.fieldtype === "price" || props.data.fieldtype === "percent"
                ? 


                    <div 
                    key={props.ij}
                    className="symbol-field"
                    style={{
                        backgroundColor:fieldStyl.bgColor
                    }}

                    >


                        {
                        props.data.fieldtype === 'price' 
                        ?

                            <div
                            className="symbol"
                            >
                                $
                            </div>

                        :
                        props.data.fieldtype === 'percent'
                        ?

                            <div
                            className="symbol"
                            >
                                %
                            </div>

                        :null
                        }

                    

                        <input
                            type="text"
                            inputMode="text"
                            // type="number" pattern="[0-9]*"
                            // inputMode="numeric"

                            className="symbol-input-tag"
                            style={{
                                backgroundColor:fieldStyl.bgColor,
                                fontStyle:fieldStyl.fontStyle,
                                color:fieldStyl.fontColor
                            }}

                            id={props.data.id}
                            value={fieldVal}
                            placeholder={props.data.hint}
                            tabIndex={props.data.tabIndex}

        
                            //onFocus={handleFocus}

                            /*
                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => target.select(), 0);
                            }}
                            */

                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}

                            //onBlur={handleBlur} 
                            onBlur={event => {

                                handleBlur({

                                    target      :event.target,
                                    indx        :props.ij,
                                    fieldType   :props.data.fieldtype

                                })


                            }}
                            
                            //onChange={handleChange}
                            onChange={event => {

                                handleChange({

                                    target      :event.target,
                                    indx        :props.ij,
                                    fieldType   :props.data.fieldtype

                                })


                            }}
                            
                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"fields",
                                    index       :props.ij
                                
        
                                }), 0);
                            }}



                        />

                    </div>



                :null


            }



        </div>

    )


}




