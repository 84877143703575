import styled from "styled-components";



export const ReportPrint = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "lightyellow",//#fcfcfc

    width:              props.width || "100%", 
    height:             props.height || "auto",

    padding:            props.padding|| "0 0 100px 0",
    margin:             props.margin || "10px auto 0 auto",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "#454d5f",



}))`

    background-color:${props => props.backgroundColor};

    width   :${props => props.width};
    height  :${props => props.height};

    min-height: 400px;

    padding :${props => props.padding}; 
    margin  :${props => props.margin};


    position:relative;  
    
    
    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


`;












