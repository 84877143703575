
const VPRdefaults = {

    "overhead": {
        "overhead": "0.0",
        "overhead_decimal": "0.0"
    },


    'hourly_direct_rates' : {

		"ownr_highest"  : "0.00",
		"ownr_lowest"	: "0.00",
		"ownr_average"  : "0.00",

		"empl_highest"  : "0.00",
		"empl_lowest"	: "0.00",
		"empl_average"  : "0.00",

	},


    /*
    "owners": {
        "owners_sti": "72428.57",
        "owners_ira_yearly": "1000.00",
        "owners_flag_case": "greater",
        "owners_hours_indirect_percent_whole": "25.00",
        "owners_sti_yearly_indirect": "18107.14",
        "owners_taxes_monthly": "1785.71",
        "owners_hours_direct_percent_whole": "75.00",
        "owners_hours_direct_percent": "0.75",
        "owners_taxrate_reciprocal": "0.70",
        "owners_flag": "<br/>The owner spends the majority of their time performing direct labor.<br/>25% of the owners salary will be considered an Overhead expense..<br/>Graphic Design, Production and Installation rates will use the owners Direct Labor rate.",
        "owners_billable_hours_weekly_direct": "30.00",
        "owners_sti_hourly_direct": "36.21",
        "owners_health_insurance_monthly": "500.00",
        "owners_total_weekly_hours": "40.00",
        "owners_taxrate_decimal": "0.30",
        "owners_ira_monthly_indirect": "20.83",
        "owners_health_insurance_yearly": "6000.00",
        "owners_taxes_monthly_direct": "1339.29",
        "owners_salary_overhead": "1041.67",
        "owners_sti_monthly_direct": "4526.79",
        "owners_sti_monthly_indirect": "1508.93",
        "owners_hours_indirect_percent": "0.25",
        "owners_ira_decimal": "0.02",
        "owners_sti_weekly": "1448.57",
        "owners_taxes_monthly_indirect": "446.43",
        "owners_ira_monthly": "83.33",
        "owners_sti_yearly_direct": "54321.43",
        "owners_sti_weekly_direct": "1086.43",
        "owners_sti_weekly_indirect": "362.14",
        "owners_st": "71428.57",
        "owners_ira_monthly_direct": "62.50",
        "owners_taxes_yearly": "21428.57",
        "owners_working_weeks": "50.00",
        "owners_salary_pretax_monthly": "4166.67"
    },
    */


    "totals": {

        "total_weekly_hours": "40.00",

        "total_facility": "0.00",
        "total_business": "0.00",
        "total_equipsoftware": "0.00",
        "total_vehicles": "0.00",

        "total_tax": "0.00",
        "total_health_insurance": "0.00",
        "total_ira": "0.00",

        "total": "0.00"

    }
}


export{

    VPRdefaults

}