import { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



export const Check = (props) => {


    //-------------------------------
    // redux 
    //-------------------------------

    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    const [validToken, setValidToken] = useState(false);


    useEffect(() => {


        if(localStorage.tokenTimestamp 
        && localStorage.tokenTimestamp !== undefined)
        {


        const interval = setInterval(() => {
        
            //console.log('This will run every second!');

            let isValid = false;

            let now,nowTime,nowDate;
            let expiresTime,expiresDate;
        
            nowTime = "N/A";
            nowDate = "N/A";
            expiresTime = "N/A";
            expiresDate = "N/A";
    
            if(localStorage.tokenTimestamp
            && localStorage.tokenTimestamp !== undefined)
            {
        
                now = new Date();
                nowTime = now.getTime();
                nowDate = new Date(parseInt(nowTime));
        
                expiresTime = localStorage.tokenTimestamp;
                expiresDate = new Date(parseInt(expiresTime));
        
                // console.log("Token Timestamp..."
                //     +"\nexpiresTime : "+expiresTime
                //     +"\nexpiresDate : "+expiresDate
                //     +"\nnowTime : "+nowTime
                //     +"\nnowDate : "+nowDate
                // );
        
                if(parseInt(nowTime) < parseInt(expiresTime))
                {
                    isValid = true;
                }else{

                    isValid = false;
                    console.log("Token Timestamp..."
                        +"\nexpiresTime : "+expiresTime
                        +"\nexpiresDate : "+expiresDate
                        +"\nnowTime : "+nowTime
                        +"\nnowDate : "+nowDate
                    );
                    if(localStorage.tokenTimestamp) localStorage.removeItem("tokenTimestamp");


                    // props.handleState({call:'showLoader',value:true});
                    // props.handleState({call:'loaderData',value: {

                    //     isError     : props.isError,
                    //     errorType   : props.errorType,
                    //     isTimed     : props.isTimed,
                    //     items       : props.items

                    // }});

                    props.databind.handle({

                        call:"showLoader",
                        isError     : false,
                        errorType   : "processing",
                        isTimed     : false,
                        items       : []

                    })



                    var basename = zoneObj.basename;

                    // -- change location -- //
                
                    var url = '';
                    // url = '/Login/';
                    // //var url = basename + 'AuthArea/Login';
                    // //var url = "/templates/AuthArea/FormLogin";
                    // //history.replace(url);
                    // //history.push(url);
                    // //history.goBack();
                
                
                    url = window.location.protocol + "//";
                
                    if(window.location.hostname.match(/192.168/gi)
                    || window.location.hostname.match(/localhost/gi)
                    )
                    {
                        url = url + window.location.hostname +":"+ window.location.port;
                    }else{
                        url = url + window.location.hostname;
                    }//=
                
                    // if(basename !== '')
                    // {
                    //     url = url + basename + 'Login/';
                    // }else{
                    //     url = url + "/Login/";
                    // }
                
                    if(basename !== '')
                    {
                        url = url + basename + '/';
                    }else{
                        url = url + "/";
                    }
                
                    url = url + "Login/";//to login screen
            
                    //alert("url:"+url);
                    window.location.replace(url);//regular do


                }
        
            }

            setValidToken(isValid);


        }, 2000);

        return () => clearInterval(interval);


        }


    }, [

        props,zoneObj

    ]);


    return validToken;



}








