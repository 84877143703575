import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import { 

    BarChart,

    //Title,
    Content,
    List,
    //Markers,
    Lines,
    Bars,
  
    // VerticalLine,
    // HorizontalLine

    //FlexVerticalContainer


} from './Styled';



import { 

    handleCreateLines,
    handleCreateBars,
    handleCreateListItems,
    //handleCreateMarkers

} from './Handles';




export const HrzBarChart = (props) => {


    // console.log("HrzBarChart")
    // console.log(JSON.stringify(props,null,2));


    // -- window dims -- //
    const { 
        width,
        //height 
    } = useWindowDimensions();


    //=============================================
    // refs
    //=============================================

    // -- ref -- //
    const refChart = useRef(null);
    //const refTitle = useRef(null);
    const refContent = useRef(null);
    const refList = useRef(null);    
    const refLines = useRef(null);
    const refBars = useRef(null);
 
    
    // const refSide = useRef(null);
    // const refMain= useRef(null);


    // const [rowRefs] = useState([]);
    // const setRowRef = (ref) => {

    //     if(ref && ref !== null && ref !== undefined)
    //     {
    //         //console.log("ref::::"+ref.id);
    //         var found;
    //         found = false;
    //         for(var i=0; i < rowRefs.length; i++)
    //         {
    //             if(rowRefs[i].id === ref.id) found = true;
    //         }
    //         if(!found) rowRefs.push(ref);
    //         //console.log(boxRefs.length);

    //     }

    // };


    //=============================================
    // state/effect - isLoaded
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [currentWidth, setCurrentWidth] = useState(false);  
    const [data, setData] = useState(false);
    const [items, setItems] = useState([]);



    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);
        }else{


            var array;
            var number;
            var i;
            var count = 0;
            
            array = props.data;

            var minHeight = 0;//default 230
            var listWidth = 0;//default 125

            if(Object.prototype.toString.call(array) === '[object Array]')
            {
                number = array.length;
            }else{
                number = 0;
            }

            var maxBarHeight = 40;
            var letterCount, newLetterCount;
            var text,charlen;


            if(!currentWidth || width !== currentWidth)
            {
    
                setCurrentWidth(width);

                var showLines = false;
                if(props.chartLines) showLines = props.chartLines;


                var markersColor = "#edeef1";
                if(props.markersColor) markersColor = props.markersColor;

                var linesColor = "#edeef1";
                if(props.linesColor) linesColor = props.linesColor;


                var textColor = "black";
                if(props.textColor) textColor = props.textColor;



                //console.log("props"+JSON.stringify(props,null,2));

                var contentHeight = refContent.current.offsetHeight;

            
                //------ 

                var linesVrtGap = 50;
                var linesVertical = refLines.current.offsetWidth / linesVrtGap;

                var linesHrzGap = 50;
                var linesHorizontal = refLines.current.offsetHeight / linesHrzGap;

        

                //-------

                var barsVrtGap = 10;
                var barsVertical = refBars.current.offsetWidth / barsVrtGap;

                var barsHrzGap = 10;
                var barsHorizontal = refBars.current.offsetHeight / barsHrzGap;



                //--------

                for(i=0; i < number; i++)
                {

                    minHeight = minHeight + (maxBarHeight + barsHrzGap);

                    letterCount = array[i].name.length;

                    //alert(letterCount+" > "+count);
                    if(letterCount > count)
                    {
                        charlen = 16;
                        if(letterCount > charlen)
                        {
                            count = letterCount;
                            newLetterCount = (charlen+2) + 5;
                            listWidth = newLetterCount * 10.5

                            text = array[i].name;             
                            text = text.substring(0,charlen);    
                            text = text+"..";


                            array[i].name = text;


                        }else{

                            count = letterCount;
                            newLetterCount = parseInt(letterCount) + 5;
                            listWidth = newLetterCount * 10.5;
                        }

                    }

                }
                if(minHeight === 0) minHeight = 230;
                if(listWidth === 0) listWidth = 125;
                
                //console.log("minHeight: "+minHeight);
                // console.log("listWidth: "+listWidth);
                // alert(listWidth)



                //------

                var linesBorder = 0;
                if(showLines) linesBorder = "1px solid "+linesColor;

                var linesWidth = refContent.current.offsetWidth - (listWidth + 30);
                //var linesWidth = refLines.current.offsetWidth;
                var linesHeight = refLines.current.offsetHeight;
                var linesCount = 10;
            
        
                var barsWidth = refContent.current.offsetWidth - (listWidth + 30);
                var barsHeight = refBars.current.offsetHeight;


                var abLeft = (listWidth + 20) + "px";

            
                setData({

                    markersColor:markersColor,
                    linesColor:linesColor,
                    textColor:textColor,

                    minHeight:minHeight,

                    contentHeight:contentHeight,

                    listWidth:listWidth,


                    abLeft:abLeft,


                    showLines:showLines,
                    linesBorder:linesBorder,
                    linesWidth:linesWidth,
                    linesHeight:linesHeight,
                    linesCount:linesCount,

                    barsWidth:barsWidth,
                    barsHeight:barsHeight,
             

                    linesHrzGap:linesHrzGap,
                    linesHorizontal:linesHorizontal,

                    linesVrtGap:linesVrtGap, 
                    linesVertical:linesVertical,


                    barsHrzGap:barsHrzGap,
                    barsHorizontal:barsHorizontal,

                    barsVrtGap:barsVrtGap, 
                    barsVertical:barsVertical,


                });

            }



            setItems(array);



        }


    },
    [
        props,

        width,currentWidth,


        isLoaded,
        //data,

        refChart,
        refContent,
        refList,
        refLines
    ])




    if(!isLoaded)
    {

        return(

            <div></div>
        )


    }else{


        return (

            <div>

                <BarChart
                ref={refChart}
                >

                    {/* <Title
                    ref={refTitle}
                    >
                    </Title> */}

                    <Content
                    ref={refContent}
                    >

                        <List
                        ref={refList}
                        width={data.listWidth+"px"}
                        height={data.minHeight+"px"}
                        //backgroundColor={'lightyellow'}
                        >

                            {handleCreateListItems({

                                maxHeight:data.minHeight,
                                array:items,
                                gap:data.barsHrzGap,
                                textColor:data.textColor

                            })}
    

                        </List>


                        <Bars
                        ref={refBars}
                        left={data.abLeft}
                        //backgroundColor={'lightblue'}
                        width={data.barsWidth+"px"}
                        height={data.minHeight+"px"}
                        >

                            <div>
                                {handleCreateBars({

                                    maxHeight:data.minHeight,
                                    array:props.data,
                                    type:"horizontal",
                                    gap:data.barsHrzGap,
                                 
                                })}
                            </div>


                        </Bars>


                        <Lines
                        ref={refLines}
                        borderRight={data.linesBorder}
                        left={data.abLeft}
                        width={data.linesWidth+"px"}
                        height={data.minHeight+"px"}
                        >
                            {data.showLines
                            ?

                                <div>
                                    {handleCreateLines({

                                        linesColor:data.linesColor,
                                        maxWidth:data.linesWidth,
                                        number:data.linesCount,
                                        type:"vertical",
              

                                    })}
                                </div> 


                            :null}

                        </Lines>


                
                    </Content>

                </BarChart>


            </div>


        )    

    }





}










/*

{[...Array(10)].map((e, i) => {
    
})}


*/

// {Array.from(Array(10), (e, i) => {
    
// })}



// let linesHorizontal = Array.apply(null, {length: 4}).map(Number.call, Number);
// let linesVertical = Array.apply(null, {length: 5}).map(Number.call, Number);

