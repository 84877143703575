import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import { 

    DonutChart,
    Hole,
    Parts

} from './Styled';



// import { 

//     handleCreateLines,
//     handleCreateBars,
//     handleCreateListItems,
//     handleCreateMarkers

// } from './Handles';



import { 

    ChartColorsA, 
    ChartColorsB, 
    ChartColorsC,
    ProfitColors

} from 'utils/components/Charts/ChartColors';




export const SvgDonutChart = (props) => {


    // console.log("HrzBarChart")
    // console.log(JSON.stringify(props,null,2));


    // -- window dims -- //
    const { 
        width,
        //height 
    } = useWindowDimensions();


    //=============================================
    // refs
    //=============================================

    // -- ref -- //
    const refChart = useRef(null);
    const refContent = useRef(null);

    

    //=============================================
    // state/effect - isLoaded
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [currentWidth, setCurrentWidth] = useState(false);  
    const [styl, setStyl] = useState(false);
    const [items, setItems] = useState([]);
    const [chartLabel, setChartLabel] = useState('');


    useEffect(() => {





        if(!isLoaded)
        {
            setIsLoaded(true);


            // -- set styl -- //
            setStyl({
                
                backgroundColor:props.data.backgroundColor,
                width:props.data.width,
                height:props.data.height,
                ring:props.data.ring,
                hole:props.data.hole

            });
            

            // // -- set items -- // 
            // var strokeColors = ChartColorsA;

            // if(props.data.colors 
            // && Object.prototype.toString.call(props.data.colors) === '[object Array]')
            // {
            //     strokeColors = props.data.colors;
            // }
            // else
            // if(Object.prototype.toString.call(props.data.colors) === '[object String]')
            // {

            //     switch(props.data.colors)
            //     {
            //     case'ChartColorsA':

            //         strokeColors = ChartColorsA;
            //     break;
            //     case'ChartColorsB':

            //         strokeColors = ChartColorsB;
            //     break;
            //     case'ChartColorsC':

            //         strokeColors = ChartColorsC;
            //     break;
            //     default:
            //     }

            // }

            // //strokeColors = props.data.colors;
            // //console.log(JSON.stringify(strokeColors,null,2));



            // // -- items sampler -- //

            // // setItems([


            // //     {
            // //         labelledby:"donut-segment-1-title donut-segment-1-desc",

            // //         titleId:"donut-segment-1-title",
            // //         title:'Dynamic Description 1',

            // //         descId:"donut-segment-1-desc",
            // //         desc:'Dynamic Description 1',

            // //         stroke:strokeColors[0],
            // //         strokeDasharray:"80 20",
            // //         strokeDashoffset:"25",

            // //     },
            // //     {

            // //         titleId:"donut-segment-1-title",
            // //         title:'Dynamic Title 2',

            // //         descId:"donut-segment-1-desc",
            // //         desc:'Dynamic Description 2',

            // //         stroke:strokeColors[1],
            // //         strokeDasharray:"20 80",
            // //         strokeDashoffset:"-55"

            // //     }


            // // ]);



            // var newItems = [];
            // if(Object.prototype.toString.call(props.data.items) === '[object Array]')
            // {

            //     var labelledby;
            //     var titleId, title;
            //     var descId, desc;
            //     var stroke, strokeDasharray, strokeDashoffset;
            //     var label;
           
            //     var array;
            //     var name, value, percent, difPercent;
            //     var i;

            //     var total = 0;
            //     const firstOffset = 25;

            //     array = props.data.items;
            //     for(i=0; i < array.length; i++)
            //     {
                
            //         //console.log(JSON.stringify(array[i],null,2));

            //         name = array[i].name;
            //         value = array[i].value;
            //         percent = array[i].percent;
            //         percent = parseFloat(percent);
            //         difPercent = (100 - percent);


            //         labelledby = "donut-segment-"+i+"-title donut-segment-1-desc";

            //         titleId = "donut-segment-"+i+"-title";
            //         title = "Title "+i;

            //         descId = "donut-segment-"+i+"-desc";
            //         desc = "Desc "+i;
                 
            //         stroke = strokeColors[i];
            //         strokeDasharray = percent+" "+difPercent;
                
            //         if(i===0)
            //         {
            //             strokeDashoffset = firstOffset;
            //         }else{
            //             //strokeDashoffset = -`${100 - preSegmentsTotalLength + firstSegmentOffset}`;
            //             strokeDashoffset = 175 + (50-total);
            //         }

            //         total = total + percent;



            //         label = percent+"%";
            //         title = name+" "+value+" ( "+percent+"% )";
            //         desc = label;



            //         // console.log("-----------------"
            //         //     +"\npercent: "+percent
            //         //     +"\ndifPercent: "+difPercent

            //         //     +"\n---VARS---"

            //         //     +"\nfirstOffset: "+firstOffset
            //         //     +"\ntotal: "+total
                      
            //         //     +"\n---STROKE---"
            //         //     +"\nstroke: "+stroke
            //         //     +"\nstrokeDasharray: "+strokeDasharray
            //         //     +"\nstrokeDashoffset: "+strokeDashoffset
                        
            //         // )

         
            //         newItems.push({
     
            //             labelledby:labelledby,

            //             titleId:titleId,
            //             title:title,

            //             descId:descId,
            //             desc:desc,

            //             stroke:stroke,
            //             strokeDasharray:strokeDasharray,
            //             strokeDashoffset:strokeDashoffset,

            //             label:label


            //         });
    

            //     }

            // }

            // setItems(newItems);
            // setShowHoleText(true);



        }else{



            // -- set items -- // 
            var strokeColors = ChartColorsA;

            if(props.data.colors 
            && Object.prototype.toString.call(props.data.colors) === '[object Array]')
            {
                strokeColors = props.data.colors;
            }
            else
            if(Object.prototype.toString.call(props.data.colors) === '[object String]')
            {

                switch(props.data.colors)
                {
                case'ChartColorsA':

                    strokeColors = ChartColorsA;
                break;
                case'ChartColorsB':

                    strokeColors = ChartColorsB;
                break;
                case'ChartColorsC':

                    strokeColors = ChartColorsC;
                break;
                case'ProfitColors':

                    strokeColors = ProfitColors;
                break;
                default:

                    strokeColors = Object.prototype.toString.call(props.data.colors) === '[object Array]' ? props.data.colors : null;
                }

            }

            //strokeColors = props.data.colors;
            //console.log(JSON.stringify(strokeColors,null,2));

            // -- items sampler -- //

            // setItems([


            //     {
            //         labelledby:"donut-segment-1-title donut-segment-1-desc",

            //         titleId:"donut-segment-1-title",
            //         title:'Dynamic Description 1',

            //         descId:"donut-segment-1-desc",
            //         desc:'Dynamic Description 1',

            //         stroke:strokeColors[0],
            //         strokeDasharray:"80 20",
            //         strokeDashoffset:"25",

            //     },
            //     {

            //         titleId:"donut-segment-1-title",
            //         title:'Dynamic Title 2',

            //         descId:"donut-segment-1-desc",
            //         desc:'Dynamic Description 2',

            //         stroke:strokeColors[1],
            //         strokeDasharray:"20 80",
            //         strokeDashoffset:"-55"

            //     }


            // ]);



            var newItems = [];
            if(Object.prototype.toString.call(props.data.items) === '[object Array]')
            {


                var stroke, strokeDasharray;
                //var strokeDashoffset;
      
        
                var array;
                var name, value;
                var percent;
                //var difPercent;
                var i;

                //var total = 0;
                //const firstOffset = 25;

                array = props.data.items;
                for(i=0; i < array.length; i++)
                {
                
                    //console.log(JSON.stringify(array[i],null,2));

                    name = array[i].name;
                    value = array[i].value;
                    percent = array[i].percent;
                    percent = parseFloat(percent);
                    //difPercent = (100 - percent);


                    stroke = strokeColors[i];
                    strokeDasharray = percent+" 100";
                
                    // if(i===0)
                    // {
                    //     strokeDashoffset = firstOffset;
                    // }else{
                    //     //strokeDashoffset = -`${100 - preSegmentsTotalLength + firstSegmentOffset}`;
                    //     strokeDashoffset = 175 + (50-total);
                    // }

                    //total = total + percent;


                    // console.log("-----------------"
                    //     +"\npercent: "+percent
                    //     +"\ndifPercent: "+difPercent

                    //     +"\n---VARS---"

                    //     +"\nfirstOffset: "+firstOffset
                    //     +"\ntotal: "+total
                      
                    //     +"\n---STROKE---"
                    //     +"\nstroke: "+stroke
                    //     +"\nstrokeDasharray: "+strokeDasharray
                    //     +"\nstrokeDashoffset: "+strokeDashoffset
                        
                    // )

         
                    newItems.push({

                        name:name,
                        value:value,
     
                        stroke:stroke,
                        strokeDasharray:strokeDasharray,
                
                    });


                }



            

            }

            setItems(newItems);
            setChartLabel(props.data.hole.label);



            if(!currentWidth || width !== currentWidth)
            {
                setCurrentWidth(width);

                //https://www.varsitytutors.com/intermediate_geometry-help/how-to-find-the-angle-for-a-percentage-of-a-circle



                // -- reset styl on width change -- //

                var chartHeight = props.data.height;
                if(props.lessThanThresh) chartHeight = "auto";
            

                setStyl({

                    backgroundColor:props.data.backgroundColor,
                    width:props.data.width,

                    height:chartHeight,
                    margin:"0 0 0 0",

                    ring:props.data.ring,
                    hole:props.data.hole

                });

            }




        }
        //console.log("SvgDonutChart - props: "+JSON.stringify(props,null,2));

        // "data": {
        //     "backgroundColor": "transparent",
        //     "width": "100%",
        //     "height": "auto",
        //     "ring": {
        //       "size": "6",
        //       "color": "lightyellow"
        //     },
        //     "hole": {
        //       "size": 0.5,
        //       "color": "#fff",
        //       "outlineColor": "#fff",
        //       "textColor": "#000",
        //       "textSize": "27px"
        //     },
        //     "items": [

            // {
            //     "name": "Rent / Mortgage ( 80% )",
            //     "value": "$5,500.00",
            //     "percent": "80"
            // },
            // {
            //     "name": "Utilities ( 20% )",
            //     "value": "$1,100.00",
            //     "percent": "20"
            // }

        //     ],
        //     "colors": [
        //       "#07a2e3",
        //       "#20b2aa",
        //       "#fde23e",
        //       "#f16e23",
        //       "#57d9ff",
        //       "#937e88",
        //       "#20b2aa",
        //       "#ccff00",
        //       "lightblue",
        //       "lightgreen",
        //       "yellow",
        //       "pink",
        //       "lavender"
        //     ]
        //   }
        // }




    },
    [
        props,
        width,currentWidth,
        isLoaded,
        refChart,
        refContent

    ])








    // const handleClick = (hndl) => {

    //     //alert(JSON.stringify(hndl,null,2));
    //     setChartLabel(hndl.label);

    // };



    // const handleOverOut = (hndl) => {

    //     //alert(JSON.stringify(hndl,null,2));
    //     setChartLabel(hndl.label);

    // };








    if(!isLoaded)
    {

        return(

            <div></div>
        )


    }else{


        return (

            <div>

                <DonutChart
                ref={refChart}
                backgroundColor={styl.backgroundColor}
                width={styl.width}
                height={styl.height}
                >


                    <Hole
                    backgroundColor={styl.hole.color}
                    width={(styl.hole.size*100)+"px"}
                    height={(styl.hole.size*100)+"px"}

                    paddingTop={styl.hole.paddingTop}

                    top={"calc(50% - "+(styl.hole.size*100)/2+"px)"}
                    left={"calc(50% - "+(styl.hole.size*100)/2+"px)"}

                    fontSize={styl.hole.textSize}                  
                    textColor={styl.hole.textColor}
                    >




                        {chartLabel}

                        {/* 
                        <div className="Title">


                        </div> */}

                    </Hole>



                    <Parts
                    svgHeight={styl.height}
                    figureMargin={styl.margin}

                    fontSize={styl.hole.textSize}                  
                    textColor={styl.hole.textColor}

                    >

                        <svg width="100%" height="100%"   
                            viewBox= "0 0 500 500"  
                            style={{
            
                                backgroundColor: "transparent"
            
                            }}
                            preserveAspectRatio="none"
                              
                        >


                    
                            {/* <!--Graph Background-->  */}
                            <path 
                                fill="none" 
                                //stroke="rgb(69,82,101)"
                                stroke={styl.ring.color}
                                pathLength="100"
                                strokeDasharray="100"
                                //strokeWidth="70" 
                                strokeWidth={styl.ring.size ? parseInt(styl.ring.size)*10 : "70"}
                                d="M250 75 C345,75, 425,155, 425,250 C425,345, 345,425, 250,425 C155,425, 75,345, 75,250 C75,155, 155,75, 250,75"
                            />  



                            {/* <!--Gross Profit Graph-->  */}
                            {/* <path 
                                fill="none" 
                                stroke="rgb(0,104,55)"
                                pathLength="100"
                                strokeDasharray="65 100"
                                strokeWidth="70" 
                                d="M250 75 C345,75, 425,155, 425,250 C425,345, 345,425, 250,425 C155,425, 75,345, 75,250 C75,155, 155,75, 250,75"
                            /> */}

                            {/* <!--Net Profit Graph-->  */}
                            {/* <path 
                                fill="none" 
                                stroke="rgb(71,183,99)"
                                pathLength="100"
                                strokeDasharray="35 100"
                                strokeWidth="70" 
                                d="M250 40 C366,40, 460,134, 460,250 C460,366, 366,460, 250,460 C134,460, 40,366, 40,250 C40,134, 134,40, 250,40"
                            />   */}



                            {items.map((itm, i) => (


                                i===0
                                ?
                                    // Main Piece (Gross Profit Graph)
                                    <path 
                                        key={i}
                                        fill="none" 
                                        stroke={itm.stroke}
                                        pathLength="100"
                                        strokeDasharray={itm.strokeDasharray}
                                        //strokeWidth="70" 
                                        strokeWidth={styl.ring.size ? parseInt(styl.ring.size)*10 : "70"}
                                        d="M250 75 C345,75, 425,155, 425,250 C425,345, 345,425, 250,425 C155,425, 75,345, 75,250 C75,155, 155,75, 250,75"
                                    />

                                :

                                    // Skewed Piece (Net Profit Graph)
                                    <path 
                                        key={i}
                                        fill="none" 
                                        stroke={itm.stroke}
                                        pathLength="100"
                                        strokeDasharray={itm.strokeDasharray}
                                        //strokeWidth="70" 
                                        strokeWidth={styl.ring.size ? parseInt(styl.ring.size)*10 : "70"}
                                        d="M250 40 C366,40, 460,134, 460,250 C460,366, 366,460, 250,460 C134,460, 40,366, 40,250 C40,134, 134,40, 250,40"
                                    />   


                            ))}

                            
                        </svg> 

                    </Parts>

                </DonutChart>

            </div>


        )    

    }





}




