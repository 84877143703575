import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import { 

    DonutChart,
    StaticParts

} from './Styled';



// import { 

//     handleCreateLines,
//     handleCreateBars,
//     handleCreateListItems,
//     handleCreateMarkers

// } from './Handles';






export const StaticDonutChart = (props) => {


    // console.log("HrzBarChart")
    // console.log(JSON.stringify(props,null,2));


    // -- window dims -- //
    const { 
        width,
        //height 
    } = useWindowDimensions();


    //=============================================
    // refs
    //=============================================

    // -- ref -- //
    const refChart = useRef(null);
    const refContent = useRef(null);

    

    //=============================================
    // state/effect - isLoaded
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [currentWidth, setCurrentWidth] = useState(false);  
    const [data, setData] = useState(false);
    const [items, setItems] = useState([]);
    const [showCenterText, setShowCenterText] = useState(false);


    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);
    
            var newItems = [];
            if(Object.prototype.toString.call(props.data.donutSections) === '[object Array]')
            {
                var array,object;
                var percent,decimal,degree;
                var rotate,itemRotate;
                var degrees = [];
                var i,j;
                array = props.data.donutSections;
                for(i=0; i < array.length; i++)
                {
                
                    object = array[i];

                    //------
                    percent = parseFloat(object.percent);
                    decimal = (percent/100);
                    degree = 360 * decimal; //360 * decimal = number;
                    //degree = parseInt(degree);

                    degrees.push(degree);

                    if(i===0)
                    {
                        rotate = 0;
                    }
                    else
                    if(i===array.length-1)
                    {
                        rotate = -degree;
                        //rotate = -(degree + 0)
                    }
                    else
                    {
                        rotate = 0;
                        for(j=0; j < i; j++)
                        {
                            rotate = rotate + degrees[j];
                        }
                    }

                    itemRotate = degree;

                    // console.log("-----------------"
                    //     +"\npercent: "+percent
                    //     +"\ndecimal: "+decimal
                    //     +"\ndegree: "+degree
                    //     +"\nrotate: "+rotate
                    //     +"\nitemRotate: "+itemRotate
                    // )

                    newItems.push({
                        percent     :percent,
                        rotate      :rotate,
                        itemRotate  :itemRotate,
                        itemColor   :object.color 
                    });
    

                }

            }

            setItems(newItems);
            setShowCenterText(false);


        }else{

            if(!currentWidth || width !== currentWidth)
            {
                setCurrentWidth(width);

                //https://www.varsitytutors.com/intermediate_geometry-help/how-to-find-the-angle-for-a-percentage-of-a-circle




                setData({

                    donutSize                   :props.data.donutSize,
                    donutHalfSize               :props.data.donutHalfSize,
                    donutCenterSize             :props.data.donutCenterSize,
                    donutCenterTopLeft          :props.data.donutCenterTopLeft,
                    donutCenterBackgroundColor  :props.data.donutCenterBackgroundColor,
                    donutCenterBorderRadius     :props.data.donutCenterBorderRadius

                });


            }


        }
        // console.log("StaticDonutChart - props: "+JSON.stringify(props,null,2));



    },
    [
        props,
        width,currentWidth,
        isLoaded,
        refChart,
        refContent

    ])




    if(!isLoaded)
    {

        return(

            <div></div>
        )


    }else{


        return (

            <div>

                <DonutChart
                ref={refChart}
                >
                    <StaticParts
                    ref={refContent}
                    donutSize={data.donutSize}
                    donutHalfSize={data.donutHalfSize}
                    donutCenterSize={data.donutCenterSize}
                    donutCenterTopLeft={data.donutCenterTopLeft}
                    donutCenterBackgroundColor={data.donutCenterBackgroundColor}
                    donutCenterBorderRadius={data.donutCenterBorderRadius}
                    >
                        <div className="donut-chart-block block">

                            <div className="donut-chart">

                                {items.map((itm, k) => (

                                    <div                           
                                    key={k}
                                    id={"section"+k}
                                    className="clip segment"
                                    style={{

                                        transform: "rotate("+itm.rotate+"deg)"
                                    }}
                                    >
                                        <div 
                                        className="item" 
                                        data-rel={itm.percent}
                                        style={{

                                            transform: "rotate("+itm.itemRotate+"deg)",
                                            backgroundColor: itm.itemColor

                                        }}
                                        >
                                        </div>
                            
                                    </div>

                                ))}


                                {/* 
                                <div id="section1" class="clip">
                                    <div class="item" data-rel="21"></div>
                                </div>
                                <div id="section2" class="clip">
                                    <div class="item" data-rel="39"></div>
                                </div>
                                <div id="section3" class="clip">
                                    <div class="item" data-rel="31"></div>
                                </div>
                                <div id="section4" class="clip">
                                    <div class="item" data-rel="9"></div>
                                </div> 
                                */}




                                {/*
                                
                                #section1 {
                                    transform: rotate(0deg);
                                }
                                
                                    #section1 .item {
                                        background-color: #E64C65;
                                        transform: rotate(76deg);
                                    }
                                
                                
                                
                                #section2 {
                                    transform: rotate(76deg);
                                }
                                
                                    #section2 .item {
                                        background-color: #11A8AB;
                                        transform: rotate(140deg);
                                    }
                                
                                
                                
                                #section3 {
                                    transform: rotate(215deg);
                                }
                                
                                    #section3 .item {
                                        background-color: #4FC4F6;
                                        transform: rotate(113deg);
                                    }
                                
                                
                                
                                #section4 {
                                    transform: rotate(-32deg);
                                }
                                
                                    #section4 .item {
                                        background-color: #FCB150;
                                        transform: rotate(32deg);
                                    }



                                */}




                                <div className="center">

                                    {
                                    showCenterText
                                    ?

                                        <div class="txt">

                                            %
                                            
                                        </div>

                                    :null
                                    }

                                </div>

                            </div>

                        </div>

                    </StaticParts>

                </DonutChart>


            </div>


        )    

    }





}




