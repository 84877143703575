import styled from "styled-components";



export const ReportBar = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#EDEEF1",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "calc(100% - 2px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "18px",
    fontWeight:         props.fontWeight  || "550",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black",



    nameWidth:          props.nameWidth || "calc(70% - 10px)",
    valueWidth:         props.valueWidth || "calc(49% - 11px)",
    actionsWidth:       props.actionsWidth || "calc(25% - 11px)",



    leftWidth:          props.leftWidth || "calc(30% - 10px)",
    rightWidth:         props.rightWidth || "calc(69% - 11px)",



}))`


    background-color:${props => props.backgroundColor};
    border:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};
 
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;



    .Name {

        background-color:transparent;

        width:${props => props.nameWidth};
        height:auto;
        padding:13px 0 11px 10px;
        margin:0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};




    }

    .Value {

        background-color:transparent;
        border-left:1px solid lightgray;

        width:${props => props.valueWidth};
        height:auto;
        padding:13px 0 11px 10px;
        margin:0 0 0 0;

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};



    }




    .LineName {

        background-color:transparent;

        width:${props => props.nameWidth};
        height:auto;
        padding:13px 0 11px 10px;
        margin:0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};


        @media (max-width: 800px) {

            width:calc(100% - 180px);
        }

        @media (max-width: 475px) {

            width:calc(100% - 160px);
        }


    }


    .Actions {

        background-color:transparent;
        border-left:1px solid lightgray;

        width:${props => props.actionsWidth};
        height:auto;
        padding:13px 0 11px 10px;
        margin:0 0 0 0;

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};


        @media (max-width: 800px) {

            width:115px;
        }

        @media (max-width: 475px) {

            width:115px;
        }



    }





    .Left {

        background-color:transparent;

        width:${props => props.leftWidth};
        height:auto;
        padding:13px 0 11px 10px;
        margin:0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};




    }

    .Right {

        background-color:transparent;
    
        width:${props => props.rightWidth};
        height:auto;
        padding:13px 0 11px 0;
        margin:0 0 0 15px;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};



    }





`;


