
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';


/*
import { 
    useSelector, 
    useDispatch 
} 
from 'react-redux';
import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';
*/

import { createPortal } from 'react-dom';
import usePortal from 'utils/hooks/Portal';

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';

//=====================================================

import { Alfa, Base} from 'app-styled/AlfaBase.js';
import { 

    ReadrBase as CompBase,
    ReadrTab as CompTab,
    Readr as Comp,
    Print

} from './Styled';


import Cards from './Comps/Cards';
import Handler from './Handler';


import { LibElem } from 'library';

import CreateMarkup from './CreateMarkup';


const Index = (props) => {



    const portalTarget = usePortal("stage");



    // -- window dims -- //
    const { width, height } = useWindowDimensions();


    // -- router -- //
    //let match = useRouteMatch();
    //let location = useLocation();
    //let history = useHistory();


    // -- redux -- //

    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));

    //if(zoneObj['handle'] && zoneObj['handle'] !== undefined) alert(zoneObj['handle']);




    // -- vars -- //

    var title;
    //var call;
    var type = 'default';
    var data;
    var styl;

    //call = props.data.call;

    var level;
    if(props.data.data.data !== undefined) level = 2;
    else if(props.data.data !== undefined) level = 1;
    else level = 0;

    switch(level)
    {
    case 1:

        //console.log('index - Readr - 1')

        type = props.data.flyr.type;
        data = props.data.data;
        styl = props.data.flyr.styl;
    break;
    case 2:

        //console.log('index - Readr - 2')

        type = props.data.data.flyr.type;
        data = props.data.data.data;
        styl = props.data.data.flyr.styl;
    break;
    default:

        //console.log('index - Readr - 0')

        type = props.flyr.type;
        data = props.data;
        styl = props.flyr.styl;
    break;
    }//===




    switch(type.toLowerCase())
    {
    case'cards':

        title = 'Cards'; 
        if(data.name !== '') title = data.name;
    break;
    default:
        title = '';      
    }



    if(width < 400) title = '';


    var maxWidth;
    maxWidth = 900;

    if(styl
    && styl !== undefined) 
    {
        maxWidth = styl.maxWidth;
    }

    if(width < maxWidth + 50)
    {
       maxWidth = width;

    }//==


    
    console.log(""

        //+"\nCALL: "+call
        +"\nTYPE: "+type
        +"\nDATA: "+JSON.stringify(data,null,2)
        +"\nSTYL: "+JSON.stringify(styl,null,2)
    );
    

    //console.log("READR DATA :::::::::"+JSON.stringify(props.data,null,2));
    //alert('props');






    //#########################################################
    //#########################################################


    // -- ref -- //
    const refBase = useRef(null);
    const refTab = useRef(null);
    const refCompBase = useRef(null);
    const refComp = useRef(null);
    const refBin = useRef(null);

    
    /*
    const cellRefs = [];
    const setRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < cellRefs.length; i++)
            {
                if(cellRefs[i].id === ref.id) found = true;
            }
            if(!found) cellRefs.push(ref);
            //console.log(cellRefs.length);

        }

    };
    */






    // -- state -- //
    const [highZ, setHighZ] = useState(0)

    useEffect(() => {
        
        //setFlyrHeight(flyrRef.current.offsetHeight);
        if(highZ === 0)
        {
            var z = LibElem.HighestZ();
            setHighZ(z)

        }

    }, [highZ])




    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);

    const [tabHeight, setTabHeight] = useState(10); 
    
    const [compWidth, setCompWidth] = useState(false);
    const [compHeight, setCompHeight] = useState(0);
    const [compLeft, setCompLeft] = useState(false);

    const [flyrType, setFlyrType] = useState(false);
    const [flyrData, setFlyrData] = useState(false);
    const [flyrStyl, setFlyrStyl] = useState(false);

    useEffect(() => {

        if(!isLoaded)
        {
        
            setIsLoaded(true);

            setFlyrType(type.toLowerCase());
            setFlyrStyl(styl);
            setFlyrData(data);

            // if(refTab.current)
            // {
            //     setTabWidth(refTab.current.offsetWidth);
            // }

        }else{


            if(tabHeight < 50)
            {
                setTabHeight(refTab.current.offsetHeight + "px");
            }
            if(compHeight < 50)
            {
                setCompHeight(refBase.current.scrollHeight + "px");
    
            }
            if(!compWidth) 
            {
                let cwidth,cleft;
                if(width > 800)
                {
                    cwidth = 750;
                    cleft = width - cwidth/2;

                    setCompWidth(cwidth+"px");
                    setCompLeft(cleft+"px");
                }else{

                    cwidth = width - 20;
                    cleft = width - cwidth/2;

                    setCompWidth(cwidth+"px");
                    setCompLeft(cleft+"px")

                }

            }
    
            

            //else
            // if(compHeight < contentsHeight+50)
            // {
            //     if(contentsHeight < height) 
            //         flyrH = refBase.current.scrollHeight; 
            //     else
            //         flyrH = contentsHeight+50; 

            // }//==


            // if(compHeight < refBin.current.offsetHeight)
            // {
            //     flyrH = refBase.current.scrollHeight;
            // }

            
            /*
            if(flyrHeight < refBase.current.scrollHeight-200
            )
            {
                console.log(refBase.current.scrollHeight);
                setFlyrHeight(refBase.current.scrollHeight);
            }
            */

            // if(flyrH !== undefined) setFlyrHeight(flyrH);    


            /*
            console.log(""

                +   "\n FLYR HEIGHT: " +flyrHeight

                +   "\n CONTENTS: " +contentsHeight 
                +   "\n BIN: "+refBin.current.offsetHeight
                +   "\n FLYR: " +flyrRef.current.offsetHeight
                +   "\n TAB: " +refTab.current.offsetHeight
                +   "\n BASE SCROLL: " +refBase.current.scrollHeight
                +   "\n HEIGHT: " +height

            );
            */


            
            /*
            console.log("Readr"

                +"\nTYPE: "+type
                +"\nDATA: "+JSON.stringify(data,null,2)
                +"\nSTYL: "+JSON.stringify(styl,null,2)
            );
            */

            //console.log("READR DATA :::::::::"+JSON.stringify(props.data,null,2));
            //alert('readr props');




        }//isLoaded




    },[

        props,
        width,height,

        refBase,
        refTab,
        refCompBase,refComp,refBin,

        isLoaded,

        tabHeight, 

        compWidth,
        compHeight,
        compLeft,

        type,
        data,
        styl,

    ])



    // -- handle -- //

    const handle = (hndl) => {

        console.log("Readr - handle: "+hndl);

        //hndl['zoneObj'] = zoneObj;
        hndl['handleState'] = (v) => {

            //if(v.call === 'contentsHeight') setContentsHeight(v.value);       
            //if(v.call === 'handleDispatch') handleDispatch(v.value);
            if(v.call === 'handleCloseReadr') handleCloseReadr(v.value); 

        };
        Handler(hndl);

    }


    /*
    const handleDispatch = (hndl) => {

        //if(hndl.action === 'DELETE_ZONE') alert('delete zone')
    
        dispatch(ModifyZoneStore({
    
            type:hndl.action,//UPDATE_ZONE or DELETE_ZONE
            item:{
                key     :hndl.key,
                value   :hndl.value
            }

        })); 

    }
    */


    const handleCloseReadr = (hndl) => {

        props.handle({

            call:'hide'

        })

    }











    //###########################################################
    //###########################################################

    // var flyrPadBottomInt = 0;
    // var flyrPadBottom = flyrPadBottomInt + "px";

    // var flyrWInt;
    // var flyrW;

    // var flyrH;

    // var flyrMarl;

    // var flyrMartInt;
    // var flyrMart;

    // var tabMartInt;
    // var tabMart;



    // flyrWInt = maxWidth;
    // flyrMarl = (width - flyrWInt)/2 + "px";

    // if(width < flyrWInt+50)
    // {
    // flyrWInt = width;
    // flyrMarl = 0 + "px";
    // }//==

    // flyrW = flyrWInt + "px";



    // //-------------------------------------------

    // flyrMartInt = tabHeight;
    // flyrMart = flyrMartInt + 'px';


    // tabMartInt = 0;
    // tabMart = tabMartInt + "px";

    // //-------------------------------------------

    // //flyrH = baseScrollHeight - (tabHeight+flyrPadBottomInt) + "px";
    // flyrH = flyrHeight + (0) + "px";






    if(!isLoaded)
    {
        return createPortal(<div>
        </div>, portalTarget);

    }else{


        return createPortal(<div>


            <Alfa
            zIndex={highZ+1}
            ></Alfa>


            <Base 
            //id="readrBase"
            ref={refBase}
            zIndex={highZ+2}
            >


                <CompBase zIndex={"1"}
                ref={refCompBase}
                //backgroundColor={"#00cc66"}
                height={compHeight}

                onClick={() => props.handle({

                    call:'hide'
    
                })}

                >
                </CompBase>


                <CompTab
                ref={refTab}
                zIndex={"3"}
                //backgroundColor={'white'}
                //width={compWidth}
                // top={tabMart}
                //left={compLeft}
                >

                
                    <div 
                    className="Title" 
                    >  

                        {title}

                    </div>
                
            
                    <div 
                    className="X" 
                    onClick={() => props.handle({

                        call:'hide'

                    })}
                    >  
                        X
                    </div>


                </CompTab>



                <Comp zIndex={"2"}
                id="readr"
                ref={refComp}
                backgroundColor={'#fcfcfc'}
                // width={flyrW}
                // height={flyrH}
                // margin={flyrMart+" 0 0 "+flyrMarl}
                // padding={"0 0 "+flyrPadBottom+" 0"}
                >


                    <div 
                    ref={refBin}
                    className="Bin"
                    >

                        {
                
                            flyrType === 'cards'
                            ?

                                <Cards 
                                
                                    primeHandle={(v) => props.handle(v)}
                                    handle={(v) => handle(v)}
                                    data={flyrData}
                                    styl={flyrStyl}
  
                                />

                            :
                         
                                <Print
                                backgroundColor={"#181926"}
                                color={"#f8f8f8"}
                                >

                                    {
                                    Object.prototype.toString.call(flyrData.value) === '[object Object]'
                                    ?

                                        <pre>

                                            {JSON.stringify(flyrData.value,null,2)}

                                        </pre> 

                                    :

                                        <div dangerouslySetInnerHTML={CreateMarkup(flyrData.value)} />

                                    }


                    
                                </Print>


                        }

                    </div>


                </Comp>

            </Base>


        </div>, portalTarget);

        
    }//isLoaded




}


export default Index;





/*

  inputRefs = [];
  
    setRef = (ref) => {
        this.inputRefs.push(ref);
    };
    
    focusInput = (id) => this.inputRefs[id].focus();
    
    render() {
        return (
        <div>
            {this.state.data.map(({ name }) => (
            <Hello 
                placeholder={name} 
                ref={this.setRef} 
                key={name} />
            ))}
            <Button onClick={this.focusInput} id={0}>focus input 1</Button>
            <Button onClick={this.focusInput} id={1}>focus input 2</Button>
        </div>
        );
    }
    }

*/