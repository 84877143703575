import React, { 

    useState, 
    useEffect,
    // useRef

} from 'react';




//---------------------------
//utils/components
//---------------------------

import {
    Cleaner as XssCleaner
} from 'utils/components/Xss';




//import '../../StyleSheets/dynamic-form.css';
import 'app-style/dynamic-form/style.css';



export const FFdynamicDisplay = (props) => {



    //const [fieldArr, setFieldArr] = useState([]);
    const [fieldVal, setFieldVal] = useState(false);

    useEffect(() => {



        // props.data = {label and field}



        var value;

        //switch(props.data.fieldtype)

 

        if(isNaN(props.data.field.text))
        {
            //not a number

            value = props.data.field.text;
            value = XssCleaner({call:"lenient",value:value});
            //alert("Xss Safe Value: "+newVal); 

        }else{
       
            // is a number 

            value = props.data.field.text.toString();
            value = XssCleaner({call:"lenient",value:value});
            value = parseFloat(value);

        }
        setFieldVal(value);

        //console.log(JSON.stringify(props,null,2));

    
        },[
            props
        
    ])
    


    return (<div key={props.indx}>



        <div 
        className="input-wrap"
        >

            <input
                type="hidden"
                className="input-tag"
                id={props.data.field.id}
                value={fieldVal}          
                placeholder={props.data.field.hint}
                tabIndex={props.data.field.tabIndex}

                //onChange={handleChange}
                // onChange={event => {

                //     handleChange({

                //         target:event.target,
                //         indx:props.ij

                //     })


                // }}

                // onKeyPress = {event => {
                //     // event properties must be copied to use async
                //     setTimeout(() => props.handleMovement({

                //         event       :event,
                //         section     :"fields",
                //         index       :props.ij
                    

                //     }), 0);
                // }}


            />


        </div>
        
    

        <div 
        className = "dnotice"
        >
            {props.data.label.text}
            
        </div>


    </div>)



}





// <div 
// key={props.indx+"_dynamicDiplayLabel"}
// className = "bar"
// >

//     {props.data.label}
    
// </div> 


        // <div 
        // className="input-wrap"
        // >

        //     <input
        //         type="hidden"
        //         className="input-tag"
        //         id={props.data.field.id}
        //         value={fieldVal}          
        //         placeholder={props.data.field.hint}
        //         tabIndex={props.data.field.tabIndex}

        //         //onChange={handleChange}
        //         // onChange={event => {

        //         //     handleChange({

        //         //         target:event.target,
        //         //         indx:props.ij

        //         //     })


        //         // }}

        //         // onKeyPress = {event => {
        //         //     // event properties must be copied to use async
        //         //     setTimeout(() => props.handleMovement({

        //         //         event       :event,
        //         //         section     :"fields",
        //         //         index       :props.ij
                    

        //         //     }), 0);
        //         // }}


        //     />


        // </div>
        
    


