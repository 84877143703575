import styled from "styled-components";



export const ReportLedger = styled.div.attrs(props => ({

    dark:               props.dark || false,

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "calc(100% - 20px)",
    height:             props.height || "auto",
    margin:             props.margin || "10px 0 0 10px",
    padding:            props.padding || "0 0 0 0",


}))`

    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;
    float:left;

    overflow:hidden;


`;


    export const ReportBullet = styled.div.attrs(props => ({


        dark:               props.dark || false,
 

        backgroundColor:    props.backgroundColor || "gray",
        border:             props.border || "0",
        borderRadius:       props.borderRadius || "22px",

        width:              props.width || "20px",
        height:             props.height || "20px",
        margin:             props.margin || "10px 0 0 0",
        padding:            props.padding || "0 0 0 0",

        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "18px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "black",


        // width: 50px;
        // height: 50px;
        // padding:0 0 0 0;
        // margin:14px 0 0 15px;
        // float:left;		
        // overflow:hidden;

        // border-radius: 25px;
        // -webkit-border-radius: 25px;
        // -moz-border-radius: 25px;



    }))`


            background-color:${props => props.backgroundColor};
            border:${props => props.border};
            border-radius:${props => props.borderRadius};

            width:${props => props.width};
            height:${props => props.height};
        
            padding:${props => props.padding};
            margin:${props => props.margin};

            position:relative;
            float:left;

            overflow:hidden;



            .Text {

                background-color:transparent;

                width:auto;
                height:auto;
                padding:11px 10px 4px 10px;
                margin:0 10px 0 0;
                position:relative;
                float:left;

                cursor:pointer;

                font-family:${props => props.fontFamily};
                font-size:${props => props.fontSize};
                font-weight:${props => props.fontWeight};
                text-align:${props => props.textAlign};
                text-decoration:${props => props.textDecoration};
                color:${props => props.color};

                &:hover{

                    color:#191e28;
                    text-decoration:underline;

                }


            }


    `;




    export const ReportPercent = styled.div.attrs(props => ({


        off:                props.off || false,
        offBorder:          props.offBorder || "2px solid #454d5f",

        dark:               props.dark || false,
        darkBorder:         props.darkBorder || "2px solid #fcfcfc",
        darkTextColor:      props.darkTextColor || "#fcfcfc",

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "2px solid black",
        borderRadius:       props.borderRadius || "18px",

        width:              props.width || "70px",
        height:             props.height || "auto",
        margin:             props.margin || "12px 7px 0 7px",
        padding:            props.padding || "0 0 0 0",

        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "18px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "black",


        // width: 50px;
        // height: 50px;
        // padding:0 0 0 0;
        // margin:14px 0 0 15px;
        // float:left;		
        // overflow:hidden;

        // border-radius: 25px;
        // -webkit-border-radius: 25px;
        // -moz-border-radius: 25px;



    
        // border-right:${props => props.border};
        // border-left:${props => props.border};

        // ${props => props.dark && `

        //     border-right:${props.darkBorder};
        //     border-left:${props.darkBorder};

        // `}





        // border-right:${({
        //     off,
        //     dark,
        //     border,
        //     offBorder,
        //     darkBorder
        // }) => 

        //     (
        //         !off 
        //         && dark
        //         && darkBorder
        //     ) 

        //     || 

        //     (
        //         off 
        //         && dark
        //         && offBorder
        //     ) 

        //     ||

        //     border
            
        // };
    
        // border-left:${({
        //     off,
        //     dark,
        //     border,
        //     offBorder,
        //     darkBorder
        // }) => 

        //     (
        //         !off 
        //         && dark
        //         && darkBorder
        //     ) 

        //     || 

        //     (
        //         off 
        //         && dark
        //         && offBorder
        //     ) 

        //     ||

        //     border
                
        // };




        // color:${props => props.color};

        // ${props => props.dark && `
    
        //     color:${props.darkTextColor};

        // `}





    }))`


            background-color:${props => props.backgroundColor};
            border-radius:${props => props.borderRadius};
            border-top:0; 
            border-bottom:0;

            border-right:${({
                off,
                dark,
                border,
                offBorder,
                darkBorder
            }) => 

                (
                    !off 
                    && dark
                    && darkBorder
                ) 

                || 

                (
                    off 
                    && dark
                    && offBorder
                ) 

                ||

                border
                
            };
        
            border-left:${({
                off,
                dark,
                border,
                offBorder,
                darkBorder
            }) => 

                (
                    !off 
                    && dark
                    && darkBorder
                ) 

                || 

                (
                    off 
                    && dark
                    && offBorder
                ) 

                ||

                border
                    
            };







            width:${props => props.width};
            height:${props => props.height};
        
            padding:${props => props.padding};
            margin:${props => props.margin};

            position:relative;
            float:left;

            overflow:hidden;



            .Text {

                background-color:transparent;

                width:100%;
                height:auto;
                padding:0 0 0 0;
                margin:0 0 0 0;
                position:relative;
                float:left;

                font-family:${props => props.fontFamily};
                font-size:${props => props.fontSize};
                font-weight:${props => props.fontWeight};
                text-align:${props => props.textAlign};
                text-decoration:${props => props.textDecoration};
                color:${({
                    off,
                    dark,
                    color,
                    darkTextColor
                }) => 
    
                    (
                        !off 
                        && dark
                        && darkTextColor
                    ) 
    
                    || 
    
                    (
                        off 
                        && dark
                        && "#454d5f"
                    ) 
    
                    ||
    
                    color
                        
                };
    


       



            }


    `;





    export const ReportBulletPercentName = styled.div.attrs(props => ({

        dark:               props.dark || false,
        darkBorder:         props.darkBorder || "2px solid #fcfcfc",
        darkTextColor:      props.darkTextColor || "#fcfcfc",

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "2px solid black",
        borderRadius:       props.borderRadius || "18px",

        width:              props.width || "calc(100% - 125px)",
        height:             props.height || "auto",
        margin:             props.margin || "12px 7px 0 7px",
        padding:            props.padding || "0 0 0 0",

        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "18px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "black",


        // width: 50px;
        // height: 50px;
        // padding:0 0 0 0;
        // margin:14px 0 0 15px;
        // float:left;		
        // overflow:hidden;

        // border-radius: 25px;
        // -webkit-border-radius: 25px;
        // -moz-border-radius: 25px;



    }))`


            background-color:${props => props.backgroundColor};


            width:${props => props.width};
            height:${props => props.height};
        
            padding:${props => props.padding};
            margin:${props => props.margin};

            position:relative;
            float:left;

            overflow:hidden;



            .Text {

                background-color:transparent;

                width:100%;
                height:auto;
                padding:0 0 0 0;
                margin:0 0 0 0;
                position:relative;
                float:left;

                font-family:${props => props.fontFamily};
                font-size:${props => props.fontSize};
                font-weight:${props => props.fontWeight};
                text-align:${props => props.textAlign};
                text-decoration:${props => props.textDecoration};
                color:${props => props.color};

                ${props => props.dark && `
    
                    color:${props.darkTextColor};

                `}




            }


    `;







