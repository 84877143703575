export const SIGNUP = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};

	let iconColor = props.iconColor || "rgb(0,0,0)";


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

			<path 
				fill={iconColor}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M345.614,223.492c12.682,0,23.057,11.617,23.057,25.814v55.98h55.959c14.135,0,25.815,10.432,25.815,23.057 c0,12.682-11.616,23.057-25.815,23.057h-55.959v55.93c0,14.135-10.433,25.816-23.057,25.816c-12.682,0-23.057-11.617-23.057-25.816 V351.4h-55.951c-14.135,0-25.815-10.434-25.815-23.057c0-12.682,11.617-23.057,25.815-23.057h55.951v-55.98 C322.558,235.172,332.99,223.492,345.614,223.492L345.614,223.492z M209.245,50.225c-53.704,0-97.505,43.803-97.505,97.502
				c0,40.807,25.294,75.893,61.003,90.393c-28.21,2.324-56.253,7.098-84.635,13.129c-29.69,6.309-55.184,24.834-55.184,55.189v100.789 h273.011v0.291l0.042,1.893l0.141,2.063l0.234,2.039l0.322,2.012l0.411,1.982l0.499,1.955l0.581,1.912l0.665,1.879l0.744,1.838 l0.825,1.797l0.903,1.752l0.978,1.703l1.053,1.656l1.126,1.602l1.199,1.547l1.271,1.492l1.341,1.432l1.409,1.369l1.478,1.301 l1.545,1.232l1.609,1.158l1.676,1.082l1.736,1l1.797,0.912l1.854,0.824l1.909,0.727l1.959,0.625l2.007,0.521l2.043,0.412 l2.078,0.299l2.11,0.182l2.137,0.061l2.133-0.063l2.112-0.182l2.082-0.303l2.047-0.414l2.004-0.525l1.957-0.629l1.906-0.73 l1.852-0.826l1.796-0.916l1.731-1.002l1.672-1.082l1.605-1.158l1.543-1.234l1.473-1.299l1.405-1.365l1.342-1.432l1.27-1.492 l1.197-1.545l1.124-1.598l1.054-1.65l0.98-1.705l0.902-1.746l0.825-1.791l0.75-1.84l0.667-1.877l0.585-1.912l0.5-1.947l0.414-1.982 l0.326-2.016l0.235-2.037l0.143-2.063l0.044-1.893v-0.295h0.251v-39.203h39.275l1.892-0.045l2.062-0.141l2.038-0.232l2.015-0.324 l1.98-0.412l1.95-0.496l1.914-0.582l1.881-0.664l1.839-0.748l1.795-0.822l1.75-0.902l1.706-0.979l1.654-1.055l1.602-1.125
				l1.546-1.197l1.493-1.27l1.436-1.346l1.367-1.408l1.299-1.475l1.233-1.545l1.159-1.611l1.081-1.674l0.999-1.736l0.914-1.797	l0.823-1.857l0.727-1.906l0.625-1.957l0.522-2.006l0.412-2.047l0.298-2.078l0.182-2.109l0.062-2.135l-0.063-2.135l-0.183-2.111 l-0.302-2.084l-0.415-2.047l-0.525-2.004l-0.629-1.957l-0.731-1.906l-0.825-1.852l-0.916-1.795l-1.001-1.732l-1.082-1.67	l-1.159-1.607l-1.232-1.541l-1.301-1.475l-1.367-1.406l-1.43-1.34l-1.489-1.268l-1.545-1.197l-1.601-1.127l-1.651-1.053l-1.7-0.979 l-1.749-0.904l-1.796-0.828l-1.834-0.746l-1.875-0.668l-1.917-0.586l-1.95-0.5l-1.98-0.414l-2.012-0.324l-2.038-0.236l-2.067-0.143 l-1.892-0.043h-39.523v-39.547l-0.044-1.893l-0.141-2.061l-0.233-2.039l-0.324-2.014l-0.412-1.982l-0.496-1.949l-0.582-1.916 l-0.664-1.879l-0.747-1.84l-0.823-1.795l-0.902-1.752l-0.978-1.703l-1.054-1.656l-1.127-1.602l-1.198-1.549L374.3,220l-1.342-1.434 l-1.41-1.367l-1.478-1.303l-1.545-1.232l-1.61-1.16l-1.676-1.08l-1.736-1l-1.795-0.912l-1.854-0.822l-1.91-0.729l-1.96-0.627 l-2.003-0.52l-2.047-0.412l-2.081-0.299l-2.112-0.182l-2.129-0.059l-2.128,0.061l-2.116,0.182l-2.086,0.303l-2.044,0.414
				l-2.004,0.525l-1.957,0.629l-1.911,0.732l-1.85,0.826l-1.791,0.912l-1.732,1.002l-1.674,1.082l-1.607,1.162l-1.541,1.23	l-1.475,1.303l-1.408,1.367l-1.337,1.43l-1.267,1.484l-1.199,1.547l-1.128,1.602l-1.053,1.652l-0.978,1.699l-0.904,1.75	l-0.828,1.795l-0.746,1.836l-0.666,1.873l-0.587,1.918l-0.5,1.951l-0.414,1.98l-0.325,2.01l-0.236,2.039l-0.085,1.24 c-20.134-3.719-40.171-6.623-60.292-8.281c35.708-14.5,61.003-49.586,61.003-90.393C306.746,94.027,262.946,50.225,209.245,50.225
				L209.245,50.225z"
			/>


		</svg>
		</div>

	);//return



	// let darklite = props.darklite || "lite";
	// if(darklite === 'dark')
	// {
	// 	return(<div></div>)
	// }
	// else // lite
	// {
	
	// }




}

