
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


//import { useWindowDimensions } from 'utils/WindowDimensionsProvider';

import CreateLink from './CreateLink';


//import 'app-style/alfabase.css';//alfa,base,tab,tab-x,flank

import { Alfa, Base } from 'app-styled/AlfaBase';

import { 

    Flank,
    Menu, 
    MenuControls,
    MenuTitle, 
    MenuButton

} from './Styled';


import { LibArray, LibElem } from 'library';


import Data from './Data';



const Index = (props) => {



    //props.isError
    //props.isTimed
    //props.items



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();


    //console.log("Location: "+JSON.stringify(location,null,2));

    // location: {
    //     "pathname": "/Text1",
    //     "search": "",
    //     "hash": "#!",
    //     "key": "90mmxu"
    // }



    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);




    // -- window dims -- //
    //const { width, height } = useWindowDimensions();

    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
  
    let currentZone = pathname;
    currentZone = currentZone.replace(/\//gi,"-");
    currentZone = currentZone.replace(/ /gi,"-");
    if(currentZone === '') currentZone = 'Home';

    let zoneKey = currentZone + "_fetch";




    // -- ref -- //

    //const baseRef = useRef(null)
    //const tabRef = useRef(null)
    const flyrRef = useRef(null) 
    const flankRef = useRef(null) 
    // const controlsRef = useRef(null)   



    // -- state -- //
    const [highZ, setHighZ] = useState(0)

    useEffect(() => {
        
        //setFlyrHeight(flyrRef.current.offsetHeight);
        if(highZ === 0)
        {
            var z = LibElem.HighestZ();
            setHighZ(z)

        }

    }, [highZ])




    // -- state -- //
    // const [flyrHeight, setFlyrHeight] = useState(0)
    // const [flankLeftWidth, setFlankLeftWidth] = useState("0px")
    // const [flankRightWidth, setFlankRightWidth] = useState("0px")
    // const [buttonStyle, setButtonStyle] = useState({});
    // useEffect(() => {

        
    //     //setFlyrHeight(flyrRef.current.offsetHeight);
    //     setButtonStyle({

    //         bgColor     :"#0291cd",
    //         txtColor    :"#FFF",
    //         brColor     :"#0291cd"

    //     });

    // }, [])







    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [menuData, setMenuData] = useState([]);
    const [menuGrfx, setMenuGrfx] = useState({

        logo:{

            src:'NOIMAGE',
            w:'260',
            h:'40'
        },
        bullet:{

            src:'NOIMAGE',
            w:'40',
            h:'40'
        }

    });
    const [menuStyl, setMenuStyl] = useState({

        border:"1px solid #C00",
        buttonBackgroundColorOver:false,
        buttonTextColorOver:false

    });


    useEffect(() => {


        // if(props.databind.settings 
        // && props.databind.settings !== undefined)
        // {
        //     if(props.databind.settings.headerNav
        //     && props.databind.settings.headerNav !== undefined)
        //     {
    
        //         var across = props.databind.settings.headerNav.length;
    
        //         if(across > 6) across = 6;
        //         setNumAcross(across);

        //     } 
        

        // } 


        // if(zoneObj.settings 
        // && zoneObj.settings !== undefined)
        // {
        //     if(zoneObj.settings.headerNav
        //     && zoneObj.settings.headerNav !== undefined)
        //     {

        //         var across = zoneObj.settings.headerNav.length;
    
        //         if(across > 6) across = 6;
        //         setNumAcross(across);

        //     } 
        
        // } 





        if(!isLoaded)
        {

            setIsLoaded(true);


            //"https://powerdigitalmedia-net.s3.amazonaws.com/" +  
            

            //---------------------------------------
            // setMenuData
            //---------------------------------------

            if(zoneObj.settings 
            && zoneObj.settings !== undefined)
            {

                var menuLogo = {
                    src:menuGrfx.logo.src,
                    w:menuGrfx.logo.w,
                    h:menuGrfx.logo.h
                };
                var menuBullet = {
                    src:menuGrfx.logo.src,
                    w:menuGrfx.bullet.w,
                    h:menuGrfx.bullet.h 
                };
                if(zoneObj.settings.graphic.logo1)
                {

                    var menuLogoWidth;
                    var menuLogoHeight;
                    menuLogoWidth = zoneObj.settings.graphic.logo1.image.w;
                    menuLogoHeight = zoneObj.settings.graphic.logo1.image.h;
                    if(menuLogoWidth > 260) menuLogoWidth = menuGrfx.logo.w;
                    if(menuLogoHeight > 40) menuLogoHeight = menuGrfx.logo.h;


                    menuLogo = {
                        src:zoneObj.settings.graphic.logo1.image.src,
                        w:menuLogoWidth,
                        h:menuLogoHeight
                    };
                }
                if(zoneObj.settings.graphic.icon)
                {
                    menuBullet = {
                        src:zoneObj.settings.graphic.icon.image.src,
                        w:menuGrfx.bullet.w,
                        h:menuGrfx.bullet.h 
                    };
                }
                setMenuGrfx({

                    logo:menuLogo,
                    bullet:menuBullet

                });




                setMenuStyl({

                    border:zoneObj.settings.appearance.menu.menuBorder,
                    buttonBackgroundColorOver:zoneObj.settings.appearance.menu.menuButtonBackgroundColorOver,
                    buttonTextColorOver:zoneObj.settings.appearance.menu.menuButtonTextColorOver

                });





                if(zoneObj.settings.menuNav
                && zoneObj.settings.menuNav !== undefined)
                {

                    // var across = zoneObj.settings.menuNav.length;
        
                    // if(across > 6) across = 6;
                    // //setNumAcross(across);

                    //console.log("MENU NAV: "+JSON.stringify(zoneObj.settings.menuNav,null,2))


                    var menuNav = zoneObj.settings.menuNav;
                    var categories = [];
                    var captured = [];
                    var array = [];
                    var object;
                    var cat;

                    for(object of menuNav)
                    {
                        categories.push(object.category);
                    }
                    for(cat of categories)
                    {
                        if(!LibArray.InArray(cat,captured))
                        {

                            //title 
                            captured.push(cat);
                            if(cat.toLowerCase() !== 'main')
                            {
                                array.push({

                                    call:'title',
                                    data:{
                                        name:cat
                                    }

                                });

                            }

                            //buttons
                            for(object of menuNav)
                            {
                                if(object.category === cat)
                                {
                                    array.push({

                                        call:'button',
                                        data:{
                                            name:object.name,
                                            to:object.to,
                                            descr:object.descr
                                        }

                                    });
                                }
                            }
                        }
                    }

                    //---------------------------------
                    // create hash links 
                    //---------------------------------

                    console.log("zone fetch: "+JSON.stringify(zoneObj[zoneKey],null,2))

                    //console.log("DYNAMIC BUTTONS: "+JSON.stringify(array,null,2));
                    setMenuData(array);

                }else{

                    setMenuData(Data);
                }
            

            } 
            else
            {

                setMenuData(Data);

            }



        }




    },[
        
        props,
        zoneObj,
        zoneKey,
        isLoaded,
        menuGrfx,
        menuStyl
    ])

    




    






    // -- handle -- //

    // const handleOverOut = (hndl) => {



    //     /*

    //     //console.log(v);
    //     setButtonStyle({

    //         bgColor     :v.bgColor,
    //         txtColor    :v.txtColor,
    //         brColor     :v.brColor

    //     });

    //     */


    //     var button = document.getElementById(hndl.id);
    //     button.style.backgroundColor = hndl.bgColor;
    //     button.style.color = hndl.txtColor;
    //     button.style.border = "1px solid "+hndl.borderColor;


    // }


    // const handleConfirm = (hndl) => {

    //     switch(hndl.call)
    //     {
    //     case'yes':

    //         props.handle({

    //             call:'runConfirm',
    //             data:hndl.data

    //         });

    //     break;
    //     default:

    //         props.handle({

    //             call:'redirect'

    //         });
    
    //     }


    // }






    const handle = (hndl) => {


        switch(hndl.call)
        {
        case'url':


            props.handle({

                call:'default',
                data:false

            });

            //alert(JSON.stringify(hndl.data,null,2))

        

            // check url

            // var href = window.location.href;                //returns the href (URL) of the current page
            // var host = window.location.host;                //returns the domain with port number
            // var hostname = window.location.hostname;        //returns the domain name of the web host
            // var pathname = window.location.pathname;        //returns the path and filename of the current page
            // var protocol = window.location.protocol;        //returns the web protocol used (http: or https:)
            // var assign = window.location.assign;            //loads a new document

            var externalUrl = false;
            var url;
            url = hndl.data;

            var internalSite = "site.site/";

            var regxInternalSite = new RegExp(internalSite,"gi");
            var regxHost = new RegExp(window.location.host,"gi");
            if(url.match(regxInternalSite))
            {
                url = url.split(".site/")[1];
            }
            else
            if(!url.match(regxHost) && url.match(/http/g))
            {
                externalUrl = true;
            }
            // alert(externalUrl+" "+url);



            if(externalUrl)
            {
                props.handle({

                    call:'url',
                    data:{

                        action:'target',
                        url:url

                    }

                });

            }else{

                props.handle({

                    call:'url',
                    data:{

                        action:'push',
                        url:url

                    }

                });

            }




        break;
        default://close

            props.handle({

                call:'default',
                data:false

            });

        }


    }










    return ( 

        <div>


            <Alfa
            zIndex={highZ+1}
            ></Alfa>

            <Base 
            //ref={baseRef}
            zIndex={highZ+2}
            >

                <Flank
                    id="flank"
                    ref={flankRef}  

                    //backgroundColor={'#1a1926'}
                    //width={flankLeftWidth}
                    //height={flyrH}
                    //margin={flyrMart+" 0 0 "+flyrMarl}
                    //top={flyrMart}
                    //left={flyrMarl}

                    onClick={(v) => handle({

                        call:"close"

                    })}

                >
                </Flank>



                <Menu

                    id="flyr"
                    ref={flyrRef}  

                    //backgroundColor={'#1a1926'}
                    //width={flyrW}
                    //height={flyrH}
                    //margin={flyrMart+" 0 0 "+flyrMarl}
                    //top={flyrMart}
                    //left={flyrMarl}

                    border={menuStyl.border}

                >



                    <MenuControls
                    //ref={setTileRef}         
                    key={"controls"}
                    id={"menuControls"}
                    >

                        {/* <div 
                        className="Name"
                        >
                            {"PowerDigitalMedia"}
                    
                        </div> */}

                        <div className="Img">

                            <img 
                                src={menuGrfx.logo.src} 
                                width={menuGrfx.logo.w} 
                                height={menuGrfx.logo.h} 
                                alt="noimg"
                            />

                        </div>





                    
                        <div 
                        className="XButton"
                        onClick={(v) => handle({

                            call:'close'

                        })}
                        >

                            {"X"}
            
                        </div>
                    
                
                    </ MenuControls>




                    {menuData.map((item, i) => (

                        item.call === 'title'
                        ?
                    
                            <MenuTitle
                            //ref={setTileRef}         
                            key={"menuTitle-"+i}
                            id={"menuTitle-"+i}
                            >

                                <div className="Img">

                                    <img 
                                        src={menuGrfx.bullet.src} 
                                        width={menuGrfx.bullet.w} 
                                        height={menuGrfx.bullet.h} 
                                        alt="noimg"
                                    />

                                </div>


                                <div className="Text">

                                    {item.data.name}
                                    {/* {keyName+ " " +item.name} */}

                                </div>
                            
                            </ MenuTitle>


                        :
                        item.call === 'button'
                        ?



                            // <MenuButton
                            // //ref={setTileRef}         
                            // key={"menuButton-"+i}
                            // id={"menuButton-"+i}

                            // //backgroundColor={item.backgroundColor}
                            // //float={"left"}
                            // onClick={(v) => handle({
                            //     call:'url',
                            //     data:item.data
                            
                            // })}
                            // >

                            //     <div className="Text">

                            //         {item.data.name}
                            //         {/* {keyName+ " " +item.name} */}

                            //     </div>
                            
                            // </ MenuButton>
                            





                            <div key={i}>
                            {
                            item.data.to.match(/site.site/gi)
                            ?

                                <MenuButton
                                id={"menuButton-"+i}
                                onClick={(v) => handle({

                                    call      :"url",
                                    data      :item.data.to

                                })}


                                hoverBackgroundColor={menuStyl.buttonBackgroundColorOver}
                                hoverTextColor={menuStyl.buttonTextColorOver}
                                >

                                    {item.data.name}


                                </ MenuButton>




                            ://ANCHOR
                            item.data.to.match(/http/gi) || item.data.to.match(/\/\//gi)
                            ?



                                <a 
                                href={CreateLink({

                                    name:item.data.name,
                                    value:item.data.to

                                })}
                                target="_blank"
                                rel="noreferrer"
                                >

                                    <MenuButton
                                    id={"menuButton-"+i}

                                    hoverBackgroundColor={menuStyl.buttonBackgroundColorOver}
                                    hoverTextColor={menuStyl.buttonTextColorOver}
                                    >
                                        {item.data.name}

                                    </ MenuButton>

                                </a>


                        
                            :

                                <MenuButton
                                id={"menuButton-"+i}
                                onClick={(v) => handle({

                                    call      :"url",
                                    data      :item.data.to

                                })}

                                hoverBackgroundColor={menuStyl.buttonBackgroundColorOver}
                                hoverTextColor={menuStyl.buttonTextColorOver}
                                >

                                    {item.data.name}


                                </ MenuButton>



                            }
                            </div>


                        :null
                 


                    ))}


            
                </Menu>

            </Base>

        </div>



    );



}





export default Index;


