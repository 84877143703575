
import styled from "styled-components";


/*
https://styled-components.com/docs

    //Stretching
    flex: 1 1 300px;
    
    //No Stretching
    flex: 0 1 450px;

*/

const FormColumn = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
    height:             props.height || "auto"

}))`

    background-color: ${props => props.backgroundColor};

    width: 100%;
    height: ${props => props.height};

    margin:0 0 0 0;

    display:flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 550px) {
    
        background-color:white;
    }




`;


const FormContainer = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    margin:             props.margin  || "0", 
    //flex:               props.flex || "1 1 450px",    //Stretching
    flex:               props.flex || "0 1 500px"       //No Stretching

}))`

    background-color: ${props => props.backgroundColor};
    margin: ${props => props.margin};
    flex: ${props => props.flex};
    overflow:hidden;


`;



const FormBox = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",
    border:             props.border || "1px solid lightgray",

}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius: 7px;
    width: 89.5%;
    height:auto;
    min-height:2px;
    padding:0 5% 50px 5%;
    margin:0 0 0 0;
    position:relative;
    float:left;

    @media (max-width: 550px) {
    
        border:0;
    }




`;








export {

    FormColumn,
    FormContainer,
    FormBox  

}
