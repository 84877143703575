import React,{ 

    Suspense,
    lazy,

    useState, 
    useEffect, 
    useRef 

} from 'react';

//import ReactDOMServer from 'react-dom/server';



import { 
    useSelector, 
    useDispatch 
} 
from 'react-redux';
import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,

} from "react-router-dom";


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
import { useTokenCheck } from 'utils/hooks/Token';

//import useAddScript from 'utils/myHooks/AddScript';


import { Track, TrackFetch } from 'utils/components/Track';


//import Controls from "./Controls";



import { Settings, Lorem, TestImages, NavigationData } from './Data';

//import Styl from './Styl.module.css';

import { 
    PrimeStage, 
    PrimeBar,
    PrimeGroup,
    PrimeMember

} from './Styled';


// import Header from "utils/components/Common/Header";
// import Footer from "utils/components/Common/Footer";
// import InfoBar from "utils/components/Common/InfoBar";


import Login from 'utils/components/Login';

import Menu from 'utils/components/Common/Menu';

import Confirm from 'utils/components/Process/Confirm';
import Loader from 'utils/components/Process/Loader';
import Messngr from 'utils/components/Process/Messngr';
import Readr from 'utils/components/Process/Readr';
//import Fetch from 'utils/components//Process/Fetch';

import Flyr from 'utils/components/Flyr';
import FGridFlyr from 'utils/components/Grid/FGrid/Flyr';
import VidFlyr from 'utils/components/Grid/VidGrid/Flyr';


import Handler from './Handler';


// import Zone from "../Zone";
const Zone = lazy(() => import('../Zone'));



//import mySvg from "grfx/Logos/test.svg";






const Prime = (props) => {


    // -- add scripts -- //

    /*

    useAddScript({

        to:'body',
        async:false,
        url: 'https://apis.google.com/js/client.js?onload=googleApiClientReady'

    });
    */



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);


    // -- window dims -- //
    const { height } = useWindowDimensions();


    // -- redux -- //
    const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    

    // -- Fetch -- // 
    // let currentZone = pathname;
    // currentZone = currentZone.replace(/\//gi,"-");
    // currentZone = currentZone.replace(/ /gi,"-");

    // let fetchd = Fetch({

    //     key      : currentZone+"_fetch",
    //     route    : pathname

    // }); 




    // -- ref -- //
    const refA = useRef(null);
    const refB = useRef(null);
    const refZone = useRef(null);



    //---------------------------------------------
    // state/effect - isLoaded
    //---------------------------------------------

    // -- state -- //

    const [isLoaded, setIsLoaded] = useState(false);

    const [groupHeight] = useState("auto");
    const [barHInt, setBarHInt] = useState(0);
    const [primeDif, setPrimeDif] = useState(1);  

    const [blueprint, setBlueprint] = useState(false);

    const [hashData, setHashData] = useState(false);

    const [showConfirm, setShowConfirm] = useState(false); 
    const [confirmData, setConfirmData] = useState(false);

    const [showLoader, setShowLoader] = useState(false); 
    const [loaderData, setLoaderData] = useState(false);

    const [showLogin, setShowLogin] = useState(false); 
    const [loginData, setLoginData] = useState(false);

    const [showMenu, setShowMenu] = useState(false); 
    const [menuData, setMenuData] = useState(false);

    const [showMessngr, setShowMessngr] = useState(false); 
    const [messngrData, setMessngrData] = useState(false);

    const [showReadr, setShowReadr] = useState(false); 
    const [readrData, setReadrData] = useState(false);

    const [showFlyr, setShowFlyr] = useState(false); 
    const [flyrData, setFlyrData] = useState(false);

    const [showFGridFlyr, setShowFGridFlyr] = useState(false); 
    const [fgridFlyrData, setFGridFlyrData] = useState(false);

    const [showVidFlyr, setShowVidFlyr] = useState(false); 
    const [vidFlyrData, setVidFlyrData] = useState(false);


    useEffect(() => {
        

        if(!isLoaded)
        {
            setIsLoaded(true);
        }
        else
        {


            if(barHInt === 0 && refB.current.offsetHeight > 0) 
            {
                setBarHInt(refB.current.offsetHeight);
            }

            var revPrimeDif = 1;
            revPrimeDif = refA.current.offsetHeight + refB.current.offsetHeight;
            if(revPrimeDif === 0) revPrimeDif = 1;
            if(primeDif === 1 && revPrimeDif > 1)
            {
                setPrimeDif(revPrimeDif);  


            }


            if(hashData && location.hash === "")
            {
                if(hashData.match(/#!/gi)
                )
                {
                    console.log("Hash Check");

                    setHashData(false);
        
                    setShowConfirm(false);
                    setShowLoader(false);  
                    setShowLogin(false);
                    setShowMessngr(false); 
                    setShowMenu(false); 
                    setShowReadr(false); 
                    setShowFlyr(false);          
                    setShowFGridFlyr(false); 
                    setShowVidFlyr(false); 
        
                } 

            } 



            if(!zoneObj['basename'] || zoneObj['basename'] === undefined 
            )
            {
                if(zoneObj['basename'] !== props.basename)
                {

                    dispatch(ModifyZoneStore({

                        type:"UPDATE_ZONE",
                        item:{
                            key:"basename",
                            value:props.basename
                        }

                    })); 

                }

            }
            //console.log('zone basename - '+zoneObj['basename']);




            if(!zoneObj['primeDif'] 
            || zoneObj['primeDif'] === undefined 
            )
            {
        

                //console.log('DISPATCH PRIME DIF: '+revPrimeDif);


                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key:"primeDif",
                        value:revPrimeDif
                    }

                })); 
                
            }


            if(!zoneObj['lorem'] || zoneObj['lorem'] === undefined 
            )
            {

                //console.log("lorem - "+Lorem);

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key:"lorem",
                        value:Lorem
                    }

                })); 
                
            }




            if(!zoneObj['images'] || zoneObj['images'] === undefined 
            )
            {
            
                //console.log("images - "+TestImages);

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key:"images",
                        value:TestImages
                    }

                })); 
                
            }



            if(!zoneObj['navigation'] || zoneObj['navigation'] === undefined 
            )
            {
        
               
                //console.log("ssg - "+NavigationData);

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key:"navigation",
                        value:NavigationData
                    }

                })); 
                
            }



            if(Settings['ssg'])
            {

                if(!zoneObj['ssg'] || zoneObj['ssg'] === undefined 
                )
                {
        
                    //console.log("ssg - "+Settings['ssg']);

                    dispatch(ModifyZoneStore({

                        type:"UPDATE_ZONE",
                        item:{
                            key:"ssg",
                            value:Settings['ssg']
                        }

                    })); 
                    
                }

            }





        }//isLoaded


    },[

        dispatch,
        props,
        height,      
        pathname,
        zoneObj,
        location,
        hashData,
        isLoaded,
        refA,
        refB,

        barHInt,
        primeDif

    ])






    //---------------------------------------------
    // state/effect - tracking
    //---------------------------------------------

    const [runTrackFetch, setRunTrackFetch] = useState(false); 
    const [runTrack, setRunTrack] = useState(false);
    const [currentTrack, setCurrentTrack] = useState(false); 

    useEffect(() => {


        //alert(animateOut);
        //console.log(boardNumber+" "+props.data.boardNumber);


        if(!zoneObj.track)
        {
            setCurrentTrack(pathname);
            setRunTrackFetch({

                call:"fetch"

            });//Turn on or off here

        }
        else
        if(pathname !== currentTrack
        )
        {
            setCurrentTrack(pathname);
            setRunTrack({

                call:"trackPageView"

            });//Turn on or off here


            // // FOR REFERENCE
            
            // setRunTrack({

            //     call:"trackEvent",
            //     category:hndl.data.category,
            // });

        }


    },
    [
        props,
        pathname,
        zoneObj,
        isLoaded,
        runTrack,
        currentTrack

    ]);








    //---------------------------------------------
    // state/effect - view zoneOBj
    //---------------------------------------------


    // -- state -- //
    const [zoneWasViewed, setZoneWasViewed] = useState(false);

    useEffect(() => {


        //alert(animateOut);
        //console.log(boardNumber+" "+props.data.boardNumber);


        if(isLoaded && !zoneWasViewed)
        {

            // console.log("transition..."+boardNumber+" --- "+props.data.boardNumber);

            // var transInterval = setInterval(() => {

            //     console.log("Billboard Overlay - transInterval");
          

            // }, 200);

            // return () => { 

            //     clearInterval(transInterval); 
            //     setBoardNumber(props.data.boardNumber)
            // }


            // console.log("===== zoneObj =====");
            // console.log(JSON.stringify(zoneObj,null,2));

            setZoneWasViewed(true);

    
            //console.log("Prime index.js - No Zone Movies");
    
        }else{

            //Delay before starting animation
            // var timer;
            // timer = setTimeout(() => {

            //     setZoneWasViewed(false);

            // }, 500);
            // return () => clearTimeout(timer);


            //console.log("Prime index.js - Zone Was Viewed");


        } 

    },
    [
        props,
        isLoaded,
        zoneObj,
        zoneWasViewed

    ])







    //---------------------------------------------
    // state/effect - view zoneOBj
    //---------------------------------------------

    // -- PrimeStage Logo -- //

    //const [primeBgImage] = useState(ReactDOMServer.renderToStaticMarkup('/_resources/images/engine.jpg'))
    //const [primeBgImage] = useState(ReactDOMServer.renderToString('/_resources/logos/test.svg'))
    const [primeBgImage, setPrimeBgImage] = useState({

        image: false,
        size: false

    });

    useEffect(() => {


        if(isLoaded)
        {
            if(!zoneObj.prime_stage && zoneObj.prime_stage === undefined)
            {
                setPrimeBgImage({

                    image: '/_resources/logos/tpc_profit_pie.svg',
                    size: "100px 100px"

                });
            }

        } 

    },
    [
        isLoaded,
        zoneObj
    ])









    // const [repos, setRepos] = useState('repos');


    // -- handle -- //

    const handle = (hndl) => {


        //alert("prime: "+hndl.call);

        // console.log(JSON.stringify(hndl,null,2));
        //alert("Prime handle function");


        // if(hndl.call === 'repos') 
        // {
        //     alert(repos+"\n"+hndl.data);
        //     setRepos(hndl.data);

        // }




    //     alert(hndl.call);

    //     switch(hndl.call)
    //     {
    //     case'already_exists':

    //         setRunFetch(false);
    //     break;
    //     case'error':

    //         setRunFetch(false);

    //         if(hndl.type)
    //         {
    //             alert(JSON.stringify(hndl.errors,null,2))
    //         }else{
    //             alert(hndl.message);
    //         }

    //     break;
    //     case'complete':

    //         setRunFetch(false);

    //         var trackInfo;
    //         trackInfo = hndl.result;

    //         dispatch(ModifyZoneStore({

    //             type:"UPDATE_ZONE",
    //             item:{
    //                 key:"track",
    //                 value:trackInfo
    //             }

    //         })); 


    //     break;
    //     default:
    //     }


        switch(hndl.call)
        {
        case'TrackFetchError':

            setRunTrackFetch(false);

            console.log("TrackFetchError");

            if(hndl.type)
            {
                console.log(JSON.stringify(hndl.errors,null,2))
            }else{
                console.log(hndl.message);
            }
            
        break;
        case'TrackExists':

            setRunTrackFetch(false);

            //alert("TrackExists");

            setRunTrack(true);
        break;
        case'TrackFetchComplete':

            setRunTrackFetch(false);

            //alert("TrackFetchComplete");
            //alert("TrackComplete: "+JSON.stringify(hndl.result,null,2));

            dispatch(ModifyZoneStore({

                type:"UPDATE_ZONE",
                item:{
                    key:"track",
                    value:hndl.result
                }

            })); 

            setRunTrack({

                call:"trackPageView"

            });

        break;
        case'Track':

            //alert("Track");
    
            if(!runTrack)
            {
                setRunTrack({

                    call:"trackEvent",
                    category:hndl.data.category,
                });

            }else{

                setRunTrack(false);
            }

        break;
        default:


            hndl['zoneObj'] = zoneObj;
            hndl['location'] = location;
            hndl['pathname'] = pathname;
            hndl['handleState'] = (v) => {


                if(v.call === 'handleDispatch') handleDispatch(v.value);
                if(v.call === 'handleBlueprint') handleBlueprint(v.value);
                if(v.call === 'handleUrl') handleUrl(v.value);

                if(v.call === 'hashData')  setHashData(v.value);
                
                if(v.call === 'showConfirm') setShowConfirm(v.value);
                if(v.call === 'confirmData') setConfirmData(v.value);

                if(v.call === 'showLoader') setShowLoader(v.value);
                if(v.call === 'loaderData') setLoaderData(v.value);

                if(v.call === 'showLogin') setShowLogin(v.value);
                if(v.call === 'loginData') setLoginData(v.value);

                if(v.call === 'showMenu') setShowMenu(v.value);
                if(v.call === 'menuData') setMenuData(v.value);

                if(v.call === 'showMessngr') setShowMessngr(v.value);
                if(v.call === 'messngrData') setMessngrData(v.value);

                if(v.call === 'showReadr') setShowReadr(v.value);
                if(v.call === 'readrData') setReadrData(v.value);

                if(v.call === 'showFlyr') setShowFlyr(v.value);
                if(v.call === 'flyrData') setFlyrData(v.value);
            
                if(v.call === 'showFGridFlyr') setShowFGridFlyr(v.value);
                if(v.call === 'fgridFlyrData') setFGridFlyrData(v.value);
            
                if(v.call === 'showVidFlyr') setShowVidFlyr(v.value);
                if(v.call === 'vidFlyrData') setVidFlyrData(v.value);

            
            };
            hndl['handleRoute'] = (v) => {

                
                switch(v.call)
                {
                case'goBack':
        
                    history.goBack();
                break;
                case'reload':
        
                    window.location.reload();//regular dom 
                break;
                case'redirect':
        
                    history.replace(v.value);
                break;
                case'push':

                    history.push(v.value);
                break;
                default:
                }
        
            }

            Handler(hndl);

        }



    }

    const handleBlueprint = (hndl) => {


       
        setBlueprint(hndl);

        var revPrimeDif = 1;
        revPrimeDif = refA.current.offsetHeight + refB.current.offsetHeight;
        if(revPrimeDif === 0) revPrimeDif = 1;

        alert(revPrimeDif);
        setPrimeDif(revPrimeDif);  


 
    }


    const handleDispatch = (hndl) => {

        //if(hndl.action === 'DELETE_ZONE') alert('delete zone')
    
        //alert(JSON.stringify(hndl,null,2));

        dispatch(ModifyZoneStore({
    
            type:hndl.action,//UPDATE_ZONE or DELETE_ZONE
            item:{
                key     :hndl.key,
                value   :hndl.value
            }

        })); 

    }

    const handleUrl = (hndl) => {

        switch(hndl.action)
        {
        case'reload':

            window.location.reload();//regular dom 
        break;
        case'replace':

            window.location.replace(hndl.url);//regular dom 
        break;
        case'target':

            //alert('target');
            // window.location.replace(hndl.url);//regular dom 
        break;
        case'redirect':
    
            history.replace(hndl.url);
        break;
        case'push':

            history.push(hndl.url);
        break;
        default:
        }

    }




    //-------------------------------
    // token
    //-------------------------------

    const validToken = useTokenCheck({
        databind:{
            handle:handle
        }
    });






    return (

        <PrimeStage
        backgroundColor     = {"#141820"}
        backgroundImage     = {`url(${primeBgImage.image})`}
        // backgroundRepeat    = "no-repeat",
        // backgroundPosition  = {"50% 45%"}
        backgroundSize      = {primeBgImage.size}

        >

            {
            runTrackFetch 
            ? 
                <TrackFetch
                    
                    basename={props.basename}
                    handle={(v) => handle(v)}    
                    data={runTrackFetch}     
        
                /> 

            : null
            }

            {
            runTrack 
            ? 
                <Track
                    
                    basename={props.basename}
                    handle={(v) => handle(v)}    
                    data={runTrack}     
        
                /> 

            : null
            }




            {
            showMenu ? 

                <Menu
                    
                    handle={(v) => handle(v)}    
                    data={menuData}     
        
                /> 

            : null
            }


            {
            showConfirm ? 

                <Confirm
                    
                    handle={(v) => handle(v)}    
                    data={confirmData}     
        
                /> 

            : null
            }

            {
            showLoader ? 

                <Loader
                    
                    handle={(v) => handle(v)}    
                    data={loaderData}     
        
                /> 

            : null
            }


            {
            showLogin ? 

                <Login
                    
                    handle={(v) => handle(v)}    
                    data={loginData}     
        
                /> 

            : null
            }



            {
            showMessngr ? 

                <Messngr
                    
                    handle={(v) => handle(v)}    
                    data={messngrData}     
        
                /> 

            : null
            }


            {
            showReadr ? 

                <Readr
                    
                    handle={(v) => handle(v)}    
                    data={readrData}     
        
                /> 

            : null
            }



            {
            showFlyr ? 

                <Flyr

                    
                    handle={(v) => handle(v)}    
                    data={flyrData}     
        
                /> 

            : null
            }

            {
            showFGridFlyr ? 

                <FGridFlyr

                    handle={(v) => handle(v)}    
                    data={fgridFlyrData}     
        
                /> 

            : null
            }

            {
            showVidFlyr ? 

                <VidFlyr

                    
                    handle={(v) => handle(v)}    
                    data={vidFlyrData}     
        
                /> 

            : null
            }




            <PrimeGroup
            height={groupHeight}
            >
                <PrimeMember ref={refA} >

                    {/* <Header />  */}

                </PrimeMember>


                <PrimeMember ref={refZone}>


                    <Suspense fallback={<Loader />}>

                    <Zone
                    
                        basename = {props.basename}
                        handle = {(v) => handle(v)} 
                        //headerRef={refA}
                        //bottomRef={refB}
                        primeDif={primeDif}
                        blueprint={blueprint}
                        refZone={refZone}
                        validToken={validToken}

                    /> 

                    </Suspense>

                </PrimeMember>

                <PrimeMember ref={refB} >

                    <PrimeBar
                    top={"calc(100% - "+barHInt+"px)"}
                    left={"0px"}
                    zIndex={"10"}
                    >
                        {/*
                        
                        
                        <InfoBar
                            
                            navigation={NavigationData.controls}
                        />      
                        
                        
                        */}

                    </PrimeBar>

                </PrimeMember>

            </PrimeGroup>


        </PrimeStage>



    );



}

export default Prime
