import styled from "styled-components";


export const ReportAct = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "24px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "#454d5f",



    spacerMarginBottom:         props.spacerMarginBottom || '20px',



    headingFontFamily:         props.headingFontFamily || 'Arial, Helvetica, sans-serif',
    headingFontSize:           props.headingFontSize || "20px",
    headingFontWeight:         props.headingFontWeight  || "500",
    headingTextAlign:          props.headingTextAlign || "left",
    headingTextDecoration:     props.headingTextDecoration  || "none",
    headingTextColor:          props.headingTextColor || "#454d5f",


    pgraphFontFamily:         props.pgraphFontFamily || 'Arial, Helvetica, sans-serif',
    pgraphFontSize:           props.pgraphFontSize || "16px",
    pgraphFontWeight:         props.pgraphFontWeight  || "500",
    pgraphTextAlign:          props.pgraphTextAlign || "left",
    pgraphTextDecoration:     props.pgraphTextDecoration  || "none",
    pgraphTextColor:          props.pgraphTextColor || "#454d5f",


    noteFontFamily:         props.noteFontFamily || 'Arial, Helvetica, sans-serif',
    noteFontSize:           props.noteFontSize || "13px",
    noteFontWeight:         props.noteFontWeight  || "500",
    noteTextAlign:          props.noteTextAlign || "center",
    noteTextDecoration:     props.noteTextDecoration  || "none",
    noteTextColor:          props.noteTextColor || "#454d5f",


    bigFontFamily:         props.bigFontFamily || 'Arial, Helvetica, sans-serif',
    bigFontSize:           props.bigFontSize || "100px",
    bigFontWeight:         props.bigFontWeight  || "550",
    bigTextAlign:          props.bigTextAlign || "center",
    bigTextDecoration:     props.bigTextDecoration  || "none",
    bigTextColor:          props.bigTextColor || "#454d5f",

    xLargeFontFamily:         props.xLargeFontFamily || 'Arial, Helvetica, sans-serif',
    xLargeFontSize:           props.xLargeFontSize || "80px",
    xLargeFontWeight:         props.xLargeFontWeight  || "550",
    xLargeTextAlign:          props.xLargeTextAlign || "center",
    xLargeTextDecoration:     props.xLargeTextDecoration  || "none",
    xLargeTextColor:          props.xLargeTextColor || "#454d5f",

    largeFontFamily:         props.largeFontFamily || 'Arial, Helvetica, sans-serif',
    largeFontSize:           props.largeFontSize || "60px",
    largeFontWeight:         props.largeFontWeight  || "550",
    largeTextAlign:          props.largeTextAlign || "center",
    largeTextDecoration:     props.largeTextDecoration  || "none",
    largeTextColor:          props.largeTextColor || "#454d5f",
    
    mediumFontFamily:         props.mediumFontFamily || 'Arial, Helvetica, sans-serif',
    mediumFontSize:           props.mediumFontSize || "50px",
    mediumFontWeight:         props.mediumFontWeight  || "550",
    mediumTextAlign:          props.mediumTextAlign || "center",
    mediumTextDecoration:     props.mediumTextDecoration  || "none",
    mediumTextColor:          props.mediumTextColor || "#454d5f",

    smallFontFamily:         props.smallFontFamily || 'Arial, Helvetica, sans-serif',
    smallFontSize:           props.smallFontSize || "20px",
    smallFontWeight:         props.smallFontWeight  || "550",
    smallTextAlign:          props.smallTextAlign || "center",
    smallTextDecoration:     props.smallTextDecoration  || "none",
    smallTextColor:          props.smallTextColor || "#454d5f"



    // border-bottom:${props => props.border};
    // border-left:${props => props.border};
    // border-right:${props => props.border};


}))`


    background-color:${props => props.backgroundColor};


    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:default;




    .Spacer {

        width:100%;
        height:20px;
        margin:0 auto 0 auto;
        padding:0 0 0 0;
     
    }





    .Heading {


        width:100%;
        height:auto;
        margin:0 auto 0 auto;


        font-family:${props => props.headingFontFamily};
        font-size:${props => props.headingFontSize};
        font-weight:${props => props.headingFontWeight};
        text-align:${props => props.headingTextAlign};
        text-decoration:${props => props.headingTextDecoration};
        color:${props => props.headingTextColor};


    }

    .Pgraph {

        width:100%;
        height:auto;
        margin:0 auto 0 auto;

        font-family:${props => props.pgraphFontFamily};
        font-size:${props => props.pgraphFontSize};
        font-weight:${props => props.pgraphFontWeight};
        text-align:${props => props.pgraphTextAlign};
        text-decoration:${props => props.pgraphTextDecoration};
        color:${props => props.pgraphTextColor};

        line-height:1.5;
    

        
    }





    .Note {


        width:100%;
        height:auto;
        margin:0 auto 20px auto;
        padding:0 0 0 0;


        font-family:${props => props.noteFontFamily};
        font-size:${props => props.noteFontSize};
        font-weight:${props => props.noteFontWeight};
        text-align:${props => props.noteTextAlign};
        text-decoration:${props => props.noteTextDecoration};
        color:${props => props.noteTextColor};

        line-height:1.5;
     
    }



    .Big {

        background-color:transparent;

        width:100%;
        height:auto;
        margin:0 auto 0 auto;

        font-family:${props => props.bigFontFamily};
        font-size:${props => props.bigFontSize};
        font-weight:${props => props.bigFontWeight};
        text-align:${props => props.bigTextAlign};
        text-decoration:${props => props.bigTextDecoration};
        color:${props => props.bigTextColor};

    }



    .XLarge {

        background-color:transparent;

        width:100%;
        height:auto;
        margin:0 auto 0 auto;

        font-family:${props => props.xLargeFontFamily};
        font-size:${props => props.xLargeFontSize};
        font-weight:${props => props.xLargeFontWeight};
        text-align:${props => props.xLargeTextAlign};
        text-decoration:${props => props.xLargeTextDecoration};
        color:${props => props.xLargeTextColor};

    }


    .Large {

        background-color:transparent;

        width:100%;
        height:auto;
        margin:0 auto 0 auto;

        font-family:${props => props.largeFontFamily};
        font-size:${props => props.largeFontSize};
        font-weight:${props => props.largeFontWeight};
        text-align:${props => props.largeTextAlign};
        text-decoration:${props => props.largeTextDecoration};
        color:${props => props.largeTextColor};

    }


    .Medium {

        background-color:transparent;
    

        width:100%;
        height:auto;
        margin:10px auto 0 auto;
        padding:0 0 0 0;


    
        font-family:${props => props.mediumFontFamily};
        font-size:${props => props.mediumFontSize};
        font-weight:${props => props.mediumFontWeight};
        text-align:${props => props.mediumTextAlign};
        text-decoration:${props => props.mediumTextDecoration};
        color:${props => props.mediumTextColor};
    
    }



    .Small {

        background-color:transparent;
    

        width:100%;
        height:auto;
        margin:10px auto 0 auto;
        padding:0 0 0 0;


    
        font-family:${props => props.smallFontFamily};
        font-size:${props => props.smallFontSize};
        font-weight:${props => props.smallFontWeight};
        text-align:${props => props.smallTextAlign};
        text-decoration:${props => props.smallTextDecoration};
        color:${props => props.smallTextColor};
    
    }
    
    



    @media (max-width: 700px) {

        .Big {

            background-color:transparent;
            font-size:80px;
    
        }


        .XLarge {

            background-color:transparent;
            font-size:60px;
    
        }


        .Large {

            background-color:transparent;
            font-size:40px;
    
        }


        .Medium {

            background-color:transparent;
            font-size:40px;
    
        }

        .Small {

            background-color:transparent;
            font-size:20px;
    
        }



    }





    


    .ButtonContainer {

        background-color:transparent;
        width:100%;
        height:50px;
        padding:0 0 0 0;
        margin:0 auto 15px auto;
 
    }

        .Button {

            background-color:transparent;
            width:100%;
            height:50px;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:relative;
            float:left;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};

            &:hover{

                color:#191e28;

            }

        }


            .ButtonMask {

                background-color:transparent;

                width:50px;
                height:50px;
                padding:0 0 0 0;
                margin:0 0 0 0;
                position:absolute;
                top:0px;
                left:calc(50% - 25px);
                z-index:1;

                cursor:pointer;

            }

            .Img {

                width:50px;
                height:50px;

            }





`;



