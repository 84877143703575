
import styled from "styled-components";


/*
https://styled-components.com/docs

    //Stretching
    flex: 1 1 300px;
    
    //No Stretching
    flex: 0 1 450px;

    position:absolute;   
    z-index:3;
    top:0;
    left:0

*/



export const Base = styled.div.attrs(props => ({


    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
    border:             props.border || "0",
    borderRadius:       props.borderRadius || "0",

    width:            props.width || "100%", 
    height:           props.height || "100%",

    zIndex:           props.zIndex || "2", 
    top:              props.top || "0", 
    right:            props.right || "0"


}))`
  

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};


    width:${props => props.width};
    height:${props => props.height};

    padding :0 0 0 0; 
    margin  :0 0 0 0;


    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    right:${props => props.right};


    overflow-x:hidden;
    overflow-y:auto;


`;




export const Tab = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%", 
    height:             props.height || "50px",

    padding:            props.padding || "0 0 0 0",
    margin:             props.margin || "0 0 0 0",

    top:                props.top || "0px",
    left:               props.left || "0px",


    zIndex:            props.zIndex || "110",



    //tab-x
    xBackgroundColor:   props.xBackgroundColor || "transparent",//#fcfcfc
    xColor:             props.xColor || "#000",
    xOverColor:         props.xOverColor || "lightseagreen",


    //tab-title
    titleBackgroundColor:   props.titleBackgroundColor || "transparent",//#fcfcfc



    //button
    buttonBackgroundColor:   props.buttonBackgroundColor || "#C00",//#fcfcfc
    buttonBorder:            props.buttonBorder || "1px solid #C00",
    buttonColor:             props.buttonColor || "white",

    buttonOverBackgroundColor:   props.buttonOverBackgroundColor || "#F00",//#fcfcfc
    buttonOverBorder:            props.buttonOverBorder || "1px solid #F00",
    buttonOverColor:             props.buttonOverColor || "white",



    //clear button
    clearButtonBackgroundColor:         props.clearButtonBackgroundColor || "transparent",//#fcfcfc
    clearButtonBorder:                  props.clearButtonBorder || "0",
    clearButtonColor:                   props.clearButtonColor || "black",

    clearButtonBackgroundColorOver:     props.clearButtonBackgroundColorOver || "lightblue",//#fcfcfc
    clearButtonBorderOver:              props.clearButtonBorderOver || "0",
    clearButtonColorOver:               props.clearButtonColorOver || "black",



    //save button
    saveButtonBackgroundColor:         props.saveButtonBackgroundColor || "#caffca",//#fcfcfc
    saveButtonBorder:                  props.saveButtonBorder || "0",
    saveButtonColor:                   props.saveButtonColor || "black",

    saveButtonBackgroundColorOver:     props.saveButtonBackgroundColorOver || "lightgreen",//#fcfcfc
    saveButtonBorderOver:              props.saveButtonBorderOver || "0",
    saveButtonColorOver:               props.saveButtonColorOver || "black",


    //close button
    closeButtonBackgroundColor:         props.closeButtonBackgroundColor || "#EDEEF1",//#fcfcfc
    closeButtonBorder:                  props.closeButtonBorder || "1px solid lightgray",
    closeButtonColor:                   props.closeButtonColor || "black",

    closeButtonBackgroundColorOver:     props.closeButtonBackgroundColorOver || "lightblue",//#fcfcfc
    closeButtonBorderOver:              props.closeButtonBorderOver || "0",
    closeButtonColorOver:               props.closeButtonColorOver || "black"



    //transition: background-color 400ms;



}))`

    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};  

    width   :${props => props.width};
    height  :${props => props.height};

    padding :${props => props.padding}; 
    margin  :${props => props.margin};

    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top}; 
    left:${props => props.left};


    @media (max-width: 100000px) {

        width:750px;
        left:calc(50% - 375px);

    }

    @media (max-width: 750px) {

        width:95%;
        left:10px;

    }




    .X {

        background-color:${props => props.xBackgroundColor};

        width   :auto;
        height  :36px;

        padding :7px 7px 0 7px; 
        margin  :0 10px 0 0;

        position:relative;   
        float:right;

        font-family:"Verdana", sans-serif;      
        font-size: 30px;
        font-weight: normal;
        text-align: left;
        text-decoration:none;
        color:${props => props.xColor};

        cursor:pointer;

    }
    .X:hover {

        color:${props => props.xOverColor};

    }
    





    .Title {

        background-color:${props => props.titleBackgroundColor};

        width   :auto;
        height  :auto;

        padding :0 0 2px 0; 
        margin  :13px 0 0 10px;

        position:relative;   
        float:left;

        font-family:"Verdana", sans-serif;      
        font-size: 20px;
        font-weight: normal;
        text-align: left;
        text-decoration:none;
        color:#1A1926;

        cursor:default;

    }







    .Button {

        background-color:${props => props.buttonBackgroundColor};
        border:${props => props.buttonBorder};
        border-radius:7px;

        width   :auto;
        height  :auto;

        min-width:175px;

        padding :8px 15px 6px 15px; 
        margin  :5px 0 0 10px;

        position:relative;   
        float:left;

        font-family: Arial, Helvetica, sans-serif;      
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        text-decoration:none;
        color:${props => props.buttonColor};

        cursor:pointer;

    }
    .Button:hover {


        background-color:${props => props.buttonOverBackgroundColor};
        border:${props => props.buttonOverBorder};
        color:${props => props.buttonOverColor};


    }



    .ClearButton {

        background-color:${props => props.clearButtonBackgroundColor};
        border:${props => props.clearButtonBorder};

        width   :50%;
        height  :auto;

        padding :16.5px 0 16.5px 0; 
        margin  :0 0 0 0;

        position:relative;   
        float:left;

        font-family: Arial, Helvetica, sans-serif;      
        font-size: 15px;
        font-weight: 550;
        text-align: center;
        text-decoration:none;
        color:${props => props.clearButtonColor};

        cursor:pointer;

    }
    .ClearButton:hover {

        background-color:${props => props.clearButtonBackgroundColorOver};
        color:${props => props.clearButtonColorOver};

    }




    .SaveButton {

        background-color:${props => props.saveButtonBackgroundColor};
        border:${props => props.saveButtonBorder};

        width   :50%;
        height  :auto;

        padding :16.5px 0 16.5px 0; 
        margin  :0 0 0 0;

        position:relative;   
        float:left;

        font-family: Arial, Helvetica, sans-serif;      
        font-size: 15px;
        font-weight: 550;
        text-align: center;
        text-decoration:none;
        color:${props => props.saveButtonColor};

        cursor:pointer;

  

 
    }
    .SaveButton:hover {

        background-color:${props => props.saveButtonBackgroundColorOver};
        color:${props => props.saveButtonColorOver};

    }





    .CloseButton {

        background-color:${props => props.closeButtonBackgroundColor};
        border-left:${props => props.closeButtonBorder};

        width   :calc(50% - 1px);
        height  :auto;

        padding :16.5px 0 16.5px 0; 
        margin  :0 0 0 0;

        position:relative;   
        float:left;

        font-family: Arial, Helvetica, sans-serif;      
        font-size: 15px;
        font-weight: 550;
        text-align: center;
        text-decoration:none;
        color:${props => props.closeButtonColor};

        cursor:pointer;




    }
    .CloseButton:hover {

        background-color:${props => props.closeButtonBackgroundColorOver};
        color:${props => props.closeButtonColorOver};

    }




`;





export const Readr = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc

    width:              props.width || "100%", 
    height:             props.height || "auto",

    padding:            props.padding|| "0 0 50px 0",
    margin:             props.margin || "0 0 0 0",

    zIndex:             props.zIndex || "3",
    //top:                props.top || "calc(50% - 175px)",
    //left:               props.left || "calc(50% - 210px)"

    top:                props.top || "50px",
    left:               props.left || "0px",


    //bin
    binBackgroundColor:    props.binBackgroundColor || "transparent",//#fcfcfc

    binWidth:              props.binWidth || "96%", 
    binHeight:             props.binHeight || "auto",

    binMargin:            props.binMargin || "0 auto 0 auto",
    binPadding:           props.binPadding || "0 0 0 0",


}))`

    background-color:${props => props.backgroundColor};

    width   :${props => props.width};
    height  :${props => props.height};

    min-height: 100vh;

    padding :${props => props.padding}; 
    margin  :${props => props.margin};

    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.right};



    @media (max-width: 100000px) {

        width:750px;
        left:calc(50% - 375px);

    }

    @media (max-width: 750px) {

        width:95%;
        left:10px;

    }






    .Bin {

        background-color:${props => props.binBackgroundColor};

        width   :${props => props.binWidth};
        height  :${props => props.binHeight};

        padding :${props => props.binPadding}; 
        margin  :${props => props.binMargin};
    
        position:relative; 
      

    }


`;


