import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import { 

    BarChart,

    //Title,
    Content,
    //List,
    Markers,
    Lines,
    Bars,
  
    // VerticalLine,
    // HorizontalLine

    FlexVerticalContainer


} from './Styled';



import { 

    handleCreateLines,
    handleCreateBars,
    //handleCreateListItems,
    handleCreateMarkers

} from './Handles';




export const VrtBarChart = (props) => {

    // console.log("VrtBarChart");
    // console.log(JSON.stringify(props,null,2));


    // -- window dims -- //
    const { 
        width,
        //height 
    } = useWindowDimensions();


    //=============================================
    // refs
    //=============================================

    // -- ref -- //
    const refChart = useRef(null);
    //const refTitle = useRef(null);
    const refContent = useRef(null);
    const refMarkers = useRef(null);    
    const refLines = useRef(null);
    const refBars = useRef(null);


    // const [rowRefs] = useState([]);
    // const setRowRef = (ref) => {

    //     if(ref && ref !== null && ref !== undefined)
    //     {
    //         //console.log("ref::::"+ref.id);
    //         var found;
    //         found = false;
    //         for(var i=0; i < rowRefs.length; i++)
    //         {
    //             if(rowRefs[i].id === ref.id) found = true;
    //         }
    //         if(!found) rowRefs.push(ref);
    //         //console.log(boxRefs.length);

    //     }

    // };

    //=============================================
    // state/effect - isLoaded
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [currentWidth, setCurrentWidth] = useState(false);  
    const [data, setData] = useState(false);
    const [items, setItems] = useState([]);



    useEffect(() => {

        const effResize = () => {

            var chartHeight = 185;
            if(props.chartHeight) 
            {
                //alert(props.chartHeight);
                chartHeight = props.chartHeight;
            }


            var difW = 12;
            //var difH = (refMarkers.current.offsetHeight - refLines.current.offsetHeight);
            var difH = 20;

            var contentWidth = refContent.current.offsetWidth;
            var markersWidth = refMarkers.current.offsetWidth;
            //var markersHeight = refMarkers.current.offsetHeight;

            var markersHeight = chartHeight + 20;

            
            var markersDif = 13.5;
            if(props.markersDif) markersDif = props.markersDif;

            var markersColor = "#edeef1";
            if(props.markersColor) markersColor = props.markersColor;

            var markersPrefix = "";
            if(props.markersPrefix) markersPrefix = props.markersPrefix;

            var markersSuffix = "";
            if(props.markersSuffix) markersSuffix = props.markersSuffix;



            var linesColor = "#edeef1";
            if(props.linesColor) linesColor = props.linesColor;

            var linesWidth = "calc(98% - "+(markersWidth+difW)+"px)";
            var linesHeight = (markersHeight - difH);
   
            var barsWidth = "calc(98% - "+(markersWidth+difW)+"px)";
            var barsHeight = (markersHeight - difH);


            var abTop = difH/2 + "px";
            var abLeft = (markersWidth+difW) + "px";

            //------ 

            var linesVrtGap = 50; //50;
            var linesVertical = refLines.current.offsetWidth / linesVrtGap;

            var linesHrzGap = 50; //;
            var linesHorizontal = refLines.current.offsetHeight / linesHrzGap;


            //-------

            var barsVrtGap = 10;
            var barsVertical = refBars.current.offsetWidth / barsVrtGap;

            var barsHrzGap = 10;
            var barsHorizontal = refBars.current.offsetHeight / barsHrzGap;



    

            var markers = [

                {
                    name:'100',
                    value:"100"
                },

                {
                    name:'80',
                    value:"80"
               
                },


                {
                    name:'60',
                    value:"60"
                },

                {
                    name:'40',
                    value:"40"
               
                },


                {
                    name:'20',
                    value:"20"
                },

                {
                    name:'0',
                    value:"0"
               
                }

            ]





            setItems(props.data);

            setData({

                chartHeight:chartHeight,

                contentWidth:contentWidth,

                markers:markers,
                markersDif:markersDif,
                markersColor:markersColor,
                markersPrefix:markersPrefix,
                markersSuffix:markersSuffix,
                markersWidth:markersWidth,
                markersHeight:markersHeight,

                linesColor:linesColor,
                linesWidth:linesWidth,
                linesHeight:linesHeight,
            
                barsWidth:barsWidth,
                barsHeight:barsHeight,


                abLeft:abLeft,
                abTop:abTop,


                linesHrzGap:linesHrzGap,
                linesHorizontal:linesHorizontal,

                linesVrtGap:linesVrtGap, 
                linesVertical:linesVertical,


                barsHrzGap:barsHrzGap,
                barsHorizontal:barsHorizontal,

                barsVrtGap:barsVrtGap, 
                barsVertical:barsVertical,


            });


            setCurrentWidth(width);


        }



        if(!isLoaded)
        {
            setIsLoaded(true);
        }else{
  
            if(!currentWidth || width !== currentWidth)
            {
                effResize();
            }        
        
        }

    },
    [
        props,
        width,currentWidth,
        isLoaded,data,
        refChart,
        refMarkers
    ])






    if(!isLoaded)
    {

        return(

            <div></div>
        )


    }else{


        return (

            <div>

                <BarChart
                ref={refChart}
                >

                    {/* <Title
                    ref={refTitle}
                    >
                    </Title> */}

                    <Content
                    ref={refContent}
                    //width={"100%"}
                    >


                        <Markers
                        ref={refMarkers}
                        //backgroundColor={"black"}
                        height={data.markersHeight+"px"}
                        >

                            {handleCreateMarkers({

                                markersDif:data.markersDif,
                                markersColor:data.markersColor,
                                markersPrefix:data.markersPrefix,
                                markersSuffix:data.markersSuffix,
                                maxHeight:data.chartHeight,
                                array:data.markers,
                                gap:0

                        
                            })}
    

                        </Markers>


                        <Lines
                        ref={refLines}
                        width={data.linesWidth}
                        height={data.linesHeight+"px"}
                        borderTop={"1px solid "+data.linesColor}
                        top={data.abTop}
                        left={data.abLeft}
                        >

                            <div>
                                {handleCreateLines({

                                    linesColor:data.linesColor,
                                    maxWidth:data.linesWidth,
                                    maxHeight:data.linesHeight,
                                    type:"horizontal",
                                    number:5

                                })}
                            </div> 


                        </Lines>



                        <Bars
                        ref={refBars}
                        width={data.barsWidth}
                        height={data.barsHeight+"px"}
                        left={data.abLeft}
                        top={data.abTop}
                        >

                            {/* <div>
                                {handleCreateBars({

                                    maxHeight:data.barsHeight,
                                    array:props.data,
                                    type:"horizontal",
                                    gap:data.barsHrzGap

                                })}
                            </div> */}


                    
                            <FlexVerticalContainer
                            height={data.barsHeight+"px"}
                            >

                                {handleCreateBars({

                                    maxWidth:data.barsWidth,
                                    maxHeight:0,
                                    array:items,
                                    type:"flex-vertical",
                                    gap:data.barsVrtGap

                                })}

                            </FlexVerticalContainer>
                            
                        </Bars>


                
                    </Content>


                </BarChart>



            </div>


        )    

    }




    
}




/*

{[...Array(10)].map((e, i) => {
    
})}


*/

// {Array.from(Array(10), (e, i) => {
    
// })}



// let linesHorizontal = Array.apply(null, {length: 4}).map(Number.call, Number);
// let linesVertical = Array.apply(null, {length: 5}).map(Number.call, Number);

