import styled from "styled-components";


/*
https://styled-components.com/docs

    //Stretching
    flex: 1 1 300px;
    
    //No Stretching
    flex: 0 1 450px;

    position:absolute;   
    z-index:3;
    top:0;
    left:0

*/


export const Overlay = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",//#fcfcfc

    width:             props.width || "calc(100% - 0px)",

    zIndex:            props.zIndex || "10",
    top:               props.top || "0px",
    left:              props.left || "0px",

    percent:           props.percent || "60", 
    decimal:           props.decimal || "0.6",


}))`



    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:auto;
    min-height:65px;

    padding :0 0 0 0; 
    margin  :0 0 0 0;

    position:absolute;   
    z-index:${props => props.zIndex};
    top::${props => props.top};
    left:${props => props.left};


    -ms-filter: "progid:pxImageTransform.Microsoft.Alpha(Opacity=${props => props.percent})";
    filter : alpha(opacity=${props => props.percent});
    -moz-opacity : ${props => props.decimal};
    -khtml-opacity : ${props => props.decimal};
    opacity : ${props => props.decimal};


`;


