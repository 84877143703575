import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';
//import WindowDimensionsProvider from 'utils/WindowDimensionsProvider';

import { 

    SliderGroup,
    SliderContainer,
    SliderMessage,
    SliderBox,
    Ranger 


} from './Styled';


import { LibNumber } from 'library';


/*
const getPercentage = (current, max) => (100 * current) / max;
//const getLeft = percentage => `calc(${percentage}% - 10px)`;
const getLeft = (percentage,difference) => {

    let calcd = `calc(${percentage}% - ${difference}px)`;
    return calcd;

}
*/

const roundPercent = (obj) => {

    var call = obj.call;
    var percent = obj.percent;
    var precision = obj.precision;
    
    switch(call)
    {
    case'30-50-70':

        if(percent > 0 && percent < 40) percent = 30;
        if(percent > 30 && percent < 50) percent = 50;
        if(percent > 50 && percent < 70) percent = 70;
        if(percent > 70 && percent < 90) percent = 70;
        if(percent > 90) percent = 100;


    break;
    default:

        if(percent > 0 && percent < precision)
        {
            percent = LibNumber.Round({

                call        :'roundUp',//round,roundDown,roundUp
                number      : percent,
                precision   : precision//10, 100, 10000

            })

        }else{

            percent = LibNumber.Round({

                call        :'round',//round,roundDown,roundUp
                number      : percent,
                precision   : precision//10, 100, 10000

            }); 

        }

        //percent = parseFloat(percent).toFixed(1);

    }


    if(isNaN(percent)) percent = 0;

    return percent;

}





const defSliderInfo = {

    title               :"DualSlider(A)",
    titleB              :"DualSlider(B)",
    bandColor           :'#00ff00',
    bandOpacity         :"0.5",
    useStickyX          :true,
    stickyIncrement     :10,

    //stickyStops         :"default",// default or 30-50-70
    //trackDivider        :10 // 10 

};


const DualSlider = (props) => {


    // -- ref -- //
    const refBox = useRef(null);


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [sliderInfo, setSliderInfo] = useState(defSliderInfo);

    const [readout,setReadout] = useState('');
    const [rangerValue, setRangerValue] = useState(0);
    const [colourWidth, setColourWidth] = useState("50%");

    const [readoutB,setReadoutB] = useState('');
    const [rangerValueB, setRangerValueB] = useState(0);
    const [colourWidthB, setColourWidthB] = useState("50%");
   


    useEffect(()=>{


        if(!isLoaded)
        {
            setIsLoaded(true);
            //setReadout("0%");
            //setReadoutB("0%");


            var val;
            val = 50;



            //console.log("PROPS DATA: "+JSON.stringify(props.data,null,2));

            if(props.data && props.data !== undefined)
            {
              
                if(props.data.info && props.data.info !== undefined)
                {
                    setSliderInfo(props.data.info);

                }

                if(props.data.value !== undefined
                && !isNaN(props.data.value)
                )
                {
                    val = props.data.value;
                }

            } 
            
            //console.log("val: "+val);



            setRangerValue(val);
            setColourWidth(val+"%");
            setReadout(val+"%");


            var valB = 100 - val;

            setRangerValueB(valB);
            setColourWidthB(valB+"%");
            setReadoutB(valB+"%");


        }



    },[
        props,
        isLoaded
    ])





    const handleRanger = (hndl) => {

        
        /*
        console.log(""
        +"\nOUT FROM: "+hndl.from
        +"\nOUT VALUE: "+hndl.target.value
        +"\nIN ID: "+hndl.target.id
        );
        */


        var call = "A";
        var regxIsB = new RegExp(props.data.id+"_b","gi");
        if(hndl.target.id.match(regxIsB)) 
            call = "B";


        
        var percent;
        var val;
        if(sliderInfo.useStickyX)
        {
            var increment;
            increment = 10;

            if(sliderInfo.stickyIncrement 
            || sliderInfo.stickyIncrement !== undefined) 
                increment = sliderInfo.stickyIncrement;





            percent = hndl.target.value
            if(sliderInfo.baseValue)
            {

                switch(call)
                {
                case'B':

                    if(percent > (100 - sliderInfo.baseValue))
                    {
                        percent = (100 - sliderInfo.baseValue);
                    }

                break;
                default:

                    if(percent < sliderInfo.baseValue)
                    {
                        percent = sliderInfo.baseValue;
                    }

                }

            }


            val = roundPercent({

                call        : "default",
                percent     : percent,
                precision   : increment
    
            });

        }
        else
        {
 
            val = hndl.target.value;
            if(sliderInfo.baseValue)
            {
                switch(call)
                {
                case'B':

                    if(val > (100 - sliderInfo.baseValue))
                    {
                        val = (100 - sliderInfo.baseValue);
                    }

                break;
                default:

                    if(val < sliderInfo.baseValue)
                    {
                        val = sliderInfo.baseValue
                    }

                }

            }


        }
        var valB = 100 - val;




        switch(call)
        {
        case'B':

            setRangerValueB(val);
            setColourWidthB(val+"%");
            setReadoutB(val+"%");

            setRangerValue(valB);
            setColourWidth(valB+"%");
            setReadout(valB+"%");

            handleCapture({

                valueA: valB,
                valueB: val

            });

        break;
        default:

            setRangerValue(val);
            setColourWidth(val+"%");
            setReadout(val+"%");

            setRangerValueB(valB);
            setColourWidthB(valB+"%");
            setReadoutB(valB+"%");

            handleCapture({

                valueA: val,
                valueB: valB

            });

        }

    }


    const handleCapture = (hndl) => {

        props.handle({

            call        :'capture',
            fieldCall   :"dualslider",
            key         :props.indx,
            data        :props.data,
            newValue    :hndl.valueA

        })

    }




    return (


        <SliderGroup>


            <SliderContainer>

                <SliderMessage>

                    <div className="SliderTitle">

                        {sliderInfo.title}

                    </div>
                    <div className="SliderReadout">

                        {readout}
                        
                    </div>

                </SliderMessage>


                <SliderBox 
                ref={refBox} 
                id={"sliderA_box"}
                >


                    <Ranger

                    bandColourBgColor={sliderInfo.bandColor}       
                    bandColourWidth={colourWidth}
                    bandColourOpacity={sliderInfo.bandOpacity}

                    >

                        <div 
                        className="RangeBand"
                        >
                            <div className="Bg">

                                <div className="Colour"></div>

                            </div>
                
                        </div>

                        <div 
                        className="RangeWrap"
                        >
                            <input
                                className="RangeInp" 
                                type="range"
                                id={props.data.id}
                                value={rangerValue}
                                onChange={event => {

                                    handleRanger({

                                        target      :event.target,
                                        from        :'onChange'
                                    
                                    })

                                }}

                                /*
                                onInput={event => {

                                    handleRanger({

                                        target      :event.target,
                                        from        :'onInput'

                                    })

                                }}
                                */

                            />

                        </div>


                    </Ranger>





                </SliderBox>

            </SliderContainer>





            <SliderContainer>

                <SliderMessage>

                    <div className="SliderTitle">

                        {sliderInfo.titleB}

                    </div>
                    <div className="SliderReadout">

                        {readoutB}
                        
                    </div>

                </SliderMessage>


                <SliderBox 
                ref={refBox} 
                id={"sliderB_box"}
                >


                    <Ranger

                    bandColourBgColor={sliderInfo.bandColor}       
                    bandColourWidth={colourWidthB}
                    bandColourOpacity={sliderInfo.bandOpacity}

                    >

                        <div 
                        className="RangeBand"
                        >
                            <div className="Bg">

                                <div className="Colour"></div>

                            </div>
                
                        </div>

                        <div 
                        className="RangeWrap"
                        >
                            <input
                                className="RangeInp" 
                                type="range"
                                id={props.data.id+"_b"}
                                value={rangerValueB}
                                onChange={event => {

                                    handleRanger({

                                        target      :event.target,
                                        from        :'onChange'
                                    
                                    })

                                }}

                                /*
                                onInput={event => {

                                    handleRanger({

                                        target      :event.target,
                                        from        :'onInput'

                                    })

                                }}
                                */

                            />

                        </div>

                    </Ranger>






                </SliderBox>

            </SliderContainer>


        </SliderGroup>




    );

};

export default DualSlider;