import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';


import {

    ReportLedger,

        ReportBullet,
        ReportPercent,
        ReportBulletPercentName,

        ReportItem,
            // ReportItemName,
            // ReportItemValue


} from '../Styled';



import LineItem from './LineItem';



const Index = (props) => {



    // -- window dims -- //
    const { 
        width, 
        //height 
    } = useWindowDimensions();



    // -- ref -- //
    const refA = useRef(null);


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState({});
    const [items, setItems] = useState([]);


    useEffect(() => {

        if(!isLoaded)
        {

            setIsLoaded(true);
            setItems(props.data.value);

            //alert('Ledger');

        }
        else
        {



            //console.log("utils/Report/Ledger");

            // alert(JSON.stringify(props.data.style,null,2));

 
            if(items.length === 0) setItems(props.data.value);
            if(Object.keys(data).length === 0)
            {

                var width;

                var margin;
                var marginTop;
                var marginBottom;
                var marginLeft;

                var border;

                width = "calc(100% - 20px)"

                marginTop = "10px";
                marginBottom = "0px";
                marginLeft = "10px";

                border = false;

                if(props.data.style && props.data.style !== undefined)
                {
                    if(props.data.style.width) width = props.data.style.width;

                    if(props.data.style.marginTop) marginTop = props.data.style.marginTop;
                    if(props.data.style.marginBottom) marginBottom = props.data.style.marginBottom;
                    if(props.data.style.marginLeft) marginLeft = props.data.style.marginLeft;

                    if(props.data.style.border) border = props.data.style.border;
 
                }


                if(props.data.boxHeight 
                && props.data.boxHeight !== undefined
                )
                {
                    marginTop = (props.data.boxHeight - refA.current.offsetHeight)/2 - (5) + "px";
                }

                if(props.lessThanThresh)
                {
                    marginTop = "0px";
                    marginBottom = "30px";
                }

                margin = marginTop+" 0 "+marginBottom+" "+marginLeft;

                setData({

                    id:props.id,
                    width:width,
                    margin:margin,
                    border:border
            
                });



            }




        }
        //console.log("ledgerData: "+JSON.stringify(props.data,null,2));


    },
    [
        props,
        isLoaded,
        //rowRefs
        data,
        items
    ])








    const [adjust, setAdjust] = useState(false);
    useEffect(() => {

        if(width < 550)
        {
            if(!adjust)
            {

                setAdjust(true);

            }

        }else{

          
            if(adjust) setAdjust(false);
        }


        // if(props.isWideReport && props.adjustSector)
        // {
        //     //console.log("isWideReport and adjustSector");
        // }



    
    },
    [

        props,
        width,     
        adjust
    ])






    return (


        <ReportLedger
        ref={refA}
        id={data.id}
        width={data.width}
        margin={data.margin}
        //backgroundColor={'lightblue'}
        >
            {items.map((itm, k) => (

                itm.bullet && itm.bullet !== undefined
                ?

                    <div
                    key={k}
                    >

                        <ReportBullet
                        backgroundColor={itm.bullet.color}
                        border={itm.bullet.border}
                        //backgroundColor={itm.value.length > 12 ? "darkslateblue" : "darkslategray"}
                        >
                        </ReportBullet>


                        {
                        adjust
                        ?


                            <div>
                            <ReportPercent 
                            off={itm.bullet.color === "#303542" ? true : false}
                            dark={props.dark}
                            >

                                <div className="Text"
                                style={{

                                        fontSize:props.data.style.fontSize
                                }}
                                >
                                    {itm.percent+"%"}
                                    
                                </div>

                            </ReportPercent>



                            <ReportBulletPercentName
                            dark={props.dark}
                            >

                                <div className="Text"
                                style={{

                                    fontSize:props.data.style.fontSize 
                                }}
                                >
                                    {itm.name}
                                    
                                </div>

                            </ReportBulletPercentName>
                            </div>



                        :
                        props.isWideReport && props.adjustSector && itm.value.length > 12
                        ?

                            <div>

                            <ReportPercent 
                            off={itm.bullet.color === "#303542" ? true : false}
                            dark={props.dark}
                            >

                                <div className="Text"
                                style={{

                                    fontSize:props.data.style.fontSize
                                }}
                                >
                                    {itm.percent+"%"}
                                    
                                </div>

                            </ReportPercent>



                            <ReportBulletPercentName
                            dark={props.dark}
                            >

                                <div className="Text"
                                style={{

                                    fontSize:props.data.style.fontSize 
                                }}
                                >
                                    {itm.name}
                                    
                                </div>

                            </ReportBulletPercentName>

                            </div>



                        // :
                        // props.isWideReport && props.adjustSector && k > 1
                        // ?

                        //     items[k-1].value.length > 12
                        //     ?

                        //         <div>

                        //         <ReportPercent 
                        //         dark={props.dark}
                        //         >

                        //             <div className="Text"
                        //             style={{

                        //                 fontSize:props.data.style.fontSize 
                        //             }}
                        //             >
                        //                 {itm.percent+"%"}
                                        
                        //             </div>

                        //         </ReportPercent>



                        //         <ReportBulletPercentName
                        //         dark={props.dark}
                        //         >

                        //             <div className="Text"
                        //             style={{

                        //                 fontSize:props.data.style.fontSize 
                        //             }}
                        //             >
                        //                 {itm.name}
                                        
                        //             </div>

                        //         </ReportBulletPercentName>

                        //         </div>


                        //     :

                        //         <ReportPercent 
                        //         dark={props.dark}
                        //         >

                        //             <div className="Text"
                        //             style={{

                        //                 fontSize:props.data.style.fontSize 
                        //             }}
                        //             >

                                        
                        //                 {itm.percent+"%"}
                                        

                        //             </div>

                        //         </ReportPercent>


                        :




                            <ReportPercent 
                            off={itm.bullet.color === "#303542" ? true : false}
                            dark={props.dark}
                            >

                                <div className="Text"
                                style={{

                                    fontSize:props.data.style.fontSize
                                }}
                                >

                                    
                                    {itm.percent+"%"}
                                    

                                </div>

                            </ReportPercent>

                        }


                        <ReportItem                           
                        id={"row_"+k}
                        dark={props.dark}
                        width={
                            
                            adjust 
                            ? 
                                "calc(100% - 1px)" 
                            :
                            props.isWideReport && props.adjustSector && itm.value.length > 12
                            ?
                                "calc(100% - 1px)" 

                            : 
                                "calc(100% - 115px)"
                        
                
            
                        }
                        >
                            <LineItem 
                                props={props}
                                dark={props.dark}
                                data={itm} 
                                fontSize={props.data.style.fontSize} 
                                //adjust={adjust}
                                adjust={adjust ? true : props.isWideReport && props.adjustSector && itm.value.length > 12 ? true : false}
                                lineType={'bullet_percent'}

                            />

                        </ReportItem>

                    </div>

                 
                :
                itm.percent && itm.percent !== undefined
                ?

                    <div
                    key={k}
                    >
                        <ReportPercent
                        dark={props.dark}
                        margin={"12px 7px 0 0px"}
                        >

                            <div className="Text"
                            style={{
            
                                fontSize:props.data.style.fontSize 
                            }}
                            >

                                {itm.percent+"%"}

                            </div>

                        </ReportPercent>

                        <ReportItem                           
                        id={"row_"+k}
                        dark={props.dark}
                        width={"calc(100% - 83px)"}
                        >
                            <LineItem 
                                props={props}
                                dark={props.dark}
                                data={itm}
                                fontSize={props.data.style.fontSize} 
                                //adjust={adjust}
                                adjust={adjust ? true : props.isWideReport && props.adjustSector && itm.value.length > 12 ? true : false}
                                lineType={'percent'}
                            
                            />

                        </ReportItem>

                    </div>

                 
                :


                    <ReportItem                           
                    key={k}
                    id={"row_"+k}
                    dark={props.dark}
                    darkBorder={data.border}
                    >
                        <LineItem 
                            props={props}
                            dark={props.dark}
                            data={itm}
                            fontSize={props.data.style.fontSize} 
                            //adjust={adjust}
                            adjust={adjust ? true : props.isWideReport && props.adjustSector && itm.value.length > 12 ? true : false}
                            lineType={false}

                        />

                    </ReportItem>



            ))}

        </ReportLedger>


    )


}

export default Index;


