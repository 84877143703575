import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';


import {

    ReportItemName,
    ReportItemValue

} from '../../Styled';


//import { LibNumber } from 'library';



const Index = (props) => {


    // -- window dims -- //
    const { 
        width, 
        //height 
    } = useWindowDimensions();


    // -- ref -- //
    const refA = useRef(null);
    const refB = useRef(null);
 

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [valueDif, setValueDif] = useState(0);
    const [value, setValue] = useState("");

    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

            if(props.data.value !== undefined)
            {
                if(props.data.value.toString().match(/\$/gi))
                {
                    //setValue(LibNumber.PrintMoney(props.data.value));
                    setValue(props.data.value);
                }else{
                    setValue(props.data.value);
                }

            }else{
                setValue(props.data.value);
            }


        }else{


            if(props.data.value && props.data.value !== undefined)
            {
                if(props.data.value.toString().match(/\$/gi))
                {
                    //setValue(LibNumber.PrintMoney(props.data.value));
                    setValue(props.data.value);
                }else{
                    setValue(props.data.value);
                }

            }else{
                setValue(props.data.value);
            }



            if(refA.current.offsetHeight > refB.current.offsetHeight)
            {
                var dif = (refA.current.offsetHeight - refB.current.offsetHeight);
                setValueDif(dif);
            }else{
                setValueDif(0);
            }

 
        }





    },
    [
        props,
        isLoaded,
        refA,refB
    ])






    const [adjust, setAdjust] = useState(false);
    useEffect(() => {

        if(width < 550)
        {
            if(!adjust)
            {

                setAdjust(true);

            }

        }else{

            if(adjust) setAdjust(false);

        }


    
    },
    [

        props,
        width,     
        adjust
    ])






    return (<div>



        {
        props.lineType === "bullet_percent"
        ?

            <div>

                <ReportItemName
                ref={refA}
                dark={props.dark}
                width={
                    
                    adjust && value.length > 12
                    ?
                        "auto"
                    :
                    props.isWideReport && props.adjustSector && value.length > 12
                    ?
                        "auto"
                    :
                        "calc(55% - 2px)"

                
                }
                fontSize={props.fontSize}
                >

                    {
                    !adjust && !props.adjust
                    ?
                        <div className="PlainText"
                        >
                            {props.data.name}

                        </div>

                    :null
                    }


                </ReportItemName>

                <ReportItemValue
                ref={refB}
                dark={props.dark}
                width={

                    adjust && value.length > 12
                    ?
                        "calc(80% - 1px)"
                    :
                    props.isWideReport && props.adjustSector && value.length > 12
                    ?
                        "calc(80% - 1px)"
                    :
    
                        "calc(45% - 2px)"


                }
                margin={valueDif+"px 0 0 0"}
                fontSize={props.fontSize}
                >
                    <div className="PlainText"
                    >
                        {value}

                    </div>

                </ReportItemValue>

            </div>



        :



            <div>

                <ReportItemName
                ref={refA}
                dark={props.dark}
                width={
                    
                    adjust && value.length > 12
                    ?
                        "calc(100% - 2px)"
                    :
                    props.isWideReport && props.adjustSector && value.length > 12
                    ?
                        "calc(100% - 2px)"
                    :
    
                        "calc(55% - 2px)"




                }
                fontSize={props.fontSize}
                >
                    <div className="PlainText"
                    >
                        {props.data.name}

                    </div>

                </ReportItemName>

                <ReportItemValue
                ref={refB}
                dark={props.dark}
                width={
                
                    adjust && value.length > 12
                    ?
                        "calc(80% - 1px)"
                    :
                    props.isWideReport && props.adjustSector && value.length > 12
                    ?
                        "calc(80% - 1px)"
                    :

                        "calc(45% - 2px)"

                }
                margin={valueDif+"px 0 0 0"}
                fontSize={props.fontSize}
                >
                    <div className="PlainText"
                    >
                        {value}

                    </div>

                </ReportItemValue>

            </div>




        }




    </div>)


    



}

export default Index;







// return (


//     adjust 
//     && value.length > 12
//     ?


//         <div>


//             {
//             props.lineType === "bullet_percent" || props.lineType === 'percent'
//             ?

//                 <div>

//                     <ReportItemName
//                     ref={refA}
//                     dark={props.dark}
//                     width={"0px"}
//                     fontSize={props.fontSize}
//                     >
//                         {/* <div className="PlainText"
//                         >
//                             {props.data.name}

//                         </div> */}

//                     </ReportItemName>

//                     <ReportItemValue
//                     ref={refB}
//                     dark={props.dark}
//                     width={"calc(80% - 1px)"}
//                     margin={valueDif+"px 0 0 0"}
//                     fontSize={props.fontSize}
//                     >
//                         <div className="PlainText"
//                         >
//                             {value}

//                         </div>

//                     </ReportItemValue>

//                 </div>



//             :
//             props.lineType === 'percent'
//             ?

//                 <div>

//                     <ReportItemName
//                     ref={refA}
//                     dark={props.dark}
//                     width={"calc(55% - 2px)"}
//                     fontSize={props.fontSize}
//                     >
//                         <div className="PlainText"
//                         >
//                             {props.data.name}

//                         </div>

//                     </ReportItemName>

//                     <ReportItemValue
//                     ref={refB}
//                     dark={props.dark}
//                     width={"calc(45% - 1px)"}
//                     margin={valueDif+"px 0 0 0"}
//                     fontSize={props.fontSize}
//                     >
//                         <div className="PlainText"
//                         >
//                             {value}

//                         </div>

//                     </ReportItemValue>

//                 </div>

                

//             :



//                 <div>

//                     <ReportItemName
//                     ref={refA}
//                     dark={props.dark}
//                     width={"calc(55% - 2px)"}
//                     fontSize={props.fontSize}
//                     >
//                         <div className="PlainText"
//                         >
//                             {props.data.name}

//                         </div>

//                     </ReportItemName>

//                     <ReportItemValue
//                     ref={refB}
//                     dark={props.dark}
//                     width={"calc(45% - 1px)"}
//                     margin={valueDif+"px 0 0 0"}
//                     fontSize={props.fontSize}
//                     >
//                         <div className="PlainText"
//                         >
//                             {value}

//                         </div>

//                     </ReportItemValue>

//                 </div>


//             }


//         </div>




//     :
    

//         <div>

//             <ReportItemName
//             ref={refA}
//             dark={props.dark}
//             width={"calc(55% - 2px)"}
//             fontSize={props.fontSize}
//             >
//                 <div className="PlainText"
//                 >
//                     {props.data.name}

//                 </div>

//             </ReportItemName>

//             <ReportItemValue
//             ref={refB}
//             dark={props.dark}
//             width={"calc(45% - 1px)"}
//             margin={valueDif+"px 0 0 0"}
//             fontSize={props.fontSize}
//             >
//                 <div className="PlainText"
//                 >
//                     {value}

//                 </div>

//             </ReportItemValue>

//         </div>


// )

