export const BREAKEVEN_TAG = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};



	let darklite = props.darklite || "lite";

	let outerColor;
	let innerColor;
	let signColor;

	if(darklite === 'dark')
	{
		outerColor = props.outerColor || "rgb(255,255,255)";
		innerColor = props.innerColor || "rgb(30,35,45)";
		signColor = props.signColor || "#26d6cc";
	}
	else // lite
	{
		outerColor = props.outerColor || "rgb(255,255,255)";
		innerColor = props.innerColor || "rgb(30,35,45)";
		signColor = props.signColor || "#26d6cc";
	}



	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>



			{
				//<!--White Backgroung-->
			}
			<path 
				fill={outerColor} 
				d="M156.662,475c-24.601,0-44.609-18.128-44.609-40.411V196.354 c0-16.716,6.482-37.603,16.135-51.965c1.91-2.843,4.871-7.175,8.633-12.689c11.098-16.269,29.654-43.469,48.834-72.766 C198.521,37.991,223.15,25,249.997,25c26.782,0,51.381,12.948,64.263,33.816c19.277,29.438,37.901,56.729,49.029,73.048 c3.712,5.437,6.629,9.712,8.521,12.525c9.648,14.362,16.137,35.248,16.137,51.965v238.234c0,22.283-20.014,40.411-44.609,40.411
				H156.662z"
			/>


			{
				//<!--Navy-->
			}
			<path 
				fill={innerColor} 
				fillRule="evenodd"
				clipRule="evenodd"
				d="M249.997,71.264c14.115,0,25.626,11.508,25.626,25.62 c0,14.114-11.512,25.624-25.626,25.624c-14.108,0-25.618-11.51-25.618-25.624C224.378,82.771,235.889,71.264,249.997,71.264 L249.997,71.264z M249.997,39.819c22.424,0,41.965,10.95,51.758,26.938c25.728,39.305,50.124,74.545,57.756,85.9	c7.737,11.516,13.614,29.623,13.614,43.696v238.234c0,14.075-13.402,25.595-29.787,25.595H250h-93.338 c-16.385,0-29.792-11.52-29.792-25.595V196.354c0-14.073,5.878-32.181,13.624-43.696c7.616-11.347,31.98-46.528,57.683-85.801 C208.205,50.391,228.29,39.819,249.997,39.819L249.997,39.819z"
			/>

			{
				//<!--$-->


				// hex #00cc66
				// rgb(0, 204, 99)
				// cmyk(100%, 0%, 51%, 20%)

			}
			<path 
				fill={signColor} 
				fillRule="evenodd"
				clipRule="evenodd"
				d="M325,273.564H175v-33h150V273.564z M325,317.561H175v33h150 V317.561z"
			/>



		</svg>
		</div>

	);//return
}