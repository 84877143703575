import styled from "styled-components";



export const Report = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    // border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",

    float:              props.float || "left",


    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",

    minHeight:          props.minHeight || "25px"

}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    max-width:10000px;

    height:${props => props.height};
    min-height:${props => props.minHeight};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:${props => props.float};

    overflow:visible;


    @media (max-width: 1100px) {

        width:90%;
        margin:25px 0 25px 5%;

    }

    @media (max-width: 600px) {

        width:calc(100% - 20px);
        margin:25px 0 25px 10px;

    }



`;


