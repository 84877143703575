
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';



//import { useWindowDimensions } from 'utils/WindowDimensionsProvider';


//import 'app-style/alfabase.css';//alfa,base,tab,tab-x,flank

import { Alfa, Base } from 'app-styled/AlfaBase';
import { LoaderBox } from './Styled';

import './StyleSheets/loader.css';


import { LibElem } from 'library';


const Index = (props) => {



    // -- window dims -- //
    //const { width, height } = useWindowDimensions();


    // -- ref -- //

    //const baseRef = useRef(null)
    //const tabRef = useRef(null)
    const flyrRef = useRef(null)    


    // -- state -- //
    const [highZ, setHighZ] = useState(0)

    useEffect(() => {
        
        //setFlyrHeight(flyrRef.current.offsetHeight);
        if(highZ === 0)
        {
            var z = LibElem.HighestZ();
            setHighZ(z)

        }

    }, [highZ])



    // -- state -- //
    const [alfaBgColor, setAlfaBgColor] = useState(false);
    const [loaderStuff, setLoaderStuff] = useState({

        box:{

            backgroundColor:'#191e28',
            border:false,
            width:false,
            height:false,
            top:false,
            left:false

        },

        loader:{

            class       :'sm-loader-circle',
            lowlight    : "0.5em solid rgba(250,250,250,0.2)",
            highlight   : "0.5em solid #fff"

        }

    });



    useEffect(() => {


        
        //setFlyrHeight(flyrRef.current.offsetHeight);
        if(props.alfaBgColor && props.alfaBgColor !== undefined && !alfaBgColor)
        {
            
            setAlfaBgColor(props.alfaBgColor);

        }


        if(props.styling && props.styling !== undefined && props.call !== 'pageLoader')
        {
            
            setLoaderStuff({

                box:{

                    backgroundColor : props.styling.box.backgroundColor,
                    border          : props.styling.box.border,
                    width           : props.styling.box.width,
                    height          : props.styling.box.height,
                    top             : props.styling.box.top
                },

                loader:{

                    class       : props.styling.loader.class,
                    lowlight    : props.styling.loader.lowlight,
                    highlight   : props.styling.loader.highlight

                }

            });

        }



    }, [
        props,
        alfaBgColor
    ])







    if(props.call === 'pageLoader')
    {


        return ( 


            props.styling
            ?

                <LoaderBox 
                id="flyr"
                ref={flyrRef}
                backgroundColor={'transparent'}
                border={"0"}
                width={"200px"}
                //top={"calc(50% - 100px)"}
                top={props.styling.box.top}         
                left={"calc(50% - 115px)"}
                //left={props.styling.box.left}
                >

                    {props.text
                    ?
                        <div 
                        id="loaderText"
                        style={{

                            width       :"100%",
                            height      :"auto",
                            padding     :"0 0 5px 0",
                            margin      :"0 auto 20px auto",
                            textAlign   :"center",
                            color       :props.styling.textColor

                        }}
                        >
                            {props.text}

                        </div>

                    :null}

                    <div 
                    id="loader"
                    className={props.styling.loader.class} 
                    style={{

                        width               : "4em",
                        height              : "4em",

                        borderTop          : props.styling.loader.lowlight,
                        borderRight        : props.styling.loader.lowlight,
                        borderBottom       : props.styling.loader.lowlight,
                        borderLeft         : props.styling.loader.highlight

                    }}
                    >
                    </div>


                </LoaderBox>



            :
            props.litedark === 'dark'
            ?


                <LoaderBox 
                id="flyr"
                ref={flyrRef}
                backgroundColor={'transparent'}
                border={"0"}
                width={"200px"}
                top={"calc(50% - 100px)"}
                left={"calc(50% - 115px)"}
                >

                    {props.text
                    ?
                        <div 
                        id="loaderText"
                        style={{

                            width       :"100%",
                            height      :"auto",
                            padding     :"0 0 5px 0",
                            margin      :"0 auto 20px auto",
                            textAlign   :"center",
                            color       :"#fcfcfc"

                        }}
                        >
                            {props.text}

                        </div>

                    :null}

                    <div 
                    id="loader"
                    className="sm-loader-circle" 
                    style={{

                        width               : "4em",
                        height              : "4em",
                    
                        borderTop           : "0.5em solid rgba(100,100,100,0.2)",
                        borderRight         : "0.5em solid rgba(100,100,100,0.2)",
                        borderBottom        : "0.5em solid rgba(100,100,100,0.2)",
                        borderLeft          : "0.5em solid #aaa"

                    }}
                    >
                    </div>


                </LoaderBox>



            :
        

                <LoaderBox 
                id="flyr"
                ref={flyrRef}
                backgroundColor={'transparent'}
                border={"0"}
                width={"200px"}
                top={"calc(50% - 100px)"}
                left={"calc(50% - 115px)"}
                >

                    {props.text
                    ?
                        <div 
                        id="loaderText"
                        style={{

                            width       :"100%",
                            height      :"auto",
                            padding     :"0 0 5px 0",
                            margin      :"0 auto 20px auto",
                            textAlign   :"center",
                            color       :"black"

                        }}
                        >
                            {props.text}

                        </div>

                    :null}

                    <div 
                    id="loader"
                    className="sm-loader-circle" 
                    style={{

                        width               : "4em",
                        height              : "4em",
                    
                        borderTop           : "0.5em solid rgba(100,100,100,0.2)",
                        borderRight         : "0.5em solid rgba(100,100,100,0.2)",
                        borderBottom        : "0.5em solid rgba(100,100,100,0.2)",
                        borderLeft          : "0.5em solid #aaa"

                    }}
                    >
                    </div>


                </LoaderBox>




    
        );


    }else{

        return ( 

            <div>

                <Alfa
                backgroundColor={alfaBgColor}
                zIndex={highZ+1}
                ></Alfa>

                <Base 
                //ref={baseRef}
                zIndex={highZ+2}
                >


                    {

                    // <LoaderBox 
                    // id="flyr"
                    // ref={flyrRef}
                    // >
                    //     <div 
                    //     id="loader"
                    //     className="sm-loader-circle" 
                    //     style={{

                    //         borderTop          : "0.5em solid rgba(250,250,250,0.2)",
                    //         borderRight        : "0.5em solid rgba(250,250,250,0.2)",
                    //         borderBottom       : "0.5em solid rgba(250,250,250,0.2)",
                    //         borderLeft         : "0.5em solid #FFF"

                    //     }}
                    //     >
                    //     </div>


                    // </LoaderBox>


                    <LoaderBox 
                    id="flyr"
                    ref={flyrRef}
                    backgroundColor={loaderStuff.box.backgroundColor}
                    border={loaderStuff.box.border}
                    width={loaderStuff.box.width}
                    height={loaderStuff.box.height}
                    top={loaderStuff.box.top}
                    >
                        <div 
                        id="loader"
                        className={loaderStuff.loader.class} 
                        style={{

                            borderTop          : loaderStuff.loader.lowlight,
                            borderRight        : loaderStuff.loader.lowlight,
                            borderBottom       : loaderStuff.loader.lowlight,
                            borderLeft         : loaderStuff.loader.highlight

                        }}
                        >
                        </div>

                    </LoaderBox>


                    }



                 

                </Base>

            </div>



        );
    }






}



export default Index;





