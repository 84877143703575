
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';



//import { useWindowDimensions } from 'utils/WindowDimensionsProvider';


//import 'app-style/alfabase.css';//alfa,base,tab,tab-x,flank

import { Alfa, Base } from 'app-styled/AlfaBase';
import { Messngr, Message, Button } from './Styled';


import Timeout from './Timeout';


import { LibElem } from 'library';



const Index = (props) => {


    //props.isError
    //props.isTimed
    //props.items



    // -- window dims -- //
    //const { width, height } = useWindowDimensions();


    // -- ref -- //

    //const baseRef = useRef(null)
    //const tabRef = useRef(null)
    const flyrRef = useRef(null)    



    // -- state -- //
    const [highZ, setHighZ] = useState(0)

    useEffect(() => {
        
        //setFlyrHeight(flyrRef.current.offsetHeight);
        if(highZ === 0)
        {
            var z = LibElem.HighestZ();
            setHighZ(z)

        }

    }, [highZ])




    // -- state -- //
    const [alfaBgColor, setAlfaBgColor] = useState(false);
    const [headingBarColor, setHeadingBarColor] = useState("#caffca")


    useEffect(() => {


        // console.log("Messngr should be showing");
        
        //setFlyrHeight(flyrRef.current.offsetHeight);
        if(props.alfaBgColor && props.alfaBgColor !== undefined && !alfaBgColor)
        {
            
            setAlfaBgColor(props.alfaBgColor);

        }
        if(props.style && props.style !== undefined)
        {
            if(props.style.headingBarColor)
            {
                
                setHeadingBarColor(props.style.headingBarColor);

            }
        }


    }, [
        props,
        alfaBgColor
    ])



    // -- state -- //
    //const [flyrHeight, setFlyrHeight] = useState(0)
    const [buttonStyle, setButtonStyle] = useState({});
 
    useEffect(() => {
        
        //setFlyrHeight(flyrRef.current.offsetHeight);
        setButtonStyle({

            bgColor     :"#0291cd",
            txtColor    :"#FFF",
            brColor     :"#0291cd"

        });
      
    }, [])




    // -- handle -- //

    const handleOverOut = (v) => {

        //console.log(v);
        setButtonStyle({

            bgColor     :v.bgColor,
            txtColor    :v.txtColor,
            brColor     :v.brColor

        });

    }


    const handleTimeoutMsg = (v) => {

        console.log("handleTimeoutMsg");
        console.log(v);

        props.handle({

            call:'redirect'

        });

    }






    // --  size and position -- //


    /*

    var maxWidth;
    maxWidth = 100; 
    if(width < maxWidth + 50)
    {
        maxWidth = width;

    }//==


    //var flyrPadBottomInt = 80;
    //var flyrPadBottom = flyrPadBottomInt + "px";

    var flyrWInt;
    var flyrW;

    //var flyrHInt;
    var flyrH;

    var flyrMarl;

    var flyrMartInt;
    var flyrMart;


    

    //------------------------------------------

    flyrWInt = maxWidth;
    flyrMarl = (width - flyrWInt)/2 + "px";

    if(width < flyrWInt+50)
    {
        flyrWInt = width;
        flyrMarl = 0 + "px";
    }//==

    flyrW = flyrWInt + "px";


    //flyrHInt = 0;
    flyrH = 'auto';
    

    //--------------------------------------------

    


    flyrMartInt = 0;
    if(flyrHeight !== 0 && flyrHeight < height)
    {  
        flyrMartInt = (height - flyrHeight)/2 - 50;
    }

    flyrMart = flyrMartInt + 'px';

    //----------------------------------------------


    



    // -- button and message -- //

    var buttonWInt;
    var buttonW;
    var buttonMarlInt;
    var buttonMarl;

    buttonWInt = 100;
    buttonW = buttonWInt + "px";

    buttonMarlInt = (flyrWInt - buttonWInt)/2;
    buttonMarl = buttonMarlInt + "px";


    

    var msgWInt;
    var msgW;
    var msgMarl;


    msgWInt = flyrWInt - 90;
    msgW = msgWInt + "px";

    msgMarl = (flyrWInt - msgWInt)/2 - 20 + "px";

    //msgMarl = buttonMarlInt - ((msgWInt - buttonWInt)/2) + "px";

    */




    var data;
    var level;
    if(props.data !== undefined) level = 1;
    else level = 0;

    switch(level)
    {
    case 1:

        data = props.data;
    break;
    default:

        data = props;
    break;
    }//===




    // -- vars -- //

    const defMessage = useState([

        {
            "call":"heading",
            "text":"Message" 
        },
        {
            "call":"span",
            //"text":"Update was Successfull!!" 
            "text":"Okay"
        }

    ]);


    let isError         = (data.isError || false);
    let errorType       = (data.errorType || '');
    let isTimed         = (data.isTimed || true);
    let timedDuration   = (data.timedDuration || 2000);
    let array           = (data.items || defMessage);







    // // -- state -- //

    // const [hasDataItems, setHasDataItems] = useState(false);
    // const [isError, setIsError] = useState(false);
    // const [errorType, setErrorType] = useState('');
    // const [isTimed, setIsTimed] = useState(false);
    // const [timedDuration, setTimedDuration] = useState(2000);
    // const [array, setArray] = useState([

    //     {
    //         "call":"heading",
    //         "text":"Message" 
    //     },
    //     {
    //         "call":"span",
    //         //"text":"Update was Successfull!!" 
    //         "text":"Okay"
    //     }

    // ]);


    // useEffect(() => {


    //     var data;
    //     var level;

    //     if(props.data !== undefined) level = 1;
    //     else level = 0;
    
    //     switch(level)
    //     {
    //     case 1:
    
    //         data = props.data;
    //     break;
    //     default:
    
    //         data = props;
    //     break;
    //     }//===


    //     if(data.items)
    //     // if(!hasDataItems && data.items)
    //     {

    //         setHasDataItems(true);//run once

    //         let isError         = (data.isError || false);
    //         let errorType       = (data.errorType || '');
    //         let isTimed         = (data.isTimed || true);
    //         let timedDuration   = (data.timedDuration || 2000);
    //         let array           = data.items;

    //         setIsError(isError);
    //         setErrorType(errorType);
    //         setIsTimed(isTimed);
    //         setTimedDuration(timedDuration);
    //         setArray(array);
        

    //         // console.log("Messengr - Original");
    //         // console.log(JSON.stringify(props,null,2));

    //     }

      
    // }, [
    //     props,
    //     hasDataItems,
    //     errorType
    // ])







    return ( 


        <div>


            <Alfa
            backgroundColor={alfaBgColor}
            zIndex={highZ+1}
            ></Alfa>

            <Base 
            //ref={baseRef}
            zIndex={highZ+2}
            >


                {
                isError
                ?


                    <Messngr 

                        id="messengrFlyr"
                        ref={flyrRef}  

                        top={"calc(50% - 200px)"}
                        //backgroundColor={'#1a1926'}
                        //width={flyrW}
                        //height={flyrH}
                        //margin={flyrMart+" 0 0 "+flyrMarl}
                    
                    >



                        <Message
                    
                            backgroundColor={'lightyellow'}
                            border={"1px solid lightgray"}
            
                        >


                            <div 
                            style={{

                                backgroundColor     : "transparent",
                                width               : "98%",
                                height              : "auto",
                                margin              : "0 0 7px 0",
                                padding             : "7px 0 7px 0",
                            
                                fontSize            : "16px",
                                textAlign           : "left",
                                color               : "orangered"

                            }}
                            >

                                {
                                //----------------------------------
                                errorType.match(/process/gi) ? 

                                    <div>Process Error</div>

                                : <div>Please Fix Errors...</div>
                                //-----------------------------------
                                }


                            </div>




                            <div>
                            {array.map((line,i) => (
                                

                                <div 
                                key={"line"+i}
                                style={{

                                    backgroundColor     : "transparent",
                                    width               : "98%",
                                    height              : "auto",
                                    margin              : "0 0 0 0",
                                    padding             : "3px 0 3px 0",

                                    fontSize            : "14px",
                                    textAlign           : "left",
                                    color               : "#000"

                                }}
                                >
                                    {line.text}

                                </div>


                            ))}
                            </div>




                        </Message>




                        {
                        isTimed
                        ?

                    
                            <Timeout 

                                duration={timedDuration} 
                                handle={(v) => props.handle(v)} 

                            />


                        :


                            <Button

                                backgroundColor={buttonStyle.bgColor} 
                                //width={buttonW}
                            
                                //margin={"35px 0 0 "+buttonMarl}

                                color={buttonStyle.txtColor}
                                border={"1px solid "+buttonStyle.brColor}


                                onClick={() => handleTimeoutMsg({call:"error",data:array})}

                
                                onMouseEnter={() => handleOverOut({

                                    call        :"over",
                                    bgColor     :"orangered",
                                    txtColor    :"#FFF",
                                    brColor     :"orangered"
                        
                                })}
                        
                                onMouseOut={() => handleOverOut({
                        
                                    call        :"out",
                                    bgColor     :"#0291cd",
                                    txtColor    :"#FFF",
                                    brColor     :"#0291cd"
                        
                        
                                })}


                            >

                                OK

                            </Button>


                        }


                    </Messngr>




                : // -- success -- //



                    <Messngr 

                        id="messengrFlyr"
                        ref={flyrRef}  

                        //backgroundColor={'#1a1926'}
                        //width={flyrW}
                        //height={flyrH}
                        //margin={flyrMart+" 0 0 "+flyrMarl}
                        //top={flyrMart}
                        //left={flyrMarl}




                    >


                        <Message
                    
                            backgroundColor={'transparent'}
                            border={"0px"}
                            maxHeight={"auto"}
                    
                        >

                            <div>
                            {array.map((line,i) => (


                                <div
                                key={"line"+i}
                                
                                >
                                {
                                //------------------------------------
                                //success message
                                //------------------------------------


                                    line.call === "heading" 
                                    ? 


                                        line.bgColor && line.txtColor
                                        ?


                                            <div 
                                            style={{

                                                backgroundColor     : line.bgColor,
                                                width               : "100%",
                                                height              : "auto",
                                                margin              : "0 0 10px 0",
                                                padding             : "10px 0 10px 0",

                                                fontSize            : "16px",
                                                textAlign           : "center",
                                                color               : line.txtColor,

                                            }}
                                            >
                                                {line.text}

                                            </div>


                                        :



                                            <div 
                                            style={{

                                                backgroundColor     : headingBarColor,
                                                width               : "100%",
                                                height              : "auto",
                                                margin              : "0 0 10px 0",
                                                padding             : "10px 0 10px 0",

                                                fontSize            : "16px",
                                                textAlign           : "center",
                                                color               : "black",

                                            }}
                                            >
                                                {line.text}

                                            </div>





                                    : line.call === "span" ? 

                    
                                        <div 
                                        style={{

                                            backgroundColor     : "transparent",
                                            width               : "100%",
                                            height              : "auto",
                                            margin              : "5px 0 5px 0",
                                            padding             : "3px 0 3px 0",

                                            fontSize            : "14px",
                                            textAlign           : "center",
                                            color               : "#000"

                                        }}
                                        >
                                            {line.text}

                                        </div>

                            
                                    : null



                                //--------------------------------------
                                }
                                </div>
                                


                            ))}
                            </div>

                            
                        </Message>




                        {
                        isTimed
                        ?

                    
                            <Timeout 

                                duration={timedDuration} 
                                handle={(v) => props.handle(v)} 

                            />


                        :


                            <Button

                                backgroundColor={buttonStyle.bgColor} 
                                //width={buttonW}
                            
                                //margin={"35px 0 0 "+buttonMarl}

                                color={buttonStyle.txtColor}
                                border={"1px solid "+buttonStyle.brColor}


                                onClick={() => handleTimeoutMsg({call:"success",data:array})}

                
                                onMouseEnter={() => handleOverOut({

                                    call        :"over",
                                    bgColor     :"orangered",
                                    txtColor    :"#FFF",
                                    brColor     :"orangered"
                        
                                })}
                        
                                onMouseOut={() => handleOverOut({
                        
                                    call        :"out",
                                    bgColor     :"#0291cd",
                                    txtColor    :"#FFF",
                                    brColor     :"#0291cd"
                        
                        
                                })}


                            >

                                Ok

                            </Button>


                        }


                    </Messngr>


                }

            </Base>

        </div>



    );



}





export default Index;


