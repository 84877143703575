import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'



import {

    Report,
        ReportRow,
            ReportTop,
                ReportTopName,
                ReportTopControls,
                // ReportTopGraphic,

            ReportBar,
            ReportMessage,
            ReportSection,
                ReportBox,
                //ReportGraphic,        
                // ReportLedger,


            // ReportLineItem,

            // ReportItem,
            //     ReportItemName,
            //     ReportItemValue 

} from './Styled';





// -- Charts -- //

import CanvasChart from 'utils/components/Charts/CanvasChart';

import { 

    HrzBarChart, 
    VrtBarChart,

    StaticDonutChart

} from 'utils/components/Charts/CssChart';


import { 

    SvgDonutChart,
    SvgDonutChartModern

} from 'utils/components/Charts/SvgChart';


import { 

    Gauge1,
    Gauge2,
    Gauge3,
    Gauge4,
    TemperatureGauge

} from 'utils/components/Charts/Gauge';


// -- local comps -- //

import GraphicComp from './Graphic';
import LedgerComp from './Ledger';
import TxtComp from './Txt';
import BloxComp from './Blox';
import DonutChartComp from './DonutChart';
import CanvasChartComp from './CanvasChart';
import BarredTxtComp from './BarredTxt';
import MathLedgerComp from './MathLedger';


const Index = (props) => {



    // -- window dims -- //
    const { 
        width,
        //height 
    } = useWindowDimensions();



    //=============================================
    // refs
    //=============================================

    const [rowRefs] = useState([]);
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            //console.log(boxRefs.length);

        }

    };

    // -- ref -- //
    const refA = useRef(null);


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [isFlyr, setIsFlyr] = useState(false);
    const [reportData, setReportData] = useState([]);
    // const [reportStyl, setReportStyl] = useState({

    //     width:false,
    //     margin:false

    // });


    useEffect(() => {


        var hasItems = false;

        if(!isLoaded)
        {

            setIsLoaded(true);


            if(props.refid && props.refid !== undefined)
            {
                if(props.refid === 'flyr')
                {
                    setIsFlyr(true);
                    hasItems = true;

                    // setReportStyl({
                    //     width:"95%",
                    //     margin:"25px 0 0 2.5%"
                    // });


                }
                else
                if(props.refid.match(/pagerow/gi))
                {
                    hasItems = true;

                    // setReportStyl({
                    //     width:"100%",
                    //     margin:"25px 0 0 0"
                    // });

                }

            }




            if(hasItems)
            {

                if(Object.prototype.toString.call(props.items) === '[object Array]')
                {
                    // console.log("Flyr Report - items; "+JSON.stringify(props.items,null,2));
                    // alert('flyr')

                    setReportData(props.items);

                }

            }
            else
            {

                if(Object.prototype.toString.call(props.data) === '[object Array]')
                {
                    setReportData(props.data);

                }

            }


        }else{


            // if(props.refid && props.refid !== undefined)
            // {
            //     if(props.refid === 'flyr')
            //     {
            //         setIsFlyr(true);
            //         hasItems = true;

            //         setReportStyl({
            //             width:"95%",
            //             margin:"25px 0 0 2.5%"
            //         });


            //     }
            //     else
            //     if(props.refid.match(/pagerow/gi))
            //     {
            //         hasItems = true;
            //         setReportStyl({
            //             width:"100%",
            //             margin:"25px 0 0 0"
            //         });

            //     }



            // }



            // if(hasItems)
            // {

            //     if(Object.prototype.toString.call(props.items) === '[object Array]')
            //     {
            //         // console.log("Flyr Report - items; "+JSON.stringify(props.items,null,2));
            //         // alert('flyr')

            //         setReportData(props.items);

            //     }

            // }
            // else
            // {

            //     if(Object.prototype.toString.call(props.data) === '[object Array]')
            //     {
            //         setReportData(props.data);

            //     }

            // }




            if(props.refid === 'flyr')
            {

                //console.log("REPORT H: "+refA.current.offsetHeight);
                props.handle({

                    call:"adjustContentsHeight",
                    data:{

                        ref:refA,
                        h:refA.current.offsetHeight

                    }

                })

            }

        }


        // console.log("Report - props: "+JSON.stringify(props,null,2));

        //console.log("utils/Report");


    

    },
    [
        props,
        isLoaded,
        refA,
        reportData
        //reportHeight

    ])





    //=============================================
    // state/effect - width
    //=============================================

    const [lessThanThresh, setLessThanThresh] = useState(false);

    useEffect(() => {

        if(isLoaded)
        {
            //if(width < 550 || props.refid === 'flyr')
            if(width < 550 && !lessThanThresh)
            {
                setLessThanThresh(true);
            }
            else
            if(width > 550 && lessThanThresh)
            {
                setLessThanThresh(false);
            }

        }

    },
    [
        props,
        isLoaded,
        width,
        lessThanThresh
    ])




    

    // //=============================================
    // // handle
    // //=============================================

    // const handle = (hndl) => {


    //     switch(hndl.call)
    //     {
    //     case'breadcrumb':

    //         var pathArr = pathname.split("/");
    //         var url = "/";

    //         if(hndl.data !== 'Home')
    //         {
    //             var i;
    //             for(i=0; i < pathArr.length; i++)
    //             {
    //                 if(pathArr[i] === hndl.data)
    //                 {
    //                     url = url + hndl.data;
    //                     break;
    //                 }else{
    //                     url = url + pathArr[i]+"/";
    //                 }
    //             }

    //         }

    //         //if(url.charAt(url.length-1) === '/') 
    //             //url = url.substring(0,url.length-1);

    //         if(url.charAt(url.length-1) !== '/') url = url + "/";

    //         history.push(url);

    //     break;
    //     case'menu':

    //         switch(hndl.data)
    //         {
    //         case'close':
    //             setShowMenu(false);
    //         break;
    //         default:
    //             setShowMenu(true);
    //         }
    //     break;
    //     default:
    //     }


    // }



    //=============================================
    // handleUrl
    //=============================================

    // const handleUrl = (hndl) => {

    //     var url;
    //     url = hndl.data.replace(/\//gi,"");
    //     url = "/" +pathname + "/" +url+ "/";
    //     history.push(url);

    // }







    

    return (


        <Report
        ref={refA}
        //backgroundColor={"darkslategrey"}
        // width={reportStyl.width}
        // margin={reportStyl.margin}
        width={props.width}
        float={props.float}
        border={props.border}
        >

            {reportData.map((row, i) => (



                // <div
                // key={i}
                // style={{

                //     backgroundColor :"lightblue",
                //     width           :"100%",
                //     height          :"auto",
                //     margin          :"0 0 50px 0",
                //     padding         :"0 0 0 0",
                //     position        :"relative",
                //     float           :"left",
                //     fontSize        :"12px",
                //     color           :"black"
                // }}
                // >
                //     <pre>
                //     {

                //         JSON.stringify(row,null,2)

                //     }
                //     </pre>



                // </div>



                <ReportRow
                key={i}
                >
                    {

        
                    row.call === 'top'
                    //row.call === 'top' && !isFlyr
                    ?

                        isFlyr
                        ?
                            <ReportTop>

                                <ReportTopName dark={row.dark}>

                                    {row.name}

                                </ReportTopName>

                                {/* <ReportTopGraphic
                                width={50+"px"}
                                height={50+"px"}
                                >

                                    <img
                                        id={"top_graphic_img"}
                                        className="Img"
                                        src={SVGHealthcare} 
                                        alt="graphic" 
                                    />

                                </ReportTopGraphic> */}


                            </ReportTop>


                        :


                            <ReportTop>


                                <ReportTopName dark={row.dark}>

                                    {row.name}

                                </ReportTopName>

                                {/* <ReportTopGraphic
                                width={50+"px"}
                                height={50+"px"}
                                >

                                    <img
                                        id={"top_graphic_img"}
                                        className="Img"
                                        src={SVGBillable} 
                                        alt="graphic" 
                                    />

                                </ReportTopGraphic> */}



                                {
                                row.controls.length > 0
                                ?

                                    <ReportTopControls>

                                        {row.controls.map((itm, j) => (

                                            <div key={i+"-"+j}>

                                                <div className="Button"
                                                onClick={() => props.handle({

                                                    //primeHandle : (v) => props.handle(v),
                                                
                                                    call        :'showFlyr',
                                                    data        :itm.form
                                                
                                                })}
                                                >

                                                    {itm.name}

                                                </div>

                                            </div>

                                        ))}

                                    </ReportTopControls>
              

                                :null
                                }


                                

                            </ReportTop>




                    // :
                    // row.call === 'bar'
                    // ?

                    //     <ReportBar>

                    //         <div className="LineName">

                    //             {row.items[0].name}

                    //         </div>

                    //         {/* <div className="Actions">

                    //             {row.items[1].name}

                    //         </div> */}

                    //     </ReportBar>

                    :
                    row.call === 'items'
                    ?


                        row.items.length > 0
                        ?

                            // row.items.map((itm, j) => (

                            //     <div 
                            //     ref={setRowRef}                              
                            //     key={i+"-"+j}
                            //     id={"row"+i+"-"+j}
                            //     >

                            //         <ReportSection
                            //         onClick={() => handleUrl({

                            //             call        :'push',
                            //             data        :itm.category
                    
                            //         })}
                            //         >

                            //             {itm.category}


                            //         </ReportSection>


                            //     </div>

                            // ))



                            row.items.map((itm, j) => (

                                <div 
                                ref={setRowRef}                              
                                key={i+"-"+j}
                                id={"row"+i+"-"+j}
                                >

                                    {

                                    //######################################################
                                    //######################################################
                                    //bar
                                
                                    itm.print === 'bar'
                                    ?
                                        <ReportBar>

                                            <div className="LineName">

                                                {itm.name}

                                            </div>

                                        </ReportBar>


                                    :

                                    //######################################################
                                    //######################################################
                                    //spacer
                                    
                                    itm.print === 'spacer'
                                    ?

                                        <ReportSection
                                        spacerBackgroundColor={itm.value.color}
                                        spacerHeight={itm.value.height}
                                        >

                                            <div className={"Spacer"}>{
                                                //JSON.stringify(itm,null,2)
                                            }</div>

                                        </ReportSection>

                                    :



                                    //######################################################
                                    //######################################################
                                    //nospacer
                                    
                                    // itm.print === 'nospacer'
                                    // ?

                                    //     <div></div>

                                    // :




                                    //######################################################
                                    //######################################################
                                    //BARRED TXT
                                    
                                    itm.print === 'barredTxt'
                                    ?
                                        <ReportSection>

                                            <BarredTxtComp 

                                                dark={row.dark}
                                            
                                                id={"row"+i+"_txt"}
                                                data={itm} 
                                            
                                            />

                                        </ReportSection>

                                    :


                                    //######################################################
                                    //######################################################
                                    //TXT
                                    
                                    itm.print === 'txt'
                                    ?
                                        <ReportSection>

                                            <TxtComp 

                                                dark={row.dark}
                                            
                                                id={"row"+i+"_txt"}
                                                data={itm} 
                                            
                                            />

                                        </ReportSection>

                                    :

                                    //######################################################
                                    //######################################################
                                    //LEDGER
                                    
                                    itm.print === 'ledger'
                                    ?

                                        <ReportSection>

                                            <LedgerComp
                                            
                                                isWideReport={props.isWideReport}
                                                adjustSector={props.adjustSector}

                                                dark={row.dark}
                                            
                                                id={"row"+i+"_ledger"}
                                                data={itm}
                                               
                                            />

                                        </ReportSection>

                               
                                    :


                                    //######################################################
                                    //######################################################
                                    //MATH LEDGER
                                    
                                    itm.print === 'mathLedger'
                                    ?

                                        <ReportSection
                                        comp={'mathLedger'}
                                        >

                                            <MathLedgerComp 

                                                dark={row.dark}
                                            
                                                id={"row"+i+"_ledger"}
                                                data={itm}
                                               
                                            />

                                        </ReportSection>

                               
                                    :



                                    //######################################################
                                    //######################################################
                                    //BLOCKS
                                    
                                    itm.print === 'blox'
                                    ?

                                        <ReportSection>

                                            <BloxComp 

                                                dark={row.dark}
                                            
                                                id={"row"+i+"_blox"}
                                                data={itm} 
                                            
                                            />

                                        </ReportSection>

                               
                                    :




                                    //######################################################
                                    //######################################################
                                    //canvas chart
                                    
                                    itm.print === 'canvas_chart'
                                    ?
                                        <ReportSection
                                        id={"row"+i+"_section"}
                                        >

                                            <CanvasChart
                                            
                                                parentId ={"row"+i+"_section"}
                                                id={"row"+i+"_chart"}
                            
                                                backgroundColor = {itm.value[0].backgroundColor}
                                                border={itm.value[0].border}
                                                width={itm.value[0].width}
                                                maxHeight={itm.value[0].maxHeight}
                                                margin={itm.value[0].margin}
                            
                                                primeHandle={(v) => props.handle(v)}
                                                drawCheckerBoard = {itm.value[0].drawCheckerBoard}
                                                draw = {false}   
                                                render = {false}
                                                chart = {{
                                                    call    :itm.value[0].chart.call,
                                                    data    :itm.value[0].chart.data
                                                }}

                                            />

                                        </ReportSection>

                                    :




                                    //######################################################
                                    //######################################################
                                    //svg chart
                                    
                                    itm.print === 'svg_donut_chart'
                                    ?
                                        <ReportSection
                                        id={"row"+i+"_section"}
                                        >

                                            <SvgDonutChart
                                            
                                                id={"row"+i+"_chart"}
                                                data={itm.value} 

                                            />

                                        </ReportSection>

                                    :




                                    //######################################################
                                    //######################################################
                                    //svg donut chart modern
                                    
                                    itm.print === 'svg_donut_chart_modern'
                                    ?
                                        <ReportSection
                                        id={"row"+i+"_section"}
                                        >

                                            <SvgDonutChartModern
                                            
                                                id={"row"+i+"_chart"}
                                                data={itm.value} 

                                            />

                                        </ReportSection>

                                    :





                                    //######################################################
                                    //######################################################
                                    //gauge
                                    
                                    itm.print === 'gauge1'
                                    ?
                                        <ReportSection
                                        id={"row"+i+"_section"}
                                        >

                                            <Gauge1
                                            
                                                id={"row"+i+"_gauge"}
                                                data={itm.value} 

                                            />

                                        </ReportSection>

                                    :


                                    //######################################################
                                    //######################################################
                                    //gauge
                                    
                                    itm.print === 'gauge2'
                                    ?
                                        <ReportSection
                                        id={"row"+i+"_section"}
                                        >

                                            <Gauge2
                                            
                                                id={"row"+i+"_gauge"}
                                                data={itm.value} 

                                            />

                                        </ReportSection>

                                    :



                                    //######################################################
                                    //######################################################
                                    //gauge
                                    
                                    itm.print === 'gauge3'
                                    ?
                                        <ReportSection
                                        id={"row"+i+"_section"}
                                        >

                                            <Gauge3
                                            
                                                id={"row"+i+"_gauge"}
                                                data={itm.value} 

                                            />

                                        </ReportSection>

                                    :


                                    //######################################################
                                    //######################################################
                                    //gauge
                                    
                                    itm.print === 'gauge4'
                                    ?
                                        <ReportSection
                                        id={"row"+i+"_section"}
                                        >

                                            <Gauge4
                                            
                                                id={"row"+i+"_gauge"}
                                                data={itm.value} 

                                            />

                                        </ReportSection>

                                    :




                                    //######################################################
                                    //######################################################
                                    //gauge
                                    
                                    itm.print === 'temperature_gauge'
                                    ?
                                        <ReportSection
                                        id={"row"+i+"_section"}
                                        >

                                            <TemperatureGauge
                                            
                                                id={"row"+i+"_gauge"}
                                                data={itm.value} 

                                            />

                                        </ReportSection>

                                    :







                                    //######################################################
                                    //######################################################
                                    //SINGLE
                                    

                                    itm.print === 'single'
                                    ?

                                        <ReportSection>

                                            {
                                            itm.value.map((valObj, k) => (

                                                <div key={k}>

                                                    {
                                                    valObj.chartType === 'horizontal'
                                                    ?
                                                        <ReportBox
                                                        width={"100%"}
                                                        >
                                                            <HrzBarChart
                                                            chartLines={valObj.chartLines}
                                                            markersColor={valObj.markersColor}
                                                            // markersPrefix={valObj.markersPrefix}
                                                            // markersSuffix={valObj.markersSuffix}
                                                            linesColor={valObj.linesColor}
                                                            textColor={valObj.textColor}
                                                            data={valObj.data}
                                                            >
                                                            </HrzBarChart>
                                            
                                                        </ReportBox>

                                                    :
                                                    valObj.chartType === 'vertical'
                                                    ?

                                                        <ReportBox
                                                        width={"100%"}
                                                        >
                                                            <VrtBarChart
                                                            chartLines={valObj.chartLines}
                                                            markersDif={valObj.markersDif}
                                                            markersColor={valObj.markersColor}
                                                            markersPrefix={valObj.markersPrefix}
                                                            markersSuffix={valObj.markersSuffix}
                                                            linesColor={valObj.linesColor}
                                                            textColor={valObj.textColor}
                                                            data={valObj.data}
                                                            >
                                                            </VrtBarChart>
                                            
                                                        </ReportBox>

                                                    :null
                                                    }

                                                </div>

                                            ))
                                            }

                                        </ReportSection>


                                    :





                            
                                    //######################################################
                                    //######################################################
                                    //GRAPHIC

                                    itm.print === 'graphic'
                                    ?

                                        <ReportSection>

                                            {
                                            itm.value.map((valObj, k) => (

                                                <div key={k}>

                                                    <ReportBox
                                                    //backgroundColor={'lightgray'}
                                                    width={valObj.boxWidth}
                                                    height={valObj.boxHeight+"px"}
                                                    >
                                                        <GraphicComp
                                                            
                                                            id={"row"+i+"-"+j+"_graphic_img"}
                                                            data={valObj}
                                                        
                                                        />

                                                    </ReportBox>

                                                </div>

                                            ))
                                            }

                                        </ReportSection>



                                    :






                                    //######################################################
                                    //######################################################
                                    //BAR CHARTS
                                    
                                    itm.print === 'singleBarChart'
                                    ?

                                        <ReportSection>

                                            {
                                            itm.value.map((valObj, k) => (

                                                <div key={k}>

                                                    {
                                                    valObj.chartType === 'horizontal'
                                                    ?
                                                        <ReportBox
                                                        width={"100%"}
                                                        >
                                                            <HrzBarChart
                                                            chartLines={valObj.chartLines}
                                                            markersColor={valObj.markersColor}
                                                            linesColor={valObj.linesColor}
                                                            textColor={valObj.textColor}
                                                            data={valObj.data}
                                                            >
                                                            </HrzBarChart>
                                            
                                                        </ReportBox>

                                                    :
                                                    valObj.chartType === 'vertical'
                                                    ?

                                                        <ReportBox
                                                        width={"100%"}
                                                        >
                                                            <VrtBarChart
                                                            chartHeight={valObj.chartHeight}
                                                            chartLines={valObj.chartLines}
                                                            markersDif={valObj.markersDif}
                                                            markersColor={valObj.markersColor}
                                                            markersPrefix={valObj.markersPrefix}
                                                            markersSuffix={valObj.markersSuffix}
                                                            linesColor={valObj.linesColor}
                                                            textColor={valObj.textColor}
                                                            data={valObj.data}
                                                            >
                                                            </VrtBarChart>
                                            
                                                        </ReportBox>

                                                    :null
                                                    }

                                                </div>

                                            ))
                                            }

                                        </ReportSection>


                                    :
                                    itm.print === 'doubleBarChart'
                                    ?


                                        <ReportSection>

                                            {
                                            itm.value.map((valObj, k) => (

                                                <div key={k}>

                                                    {
                                                    valObj.chartType === 'horizontal'
                                                    ?
                                                        <ReportBox
                                                        width={"50%"}
                                                        >
                                                            <HrzBarChart
                                                            chartLines={valObj.chartLines}
                                                            markersColor={valObj.markersColor}
                                                            linesColor={valObj.linesColor}
                                                            textColor={valObj.textColor}
                                                            data={valObj.data}
                                                            >
                                                            </HrzBarChart>
                                            
                                                        </ReportBox>

                                                    :
                                                    valObj.chartType === 'vertical'
                                                    ?

                                                        <ReportBox
                                                        width={"50%"}
                                                        >
                                                            <VrtBarChart
                                                            chartLines={valObj.chartLines}
                                                            markersDif={valObj.markersDif}
                                                            markersColor={valObj.markersColor}
                                                            markersPrefix={valObj.markersPrefix}
                                                            markersSuffix={valObj.markersSuffix}
                                                            linesColor={valObj.linesColor}
                                                            textColor={valObj.textColor}
                                                            data={valObj.data}
                                                            >
                                                            </VrtBarChart>
                                            
                                                        </ReportBox>

                                                    :null
                                                    }

                                                </div>

                                            ))
                                            }

                                        </ReportSection>



                                    :

    

                                    //######################################################
                                    //######################################################
                                    //DONUT CHART
                                    
                                    itm.print === 'donutChart'
                                    ?

                                        <ReportSection>

                                            {
                                            itm.value.map((valObj, k) => (

                                                <div key={k}>

                                                    {
                                                    valObj.donutType === 'static'
                                                    ?
                                                        <ReportBox
                                                        width={"50%"}
                                                        >
                                                            <StaticDonutChart
                                                            data={valObj}
                                                            >
                                                            </StaticDonutChart>
                                            
                                                        </ReportBox>

                                                    :
                                                    valObj.donutType === 'dynamic'
                                                    ?

                                                        <ReportBox
                                                        width={"50%"}
                                                        >
                                                            <StaticDonutChart
                                                            data={valObj}
                                                            >
                                                            </StaticDonutChart>
                                            
                                                        </ReportBox>

                                                    :null
                                                    }

                                                </div>

                                            ))
                                            }

                                        </ReportSection>

                                    :



                                    


                                    //######################################################
                                    //######################################################
                                    //report_box

                                    itm.print === 'report_box'
                                    ?

                                        <ReportSection
                                        //backgroundColor={'green'}
                                        >

                                            {
                                            lessThanThresh
                                            ?

                                                itm.value.map((valObj, k) => (

                                                    <div key={k}>

                                                        {
                                                        valObj.boxCall === 'graphic'
                                                        ?

                                                            <ReportBox
                                                            //backgroundColor={'lightyellow'}
                                                            width={"100%"}
                                                            height={"auto"}
                                                            //height={valObj.boxHeight+"px"}
                                                            >
                                                                <GraphicComp 
                                                                    
                                                                    id={"row"+i+"-"+j+"_box_img"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj}
                                                                
                                                                />

                                                            </ReportBox>
                                                    

                                                        :
                                                        valObj.boxCall === 'gauge1'
                                                        ?

                                                            <ReportBox
                                                            id={"row"+i+"-"+j+"_box"}
                                                            //backgroundColor={'lavender'}
                                                            width={"100%"}
                                                            height={"auto"}
                                                            padding={"0 0 20px 0"}

                                                            >
                                                                <Gauge1
                                                                    
                                                                    refid={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj.value}
                                                                
                                                                />


                                                            </ReportBox>
                                                    

                                                        :
                                                        valObj.boxCall === 'gauge2'
                                                        ?

                                                            <ReportBox
                                                            id={"row"+i+"-"+j+"_box"}
                                                            //backgroundColor={'lavender'}
                                                            width={"100%"}
                                                            height={"auto"}
                                                            padding={"0 0 20px 0"}

                                                            >
                                                                <Gauge2
                                                                    
                                                                    refid={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj.value}
                                                                
                                                                />


                                                            </ReportBox>
                                                    

                                                        :

                                                        valObj.boxCall === 'gauge3'
                                                        ?

                                                            <ReportBox
                                                            id={"row"+i+"-"+j+"_box"}
                                                            //backgroundColor={'lavender'}
                                                            width={"100%"}
                                                            height={"auto"}
                                                            padding={"0 0 20px 0"}

                                                            >
                                                                <Gauge3
                                                                    
                                                                    refid={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj.value}
                                                                
                                                                />


                                                            </ReportBox>
                                                    

                                                        :

                                                        valObj.boxCall === 'gauge4'
                                                        ?

                                                            <ReportBox
                                                            id={"row"+i+"-"+j+"_box"}
                                                            //backgroundColor={'lavender'}
                                                            width={"100%"}
                                                            height={"auto"}
                                                            padding={"0 0 20px 0"}

                                                            >
                                                                <Gauge4
                                                                    
                                                                    refid={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj.value}
                                                                
                                                                />


                                                            </ReportBox>
                                                    

                                                        :

                                                        valObj.boxCall === 'temperature_gauge'
                                                        ?

                                                            <ReportBox
                                                            id={"row"+i+"-"+j+"_box"}
                                                            //backgroundColor={'lavender'}
                                                            width={"100%"}
                                                            height={"auto"}
                                                            padding={"0 0 20px 0"}

                                                            >
                                                                <TemperatureGauge
                                                                    
                                                                    refid={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj.value}
                                                                
                                                                />


                                                            </ReportBox>
                                                    

                                                        :



                                                        valObj.boxCall === 'svg_donut_chart'
                                                        ?

                                                            <ReportBox
                                                            id={"row"+i+"-"+j+"_box"}
                                                            //backgroundColor={'lavender'}
                                                            width={"100%"}
                                                            height={"auto"}
                                                            padding={"0 0 20px 0"}

                                                            >
                                                                <SvgDonutChart 
                                                                    
                                                                    refid={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj.value}
                                                                
                                                                />


                                                            </ReportBox>
                                                    

                                                        :
                                                        valObj.boxCall === 'svg_donut_chart_modern'
                                                        ?

                                                            <ReportBox
                                                            id={"row"+i+"-"+j+"_box"}
                                                            //backgroundColor={'lavender'}
                                                            width={"100%"}
                                                            height={"auto"}
                                                            padding={"0 0 20px 0"}

                                                            >
                                                                <SvgDonutChartModern
                                                                    
                                                                    refid={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj.value}
                                                                
                                                                />


                                                            </ReportBox>
                                                    

                                                        :
                                                        valObj.boxCall === 'canvas_chart'
                                                        ?

                                                            <ReportBox
                                                            id={"row"+i+"-"+j+"_box"}
                                                            //backgroundColor={'lavender'}
                                                            width={"100%"}
                                                            height={"auto"}
                                                            padding={"0 0 20px 0"}

                                                            >
                                                                <CanvasChartComp 
                                                                    
                                                                    refid={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj}
                                                                
                                                                />


                                                            </ReportBox>
                                                    

                                                        :
                                                        valObj.boxCall === 'donutChart'
                                                        ?

                                                            <ReportBox
                                                            //backgroundColor={'pink'}
                                                            width={"100%"}
                                                            height={"auto"}
                                                            padding={"0 0 20px 0"}
                                                            >
                                                                <DonutChartComp 
                                                                    
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj}
                                                                
                                                                />

                                                            </ReportBox>
                                                    

                                                        :
                                                        valObj.boxCall === 'txt'
                                                        ?

                                                            <ReportBox
                                                            //backgroundColor={'lightgray'}
                                                            width={"100%"}
                                                            height={"auto"}
                                                            //height={valObj.boxHeight+"px"}
                                                            padding={"0 0 20px 0"}
                                                            >

                                                                <TxtComp 

                                                                    id={"row"+i+"-"+j+"_box_txt"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj} 

                                                                />

                                                            </ReportBox>

                                                        :
                                                        valObj.boxCall === 'ledger'
                                                        ?

                                                            <ReportBox
                                                            //backgroundColor={'lightgray'}
                                                            width={"100%"}
                                                            height={"auto"}
                                                            //height={valObj.boxHeight+"px"}
                                                            >

                                                                <LedgerComp 

                                                                    dark={row.dark}

                                                                    id={"row"+i+"-"+j+"_box_ledger"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj}
                                                        

                                                                />

                                                            </ReportBox>

                                                        :null
                                                        }

                                                    </div>

                                                ))


                                            :


                                                itm.value.map((valObj, k) => (

                                                    <div key={k}>

                                                        {
                                                        valObj.boxCall === 'graphic'
                                                        ?

                                                            <ReportBox
                                                            //backgroundColor={'lightgray'}
                                                            width={valObj.boxWidth}
                                                            height={valObj.boxHeight+"px"}
                                                            >
                                                                <GraphicComp 
                                                                    
                                                                    id={"row"+i+"-"+j+"_box_img"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj}
                                                                
                                                                />

                                                            </ReportBox>
                                                    

                                                        :

                                                        valObj.boxCall === 'gauge1'
                                                        ?

                                                            <ReportBox
                                                            id={"row"+i+"-"+j+"_box"}
                                                            //backgroundColor={'lavender'}
                                                            width={valObj.boxWidth}
                                                            height={valObj.boxHeight+"px"}
                                                            padding={"0 0 0 0"}

                                                            >
                                                                <Gauge1
                                                                    
                                                                    refid={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj.value}
                                                                
                                                                />


                                                            </ReportBox>
                                                    

                                                        :

                                                        valObj.boxCall === 'gauge2'
                                                        ?

                                                            <ReportBox
                                                            id={"row"+i+"-"+j+"_box"}
                                                            //backgroundColor={'lavender'}
                                                            width={valObj.boxWidth}
                                                            height={valObj.boxHeight+"px"}
                                                            padding={"0 0 0 0"}

                                                            >
                                                                <Gauge2
                                                                    
                                                                    refid={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj.value}
                                                                
                                                                />


                                                            </ReportBox>
                                                    

                                                        :


                                                        valObj.boxCall === 'gauge3'
                                                        ?

                                                            <ReportBox
                                                            id={"row"+i+"-"+j+"_box"}
                                                            //backgroundColor={'lavender'}
                                                            width={valObj.boxWidth}
                                                            height={valObj.boxHeight+"px"}
                                                            padding={"0 0 0 0"}

                                                            >
                                                                <Gauge3
                                                                    
                                                                    refid={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj.value}
                                                                
                                                                />


                                                            </ReportBox>
                                                    

                                                        :

                                                        valObj.boxCall === 'gauge4'
                                                        ?

                                                            <ReportBox
                                                            id={"row"+i+"-"+j+"_box"}
                                                            //backgroundColor={'lavender'}
                                                            width={valObj.boxWidth}
                                                            height={valObj.boxHeight+"px"}
                                                            padding={"0 0 0 0"}

                                                            >
                                                                <Gauge4
                                                                    
                                                                    refid={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj.value}
                                                                
                                                                />


                                                            </ReportBox>
                                                    

                                                        :

                                                        valObj.boxCall === 'temperature_gauge'
                                                        ?

                                                            <ReportBox
                                                            id={"row"+i+"-"+j+"_box"}
                                                            //backgroundColor={'lavender'}
                                                            width={valObj.boxWidth}
                                                            height={valObj.boxHeight+"px"}
                                                            padding={valObj.boxPadding}
                                                            margin={valObj.boxMargin}

                                                            >
                                                                <TemperatureGauge
                                                                    
                                                                    refid={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj.value}
                                                                
                                                                />


                                                            </ReportBox>
                                                    

                                                        :



                                                        valObj.boxCall === 'svg_donut_chart'
                                                        ?

                                                            <ReportBox
                                                            id={"row"+i+"-"+j+"_box"}
                                                            //backgroundColor={'lavender'}
                                                            width={valObj.boxWidth}
                                                            height={valObj.boxHeight+"px"}
                                                            padding={valObj.boxPadding}
                                                            margin={valObj.boxMargin}

                                                            >
                                                                <SvgDonutChart 
                                                                    
                                                                    refid={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj.value}
                                                                
                                                                />


                                                            </ReportBox>
                                                    

                                                        :
                                                        valObj.boxCall === 'svg_donut_chart_modern'
                                                        ?

                                                            <ReportBox
                                                            id={"row"+i+"-"+j+"_box"}
                                                            //backgroundColor={'lavender'}
                                                            width={valObj.boxWidth}
                                                            height={valObj.boxHeight+"px"}
                                                            padding={valObj.boxPadding}
                                                            margin={valObj.boxMargin}
                                                            >
                                                                <SvgDonutChartModern 
                                                                    
                                                                    refid={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj.value}
                                                                
                                                                />


                                                            </ReportBox>
                                                    

                                                        :
                                                        valObj.boxCall === 'canvas_chart'
                                                        ?

                                                            <ReportBox
                                                            id={"row"+i+"-"+j+"_box"}
                                                            //backgroundColor={'lavender'}
                                                            width={valObj.boxWidth}
                                                            height={valObj.boxHeight+"px"}
                                                            padding={valObj.boxPadding}
                                                            margin={valObj.boxMargin}

                                                            >
                                                                {/* <ChartComp 
                                                                    
                                                                    refid={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj}
                                                                
                                                                /> */}


                                                                <CanvasChart
                                                                    
                                                                    parentId={"row"+i+"-"+j+"_box"}
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                
                                                                    backgroundColor = {valObj.value.backgroundColor}
                                                                    border={valObj.value.border}
                                                                    width={valObj.value.width}
                                                                    maxHeight={valObj.value.maxHeight}
                                                                    margin={valObj.value.margin}
                                                
                                                                    primeHandle={(v) => props.handle(v)}
                                                                    drawCheckerBoard = {valObj.value.drawCheckerBoard}
                                                                    draw = {false}   
                                                                    render = {false}
                                                                    chart = {{
                                                                        call    :valObj.value.chart.call,
                                                                        data    :valObj.value.chart.data
                                                                    }}

                                                                />



                                                            </ReportBox>
                                                    

                                                        :
                                                        valObj.boxCall === 'donutChart'
                                                        ?

                                                            <ReportBox
                                                            //backgroundColor={'lavender'}
                                                            width={valObj.boxWidth}
                                                            height={valObj.boxHeight+"px"}
                                                            padding={valObj.boxPadding}
                                                            margin={valObj.boxMargin}
                                                            >
                                                                <DonutChartComp 
                                                                    
                                                                    id={"row"+i+"-"+j+"_box_chart"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj}
                                                                
                                                                />

                                                            </ReportBox>
                                                    

                                                        :
                                                        valObj.boxCall === 'txt'
                                                        ?

                                                            <ReportBox
                                                            //backgroundColor={'lightyellow'}
                                                            width={valObj.boxWidth}
                                                            height={valObj.boxHeight+"px"}
                                                            padding={valObj.boxPadding}
                                                            margin={valObj.boxMargin}
                                                            >

                                                                <TxtComp 

                                                                    id={"row"+i+"-"+j+"_box_txt"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj} 

                                                                />

                                                            </ReportBox>

                                                        :
                                                        valObj.boxCall === 'ledger'
                                                        ?

                                                            <ReportBox
                                                            //backgroundColor={'lightgray'}
                                                            width={valObj.boxWidth}
                                                            height={valObj.boxHeight+"px"}
                                                            padding={valObj.boxPadding}
                                                            margin={valObj.boxMargin}
                                                            >

                                                                <LedgerComp 

                                                                    dark={row.dark}

                                                                    id={"row"+i+"-"+j+"_box_ledger"}
                                                                    lessThanThresh={lessThanThresh}    
                                                                    data={valObj} 
                                          

                                                                />

                                                            </ReportBox>

                                                        :null
                                                        }

                                                    </div>

                                                ))



                                            }

                                        </ReportSection>



                                
                                    :null
        
                                    }




                                </div>

                            ))



                        :



                            <ReportMessage>

                                {"No Items Found"}

                            </ReportMessage>



                            // <ReportSection>

                            //     <ReportGraphic>

                            //         {"Graphic"}

                            //     </ReportGraphic>

                            // </ReportSection>





                            // sectionCall === 'graphic'
                            // ?

                            //     <ReportSection>

                            //         <ReportGraphic>

                            //             {"Graphic"}

                            //         </ReportGraphic>

                            //     </ReportSection>



                            // :
                            // sectionCall === 'lineitems'
                            // ?

                            //     <ReportSection>

                            //         <ReportGraphic>

                            //             {"Line Items"}

                            //         </ReportGraphic>

                            //     </ReportSection>


                            // :null





                    :null

                    }

                </ReportRow>


            ))}

        </Report>



    )


    

}

export default Index;


